'use strict';
angular
    .module('ramperv3')
    .service('AcessosService', ['$rootScope', '$q', '$http', 'RAMPER_V3_API_BASE_URL', function ($rootScope, $q, $http, RAMPER_V3_API_BASE_URL) {
        var time;
        var login;
        $rootScope.ramperV3ApiBaseUrl = RAMPER_V3_API_BASE_URL;
        function setAcessos(dadosTime) {
            time = dadosTime;
            var dados = {
                time: time,
            };
            $http({
                method: "GET",
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/acessos-time/").concat(time._id),
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
            })
                .then(function (response) {
                $rootScope.$emit("acessos-time-salvos", response.data);
            })
                .catch(function (error) {
                $rootScope.$emit("acessos-time-salvos", null);
            });
        }
        return {
            setAcessos: setAcessos,
        };
    }]);
