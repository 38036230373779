angular.module('ramperv3')
    .controller('ChatFormularioController', ['$rootScope', '$scope', '$location', function ($rootScope, $scope, $location) {
        $rootScope.mostrarMenu = null;
        $rootScope.loaderAtivo = null;
        var idLinkNomeEmpresaB64 = document.URL.split('chat/assinatura/')[1];
        var decodedEmpresa = decodeURI(atob(idLinkNomeEmpresaB64).split('-')[1]);
        $scope.nomeEmpresa = decodedEmpresa;
        var objectIdLink = atob(idLinkNomeEmpresaB64).split('-')[0];
        $scope.onlyletters = /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/;
        $scope.onlyEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        var criarCookie = function () {
            var _cookie = 'chatForm_ramper';
            gravarCookie(_cookie, $scope.nomeProspect +
                "#EMA_" + $scope.emailProspect, 1, document.domain.indexOf('localhost') !== -1 ? 'localhost' : '.ramper.com.br');
        };
        var enviarForm = function () {
            if (!verificarCokie) {
                criarCookie();
            }
            mixpanel.identify($scope.emailProspect);
            mixpanel.people.set({
                "$email": $scope.emailProspect,
                "$name": $scope.nomeProspect,
                "Prospect": "Prospect",
                "$created": '',
                "Status": '',
                "TipoUsuario": '',
                "Envios": '',
                "EnviosDisponiveis": '',
                "Capturas": '',
                "CapturasDisponiveis": '',
                "Versao": 'V3',
                "CRM": '',
                "Pre Vendas": '',
                "Inbound": '',
                "Inadimplente": '',
                "Dias inadimplente": '',
                "SuperLogica": '',
                "Cs Responsável": '',
                "Intercom": '',
                "UltimoLogin": '',
                "UltimaCaptura": '',
                "UltimoDisparo": '',
                "UltimaResposta": '',
                "UltimaCadenciaCriada": '',
                "QuantidadeCadenciaAtiva": '',
                "Plano": ''
            });
            mixpanel.track("Prospect | Enviou o formulário preenchido", {
                "Local_IP": $rootScope.ip
            });
            $rootScope.loaderAtivo = true;
            $location.path('chat/!' + objectIdLink);
        };
        var _cookie = 'chatForm_ramper';
        var verificarCokie = lerCookie(_cookie);
        if (verificarCokie) {
            var nome = verificarCokie.split('#EMA_')[0];
            var email = verificarCokie.split('#EMA_')[1];
            $scope.nomeProspect = nome;
            $scope.emailProspect = email;
            criarCookie();
            enviarForm();
        }
        ga('set', 'page', document.location.hash);
        ga('send', 'pageview');
        $scope.noOnlyNumbers = function () {
            var valorInpEmail = $scope.formulario.email.$viewValue;
            var splitValorEmail = valorInpEmail.split('@');
            var valorAntesArroba = Number(splitValorEmail[0]);
            if (splitValorEmail[1] != null || splitValorEmail[1] != undefined) {
                var valorDepoisArroba = splitValorEmail[1].split('.');
                var dominioEmail = dominioBloqueado(valorDepoisArroba[0]);
                var splitHifenAposArroba = valorDepoisArroba[0].split('-');
            }
            if (isNaN(valorAntesArroba) === false || dominioEmail === false || splitHifenAposArroba.length >= 3) {
                $scope.formulario.email.$invalid = true;
                $scope.formulario.email.$error = { pattern: true };
            }
        };
        var dominioBloqueado = function (email) {
            var dominiosBloqueados = ['hotmail', 'outlook', 'yahoo', 'gmail', 'googlemail', 'live', 'msn', 'ymail', 'aol', 'uol', 'bol', 'globo', 'globomail', 'zipmail', 'terra', 'ig', 'r7'];
            if (dominiosBloqueados.indexOf(email) === -1) {
                return true;
            }
            return false;
        };
        $scope.submitForm = function () {
            if ($scope.formulario.nome.$invalid || $scope.formulario.email.$invalid) {
                return;
            }
            enviarForm();
        };
    }]);
