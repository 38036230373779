var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
angular.module('ramperv3')
    .controller('ConexaoController', ['$rootScope', '$scope', '$http', '$interval', '$locale', '$filter', 'FormEngageService', 'JanelaDeLigacaoService', 'VoipService', 'MonsterToasterService', function ($rootScope, $scope, $http, $interval, $locale, $filter, FormEngageService, JanelaDeLigacaoService, VoipService, MonsterToasterService) {
        var _this = this;
        ga('set', 'page', document.location.hash);
        ga('send', 'pageview');
        setTimeout(function () {
            $scope.integracaoInbound = $rootScope.intInboundLogin;
            $scope.integracaoCrm = $rootScope.intCrmLogin;
            $scope.integracaoPreVenda = $rootScope.intPreVendaLogin;
        }, 1500);
        $rootScope.redirectConexao();
        $locale.NUMBER_FORMATS.GROUP_SEP = '.';
        var _id = 0;
        $scope.bucket = $rootScope.bucket;
        $scope.txtTecladoLigacao = 'Teclado';
        $scope.qtdAbriuEmail = 'NE';
        $scope.qtdIntervaloAbertura = 'NA';
        $scope.tempoJanelaAtencao = 'NT';
        $scope.tecladoAtivado = false;
        $scope.ligacaoMutada = false;
        $scope.abrirSubMenu = false;
        $scope.historicoENotasDialer = true;
        $scope.semParametros = false;
        $scope.podeDesligar = false;
        $scope.tocarAudioSelecionado = false;
        $scope.mostrarDivAbaixo = false;
        $scope.semContatosEmailsAbertos = false;
        $scope.mostrarDivEmail = false;
        $scope.semNotas = true;
        $scope.txtImgAudio = 'alto';
        $scope.BuscarContatoDialer = '';
        $scope.mostraAnalytics = false;
        $scope.mostraModalFiltros = false;
        $scope.popupFiltrarCadencias = false;
        $scope.filtroSegmentacaoAberto = false;
        $scope.contadorEmailsAbertos = 0;
        $scope.filtroCadenciaSegAberto = false;
        $scope.filtroUsuariosSegAberto = false;
        $scope.valorConversaoJanelaConexao = 0;
        $scope.contadorJanelaConexao = 0;
        $scope.listaUsuarios = [];
        $scope.telaConversao = "";
        $scope.contadorForaJanelaConexao = 0;
        $scope.arrayDiasConversao = [];
        $scope.arrayHorasConversao = [];
        $scope.popupFiltrar = false;
        $scope.contJanelaDeConexao = 0;
        $scope.graficoDiario = true;
        $scope.graficoHora = false;
        $scope.arrayGraficoDiarioAnalytics = [];
        $scope.arrayGraficoHorarioAnalytics = [];
        $scope.calendarAnalytics = false;
        $scope.removerFiltrosModalConfirmacao = false;
        $scope.removerContatoModalConfirmacao = false;
        $scope.converterContatoModalConfirmacao = false;
        $scope.agendarRetornoModalConfirmacao = false;
        $scope.ligacaoRealizadaModalConfirmacao = false;
        $scope.enviarParaIntegracaoModalConfirmacao = false;
        $scope.removerContatoAoEnviarIntegracao = false;
        $scope.removerNotasModalConfirmacao = false;
        $scope.listaSegmentacoesContato = [];
        $scope.listaCadenciasContato = [];
        $scope.listaDisparosEmailsContato = [];
        $scope.listaDadosCadastraisSegmContato = [];
        $scope.searchEmailAbertoValue = '';
        $scope.orderBy = "qntAbertura:asc";
        $scope.pagEmailsAbertos = false;
        $scope.naoChamaOif = false;
        $scope.habilitarFazerLigacao = $rootScope.saldoSemFormatar > 0 && $rootScope.permissoes.ligacoes.makeCall;
        $scope.meta = { offset: 0, limit: 10, total: 0, hasNextPage: false, hasPreviousPage: false, totalPages: 0, paginaAtual: 1 },
            $scope.adicionarNotas = false;
        $scope.existeNotas = false;
        $scope.minhasNotasTexto = '';
        $scope.onCancelNotas = function () {
            $scope.adicionarNotas = false;
            $scope.minhasNotasTexto = '';
        };
        $scope.onExcluirNotas = function () {
            $scope.removerNotasModalConfirmacao = true;
        };
        $scope.onRemoverNotas = function () {
            $scope.removerNotasModalConfirmacao = false;
            $scope.adicionarNotas = false;
            $scope.existeNotas = false;
            $scope.minhasNotasTexto = '';
            mixpanel.track("JANELA DE LIGAÇÃO | Removeu as notas do contato", { Local_IP: $rootScope.ip });
            $scope.salvarNotas();
        };
        $scope.salvarNotas = function () {
            $rootScope.monsterShowLoading = true;
            var data = { notas: $scope.minhasNotasTexto };
            return $http({
                method: "PATCH",
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/contatos/").concat($scope.contatoSelecionado.objectIdContato),
                data: data,
            }).then(function (response) {
                if (response.status == 204) {
                    MonsterToasterService.showSuccessToaster("", "Notas do contato atualizada com sucesso!", 6000, 350);
                }
                mixpanel.track("JANELA DE LIGAÇÃO | Atualizou as notas do contato", { Local_IP: $rootScope.ip });
                $scope.existeNotas = $scope.minhasNotasTexto && $scope.minhasNotasTexto != '' ? true : false;
                $scope.detalhesContato.notas = $scope.minhasNotasTexto;
                $rootScope.monsterShowLoading = false;
            }, function () {
                MonsterToasterService.showErrorToaster("", "Erro ao atualizar notas do contato. Tente novamente.", 6000);
                $rootScope.monsterShowLoading = false;
            });
        };
        $scope.pages = function () {
            var pages = Array.from({ length: $scope.meta.totalPages }, function (_, i) { return i + 1; });
            var start = $scope.meta.paginaAtual - 3 >= 0 ? $scope.meta.paginaAtual - 3 : 0;
            var end = $scope.meta.paginaAtual + 2;
            return pages.slice(start, end);
        };
        $scope.paginacaoAnterior = function () {
            $scope.meta.offset = $scope.meta.offset - $scope.meta.limit;
            $scope.mostrarContatos('abertos');
        };
        $scope.paginacaoSeguinte = function () {
            $scope.meta.offset = $scope.meta.offset + $scope.meta.limit;
            $scope.mostrarContatos('abertos');
        };
        $scope.paginacaoAtual = function (page) {
            $scope.meta.offset = (page - 1) * $scope.meta.limit;
            $scope.mostrarContatos('abertos');
        };
        $scope.definirPagina = function (page) {
            $scope.meta.offset = 0;
            $scope.searchEmailAbertoValue = '';
            $scope.mostrarContatos(page);
        };
        var timeout = null;
        $scope.searchEmailAberto = function (value) {
            clearTimeout(timeout);
            timeout = setTimeout(function () {
                if (value.target.value == '' || value.target.value.trim().length >= 3) {
                    $scope.searchEmailAbertoValue = value.target.value;
                    $scope.meta.offset = 0;
                    $scope.mostrarContatos('abertos');
                }
            }, 500);
        };
        $('#idFundoPopupDialer').hide();
        var _tempo = 0;
        $scope.arrayDialerNotasTemp = [
            { id: _id++, tituloTextoNotas: 'Reunião da semana passada.', descricaoTextoNotas: 'Prévia do texto, Prévia do texto, Prévia do texto, Prévia do texto 0', descricaoTudo: 'Reunião Segunda. Prévia do texto, Prévia do texto, Prévia do texto, Prévia do texto 0' },
            { id: _id++, tituloTextoNotas: 'Reunião da terça.', descricaoTextoNotas: 'Prévia do texto, Prévia do texto, Prévia do texto, Prévia do texto 1', descricaoTudo: 'Reunião Terça. Prévia do texto, Prévia do texto, Prévia do texto, Prévia do texto 1' },
            { id: _id++, tituloTextoNotas: 'Reunião da quarta.', descricaoTextoNotas: 'Prévia do texto, Prévia do texto, Prévia do texto, Prévia do texto 2', descricaoTudo: 'Reunião Quarta. Prévia do texto, Prévia do texto, Prévia do texto, Prévia do texto 2' },
            { id: _id++, tituloTextoNotas: 'Reunião da quinta.', descricaoTextoNotas: 'Prévia do texto, Prévia do texto, Prévia do texto, Prévia do texto 3', descricaoTudo: 'Reunião quinta. Prévia do texto, Prévia do texto, Prévia do texto, Prévia do texto 3' },
            { id: _id++, tituloTextoNotas: 'Reunião da sexta.', descricaoTextoNotas: 'Prévia do texto, Prévia do texto, Prévia do texto, Prévia do texto 4', descricaoTudo: 'Reunião sexta. Prévia do texto, Prévia do texto, Prévia do texto, Prévia do texto 4' },
            { id: _id++, tituloTextoNotas: 'Reunião agora.', descricaoTextoNotas: 'Prévia do texto, Prévia do texto, Prévia do texto, Prévia do texto 5', descricaoTudo: 'Reunião agora. Prévia do texto, Prévia do texto, Prévia do texto, Prévia do texto 5' }
        ];
        $scope.divDetalhesAberta = false;
        VoipService.getAccountBalance();
        $scope.abrirWebphone = function (phoneNumber) {
            if ($scope.habilitarFazerLigacao) {
                mixpanel.track("JANELA DE LIGAÇÃO | Clicou no botão ligar da navbar", { Local_IP: $rootScope.ip });
                VoipService.presetWebphone(phoneNumber);
            }
        };
        setTimeout(function () {
            for (var i = 0; i <= $scope.arrayDialerNotasTemp.length; i++) {
                try {
                    var _dialerId = $scope.arrayDialerNotasTemp[i].id;
                }
                catch (e) { }
            }
            ;
            $('#listasNotas' + _dialerId).addClass('addNota');
        });
        getCorConfiabilidade = function (percAcerto) {
            if (percAcerto >= 71)
                return 'rgb(27, 178, 88)';
            if (percAcerto >= 41)
                return 'rgb(239, 183, 52)';
            if (percAcerto >= 1)
                return 'rgb(224, 71, 71)';
            if (percAcerto == 0)
                return 'rgb(122, 122, 122)';
        };
        $scope.carregarEmailsAbertos = function () {
            $scope.emailsAbertos = [];
            $rootScope.monsterShowLoading = true;
            $scope.semParametros = false;
            $scope.listaEmailsAbertos = true;
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/conexao/lista-de-emails-abertos"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                params: {
                    sort: $scope.orderBy,
                    abriuEmail: $scope.qtdAbriuEmail,
                    offset: $scope.meta.offset,
                    limit: $scope.meta.limit,
                    search: encodeURI($scope.searchEmailAbertoValue)
                }
            }).then(function (response) {
                mixpanel.track("JANELA DE LIGAÇÃO | Entrou na janela de emails abertos", { Local_IP: $rootScope.ip });
                if (response.data.data.length > 0) {
                    $scope.semContatosEmailsAbertos = false;
                    var now = new Date().getTime();
                    var tempoJanela = document.getElementById("idSpanFiltroTempoJanelaAtencao").innerText.split(' ')[0];
                    var opcaoHoraJanela = { "12": 720, "24": 1440, "48": 2880, "72": 4320, "default": tempoJanela };
                    tempoJanela = opcaoHoraJanela[tempoJanela] || opcaoHoraJanela.default;
                    $scope.meta = response.data.meta;
                    $scope.meta.paginaAtual = $scope.meta.offset / $scope.meta.limit + 1;
                    response.data.data.forEach(function (contato) {
                        var countDownDate = new Date(contato.dataInicioOportunidade);
                        countDownDate.setMinutes(countDownDate.getMinutes() + new Number(tempoJanela));
                        countDownDate = countDownDate.getTime();
                        distance = (countDownDate - now) > 0 ? true : false;
                        var contatos = {
                            id: contato.objectIdContato,
                            _id: contato.objectIdContato,
                            objectIdCadencia: contato.objectIdCadencia,
                            objectIdContato: contato.objectIdContato,
                            nomeCompleto: contato.nomeCompleto,
                            cargo: contato.cargo,
                            empresa: contato.empresa,
                            cor: getCorConfiabilidade(contato.percAcerto),
                            email: contato.email,
                            telefone: contato.telefone ? contato.telefone : null,
                            cadencia: contato.cadencia,
                            ultimaAbertura: $scope.formatarData(contato.dataUltimaAbertura),
                            qtdEnviados: contato.enviados,
                            txtEnvios: contato.enviados == 1 ? 'enviado' : 'enviados',
                            qtdAberturas: contato.aberturas,
                            txtAberturas: contato.aberturas == 1 ? 'abertura' : 'aberturas',
                            origem: contato.origem,
                            salvoPor: contato.salvoPor,
                            ultimaAcao: contato.ultimaAcao,
                            oportunidade: distance,
                        };
                        if (contatos.ultimaAcao) {
                            contatos.ultimaAcao.data = $scope.formatarData(contatos.ultimaAcao.data);
                            contatos.ultimaAcao.dataAgendada = $scope.formatarData(contato.ultimaAcao.dataAgendada);
                        }
                        $scope.emailsAbertos.push(contatos);
                    });
                }
                else {
                    $scope.semContatosEmailsAbertos = true;
                    if (!$scope.qtdAbriuEmail) {
                        $scope.semParametros = true;
                    }
                    $scope.listaEmailsAbertos = false;
                }
                $('#pagConexao').removeClass('blur');
                $('#menuPrincipal').removeClass('blur');
                $rootScope.monsterShowLoading = false;
            });
        };
        $scope.abrirdetalhesTexto = function (idInput) {
            $scope.textoCaixaNotas = idInput;
            $('#listasNotas' + idInput).addClass('addNota');
            $('#dialerTextDescricao' + idInput).show();
            $('#conteudoNotasEHistorico' + idInput).show();
            $scope.arrayDialerNotasTemp.forEach(function (nota) {
                if (idInput != nota.id) {
                    $('#dialerTextDescricao' + nota.id).hide();
                    $('#conteudoNotasEHistorico' + nota.id).hide();
                    $('#listasNotas' + nota.id).removeClass('addNota');
                }
            });
        };
        $scope.addNovaNota = function () {
            $scope.semNotas = true;
            $scope.arrayDialerNotasTemp.push({ id: _id++, tituloTextoNotas: 'Sem título', descricaoTextoNotas: 'Sem descrição' });
            for (var i = 0; i <= $scope.arrayDialerNotasTemp.length; i++) {
                try {
                    var _dialerId = $scope.arrayDialerNotasTemp[i].id;
                    $('#listasNotas' + _dialerId).removeClass('addNota');
                }
                catch (e) { }
            }
            ;
            setTimeout(function () {
                $('#listasNotas' + _dialerId).addClass('addNota');
            });
        };
        $scope.jogarNoLixo = function () {
            $scope.arrayDialerNotasTemp.pop();
            if ($scope.arrayDialerNotasTemp.length == 0) {
                $scope.semNotas = false;
            }
        };
        $scope.abrirCriarAnotacao = function () {
            $scope.emptyNotasDialer = false;
        };
        function esconderDivsContatos() {
            $scope.listaJanelaAtencao = false;
            $scope.listaEmailsAbertos = false;
            $scope.semParametros = false;
            $scope.listaEmailsAbertos = false;
            $('#idJanelaAtencao').hide();
            $('#idEmailsAbertos').hide();
        }
        ;
        /**
         * abrirDetalhes - exibe detalhes do contato e exibe na tela de ligacao
         * @param {object} pContato
         * @param {string} telaConversao
         */
        $scope.abrirDetalhes = function (pContato, telaConversao) {
            $scope.telaConversao = telaConversao;
            $scope.minhasNotasTexto = '';
            $scope.contatoSelecionado = pContato;
            $scope.esconderTelasDiscador();
            $scope.primeiraTelaDiscador = true;
            $scope.divDetalhesAberta = true;
            $scope.nomeContatoAberto = pContato.nomeCompleto;
            $scope.cargoContatoAberto = pContato.cargo;
            $scope.localidadeContatoAberto = pContato.localidade;
            $scope.confiabilidadeContatoAberto = pContato.cor;
            $scope.emailContatoAberto = pContato.email;
            $scope.telefoneContatoAberto = $scope.formatarNumero(pContato.telefone);
            $scope.telefoneEmpresaAberto = pContato.telefoneEmpresa != '' ? pContato.telefoneEmpresa : pContato.empresaTelefone;
            $scope.empresaEnderecoLinha1 = (pContato.empresaEndereco && pContato.empresaEndereco != '' ? (pContato.empresaEndereco + ', ') : '') +
                (pContato.empresaNumero && pContato.empresaNumero != '' ? (pContato.empresaNumero + ', ') : '') +
                (pContato.empresaComplemento && pContato.empresaComplemento != '' ? (' ' + pContato.empresaComplemento + ' -') : '');
            $scope.empresaEnderecoLinha1 = $scope.empresaEnderecoLinha1.length > 2 ? $scope.empresaEnderecoLinha1.substring(0, $scope.empresaEnderecoLinha1.length - 2) : '';
            $scope.empresaEnderecoLinha2 = (pContato.empresaBairro && pContato.empresaBairro != '' ? (pContato.empresaBairro + ' -') : '') +
                (pContato.empresaCidade && pContato.empresaCidade != '' ? (' ' + pContato.empresaCidade + ' -') : '') +
                (pContato.empresaEstado && pContato.empresaEstado != '' ? (' ' + pContato.empresaEstado + ', ') : '') +
                (pContato.empresaPais && pContato.empresaPais != '' ? (pContato.empresaPais + ', ') : '');
            $scope.empresaEnderecoLinha2 = $scope.empresaEnderecoLinha2.length > 2 ? $scope.empresaEnderecoLinha2.substring(0, $scope.empresaEnderecoLinha2.length - 2) : '';
            $scope.dadosForm = null;
            if (pContato.dadosForm) {
                $scope.dadosForm = FormEngageService.getDadosForm(pContato.dadosForm);
                $scope.outrosDadosForm = FormEngageService.getOutrosDadosForm(pContato.dadosForm);
            }
            if ((!pContato.telefoneEmpresa) || (pContato.telefoneEmpresa == '') || (pContato.telefoneEmpresa == null)) {
                $scope.colocarNumeroDiscador(pContato.telefone);
            }
            else {
                $scope.colocarNumeroDiscador(pContato.telefoneEmpresa);
            }
            consultarDetalhesContato(pContato);
        };
        function consultarDetalhesContato(pContato) {
            $scope.listaSegmentacoesContato = [];
            $scope.listaCadenciasContato = [];
            $scope.listaDisparosEmailsContato = [];
            $scope.listaDadosCadastraisSegmContato = [];
            $scope.detalhesContato = null;
            $scope.linkedin = null;
            $rootScope.monsterShowLoading = true;
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/conexao/contato/").concat(pContato.objectIdContato, "/cadencia/").concat(pContato.objectIdCadencia),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
            }).then(function (rConsulta) {
                var detalhesContato = rConsulta.data;
                $scope.detalhesContato = detalhesContato.contato;
                $scope.empresaContatoAberto = detalhesContato.contato.empresa.nome;
                $scope.detalhesContato.contatoRemovido = $scope.detalhesContato.cadenciasNaoListadasJdl ? $scope.detalhesContato.cadenciasNaoListadasJdl.includes(detalhesContato.cadencia.id) : false;
                $scope.linkedin = ($scope.detalhesContato.linkedin) ? $scope.adicionaHttp($scope.detalhesContato.linkedin) : '';
                if ($scope.detalhesContato.historicoAcoes) {
                    $scope.detalhesContato.historicoAcoes.map(function (acoes) {
                        acoes.dataAgendada = $scope.formatarData(acoes.dataAgendada);
                        acoes.data = $scope.formatarData(acoes.data);
                        return acoes;
                    });
                }
                $scope.listaSegmentacoesContato = [detalhesContato.segmentacao];
                $scope.listaCadenciasContato = [detalhesContato.cadencia];
                for (var i = 0; i < $scope.listaCadenciasContato.length; i++) {
                    if ($scope.listaCadenciasContato[i].status == 'F')
                        $scope.listaCadenciasContato[i].status = 'FINALIZADA';
                    if ($scope.listaCadenciasContato[i].status == 'P')
                        $scope.listaCadenciasContato[i].status = 'PAUSADA';
                    if ($scope.listaCadenciasContato[i].status == 'A')
                        $scope.listaCadenciasContato[i].status = 'ATIVA';
                }
                $scope.listaCadenciasContato[0].disparos.map(function (detalheDisparosEmails) {
                    if ((detalheDisparosEmails.dataResposta) && (detalheDisparosEmails.dataResposta != null) && (detalheDisparosEmails.dataResposta != ''))
                        detalheDisparosEmails.cor = '#2f6d6c';
                    else if ((detalheDisparosEmails.statusEnvio == 'E') && (detalheDisparosEmails.dataResposta == null || !detalheDisparosEmails.dataResposta))
                        detalheDisparosEmails.cor = '#3A96C6';
                    else
                        detalheDisparosEmails.cor = '#b5b5b5';
                    detalheDisparosEmails.dataEnvio = $scope.formatarData(detalheDisparosEmails.dataEnvio);
                    detalheDisparosEmails.dataResposta = $scope.formatarData(detalheDisparosEmails.dataResposta);
                    detalheDisparosEmails.dataAbertura = $scope.formatarData(detalheDisparosEmails.dataAbertura);
                    return detalheDisparosEmails;
                });
                if ($scope.detalhesContato.notas && $scope.detalhesContato.notas != '') {
                    $scope.adicionarNotas = true;
                    $scope.minhasNotasTexto = $scope.detalhesContato.notas;
                    $scope.existeNotas = true;
                }
                $rootScope.monsterShowLoading = false;
            });
        }
        ;
        $scope.formatarData = function (data) {
            return data ? new Date(data).toLocaleDateString('pt-BR') : null;
        };
        $scope.formatarStatus = function (status) {
            switch (status) {
                case 'P':
                    return 'Com interesse';
                case 'U':
                    return 'Neutro';
                case 'S':
                    return 'Sem interesse';
                default:
                    return '';
            }
        };
        $scope.formatarNumero = function (numero) {
            if (numero) {
                var numeroLimpo = numero.replace(/\D/g, '');
                if (numeroLimpo.length === 11) {
                    return "(".concat(numeroLimpo.slice(0, 2), ") ").concat(numeroLimpo[2], " ").concat(numeroLimpo.slice(3, 7), "-").concat(numeroLimpo.slice(7));
                }
                else if (numeroLimpo.length === 10) {
                    return "(".concat(numeroLimpo.slice(0, 2), ") ").concat(numeroLimpo.slice(2, 6), "-").concat(numeroLimpo.slice(6));
                }
                else if (numeroLimpo.length === 9) {
                    return "".concat(numeroLimpo.slice(0, 4), "-").concat(numeroLimpo.slice(4));
                }
            }
            return numeroLimpo;
        };
        $scope.retornarUsuarioSegmentacao = function (pSegmentacao) {
            var usuario = '';
            try {
                var dadosCadastrais = $scope.listaDadosCadastraisSegmContato.find(function (x) { return x.objectIdLogin == pSegmentacao.objectIdLogin; });
                usuario = (dadosCadastrais.nome + ' ' + dadosCadastrais.sobrenome);
            }
            catch (e) {
                usuario = '';
            }
            return usuario;
        };
        $scope.getTimestampTela = function (_id) {
            return getTimestamp(_id);
        };
        $scope.adicionaHttp = function (url) {
            if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
                url = "http://" + url;
            }
            return url;
        };
        $scope.voltarTelaInicialDialer = function () {
            $scope.divDetalhesAberta = false;
            $scope.arrayGraficoDiarioAnalytics = [];
            $scope.arrayGraficoHorarioAnalytics = [];
            $scope.arrayDiasConversao = [];
            $scope.arrayHorasConversao = [];
            $scope.contJanelaDeConexao = 0;
            $('.setaOutrasInformacoes').removeClass("setaRotate");
        };
        $scope.clickNumero = function (pNumeroClick) {
            $scope.textoTelefoneDiscador += pNumeroClick.toString();
        };
        $scope.removerUltimo = function () {
            $scope.textoTelefoneDiscador = $scope.textoTelefoneDiscador.substr(0, $scope.textoTelefoneDiscador.length - 1);
        };
        $('#idTxtTelefone').bind('input propertychange', function () {
            var texto = $(this).val();
            texto = texto.replace(/[^\d]/g, '');
            $(this).val(texto);
        });
        $scope.esconderTelasDiscador = function () {
            $scope.primeiraTelaDiscador = false;
            $scope.segundaTelaDiscador = false;
        };
        var lastTimestamp;
        var intervalHandler;
        var milliseconds = 0;
        var _script = '';
        $scope.fazerChamadaConexao = function () {
            realizarChamada();
        };
        function realizarChamada() {
            if ($scope.telefoneEmpresaAberto) {
                var telEmpresaLigacao = $scope.telefoneEmpresaAberto;
                telEmpresaLigacao = telEmpresaLigacao.replace(/[^\d]/g, '');
            }
            if ($scope.telefoneContatoAberto) {
                var telContatoLigacao = $scope.telefoneContatoAberto;
                telContatoLigacao = telContatoLigacao.replace(/[^\d]/g, '');
            }
            if ($scope.textoTelefoneDiscador == telEmpresaLigacao)
                $scope.mostrarNumeroLigacao = $scope.empresaContatoAberto;
            else if ($scope.textoTelefoneDiscador == telContatoLigacao)
                $scope.mostrarNumeroLigacao = $scope.nomeContatoAberto;
            else
                $scope.mostrarNumeroLigacao = $scope.textoTelefoneDiscador;
            $scope.esconderTelasDiscador();
            $scope.tecladoAtivado = false;
            $scope.segundaTelaDiscador = true;
            $scope.minutos = '00';
            $scope.segundos = '00';
            $scope.ligandoOuChamando = 'Conectando API...';
            if (_script == '') {
                $http({
                    method: 'GET',
                    url: "".concat($rootScope.ramperV3ApiBaseUrl, "/chamada"),
                    headers: {
                        'ramp3r-auth': 'ramp3r-authorization',
                        'ramp3r-l': $rootScope.IDCrypto,
                        'Authorization': 'Bearer ' + $rootScope.Token
                    },
                    params: {
                        numero_destino: $scope.textoTelefoneDiscador
                    }
                }).then(function (rIntegracao) {
                    $('#dvPrimeiraTelaDialer').fadeOut();
                    $('#dvSegundaTelaDialer').fadeIn();
                    var body = document.getElementsByTagName("body")[0];
                    var script = document.createElement('script');
                    script.type = 'text/javascript';
                    script.src = rIntegracao.data;
                    _script = script.src;
                    body.appendChild(script);
                    setTimeout(function () {
                        webphone.contentWindow.postMessage({
                            message: 'conectar',
                            'numero': $scope.textoTelefoneDiscador
                        }, '*');
                        webphone.contentWindow.postMessage({
                            message: 'chamaNumero',
                            'numero': $scope.textoTelefoneDiscador
                        }, '*');
                    }, 3000);
                });
            }
            else {
                $('#dvPrimeiraTelaDialer').fadeOut();
                $('#dvSegundaTelaDialer').fadeIn();
                webphone.contentWindow.postMessage({
                    message: 'conectar',
                    'numero': $scope.textoTelefoneDiscador
                }, '*');
                webphone.contentWindow.postMessage({
                    message: 'chamaNumero',
                    'numero': $scope.textoTelefoneDiscador
                }, '*');
            }
        }
        //  Filtro Usuarios
        // Atualiza valor interno do cronômetro
        function updateTime() {
            var now = moment();
            milliseconds += now.diff(lastTimestamp);
            lastTimestamp = now;
            updateDisplay();
        }
        function mute() {
            webphone.contentWindow.postMessage({
                message: 'mute'
            }, '*');
        }
        // Atualiza display do cronômetro
        function updateDisplay() {
            var seconds = String(moment.duration(milliseconds).seconds());
            var minutes = String(moment.duration(milliseconds).minutes());
            $scope.segundos = seconds.length == 1 ? '0' + seconds : seconds;
            $scope.minutos = minutes.length == 1 ? '0' + minutes : minutes;
        }
        $scope.mudarImgMudo = function () {
            $scope.ligacaoMutada = !$scope.ligacaoMutada;
            if ($scope.ligacaoMutada) {
                $('#imgMicrofone').attr('src', "".concat($rootScope.bucket, "/mic-mudo-gr.png"));
                mute('Mute');
            }
            else {
                $('#imgMicrofone').attr('src', "".concat($rootScope.bucket, "/mic-gr.png"));
                mute('Unmute');
            }
        };
        $scope.fazerLigacaoDireta = function (pContato) {
            $scope.abrirDetalhes(pContato);
            var textoTelContato = pContato.telefone;
            textoTelContato = textoTelContato.replace(/[^\d]/g, '');
            $scope.textoTelefoneDiscador = textoTelContato;
            textoTelContato.length > 0 ? realizarChamada() : '';
        };
        $scope.notasOuHistorico = function (NouH) {
            if (NouH == 'n') {
                $scope.historicoENotasDialer = true;
                $('#idNotasDialer').addClass('bold').addClass('borderBottomNovo');
                $('#idHistoricoDialer').removeClass('bold').removeClass('borderBottomNovo');
            }
            else {
                $scope.historicoENotasDialer = false;
                $('#idHistoricoDialer').addClass('bold').addClass('borderBottomNovo');
                $('#idNotasDialer').removeClass('bold').removeClass('borderBottomNovo');
                ;
            }
        };
        $scope.colocarNumeroDiscador = function (pTel) {
            var textoTelContato = pTel;
            textoTelContato = textoTelContato ? textoTelContato.replace(/[^\d]/g, '') : '';
            $scope.textoTelefoneDiscador = textoTelContato;
        };
        $scope.subMenuConverter = function () {
            $scope.abrirSubMenu = !$scope.abrirSubMenu;
            $('#idSubMenuConverter').show();
        };
        $(document).mouseup(function (e) {
            var container = $('#btnConverterContato');
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                $('#idSubMenuConverter').hide();
                $scope.abrirSubMenu = false;
            }
        });
        $scope.classificacaoInteresse = function (status, idContato) {
            $scope.contadorJanelaConexao = 0;
            $scope.contadorForaJanelaConexao = 0;
            if (status == "S") {
                teladeConversao = $scope.telaConversao;
                $http({
                    method: "POST",
                    url: "".concat($rootScope.ramperV3ApiBaseUrl, "/contatos/converterContato"),
                    headers: {
                        "ramp3r-auth": "ramp3r-authorization",
                        "ramp3r-l": $rootScope.IDCrypto,
                    },
                    data: {
                        status: "S",
                        todos: false,
                        objectIdContato: idContato,
                        origemConversao: "Sem Interesse"
                    }
                });
                if ($rootScope.intInboundLogin) {
                    $scope.enviarIntegracaoGeral(idContato, $rootScope.intInboundLogin, 'I');
                }
            }
            if (status == 'U') {
                teladeConversao = $scope.telaConversao;
                $http({
                    method: 'POST',
                    url: "".concat($rootScope.ramperV3ApiBaseUrl, "/contatos/converterContato"),
                    headers: {
                        'ramp3r-auth': 'ramp3r-authorization',
                        'ramp3r-l': $rootScope.IDCrypto,
                        'Authorization': 'Bearer ' + $rootScope.Token
                    },
                    data: {
                        status: 'U',
                        todos: false,
                        objectIdContato: idContato,
                        origemConversao: teladeConversao
                    }
                });
            }
            if (status == 'P') {
                teladeConversao = $scope.telaConversao;
                $http({
                    method: "POST",
                    url: "".concat($rootScope.ramperV3ApiBaseUrl, "/contatos/converterContato"),
                    headers: {
                        "ramp3r-auth": "ramp3r-authorization",
                        "ramp3r-l": $rootScope.IDCrypto,
                    },
                    data: {
                        status: "P",
                        todos: false,
                        objectIdContato: idContato,
                        origemConversao: teladeConversao
                    }
                });
                if ($rootScope.intCrmLogin) {
                    $scope.enviarIntegracaoGeral(idContato, $rootScope.intCrmLogin, 'C');
                }
                else if ($rootScope.intPreVendaLogin) {
                    $scope.enviarIntegracaoGeral(idContato, $rootScope.intPreVendaLogin, 'P');
                }
            }
        };
        $scope.verificarIntegracao = function () {
            $scope.applicationInbound = '';
            $scope.applicationPreVendas = '';
            $scope.applicationCRM = '';
            $scope.applicationConexao = '';
            $scope.enviarInbound = false;
            $scope.enviarPreVendas = false;
            $scope.enviarCRM = false;
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/integracoes/consultarIntegracoes"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto
                },
                params: {
                    status: 'A'
                }
            }).then(function (rVerificarSeTemIntegracao) {
                if ((rVerificarSeTemIntegracao.data) && (rVerificarSeTemIntegracao.data.length > 0)) {
                    rVerificarSeTemIntegracao.data.forEach(function (iIntegracao) {
                        if (iIntegracao.application == 'R') {
                            $scope.integracaoInbound = iIntegracao;
                            $scope.applicationInbound = iIntegracao.nomeAplicao;
                            $scope.enviarInbound = true;
                        }
                        else if (iIntegracao.application == 'E') {
                            $scope.integracaoPreVendas = iIntegracao;
                            $scope.applicationPreVendas = iIntegracao.nomeAplicao;
                            $scope.enviarPreVendas = true;
                        }
                        else {
                            $scope.integracaoCRM = iIntegracao;
                            $scope.applicationCRM = iIntegracao.nomeAplicao;
                            $scope.enviarCRM = true;
                        }
                    });
                }
            });
        };
        $scope.abrirDivEmbaixo = function (pDados) {
            if (pDados == 't') {
                $scope.mostrarDivAbaixo = !$scope.mostrarDivAbaixo;
                if ($scope.mostrarDivAbaixo) {
                    $('#setaAbrirFecharTel').css('transform', 'rotate(270deg)');
                    _player.currentTime = 0;
                    $scope.tempoTotal = calculateTotalValue(_player.duration);
                    $scope.tempoAtual = '00:00';
                }
                else {
                    $scope.pausarAudio();
                    $('#setaAbrirFecharTel').css('transform', 'rotate(90deg)');
                    document.getElementById('seek-obj').value = 0;
                }
            }
            else {
                $scope.mostrarDivEmail = !$scope.mostrarDivEmail;
                if ($scope.mostrarDivEmail) {
                    $('#setaAbrirFecharEmail').css('transform', 'rotate(270deg)');
                }
                else {
                    $('#setaAbrirFecharEmail').css('transform', 'rotate(90deg)');
                }
            }
        };
        function calculateTotalValue(length) {
            var minutes = Math.floor(length / 60);
            var seconds_int = length - minutes * 60;
            var seconds_str = seconds_int.toString();
            var seconds = seconds_str.substr(0, 2);
            var time = minutes + ':' + seconds;
            return time;
        }
        ;
        $scope.removerFiltros = function () {
            $rootScope.monsterShowLoading = true;
            $('#estrelaVermelha1').removeClass('colorRedImportant');
            $('#estrelaVermelha2').removeClass('colorRedImportant');
            $('#estrelaVermelha3').removeClass('colorRedImportant');
            $scope.camposObrigatorios = false;
            $scope.semParametros = false;
            JanelaDeLigacaoService.removerParametros()
                .then(function () {
                $scope.qtdAbriuEmail = 'NE';
                $scope.qtdIntervaloAbertura = 'NA';
                $scope.tempoJanelaAtencao = 'NT';
                mixpanel.track("JANELA DE LIGAÇÃO | Removeu os parâmetros", { Local_IP: $rootScope.ip });
            })
                .catch(function () { })
                .finally(function () {
                $scope.abrirTela();
                $scope.removerFiltrosModalConfirmacao = false;
                $scope.recarregarPagina();
            });
        };
        $scope.stopEvent = function ($event) {
            $event.stopPropagation();
            $event.preventDefault();
        };
        var contatoParaAgendarRetorno = null;
        $scope.minDateToDatePicker = new Date().toISOString();
        $scope.agendarRetornoData = { data: null };
        $scope.agendarRetornoDropdown = function ($event, contato) {
            $scope.stopEvent($event);
            $scope.agendarRetornoModalConfirmacao = true;
            contatoParaAgendarRetorno = contato;
        };
        $scope.agendamento = function () {
            if (contatoParaAgendarRetorno) {
                var data = { "data": $scope.agendarRetornoData.data };
                $scope.agendarRetorno(data);
            }
        };
        $scope.agendarRetorno = function (data) {
            $scope.agendarRetornoModalConfirmacao = false;
            $rootScope.monsterShowLoading = true;
            return $http({
                method: "POST",
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/contatos/").concat(contatoParaAgendarRetorno.objectIdContato, "/acoes/agendamento/agendar"),
                data: data,
                headers: {
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
            }).then(function (response) {
                if (response.status == 204) {
                    $scope.mostrarContatos($scope.pagEmailsAbertos ? 'email' : 'janela');
                    $scope.voltarTelaInicialDialer();
                    MonsterToasterService.showSuccessToaster("", "Oportunidade agendada com sucesso!", 6000, 350);
                }
                mixpanel.track("JANELA DE LIGAÇÃO | Agendou ligação para oportunidade", { Local_IP: $rootScope.ip });
                $scope.onAgendarRetornoFormLimpar();
                $rootScope.monsterShowLoading = false;
            }, function () {
                MonsterToasterService.showErrorToaster("", "Erro ao agendar oportunidade da janela de liga\u00E7\u00E3o. Tente novamente.", 6000);
                $rootScope.monsterShowLoading = false;
            });
        };
        $scope.onAgendarRetornoFormLimpar = function () {
            $scope.agendarRetornoData = { data: '' };
            var form = $scope.formAgendarRetorno;
            angular.forEach(form.$$controls, function (control) {
                var fieldElement = angular.element(control.$$element);
                fieldElement.attr('dirty', 'false');
            });
            $scope.agendarRetornoModalConfirmacao = false;
        };
        var contatoLigacaoRealizada = null;
        $scope.maxDateToDatePicker = new Date().toISOString();
        $scope.ligacaoRealizadaData = { data: null };
        $scope.ligacaoRealizadaDropdown = function ($event, contato) {
            $scope.stopEvent($event);
            $scope.ligacaoRealizadaModalConfirmacao = true;
            contatoLigacaoRealizada = contato;
        };
        $scope.ligacaoRealizada = function (data) {
            $scope.ligacaoRealizadaModalConfirmacao = false;
            if (contatoLigacaoRealizada) {
                var data_1 = { "data": $scope.ligacaoRealizadaData.data };
                $rootScope.monsterShowLoading = true;
                return $http({
                    method: "POST",
                    url: "".concat($rootScope.ramperV3ApiBaseUrl, "/contatos/").concat(contatoLigacaoRealizada.objectIdContato, "/acoes/ligacao-realizada"),
                    data: data_1,
                    headers: {
                        'Authorization': 'Bearer ' + $rootScope.Token
                    },
                }).then(function (response) {
                    if (response.status == 204) {
                        $scope.mostrarContatos($scope.pagEmailsAbertos ? 'email' : 'janela');
                        $scope.voltarTelaInicialDialer();
                        MonsterToasterService.showSuccessToaster("", "Data da liga\u00E7\u00E3o realizada salva com sucesso!", 6000, 450);
                    }
                    mixpanel.track("JANELA DE LIGAÇÃO | Salvou data da ligação realizada para a oportunidade", { Local_IP: $rootScope.ip });
                    $scope.onLigacaoRealizadaFormLimpar();
                    $rootScope.monsterShowLoading = false;
                }, function () {
                    MonsterToasterService.showErrorToaster("", "Erro ao salvar data da liga\u00E7\u00E3o realizada. Tente novamente.", 6000);
                    $rootScope.monsterShowLoading = false;
                });
            }
        };
        $scope.onLigacaoRealizadaFormLimpar = function () {
            $scope.ligacaoRealizadaData = { data: '' };
            var form = $scope.formLigacaoRealizada;
            angular.forEach(form.$$controls, function (control) {
                var fieldElement = angular.element(control.$$element);
                fieldElement.attr('dirty', 'false');
            });
            $scope.ligacaoRealizadaModalConfirmacao = false;
        };
        $scope.enviarParaIntegracaoDropdown = function ($event, oportunidade, integracao, categoria) {
            $scope.stopEvent($event);
            $scope.enviarIntegracaoGeral(oportunidade.objectIdContato, integracao, categoria);
        };
        $scope.enviarIntegracaoGeral = function (idContato, integracao, categoria) {
            $scope.enviarParaIntegracaoModalConfirmacao = true;
            $scope.categoriaIntegracao = categoria;
            $scope.tipoIntegracao = integracao.application;
            $scope.integracaoApikey = integracao.apiKey;
            $scope.contatoIds = [idContato];
            $scope.queryParams = {
                crm: integracao.application,
                apiKey: integracao.apiKey,
                objectIdContato: idContato,
            };
        };
        $scope.onRemoverContatoAoEnviarIntegracao = function ($event) {
            $scope.removerContatoAoEnviarIntegracao = $event.detail.checked;
        };
        $scope.onModalEnviarContatosIntegracaoFechar = function (status, contatoIds) { return __awaiter(_this, void 0, void 0, function () {
            var data, contato;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(status == 'Enviado')) return [3 /*break*/, 2];
                        data = { integracao: $scope.tipoIntegracao };
                        return [4 /*yield*/, $http({
                                method: "POST",
                                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/contatos/").concat(contatoIds[0], "/acoes/enviou-integracao"),
                                data: data,
                                headers: {
                                    'Authorization': 'Bearer ' + $rootScope.Token
                                },
                            })];
                    case 1:
                        _a.sent();
                        if ($scope.removerContatoAoEnviarIntegracao) {
                            contato = $scope.pagEmailsAbertos ? $scope.emailsAbertos.find(function (contato) { return contato.objectIdContato === contatoIds[0]; }) : $scope.listaDeOportunidades.find(function (oportunidade) { return oportunidade.objectIdContato === contatoIds[0]; });
                            contatoParaRemover = contato;
                            $scope.removerContato();
                        }
                        else {
                            $scope.mostrarContatos($scope.pagEmailsAbertos ? 'email' : 'janela');
                            $scope.voltarTelaInicialDialer();
                        }
                        _a.label = 2;
                    case 2:
                        $scope.removerContatoAoEnviarIntegracao = false;
                        return [2 /*return*/];
                }
            });
        }); };
        var contatoParaRemover = null;
        $scope.removerContatoDropdown = function ($event, contato) {
            $scope.stopEvent($event);
            $scope.removerContatoModalConfirmacao = true;
            contatoParaRemover = contato;
        };
        $scope.removerContato = function () {
            $scope.removerContatoModalConfirmacao = false;
            if (contatoParaRemover) {
                $rootScope.monsterShowLoading = true;
                var data = { "idCadencia": contatoParaRemover.objectIdCadencia };
                return $http({
                    method: "POST",
                    url: "".concat($rootScope.ramperV3ApiBaseUrl, "/contatos/").concat(contatoParaRemover.objectIdContato, "/acoes/remover-oportunidade"),
                    data: data,
                    headers: {
                        'Authorization': 'Bearer ' + $rootScope.Token
                    },
                }).then(function (response) {
                    if (response.status == 204) {
                        $scope.mostrarContatos($scope.pagEmailsAbertos ? 'email' : 'janela');
                        $scope.voltarTelaInicialDialer();
                        MonsterToasterService.showSuccessToaster("", "Oportunidade removida com sucesso!", 6000, 350);
                    }
                    mixpanel.track("JANELA DE LIGAÇÃO | Removeu oportunidade", { Local_IP: $rootScope.ip });
                    $rootScope.monsterShowLoading = false;
                }, function () {
                    MonsterToasterService.showErrorToaster("", "Erro ao remover oportunidade da janela de liga\u00E7\u00E3o. Tente novamente.", 6000);
                    $rootScope.monsterShowLoading = false;
                });
            }
        };
        var converterContato = null;
        $scope.converterContatoDropdown = function ($event, contato, status) {
            $scope.stopEvent($event);
            $scope.converterContatoModalConfirmacao = true;
            converterContato = { contato: contato, status: status };
        };
        $scope.converterContato = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                $scope.converterContatoModalConfirmacao = false;
                if (converterContato) {
                    $rootScope.monsterShowLoading = true;
                    $scope.classificacaoInteresse(converterContato.status, converterContato.contato.objectIdContato);
                    $http({
                        method: "POST",
                        url: "".concat($rootScope.ramperV3ApiBaseUrl, "/contatos/").concat(converterContato.contato.objectIdContato, "/acoes/converteu/").concat(converterContato.status),
                        headers: {
                            'Authorization': 'Bearer ' + $rootScope.Token
                        },
                    }).then(function (response) {
                        if (response.status == 204) {
                            $scope.mostrarContatos($scope.pagEmailsAbertos ? 'email' : 'janela');
                            $scope.voltarTelaInicialDialer();
                            MonsterToasterService.showSuccessToaster("", "Contato convertido com sucesso!", 6000, 350);
                        }
                        mixpanel.track("JANELA DE LIGAÇÃO | Converteu contato para " + $scope.formatarStatus(converterContato.status), { Local_IP: $rootScope.ip });
                        $rootScope.monsterShowLoading = false;
                    }, function () {
                        MonsterToasterService.showErrorToaster("", "Erro ao converter contato. Tente novamente.", 6000);
                        $rootScope.monsterShowLoading = false;
                    });
                }
                return [2 /*return*/];
            });
        }); };
        $scope.alterarParametrosConexao = function () {
            $scope.semParametros = false;
            $scope.listaEmailsAbertos = true;
            $scope.listaJanelaAtencao = true;
            var qtdErro = 0;
            if ($scope.qtdAbriuEmail == 'NE') {
                $('#estrelaVermelha1').addClass('colorRedImportant');
                $scope.camposObrigatorios = true;
                $scope.semParametros = true;
                qtdErro++;
            }
            else {
                $('#estrelaVermelha1').removeClass('colorRedImportant');
            }
            if ($scope.qtdIntervaloAbertura == 'NA') {
                $('#estrelaVermelha2').addClass('colorRedImportant');
                $scope.camposObrigatorios = true;
                $scope.semParametros = true;
                qtdErro++;
            }
            else {
                $('#estrelaVermelha2').removeClass('colorRedImportant');
            }
            if ($scope.tempoJanelaAtencao == 'NT') {
                $('#estrelaVermelha3').addClass('colorRedImportant');
                $scope.camposObrigatorios = true;
                $scope.semParametros = true;
                qtdErro++;
            }
            else {
                $('#estrelaVermelha3').removeClass('colorRedImportant');
            }
            if (qtdErro == 0) {
                $rootScope.monsterShowLoading = true;
                $('#estrelaVermelha1').removeClass('colorRedImportant');
                $('#estrelaVermelha2').removeClass('colorRedImportant');
                $('#estrelaVermelha3').removeClass('colorRedImportant');
                $scope.camposObrigatorios = false;
                var data = {
                    abriuEmail: $scope.qtdAbriuEmail,
                    periodo: $scope.qtdIntervaloAbertura === "0" ? 0.5 : $scope.qtdIntervaloAbertura,
                    janelaAtencao: $scope.tempoJanelaAtencao
                };
                JanelaDeLigacaoService.atualizarParametros(data)
                    .then(function () {
                    _tempo = $scope.tempoJanelaAtencao !== 'NT' ? ($scope.tempoJanelaAtencao * 60) : 0;
                    if ($scope.semParametros) {
                        mixpanel.track("JANELA DE LIGAÇÃO | Configurou os parâmetros", { Local_IP: $rootScope.ip });
                    }
                    else {
                        mixpanel.track("JANELA DE LIGAÇÃO | Alterou os parâmetros", { Local_IP: $rootScope.ip });
                    }
                })
                    .catch(function () { })
                    .finally(function () {
                    $scope.semParametros = false;
                    $scope.meta.offset = 0;
                    $scope.searchEmailAbertoValue = '';
                    $scope.mostrarContatos($scope.pagEmailsAbertos ? 'email' : 'janela');
                });
            }
            ;
        };
        $scope.carregaAnalytics = function () {
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/conexao/buscaValoresIndicadores"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                }
            }).then(function (rResult) {
                if (rResult.data.length > 0) {
                    var arrayDiasConversao = [];
                    var arrayHorasConversao = [];
                    $scope.contadorJanelaConexao = 0;
                    $scope.contadorForaJanelaConexao = 0;
                    $scope.arrayHorasConversao = [];
                    $scope.arrayDiasConversao = [];
                    //Dados Graficos
                    //Dias
                    for (var i = 0; i < rResult.data.length; i++) {
                        var aux = 0;
                        aux = new Date(rResult.data[i].dataConversao).getDay();
                        arrayDiasConversao.push(aux);
                    }
                    $scope.arrayDiasConversao = arrayDiasConversao;
                    //Horas
                    for (var i = 0; i < rResult.data.length; i++) {
                        var auxHora;
                        auxHora = new Date(rResult.data[i].dataConversao).getHours();
                        arrayHorasConversao.push(auxHora);
                    }
                    $scope.arrayHorasConversao = arrayHorasConversao;
                    rResult.data.forEach(function (contatoJanela) {
                        if (contatoJanela.origemConversao === "dentroDaJaneladeConexao") {
                            $scope.contadorJanelaConexao = $scope.contadorJanelaConexao + 1;
                        }
                        if (contatoJanela.origemConversao === "foraDaJanelaDeConexao") {
                            $scope.contadorForaJanelaConexao = $scope.contadorForaJanelaConexao + 1;
                        }
                    });
                    $scope.carregaGraficoHoraAnalytics();
                    $scope.carregaGraficoDiarioAnalytics();
                }
            });
        };
        montarOportunidades = function (oportunidade) {
            if (oportunidade.length > 0) {
                $scope.listaJanelaAtencao = true;
                oportunidade.forEach(function (contatoJanela) {
                    contatoJanela.id = contatoJanela._id;
                    contatoJanela.cor = getCorConfiabilidade(contatoJanela.percAcerto);
                    if (contatoJanela.ultimaAcao) {
                        contatoJanela.ultimaAcao.data = $scope.formatarData(contatoJanela.ultimaAcao.data);
                        contatoJanela.ultimaAcao.dataAgendada = $scope.formatarData(contatoJanela.ultimaAcao.dataAgendada);
                    }
                    $scope.listaDeOportunidades.push(contatoJanela);
                    $scope.contJanelaDeConexao++;
                });
            }
            else {
                $scope.listaJanelaAtencao = false;
            }
            for (var i = 0; i <= $scope.listaDeOportunidades.length; i++) {
                try {
                    $('#mostrarValor' + [i]._d).removeClass('btnLigarTempoZerado');
                }
                catch (e) { }
            }
            ;
        };
        $scope.abrirTela = function () {
            $('#graficoPorDia').addClass('bold');
            $rootScope.monsterShowLoading = true;
            $scope.listaJanelaAtencao = true;
            $scope.listaDeOportunidades = [];
            $scope.semContatosJanelaAtencao = false;
            $scope.arrayGraficoDiarioAnalytics = [];
            $scope.arrayGraficoHorarioAnalytics = [];
            $scope.contJanelaDeConexao = 0;
            $scope.carregaAnalytics();
            setTimeout(function () {
                JanelaDeLigacaoService.buscarParametros()
                    .then(function (data) {
                    $scope.qtdAbriuEmail = data.abriuEmail;
                    $scope.qtdIntervaloAbertura = data.periodo === 0.5 ? '0' : data.periodo;
                    $scope.tempoJanelaAtencao = data.janelaAtencao;
                    $scope.mudarTxtParametros(1, $scope.qtdAbriuEmail);
                    $scope.mudarTxtParametros(2, $scope.qtdIntervaloAbertura);
                    $scope.mudarTxtParametros(3, $scope.tempoJanelaAtencao);
                    $('#estrelaVermelha3').removeClass('colorRedImportant');
                    $('#estrelaVermelha2').removeClass('colorRedImportant');
                    $('#estrelaVermelha1').removeClass('colorRedImportant');
                    $scope.camposObrigatorios = false;
                    $scope.semParametros = false;
                })
                    .catch(function () {
                    $scope.qtdAbriuEmail = 'NE';
                    $scope.qtdIntervaloAbertura = 'NA';
                    $scope.tempoJanelaAtencao = 'NT';
                    document.getElementById("idSpanFiltroAbriuEmail").innerText = 'Quantas vezes abriu o e-mail';
                    document.getElementById("idSpanFiltroTempoEntreAbertura").innerText = 'Tempo entre as aberturas';
                    document.getElementById("idSpanFiltroTempoJanelaAtencao").innerText = 'Tempo na janela de conexão';
                    $scope.semParametros = true;
                })
                    .finally(function () {
                    _tempo = $scope.tempoJanelaAtencao !== 'NT' ? ($scope.tempoJanelaAtencao * 60) : 0;
                    JanelaDeLigacaoService.buscarOportunidades($rootScope.Tipo, $scope.qtdAbriuEmail, $scope.qtdIntervaloAbertura, $scope.tempoJanelaAtencao).then(function (rResult) {
                        mixpanel.track("JANELA DE LIGAÇÃO | Entrou na janela de ligação", { Local_IP: $rootScope.ip });
                        montarOportunidades(rResult);
                    });
                });
                $('.boxInterno').removeClass('blur');
                $('#menuPrincipal').removeClass('blur');
                $('#chart_div').show();
                $rootScope.monsterShowLoading = false;
                $scope.$apply();
            }, 2000);
        };
        $scope.getTimestampTela = function (_id) {
            return getTimestamp(_id);
        };
        $scope.recarregarPagina = function () {
            window.location.reload();
        };
        $scope.mostrarContatos = function (pDados) {
            esconderDivsContatos();
            if (pDados == 'janela') {
                $scope.pagEmailsAbertos = false;
                $scope.abrirTela();
                $('#idJanelaAtencao').fadeIn();
                $('#idSpanJanelaAtencao').addClass('bold');
                $('#idSpanEmailsAbertos').removeClass('bold');
                $('#divJanelaAtencao').css('border-left', '3px solid #172f57');
                $('#divEmailsAbertosDialer').css('border-left', '3px solid transparent');
                $('#imgJanelaAtencao').attr('src', "".concat($rootScope.bucket, "/seta-escura.png"));
                $('#imgEmailsAbertos').attr('src', "".concat($rootScope.bucket, "/seta-clara.png"));
                $('#btnAnalyticTela').show();
                $('#btnFiltrar').show();
            }
            else {
                $scope.pagEmailsAbertos = true;
                $scope.carregarEmailsAbertos();
                $('#idEmailsAbertos').fadeIn();
                $('#idSpanJanelaAtencao').removeClass('bold');
                $('#idSpanEmailsAbertos').addClass('bold');
                $('#divJanelaAtencao').css('border-left', '3px solid transparent');
                $('#divEmailsAbertosDialer').css('border-left', '3px solid #172f57');
                $('#imgJanelaAtencao').attr('src', "".concat($rootScope.bucket, "/seta-clara.png"));
                $('#imgEmailsAbertos').attr('src', "".concat($rootScope.bucket, "/seta-escura.png"));
                $('#btnAnalyticTela').hide();
                $('#btnAnalyticTela').hide();
                $('#btnFiltrar').hide();
            }
        };
        $scope.abrirFiltroAbriuEmail = function () {
            $scope.filtroAbriuEmailAberto = !$scope.filtroAbriuEmailAberto;
            if ($scope.filtroAbriuEmailAberto) {
                $('#idFiltroAbriuEmail').show();
            }
            else
                $('#idFiltroAbriuEmail').hide();
        };
        $scope.fixarQtdVezesAbriuEmail = function (pDados, pTxt) {
            if (pDados || pDados != null) {
                $scope.qtdAbriuEmail = pDados;
                document.getElementById("idSpanFiltroAbriuEmail").innerText = pTxt;
            }
            else {
                $scope.qtdAbriuEmail = 'NE';
                document.getElementById("idSpanFiltroAbriuEmail").innerText = 'Quantas vezes abriu o e-mail';
            }
            $scope.abrirFiltroAbriuEmail();
        };
        $scope.abrirFiltroTempoEntreAbertura = function () {
            $scope.filtroTempoEntreAbertura = !$scope.filtroTempoEntreAbertura;
            if ($scope.filtroTempoEntreAbertura) {
                $('#idFiltroTempoEntreAbertura').show();
            }
            else
                $('#idFiltroTempoEntreAbertura').hide();
        };
        $scope.opcaoParametroConexao = function (pDados) {
            if (pDados) {
                $scope.pDadosMinutos = ["5", "10", "15", "20", "30", "60", "90"].includes(pDados.toString());
                $scope.tempoAbertura = {
                    "61": "1 hora",
                    "720": "12 horas",
                    "1440": "24 horas",
                    "2880": "48 horas",
                    "4320": "72 horas",
                    "0": " Qualquer intervalo de abertura",
                    "default": " minutos"
                };
                return $scope.pDadosMinutos ? pDados + $scope.tempoAbertura.default : $scope.tempoAbertura[pDados];
            }
        };
        $scope.fixarTempoEntreAbertura = function (pDados) {
            if (pDados) {
                var opcaoTempoAbertura = $scope.opcaoParametroConexao(pDados);
                $scope.qtdIntervaloAbertura = pDados;
                document.getElementById("idSpanFiltroTempoEntreAbertura").innerText = opcaoTempoAbertura;
            }
            else {
                $scope.qtdIntervaloAbertura = 'NA';
                document.getElementById("idSpanFiltroTempoEntreAbertura").innerText = 'Tempo entre as aberturas';
            }
            $scope.abrirFiltroTempoEntreAbertura();
        };
        $scope.abrirFiltroTempoJanelaAtencao = function () {
            $scope.filtroTempoJanelaAtencao = !$scope.filtroTempoJanelaAtencao;
            if ($scope.filtroTempoJanelaAtencao) {
                $('#idFiltroTempoJanelaAtencao').show();
            }
            else
                $('#idFiltroTempoJanelaAtencao').hide();
        };
        $scope.fixarTempoJanelaAtencao = function (pDados) {
            if (pDados) {
                $scope.tempoJanelaAtencao = pDados;
                var opcaoTempoJanelaAtencao = $scope.opcaoParametroConexao(pDados);
                document.getElementById("idSpanFiltroTempoJanelaAtencao").innerText = opcaoTempoJanelaAtencao;
            }
            else {
                $scope.tempoJanelaAtencao = 'NT';
                document.getElementById("idSpanFiltroTempoJanelaAtencao").innerText = 'Tempo na janela de conexão';
            }
            $scope.abrirFiltroTempoJanelaAtencao();
        };
        $scope.abrirTela();
        $scope.mudarTxtParametros = function (pDados, pDadosValor) {
            if (pDados == 1) {
                var txtTempAbriuEmail = {
                    "1": " vez",
                    "2": " vezes",
                    "3": " vezes",
                    "4": " vezes",
                    "5": " vezes ou +"
                };
                document.getElementById("idSpanFiltroAbriuEmail").innerText = $scope.qtdAbriuEmail + txtTempAbriuEmail[$scope.qtdAbriuEmail];
            }
            else if (pDados == 2) {
                var mudarTxtIntevaloAbertura = $scope.opcaoParametroConexao(pDadosValor);
                document.getElementById("idSpanFiltroTempoEntreAbertura").innerText = mudarTxtIntevaloAbertura;
            }
            else {
                var mudarTxtTempoJanelaAtencao = $scope.opcaoParametroConexao(pDadosValor);
                document.getElementById("idSpanFiltroTempoJanelaAtencao").innerText = mudarTxtTempoJanelaAtencao;
            }
        };
        //Funccao analytic
        $scope.esconderAnalytics = function () {
            if ($scope.mostraAnalytics == false) {
                $scope.mostraAnalytics = true;
            }
            else {
                $scope.mostraAnalytics = false;
            }
        };
        $scope.abrirFiltroSegmentacao = function () {
            $scope.filtroSegmentacaoAberto = !$scope.filtroSegmentacaoAberto;
            if ($scope.filtroSegmentacaoAberto) {
                $('#idFiltroUsuariosSeg').hide();
                $('#idFiltroCadenciaSeg').hide();
                $('#idFiltroSegmentacao').show();
                $('#idListaSegmentacao').animate({ scrollTop: 0 }, 10);
                setTimeout(function () { document.getElementById("idInptFiltroSegmentacao").focus(); }, 10);
            }
            else
                $('#idFiltroSegmentacao').hide();
        };
        $scope.carregaGraficoDiarioAnalytics = function () {
            $rootScope.monsterShowLoading = true;
            var arrayDados = $scope.arrayDiasConversao;
            var contAuxAtual = 0;
            for (var i = 0; i < 7; i++) {
                var contDiasAtual = 0;
                for (var j = 0; j <= arrayDados.length; j++) {
                    if (arrayDados[j] == i) {
                        contDiasAtual++;
                    }
                }
                contAuxAtual = contDiasAtual;
                $scope.arrayGraficoDiarioAnalytics.push(contAuxAtual);
            }
            $rootScope.monsterShowLoading = false;
        };
        $scope.carregaGraficoHoraAnalytics = function () {
            $rootScope.monsterShowLoading = true;
            var arrayDadosHora = $scope.arrayHorasConversao;
            var contAuxAtual = 0;
            for (var i = 0; i < 23; i++) {
                var contDiasAtual = 0;
                for (var j = 0; j <= arrayDadosHora.length; j++) {
                    if (arrayDadosHora[j] == i) {
                        contDiasAtual++;
                    }
                }
                contAuxAtual = contDiasAtual;
                $scope.arrayGraficoHorarioAnalytics.push(contAuxAtual);
            }
            $rootScope.monsterShowLoading = false;
        };
        $scope.consultarCapturasHoje = function () {
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/login/consultarContatosMixpanel"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto
                }
            }).then(function (rConsultarContatosMixpanel) {
                if (rConsultarContatosMixpanel.data) {
                    rConsultarContatosMixpanel.data = false;
                    mixpanel.track("Captura Extensão | O usuario capturou contatos hoje", {});
                }
            });
        };
        $scope.consultarCapturasHoje();
        $scope.onOrderChange = function ($event) {
            switch ($event.detail.value) {
                case 1:
                    $scope.orderBy = 'qntAbertura:asc';
                    break;
                case 2:
                    $scope.orderBy = 'qntAbertura:desc';
                    break;
                case 3:
                    $scope.orderBy = 'dataAbertura:asc';
                    break;
                case 4:
                    $scope.orderBy = 'dataAbertura:desc';
                    break;
            }
            ;
            $scope.carregarEmailsAbertos();
        };
        $scope.abrirOutrasInformacoesForm = function () {
            document.querySelector('.setaOutrasInformacoes').classList.toggle("setaRotate");
        };
        $rootScope.$watch('saldoSemFormatar', function () {
            $scope.habilitarFazerLigacao = $rootScope.saldoSemFormatar > 0 && $rootScope.permissoes.ligacoes.makeCall;
        });
    }])
    .directive('countdown', function () {
    return {
        restrict: 'E',
        template: '<div>{{countdownVal}}</div>',
        scope: {
            initVal: '='
        },
        controller: ['$scope', '$interval', function ($scope, $interval) {
                var tempoJanela = document.getElementById("idSpanFiltroTempoJanelaAtencao").innerText.split(' ')[0];
                var opcaoHoraJanela = {
                    "12": 720,
                    "24": 1440,
                    "48": 2880,
                    "72": 4320,
                    "default": tempoJanela
                };
                tempoJanela = opcaoHoraJanela[tempoJanela] || opcaoHoraJanela.default;
                var countDownDate = $scope.initVal.agendado ? new Date() : new Date($scope.initVal.dataInicioOportunidade);
                countDownDate.setMinutes(countDownDate.getMinutes() + new Number(tempoJanela));
                if ($scope.initVal.agendado) {
                    countDownDate = new Date().setHours(23, 59, 59, 999);
                }
                $interval(function () {
                    var now = new Date().getTime();
                    var distance = countDownDate - now;
                    var hours = Math.floor((distance % (1000 * 60 * 60 * 72)) / (1000 * 60 * 60));
                    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                    var seconds = Math.floor((distance % (1000 * 60)) / 1000);
                    if ((minutes < 0) && (seconds < 0)) {
                        $scope.countdownVal = "00:00:00";
                    }
                    else {
                        $scope.countdownVal = lpad(hours, 2) + ":" + lpad(minutes, 2) + ":" + lpad(seconds, 2);
                    }
                });
            }]
    };
});
