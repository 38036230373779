angular.module('ramperv3')
    .controller('TimeController', ['$rootScope', '$scope', '$http', 'LoginService', function ($rootScope, $scope, $http, LoginService) {
        ga('set', 'page', document.location.hash);
        ga('send', 'pageview');
        $rootScope.consultarStatusUsuario();
        $rootScope.redirectTime();
        $scope.menuNavAberto = true;
        $rootScope.mostrarMenu = true;
        $scope.adicionandoUsuario = false;
        $scope.alterarEmail = false;
        $scope.buscarHabilitado = false;
        $scope.buscarUsuarios = '';
        $scope.botaoBuscarVisivel = false;
        $scope.editandoUsuario = false;
        $scope.filtroHabilitado = true;
        $scope.filtroTipoUsuario = '';
        $scope.listaUsuarios = [];
        $scope.falhaResetarSenha = false;
        $scope.mostrarLinhaDividir = true;
        $scope.nomeStatusUsuarioSelecionado = 'Ativo';
        $scope.podeAdicionar = false;
        $scope.popupCompararTimes = false;
        $scope.popupErroAddUsuario = false;
        $scope.semUsuarios = false;
        $scope.semUsuariosBusca = false;
        $scope.semUsuariosFiltro = false;
        $scope.slctTipoUsuario = 'U';
        $scope.slctTipoUsuarioAberto = false;
        $scope.statusFiltroUsuario = 'A';
        $scope.totalSelecionados = 0;
        $scope.trocarOrdem = true;
        var _usuarioSelecionado = null;
        var statusOrdenacao = 'statusUsuario';
        $scope.propertyName = [statusOrdenacao, $scope.valorOrdenar = "nome"];
        $('#idFundoPopupTime').hide();
        $('#dvMensagemEmailJaExiste').hide();
        $scope.erroCodigoSuperlogica = false;
        $scope.msgEmailJaexiste = '';
        $scope.msgTimeNaoPossuiSuperlogica = '';
        $scope.consultarDetalhesTimeUsuarios = function () {
            $scope.listaUsuarios = [];
            $rootScope.loaderAtivo = true;
            $('#pagTime').addClass('blur');
            $('#menuPrincipal').addClass('blur');
            if ($rootScope.permissoes.tipoUsuario == 'A') {
                $scope.consultarQuantidadeUsuarioTime();
            }
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/time/consultarDetalheTimeUsuarios/"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                params: {
                    dataInicio: $scope.dataInicioFiltro ? new Date($scope.dataInicioFiltro) : null,
                    dataFim: $scope.dataFinalFiltro ? new Date($scope.dataFinalFiltro) : null,
                    tipo: $scope.filtroTipoUsuario,
                    status: $scope.statusFiltroUsuario,
                    buscar: $scope.buscarUsuarios
                }
            }).then(function (response) {
                $scope.total = response.data.length;
                if ((response.data.length >= 1) || ((response.data.length == 1) && ($scope.filtroTipoUsuario))) {
                    $scope.esconderEmptyStates();
                    response.data.forEach(function (usuario) {
                        usuario.txtMais = 'mais';
                        usuario.usuSelecionado = '';
                        usuario.controleInfos = 0;
                        usuario.corStatus = usuario.statusUsuario == 'ATIVO' ? 'Ativo' : 'Inativo';
                        $scope.listaUsuarios.push(usuario);
                    });
                }
                else {
                    $scope.semUsuariosFiltro = false;
                    $scope.semUsuarios = false;
                    $scope.semUsuariosBusca = $scope.buscarHabilitado;
                    $scope.buscaOuFiltro = $scope.buscarHabilitado ? 'Sua busca' : 'Seu filtro';
                    if ($scope.filtroTipoUsuario != '' || $scope.statusFiltroUsuario != '' || $scope.dataInicioFiltro || $scope.dataFinalFiltro) {
                        $scope.semUsuariosFiltro = true;
                    }
                    else if (!$scope.buscarHabilitado) {
                        $scope.semUsuarios = true;
                    }
                }
                $rootScope.loaderAtivo = false;
                $('#pagTime').removeClass('blur');
                $('#menuPrincipal').removeClass('blur');
            });
        };
        $scope.consultarDetalhesTimeUsuariosEstatisticas = function (pObjectId) {
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/time/consultarDetalhesTimeUsuariosEstatisticas/"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                params: {
                    objectId: pObjectId
                }
            }).then(function (response) {
                $scope.listaUsuarios.forEach(function (usuario) {
                    if (usuario.objectIdLogin == response.data._id) {
                        usuario.controleInfos = 1;
                        usuario.infoContatos = response.data.infoContatos;
                        usuario.infoDisparos = response.data.infoDisparos;
                    }
                });
            });
        };
        $scope.consultarQuantidadeUsuarioTime = function () {
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/timeUsuarios/consultarQuantidadeUsuarioTime"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                }
            }).then(function (rConsulta) {
                var usersAtivos = [];
                if (rConsulta.data.length > 0) {
                    for (var index = 0; index < rConsulta.data.length; index++) {
                        var el = rConsulta.data[index].aliasUsuarios;
                        if (!(el.status == "I" || el.tipo == "V")) {
                            usersAtivos.push(el);
                        }
                    }
                    $scope.podeAdicionar = (rConsulta.data[0].aliasTimes.quantidadeUsuario > usersAtivos.length);
                }
            });
        };
        $scope.abrirFiltrar = function () {
            $scope.popupFiltrar = true;
            $('#idFiltroTime').show();
        };
        $scope.fecharFiltrar = function (pFechar) {
            $scope.popupFiltrar = false;
            $('#idFiltroTime').hide();
            if (pFechar) {
                if ($('#sltDataTimes')[0].value == '' && ($scope.filtroTipoUsuario == null || $scope.filtroTipoUsuario == undefined || $scope.filtroTipoUsuario == '') && ($scope.statusFiltroUsuario == null || $scope.statusFiltroUsuario == undefined || $scope.statusFiltroUsuario == '')) {
                    $scope.filtroHabilitado = false;
                    $('#idBtnFiltroTime').removeClass().addClass('btnVoltarPadroNovoFiltro');
                    $('#idImgFiltrarTime').attr('src', "".concat($rootScope.bucket, "/filtro.png"));
                }
                else {
                    $scope.filtroHabilitado = true;
                    $('#idBtnFiltroTime').removeClass().addClass('btnFiltroTempNovoHabilitado');
                    $('#idImgFiltrarTime').attr('src', "".concat($rootScope.bucket, "/filtro-wt-novo.svg"));
                }
                $scope.buscarHabilitado = false;
                $scope.buscarUsuarios = '';
                $scope.botaoBuscarVisivel = false;
                $scope.consultarDetalhesTimeUsuarios();
                $scope.montarNomeFiltros();
                $scope.zerarCamposOrdenacao();
            }
        };
        $scope.colapseOrExpandMenu = function (menu) {
            if (menu == 'time') {
                $scope.menuNavAberto = !$scope.menuNavAberto;
                if ($scope.menuNavAberto) {
                    $("#expandOrCollapseSideMenu").addClass("collapseMenuNavFiltro");
                    $("#menuFiltroTime").removeClass("menuNavCol");
                    $("#expandOrCollapseSideMenu").removeClass("expandMenuNavFiltro");
                    setTimeout(function () {
                        $("#tituloMenuLateral").removeClass("escondeMenuLateral");
                        $("#conteudoMenuLateral").removeClass("escondeMenuLateral");
                    }, 300);
                }
                else {
                    $("#expandOrCollapseSideMenu").removeClass("collapseMenuNavFiltro");
                    $("#menuFiltroTime").addClass("menuNavCol");
                    $("#tituloMenuLateral").addClass("escondeMenuLateral");
                    $("#conteudoMenuLateral").addClass("escondeMenuLateral");
                    $("#expandOrCollapseSideMenu").addClass("expandMenuNavFiltro");
                }
            }
        };
        $scope.identifInputVazio = function (keyEvent) {
            var input = keyEvent.target.value.trim();
            $scope.botaoBuscarVisivel = Boolean(input);
        };
        $scope.verificarFiltrosTime = function () {
            if ($scope.filtroHabilitado == true) {
                $scope.removerFiltros();
            }
            else {
                $scope.limparFiltros();
            }
        };
        $scope.removerFiltros = function () {
            $scope.limparFiltros();
            $scope.zerarCamposOrdenacao();
            $scope.esconderEmptyStates();
            $scope.buscarUsuarios = '';
            $scope.botaoBuscarVisivel = false;
            $scope.consultarDetalhesTimeUsuarios();
            $scope.montarNomeFiltros();
            $scope.filtroHabilitado = false;
            $('#idBtnFiltroTime').removeClass().addClass('btnVoltarPadroNovoFiltro');
            $('#idImgFiltrarTime').attr('src', "".concat($rootScope.bucket, "/filtro.png"));
        };
        $('#sltDataTimes').dateRangePicker({
            startOfWeek: 0,
            format: 'DD/MM/YY',
            separator: ' até ',
            shortcuts: {
                'prev-days': [1, 7, 15],
                'prev': ['week', 'month', 'year']
            },
            animationTime: 200
        })
            .bind('DRPEx-change', function (event, obj) {
            $scope.dataInicioFiltro = obj.start;
            $scope.dataFinalFiltro = obj.end;
        });
        $("#ordemUsuarios").on('change', function () {
            var statusUsuarioOrdem;
            if ($('#ordemUsuarios')[0].selectedOptions[0].text == "A-Z" || $('#ordemUsuarios')[0].selectedOptions[0].text == "Menor data") {
                $scope.reverse = false;
                statusUsuarioOrdem = 'statusUsuario';
            }
            else {
                $scope.reverse = true;
                statusUsuarioOrdem = '-statusUsuario';
            }
            $scope.propertyName = [statusUsuarioOrdem, $('#ordemUsuarios')[0].selectedOptions[0].value];
            $scope.$apply();
        });
        $scope.enter = function (keyEvent) {
            if (keyEvent.which === 13) {
                if ($scope.editandoUsuario) {
                    if ($('#idBtnSalvarEditar').is(':enabled'))
                        $scope.validacaoEmail('e');
                }
                else if ($scope.adicionandoUsuario) {
                    if ($('#idBtnSalvarEditar').is(':enabled'))
                        $scope.validacaoEmail('a');
                }
                else {
                    $scope.buscarHabilitado = true;
                    $scope.filtroHabilitado = false;
                    $('#idBtnFiltroTime').removeClass().addClass('btnVoltarPadroNovoFiltro');
                    $('#idImgFiltrarTime').attr('src', "".concat($rootScope.bucket, "/filtro.png"));
                    $scope.limparFiltros();
                    $scope.zerarCamposOrdenacao();
                    $scope.montarNomeFiltros();
                    $scope.consultarDetalhesTimeUsuarios();
                }
            }
        };
        $scope.limparFiltros = function () {
            $scope.filtroTipoUsuario = '';
            $scope.statusFiltroUsuario = '';
            document.getElementById("idTxtTipoUsuario").innerText = 'Tipo de usuário';
            document.getElementById("idTxtStatusUsuario").innerText = 'Status do usuário';
            $scope.nomeStatusUsuarioSelecionado = '';
            $scope.nomeTipoUsuarioSelecionado = '';
            $('#sltDataTimes')[0].value = null;
            setTimeout(function () { $('#sltDataTimes').data('DRPEx').clear(); }, 10);
            $scope.dataInicioFiltro = null;
            $scope.dataFinalFiltro = null;
        };
        $scope.esconderEmptyStates = function () {
            $scope.semUsuarios = false;
            $scope.semUsuariosBusca = false;
            $scope.semUsuariosFiltro = false;
            $scope.buscarHabilitado = false;
        };
        $scope.naoAdicionarUsuario = function () {
            $('#idFundoPopupTime').fadeIn();
            $scope.popupErroAddUsuario = true;
        };
        $scope.fecharNaoAdicionarUsuario = function () {
            $('#idFundoPopupTime').fadeOut();
            $scope.popupErroAddUsuario = false;
            $scope.erroCodigoSuperlogica = true;
            $scope.msgTimeNaoPossuiSuperlogica = null;
        };
        $scope.getTimestampTela = function (_id) {
            return getTimestamp(_id);
        };
        $(document).mouseup(function (e) {
            var popupFiltro = $('#idFiltroTime');
            var data = $('.dr-picker');
            var divOrdenar = $('#idOrdenarTime');
            var divSlctUsuarioNovo = $('#idSlctTipoUsuario');
            var fundoPopupNovo = $('#idFundoPopupTime');
            if (popupFiltro.has(e.target).length === 0 && data.has(e.target).length === 0) {
                popupFiltro.hide();
                data.hide();
                $scope.popupFiltrar = false;
            }
            if (!divOrdenar.is(e.target) && divOrdenar.has(e.target).length === 0) {
                $scope.ordenarTimeAberto = false;
                divOrdenar.hide();
            }
            if ($scope.slctTipoUsuarioAberto) {
                if (!divSlctUsuarioNovo.is(e.target) && divSlctUsuarioNovo.has(e.target).length === 0) {
                    $scope.abrirTiposUsuario();
                }
            }
            if (fundoPopupNovo.is(e.target)) {
                if ($scope.popupErroAddUsuario) {
                    $scope.fecharNaoAdicionarUsuario();
                }
                else if ($scope.popupConclusaoAddEditar) {
                    $scope.fecharPopupConclusao();
                }
                else if ($scope.popupResetarSenha) {
                    $scope.fecharPopupResetarSenha();
                }
                $scope.$apply();
            }
        });
        $(document).keydown(function (e) {
            if (e.keyCode == 27) {
                if ($scope.popupErroAddUsuario) {
                    $scope.fecharNaoAdicionarUsuario();
                }
                else if ($scope.popupConclusaoAddEditar) {
                    $scope.fecharPopupConclusao();
                }
                else if ($scope.popupResetarSenha) {
                    $scope.fecharPopupResetarSenha();
                }
                $scope.$apply();
            }
        });
        $scope.obterDadosVinculados = function (pObjectId) {
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/time/consultarVinculadosUsuarioTime/"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                params: {
                    objectId: pObjectId
                }
            }).then(function (response) {
                $scope.listaUsuarios.forEach(function (usuario) {
                    if (usuario.objectIdLogin === response.data.objectIdLogin) {
                        usuario['segmentacaoNome'] = response.data.segmentacoes;
                        usuario['disparosNome'] = response.data.cadencias;
                    }
                });
            });
        };
        $scope.mostrarSegundaLinha = function (pTime) {
            _usuarioSelecionado = pTime;
            if (_usuarioSelecionado.usuSelecionado == 'Selecionada') {
                _usuarioSelecionado.usuSelecionado = '';
                _usuarioSelecionado.txtMais = 'mais';
                $('#linha' + pTime.objectIdLogin).addClass('subirListaFora');
                $('#img' + pTime.objectIdLogin).removeClass('classeVirarImagem');
                setTimeout(function () { $('#linha' + pTime.objectIdLogin).removeClass('subirListaFora').addClass('classeInvisivelCad'); }, 300);
            }
            else {
                _usuarioSelecionado.usuSelecionado = 'Selecionada';
                $('#linha' + pTime.objectIdLogin).removeClass('classeInvisivelCad').addClass('descerLinhaDentro');
                setTimeout(function () { $('#linha' + pTime.objectIdLogin).removeClass('descerLinhaDentro'); }, 500);
                $('#img' + pTime.objectIdLogin).addClass('classeVirarImagem');
                _usuarioSelecionado.txtMais = 'menos';
                if (_usuarioSelecionado.controleInfos == 0) {
                    $scope.obterDadosVinculados(pTime.objectIdLogin);
                    $scope.consultarDetalhesTimeUsuariosEstatisticas(_usuarioSelecionado.objectIdLogin);
                }
            }
        };
        $scope.abrirFiltroTipoUsuario = function () {
            $scope.filtroTipoUsuarioAberto = !$scope.filtroTipoUsuarioAberto;
            if ($scope.filtroTipoUsuarioAberto)
                $('#idFiltroTipoUsuario').show();
            else
                $('#idFiltroTipoUsuario').hide();
        };
        $scope.filtrarTipoUsuario = function (pDados) {
            if (pDados == 'A')
                document.getElementById("idTxtTipoUsuario").innerText = 'Administrador';
            else if (pDados == 'U')
                document.getElementById("idTxtTipoUsuario").innerText = 'Usuário';
            else if (pDados == 'V')
                document.getElementById("idTxtTipoUsuario").innerText = 'Visão';
            $scope.abrirFiltroTipoUsuario();
            $scope.filtroTipoUsuario = pDados;
            $scope.nomeTipoUsuarioSelecionado = document.getElementById("idTxtTipoUsuario").innerText;
        };
        $(document).mouseup(function (e) {
            var filtroTipo = $('#idFiltroTipoUsuario');
            if (!filtroTipo.is(e.target) && filtroTipo.has(e.target).length === 0) {
                $scope.filtroTipoUsuarioAberto = false;
                filtroTipo.hide();
            }
        });
        $scope.abrirFiltroStatusUsuario = function () {
            $scope.filtroStatusUsuarioAberto = !$scope.filtroStatusUsuarioAberto;
            if ($scope.filtroStatusUsuarioAberto)
                $('#idFiltroStatusUsuario').show();
            else
                $('#idFiltroStatusUsuario').hide();
        };
        $scope.filtrarStatusUsuario = function (pDados) {
            if (pDados == 'A')
                document.getElementById("idTxtStatusUsuario").innerText = 'Ativo';
            else if (pDados == 'I')
                document.getElementById("idTxtStatusUsuario").innerText = 'Inativo';
            else if (pDados == 'T')
                document.getElementById("idTxtStatusUsuario").innerText = 'Todos';
            $scope.abrirFiltroStatusUsuario();
            $scope.statusFiltroUsuario = pDados;
            $scope.nomeStatusUsuarioSelecionado = document.getElementById("idTxtStatusUsuario").innerText;
        };
        $(document).mouseup(function (e) {
            var filtroStatus = $('#idFiltroStatusUsuario');
            if (!filtroStatus.is(e.target) && filtroStatus.has(e.target).length === 0) {
                $scope.filtroStatusUsuarioAberto = false;
                filtroStatus.hide();
            }
        });
        $scope.montarNomeFiltros = function () {
            var contagem = 0;
            $scope.txtNomeFiltros = '';
            if ($('#sltDataTimes')[0].value != '' && $('#sltDataTimes')[0].value != null) {
                $scope.txtNomeFiltros = 'Filtrado por ' + $('#sltDataTimes')[0].value;
                contagem++;
            }
            if ($scope.nomeTipoUsuarioSelecionado != '' && $scope.nomeTipoUsuarioSelecionado != null) {
                contagem == 0 ? $scope.txtNomeFiltros = 'Filtrado por ' + $scope.nomeTipoUsuarioSelecionado : $scope.txtNomeFiltros += ' > ' + $scope.nomeTipoUsuarioSelecionado;
                contagem++;
            }
            if ($scope.nomeStatusUsuarioSelecionado != '' && $scope.nomeStatusUsuarioSelecionado != null) {
                contagem == 0 ? $scope.txtNomeFiltros = 'Filtrado por ' + $scope.nomeStatusUsuarioSelecionado : $scope.txtNomeFiltros += ' > ' + $scope.nomeStatusUsuarioSelecionado;
                contagem++;
            }
        };
        $scope.abrirOrdenar = function () {
            $scope.ordenarTimeAberto = !$scope.ordenarTimeAberto;
            if ($scope.ordenarTimeAberto) {
                $('#idOrdenarTime').show();
            }
            else
                $('#idOrdenarTime').hide();
        };
        var ordemNome = false;
        var ordemData = false;
        $scope.ordenarTime = function (pDados, pValue) {
            $scope.trocarOrdem = false;
            var textoOrd = document.getElementById("idTxtOrdenarTime");
            document.getElementById("txtOrdenacaoAlfabeticaTime").innerText = 'A-Z';
            document.getElementById("txtOrdenacaoDataTime").innerText = 'Data';
            if (pDados == 'n') {
                if (!ordemNome) {
                    $scope.reverse = false;
                    statusOrdenacao = 'statusUsuario';
                    $('#imgOrdenacaoTime').attr('src', "".concat($rootScope.bucket, "/imgOrdemCrescente.png"));
                    textoOrd.innerText = 'A-Z';
                    document.getElementById("txtOrdenacaoAlfabeticaTime").innerText = 'Z-A';
                }
                else {
                    $scope.reverse = true;
                    statusOrdenacao = '-statusUsuario';
                    $('#imgOrdenacaoTime').attr('src', "".concat($rootScope.bucket, "/imgOrdemDecrescente.png"));
                    textoOrd.innerText = 'Z-A';
                    document.getElementById("txtOrdenacaoAlfabeticaTime").innerText = 'A-Z';
                }
                ordemData = false;
                ordemNome = !ordemNome;
            }
            else if (pDados == 'd') {
                if (!ordemData) {
                    $scope.reverse = false;
                    statusOrdenacao = 'statusUsuario';
                    $('#imgOrdenacaoTime').attr('src', "".concat($rootScope.bucket, "/imgOrdemCrescente.png"));
                    textoOrd.innerText = 'Menor data primeiro';
                    document.getElementById("txtOrdenacaoDataTime").innerText = 'Maior data';
                }
                else {
                    $scope.reverse = true;
                    statusOrdenacao = '-statusUsuario';
                    $('#imgOrdenacaoTime').attr('src', "".concat($rootScope.bucket, "/imgOrdemDecrescente.png"));
                    textoOrd.innerText = 'Maior data primeiro';
                    document.getElementById("txtOrdenacaoDataTime").innerText = 'Menor data';
                }
                ordemNome = false;
                ordemData = !ordemData;
            }
            $scope.tituloOrdenacao = textoOrd.innerText;
            $scope.dadosParaOrdenacao = pDados;
            $scope.valorOrdenar = pValue;
            $scope.propertyName = [statusOrdenacao, $scope.valorOrdenar];
            $scope.ordenarTimeAberto = false;
            $('#idOrdenarTime').hide();
        };
        $scope.alterarOrdenacaoTime = function () {
            $scope.ordenarTime($scope.dadosParaOrdenacao, $scope.valorOrdenar);
        };
        $scope.zerarCamposOrdenacao = function () {
            $scope.reverse = false;
            statusOrdenacao = 'statusUsuario';
            $scope.valorOrdenar = 'nome';
            $scope.propertyName = [statusOrdenacao, $scope.valorOrdenar];
            $scope.trocarOrdem = true;
            $scope.tituloOrdenacao = '';
            document.getElementById("idTxtOrdenarTime").innerText = 'Ordenar';
            document.getElementById("txtOrdenacaoAlfabeticaTime").innerText = 'A-Z';
            document.getElementById("txtOrdenacaoDataTime").innerText = 'Data';
        };
        $scope.detalhesUsuarios = function (pUsuario) {
            _usuarioSelecionado = pUsuario;
            $scope.objectIdLoginUsuarioSelecionado = _usuarioSelecionado.objectIdLogin;
            $scope.nomeCompletoUsuarioSelecionado = _usuarioSelecionado.nomeCompleto;
            $scope.statusUsuarioSelecionado = _usuarioSelecionado.corStatus;
            $scope.emailUsuarioSelecionado = _usuarioSelecionado.email.split('@')[0];
            $scope.emailUsuarioSelecionadoAntigo = _usuarioSelecionado.email.split('@')[0];
            $scope.dominioAddUsuario = _usuarioSelecionado.email.split('@')[1];
            $scope.tipoUsuarioSelecionado = _usuarioSelecionado.tipoUsuario;
            $scope.dataInclusaoUsuarioSelecionado = _usuarioSelecionado.usuarioDesde;
            $scope.nomeUsuarioSelecionado = _usuarioSelecionado.nome;
            $scope.sobrenomeUsuarioSelecionado = _usuarioSelecionado.sobrenome;
            $scope.objectIdLogin = _usuarioSelecionado.objectIdLogin;
            $scope.divDetalhes = true;
        };
        $scope.voltarListaUsuarios = function () {
            $scope.divDetalhes = false;
            $scope.editandoUsuario = false;
            $scope.adicionandoUsuario = false;
            $scope.msgEmailJaexiste = '';
            $scope.msgTimeNaoPossuiSuperlogica = '';
        };
        $scope.finalizarEdicaoContato = function (pDados) {
            if (!pDados) {
                $scope.nomeUsuarioSelecionado = _usuarioSelecionado.nome;
                $scope.sobrenomeUsuarioSelecionado = _usuarioSelecionado.sobrenome;
                $scope.emailUsuarioSelecionado = _usuarioSelecionado.email.split('@')[0];
            }
            else {
                $scope.emailUsuarioSelecionadoAntigo = $scope.emailUsuarioSelecionado;
            }
            $scope.editandoUsuario = false;
            $scope.adicionandoUsuario = false;
        };
        $scope.adicionarUsuario = function () {
            $scope.nomeUsuarioSelecionado = '';
            $scope.sobrenomeUsuarioSelecionado = '';
            $scope.emailUsuarioSelecionado = '';
            $scope.slctTipoUsuario = $scope.podeAdicionar ? 'U' : 'V';
            $scope.nomeTipoUsuarioAdd = $scope.podeAdicionar ? 'Usuário' : 'Visão';
            $scope.adicionandoUsuario = true;
            $scope.editandoUsuario = false;
            $scope.divDetalhes = true;
            setTimeout(function () { $('#idInptNomeUsuario').focus(); }, 90);
            $scope.dominioAddUsuario = $rootScope.Login.email.split('@')[1];
        };
        $scope.editarUsuarioFora = function (pUsuario) {
            $scope.detalhesUsuarios(pUsuario);
            setTimeout(function () { $scope.editarUsuarioSelecionado(); $scope.$apply(); }, 90);
        };
        $scope.editarUsuarioSelecionado = function () {
            $scope.editandoUsuario = true;
            $scope.adicionandoUsuario = false;
            $('#idInptNomeUsuario').focus();
        };
        $scope.salvarEditarUsuario = function () {
            if ($scope.editandoUsuario)
                $scope.validacaoEmail('e');
            else
                $scope.validacaoEmail('a');
        };
        $scope.salvarNovoUsuario = function () {
            $http({
                method: 'POST',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/time/usuario/salvar"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto
                },
                params: {
                    nome: $scope.nomeUsuarioSelecionado,
                    sobrenome: $scope.sobrenomeUsuarioSelecionado,
                    email: $scope.mandarEmail,
                    tipo: $scope.slctTipoUsuario,
                    empresa: $rootScope.empresaUsuario,
                    login: null,
                    objectIdTime: $rootScope.Login.timeusuarios.objectIdTime
                }
            }).then(function (response) {
                $('#dvMensagemEmailJaExiste').fadeOut();
                $scope.abrirPopupConclusao();
                mixpanel.track("Time | Adicionou usuário no time", {
                    "Nome": $scope.nomeUsuarioSelecionado,
                    "Sobrenome": $scope.sobrenomeUsuarioSelecionado,
                    "Email": $scope.mandarEmail,
                    "Tipo": $scope.slctTipoUsuario,
                    "Local_IP": $rootScope.ip
                });
            }).catch(function (error) {
                if (error.data.origem === "email") {
                    $('#dvMensagemEmailJaExiste').fadeIn();
                    $scope.msgEmailJaexiste = error.data.message;
                }
                else if (error.data.origem === "superlogica") {
                    $('#dvMensagemEmailJaExiste').fadeOut();
                    $scope.msgEmailJaexiste = null;
                    $scope.msgTimeNaoPossuiSuperlogica = error.data.message;
                    $scope.erroCodigoSuperlogica = true;
                    $scope.popupErroAddUsuario = true;
                    $scope.naoAdicionarUsuario();
                }
            });
        };
        $scope.editarUsuario = function () {
            $http({
                method: 'POST',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/time/usuario/editar"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                data: {
                    objectIdLogin: $scope.objectIdLogin,
                    email: $scope.mandarEmail,
                    nome: $scope.nomeUsuarioSelecionado,
                    sobrenome: $scope.sobrenomeUsuarioSelecionado,
                    empresa: $rootScope.empresaUsuario,
                    alterarEmail: $scope.alterarEmail
                }
            }).then(function (response) {
                $scope.abrirPopupConclusao();
                mixpanel.track("Time | Editou usuário do time", {
                    "Nome": $scope.nomeUsuarioSelecionado,
                    "Sobrenome": $scope.sobrenomeUsuarioSelecionado,
                    "Email": $scope.mandarEmail,
                    "Tipo": $scope.tipoUsuarioSelecionado,
                    "Local_IP": $rootScope.ip
                });
            });
        };
        $scope.validacaoEmail = function (pDados) {
            if ($scope.emailUsuarioSelecionado !== $scope.emailUsuarioSelecionadoAntigo) {
                $scope.alterarEmail = true;
                $scope.emailLogoff = $scope.emailUsuarioSelecionadoAntigo + '@' + $scope.dominioAddUsuario;
            }
            else
                $scope.alterarEmail = false;
            $scope.mandarEmail = $scope.emailUsuarioSelecionado + '@' + $scope.dominioAddUsuario;
            pDados === 'e' ? $scope.editarUsuario() : $scope.salvarNovoUsuario();
        };
        $scope.abrirPopupConclusao = function () {
            $scope.popupConclusaoAddEditar = true;
            $('#idFundoPopupTime').fadeIn();
        };
        $scope.fecharPopupConclusao = function () {
            $scope.popupConclusaoAddEditar = false;
            $('#idFundoPopupTime').fadeOut();
            if ($scope.adicionandoUsuario) {
                $scope.voltarListaUsuarios();
                $scope.consultarDetalhesTimeUsuarios();
            }
            else {
                if ($scope.alterarEmail && ($scope.emailLogoff === $rootScope.Login.email)) {
                    return LoginService.logout();
                }
                else {
                    $scope.finalizarEdicaoContato(true);
                    $scope.consultarDetalhesTimeUsuarios();
                }
            }
        };
        $scope.abrirTiposUsuario = function () {
            $scope.slctTipoUsuarioAberto = !$scope.slctTipoUsuarioAberto;
            if ($scope.slctTipoUsuarioAberto) {
                $('#idSlctTipoUsuario').show();
            }
            else
                $('#idSlctTipoUsuario').hide();
        };
        $scope.selecionarTipoUsuario = function (pDados, pNome) {
            $scope.slctTipoUsuario = pDados;
            $scope.nomeTipoUsuarioAdd = pNome;
            $scope.abrirTiposUsuario();
        };
        $('#idInptEmailUsuarioSelecionado').bind('input propertychange', function () {
            var texto = $(this).val();
            texto = texto.replace(/[\@\ ]/g, '');
            $(this).val(texto);
        });
        $scope.abrirPopupResetarSenha = function () {
            $scope.senhaJaResetada = false;
            $scope.popupResetarSenha = true;
            $('#idFundoPopupTime').fadeIn();
        };
        $scope.fecharPopupResetarSenha = function () {
            $scope.popupResetarSenha = false;
            $scope.falhaResetarSenha = false;
            $('#idFundoPopupTime').fadeOut();
        };
        $scope.resetarSenhaUsuario = function () {
            $http({
                method: 'POST',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/login/resetarSenha/").concat($scope.objectIdLoginUsuarioSelecionado),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': '0881d3992f',
                    'Authorization': 'Bearer ' + $rootScope.Token
                }
            }).then(function () {
                $scope.senhaJaResetada = true;
            })
                .catch(function () {
                $scope.falhaResetarSenha = true;
            });
        };
        $scope.consultarDetalhesTimeUsuarios();
        $scope.montarNomeFiltros();
        document.getElementById("idTxtStatusUsuario").innerText = 'Ativo';
        $scope.consultarCapturasHoje = function () {
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/login/consultarContatosMixpanel"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto
                }
            }).then(function (rConsultarContatosMixpanel) {
                if (rConsultarContatosMixpanel.data) {
                    rConsultarContatosMixpanel.data = false;
                    mixpanel.track("Captura Extensão | O usuario capturou contatos hoje", {});
                }
            });
        };
        $scope.consultarCapturasHoje();
    }]);
