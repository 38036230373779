'use strict';
angular.module('ramperv3')
    .service('AgenciaService', ['$rootScope', '$http', 'RAMPER_V3_API_BASE_URL', function ($rootScope, $http, RAMPER_V3_API_BASE_URL) {
        $rootScope.ramperV3ApiBaseUrl = RAMPER_V3_API_BASE_URL;
        function consultarAgenciasLogins(usuario) {
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/agencias/consultarAgenciaLogins"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': usuario ? usuario.iDCrypto : $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                }
            }).then(function (res) {
                var agencias = res.data;
                if (agencias.length > 0) {
                    $rootScope.mostrarTrocarConta = true;
                    agencias.forEach(function (agencia) {
                        if (agencia.fotoPerfil) {
                            agencia.fotoPerfil = "".concat($rootScope.bucketUserPhotos, "/usuariosRamper/") + agencia.objectIdLogin.toString() + "/" + agencia.fotoPerfil;
                        }
                        else {
                            agencia.fotoPerfil = "".concat($rootScope.bucket, "/avatarPadrao.jpg");
                        }
                        if ($rootScope.Login.email !== agencia.email) {
                            $rootScope.showBrowserLogins.push(agencia);
                        }
                    });
                }
            });
        }
        return {
            consultarAgenciasLogins: consultarAgenciasLogins
        };
    }]);
