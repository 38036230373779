angular.module("ramperv3").controller("MonsterEsqueciMinhaSenhaController", [
    "$rootScope",
    "$scope",
    "$location",
    "$timeout",
    "MonsterToasterService",
    "MonsterEsqueciMinhaSenhaService",
    function ($rootScope, $scope, $location, $timeout, MonsterToasterService, MonsterEsqueciMinhaSenhaService) {
        /**
         * Google Analytics
         */
        ga("set", "page", document.location.hash);
        ga("send", "pageview");
        /**
         * Estado inicial da página $rootScope
         */
        $rootScope.monsterShowLoading = true;
        setTimeout(function () {
            $rootScope.monsterShowLoading = false;
            $rootScope.$apply();
        }, 1000);
        /**
         * Estado inicial da página $scope
         */
        $scope.user = { email: "" };
        /**
         * Deve abrir a tela 'login'
         */
        $scope.onRedirecionarLogin = function () {
            $location.path("/v2/login");
        };
        /**
         * Deve consumir o serviço para realizar o login na aplicação
         */
        $scope.onRedefinirSenha = function () {
            $rootScope.monsterShowLoading = true;
            MonsterEsqueciMinhaSenhaService.esqueciMinhaSenha($scope.user.email).then(function () {
                MonsterToasterService.showSuccessToaster("E-mail enviado!", "Verifique sua caixa de e-mail e siga as instru\u00E7\u00F5es para redefinir sua senha. Caso n\u00E3o receba, verifique se o e-mail digitado est\u00E1 correto e tente novamente.", 6000);
                $timeout(function () { return $scope.onRedirecionarLogin(); }, 6000);
                $rootScope.monsterShowLoading = false;
            }, function () {
                MonsterToasterService.showErrorToaster("", "Desculpe, ocorreu um erro ao enviar o email de recupera\u00E7\u00E3o de senha. Tente novamente mais tarde.", 6000);
                $rootScope.monsterShowLoading = false;
            });
        };
        /**
         * Evento disparado quando o controlador for destruído
         */
        $scope.$on("$destroy", function () {
            $rootScope.monsterShowLoading = false;
        });
    },
]);
