angular.module('ramperv3')
    .component('monsterModalNoticia', {
    templateUrl: 'views/monsterModalNoticia.html',
    controller: ['$rootScope', '$scope', 'MonsterNoticiaService', 'MonsterToasterService', function ($rootScope, $scope, MonsterNoticiaService, MonsterToasterService) {
            $scope.bucket = $rootScope.bucket;
            /**
             * Deve ser executado quando o componente for carregado
             */
            $scope.$ctrl.$onInit = function () {
                $scope.obterNoticia();
            };
            /**
             * Deve consumir o serviço para procurar por noticia
             */
            $scope.obterNoticia = function () {
                MonsterNoticiaService.obterNoticia().then(function (result) {
                    if (result.data) {
                        $scope.noticia = result.data;
                        $scope.openModal = true;
                    }
                }, function () {
                    MonsterToasterService.showErrorToaster("", "Erro ao carregar not\u00EDcias. Tente novamente.", 6000);
                });
            };
            /**
             * Deve consumir o serviço para não exibir a noticia novamente
             */
            $scope.onNaoExibirNovamente = function () {
                MonsterNoticiaService.naoExibirNoticiaNovamente($scope.noticia._id).then(function () {
                    mixpanel.track("NOT\u00CDCIA | Realizou a a\u00E7\u00E3o para n\u00E3o exibir novamente", { Local_IP: $rootScope.ip });
                }, function () {
                    MonsterToasterService.showErrorToaster("", "Erro ao carregar not\u00EDcias. Tente novamente.", 6000);
                });
                $scope.onFechar();
            };
            /**
             * Deve emitir um evento para o mixpanel
             */
            $scope.onSaibaMais = function () {
                mixpanel.track("NOT\u00CDCIA | Realizou a a\u00E7\u00E3o para saber mais", { Local_IP: $rootScope.ip });
            };
            /**
             * Deve emitir um evento para o mixpanel
             */
            $scope.onLembrarMaisTarde = function () {
                mixpanel.track("NOT\u00CDCIA | Realizou a a\u00E7\u00E3o lembrar mais tarde", { Local_IP: $rootScope.ip });
                $scope.onFechar();
            };
            /**
             * Deve fechar a modal
             */
            $scope.onFechar = function () {
                $scope.openModal = false;
            };
        }],
});
