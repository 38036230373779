'use strict';
angular
    .module('ramperv3')
    .service('FormEngageService', function () {
    var dadosComLabel = function (dadosFormulario) {
        if (dadosFormulario.label !== '') {
            return dadosFormulario;
        }
    };
    var dadosSemLabel = function (dadosFormulario) {
        if (dadosFormulario.label === '') {
            return dadosFormulario;
        }
    };
    var formularioPergunta = function (dadoForm) {
        var camposPergunta = [dadoForm.label, dadoForm.id, dadoForm.name];
        if (['checkbox', 'radio'].includes(dadoForm.type)) {
            camposPergunta = [dadoForm.name, dadoForm.label, dadoForm.id];
        }
        return camposPergunta.find(function (campo) {
            return campo != '';
        });
    };
    var formularioResposta = function (dadoForm) {
        if (['checkbox', 'radio'].includes(dadoForm.type)) {
            return dadoForm.label || 'Verdadeiro';
        }
        return dadoForm.value;
    };
    var retornaDados = function (dadosFiltrados) {
        return dadosFiltrados.map(function (item) {
            var formItem = {};
            formItem.Pergunta = formularioPergunta(item);
            formItem.Resposta = formularioResposta(item);
            return formItem;
        });
    };
    function getDadosForm(dadosFormulario) {
        var dadosForm = dadosFormulario.filter(dadosComLabel);
        return retornaDados(dadosForm);
    }
    function getOutrosDadosForm(dadosFormulario) {
        var dadosForm = dadosFormulario.filter(dadosSemLabel);
        return retornaDados(dadosForm);
    }
    return {
        getDadosForm: getDadosForm,
        getOutrosDadosForm: getOutrosDadosForm
    };
});
