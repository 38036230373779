'use strict';
angular
    .module('ramperv3')
    .service('VoipService', ['$rootScope', '$http', 'RAMPER_V3_API_BASE_URL', function ($rootScope, $http, RAMPER_V3_API_BASE_URL) {
        $rootScope.ramperV3ApiBaseUrl = RAMPER_V3_API_BASE_URL;
        function getAccountAndExtension() {
            $http({
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/voip/conta"),
            }).then(function (response) {
                return response;
            }).catch(function (error) { return error; });
            return;
        }
        function getAccountBalance() {
            $http({
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/voip/saldo"),
            }).then(function (response) {
                return addZeroToBalance(response.data.saldo);
            }).catch(function (error) { return error; });
            return;
        }
        function addZeroToBalance(balance) {
            $rootScope.saldoSemFormatar = balance;
            return $rootScope.mostrarSaldo = balance.toLocaleString('pt-br', { minimumFractionDigits: 2 });
        }
        var defaultWidth = 305;
        var defaultHeight = 550;
        function presetWebphone(phoneNumber) {
            var _a = centralize(), top = _a.top, left = _a.left;
            $http({
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/voip/preset-webphone"),
            }).then(function (response) {
                var data = response.data;
                return window.open("".concat(data, "?phone-number=").concat(phoneNumber), 'webphone', "scrollbars=no, width=".concat(defaultWidth, ", height=").concat(defaultHeight, ", top=").concat(top, ", left=").concat(left, ","));
            }).catch(function (error) { return error; });
        }
        function centralize() {
            var dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
            var dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;
            var width = window.innerWidth
                ? window.innerWidth
                : document.documentElement.clientWidth
                    ? document.documentElement.clientWidth
                    : window.screen.width;
            var height = window.innerHeight
                ? window.innerHeight
                : document.documentElement.clientHeight
                    ? document.documentElement.clientHeight
                    : window.screen.height;
            var systemZoom = width / window.screen.availWidth;
            var left = (width - defaultWidth) / 2 / systemZoom + dualScreenLeft;
            var top = (height - defaultHeight) / 2 / systemZoom + dualScreenTop;
            return { top: top, left: left };
        }
        return {
            getAccountAndExtension: getAccountAndExtension,
            getAccountBalance: getAccountBalance,
            presetWebphone: presetWebphone,
        };
    }]);
