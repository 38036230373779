angular.module('ramperv3')
    .controller('CadenciasController', ['$rootScope', '$scope', '$http', '$locale', 'PipedriveService', 'PermissionamentoService', 'MonsterToasterService', function ($rootScope, $scope, $http, $locale, PipedriveService, PermissionamentoService, MonsterToasterService) {
        ga('set', 'page', document.location.hash);
        ga('send', 'pageview');
        $locale.NUMBER_FORMATS.GROUP_SEP = '.';
        $rootScope.consultarStatusUsuario();
        $scope.menuNavAberto = true;
        var _skipContatos = -10;
        var _campoOrdem = 'nomeContato';
        var _ordem = 1;
        var _filtroResposta = null;
        var _inptAssunto = true;
        var _inptAssuntoAutomacao = true;
        var _filtros = {
            buscar: '',
            objectId: '',
            tipoDaChave: '',
            dataInicio: '',
            dataFim: '',
            status: '',
            segmentacao: '',
            campoOrdem: 'disparosNome',
            ordem: '1',
            origem: ''
        };
        var _filtrosEstatisticas = {
            objectId: '',
            dataInicio: '',
            dataFim: '',
            status: ''
        };
        $scope.buscarContato = '';
        $('#dvMensagemDataInicioCadencia').hide();
        $('#dvMensagemDataInicioCadenciaB').hide();
        $('#dvMensagemDataInicioCadenciaEngage').hide();
        $('#dvMensagemDataInicioCadenciaEngageB').hide();
        $('#dvMensagemIntervaloDias').hide();
        $('#dvMensagemIntervaloDiasB').hide();
        $('#dvMensagemDiasSemana').hide();
        $('#dvMensagemDiasSemanaB').hide();
        $('#dvMensagemAssunto').hide();
        $('#dvMensagemAssuntoB').hide();
        $('#dvMensagemEmail').hide();
        $('#dvMensagemEmailB').hide();
        $('#dvMensagemHorarioInicial').hide();
        $('#dvMensagemAssuntoBounce').hide();
        $('#dvMensagemSalvarTemplateBounce').hide();
        $('#dvMensagemSalvarTemplateA').hide();
        $('#dvMensagemSalvarTemplateB').hide();
        $('#dvMensagemEmailBounce').hide();
        $('#dvMensagemSelecionarTemplateA').hide();
        $('#dvMensagemSelecionarTemplateB').hide();
        $('#dvMensagemSelecionarTemplateBounce').hide();
        $('#idFundoPopupCadencica').hide();
        $('#labelErroValueEmail').hide();
        $('#linkGlobalWebhook').hide();
        $scope.imgEntregues = '';
        $scope.imgAbertos = '';
        $scope.imgRespondidos = '';
        $scope.imgInteresses = '';
        $scope.imgBounces = '';
        $scope.configuracaoEmailErrada = false;
        $scope.dvMensagemSegCad = false;
        $('#dvMensagemNomeCadencia').hide();
        $rootScope.redirectCadencias();
        $scope.mostrarAnalytics = false;
        $('#dataFiltroDefinida').hide();
        var endDate = new Date();
        var startDate = new Date();
        startDate.setDate(startDate.getDate() - 7);
        $('#spanEmptyAnalytics').hide();
        var _dataHoje = new Date();
        _dataHoje.setDate(_dataHoje.getDate() - 1);
        var _tresMesesCalculo = new Date();
        _tresMesesCalculo.setDate(_tresMesesCalculo.getDate() + 90);
        _tresMesesCalculo.setHours(0);
        _tresMesesCalculo.setMinutes(0);
        _tresMesesCalculo.setSeconds(0);
        $scope.dataInicioFiltro = null;
        $scope.dataFinalFiltro = null;
        $('#dvDetalhesResumo').hide();
        $scope.filtroUsuarioAberto = false;
        var _usuarioSelecionadoFiltroAnalytics = false;
        var _countDiv = 1;
        $scope.exibeVariacaoTesteAB = true;
        $scope.nomeBtnVoltar = 'Voltar';
        $scope.nomeBtnAvancar = 'AVANÇAR';
        $scope.nomeBtnExcluirCad = '';
        $scope.nomeSelecionado = '';
        $scope.popupRemove = false;
        $scope.ordemCadencia = "disparosNome";
        $scope.trocarOrdem = true;
        $scope.filtroDetalheContato = false;
        $scope.buscaDetalheContato = false;
        var _cadenciaSelecionada = null;
        var _clickCheckTodos = false;
        var qtdEnviosPorDia = $rootScope.Login.qtdEnvioDiario ? $rootScope.Login.qtdEnvioDiario : 200;
        $scope.popupFinalizarCadencia = false;
        $scope.slideUp = true;
        $scope.popupTipoCadenciaCampanha = false;
        $scope.filtroStatus = '-';
        $scope.filtroConfiabilidade = 'T';
        if ($rootScope.administrativoOuVisao)
            $scope.filtroUsuario = '-';
        else
            $scope.filtroUsuario = $rootScope.Login._id;
        $scope.filtroSegmentacao = '-';
        $scope.filtroContato = '-';
        $scope.acao = 'a';
        $scope.destino = 'd';
        $scope.especificacao = 'e';
        $scope.popupAutomacaoBounce = false;
        $scope.popupExcluirBounce = false;
        $scope.popupTesteAB = false;
        $rootScope.divAutRespondidos = false;
        $rootScope.divAutNaoRespondidos = false;
        $rootScope.addAutRespostas = true;
        $scope.popupAutNaoResp = false;
        $rootScope.seqCriacaoCad = false;
        $scope.cadenciaFinalizada = false;
        $scope.cadenciaAtiva = false;
        $scope.selectQuemRecebe = false;
        $rootScope.contatoBounce = false;
        $scope.popupDetalhesContatos = false;
        $rootScope.popupAutResp = false;
        $scope.popupFiltrarCadencias = false;
        $scope.popupFiltrarContatosCadencias = false;
        $rootScope.editarDataDisparo = false;
        $rootScope.editarTemplateDisparo = false;
        $rootScope.btnEditarDataTemplate = false;
        $rootScope.subMenuDeCadencias = false;
        $rootScope.subMenuCadenciasEspecifica = false;
        $scope.showBrowserContatos = [];
        $scope.totalSelecionados = 0;
        $scope.divSegundaTelaPopupSeq = false;
        $scope.divSegundaTelaPopupSeqB = false;
        $scope.mostrarCalendario = false;
        $rootScope.btnSalvarNovoTemplate = false;
        $rootScope.popupCompararCadencias = false;
        $scope.numeroContatosAbordados = false;
        $scope.criacaoCadenciaAB = true;
        $scope.dataInicioCadencia = new Date();
        $rootScope.semAutomacoes = true;
        $scope.linkInboudGerado = false;
        $scope.selecionarTemplateAut = false;
        $scope.mostrarBtnInteresseCrm = false;
        $scope.mostrarBtnInteresseInbound = false;
        $scope.listaFunisCadP = [];
        $scope.listaMotivosCadP = [];
        $scope.listaEtapasCadP = [];
        $scope.listaEtapasOriginal = [];
        $scope.listaUsuariosCadP = [];
        $scope.funisSelecionados = [];
        $scope.reengajamento = { checkDiasRetroativos: false, diasRetroativos: 1 };
        $scope.dataContatosRetroativos = new Date();
        $scope.dataContatosRetroativos.setDate($scope.dataContatosRetroativos.getDate() - $scope.reengajamento.diasRetroativos);
        $scope.dataFinalFiltroAnalytics = new Date();
        $scope.dataInicioFiltroAnalytics = new Date();
        $scope.dataInicioFiltroAnalytics.setDate($scope.dataInicioFiltroAnalytics.getDate() - 30);
        var _contPagina = 1;
        $scope.mensagemErroConfigEmailCadenciaAdm = null;
        setTimeout(function () {
            $scope.$broadcast('getEmpty', { tela: "CA", callImage: 'emptyInicio.svg', calltoation: $scope.abrirFormNovaCadencia, textoEmpty: 'Você ainda não criou nenhuma cadência dentro do Ramper.', botaoEmpty: 'CRIAR CADÊNCIA' });
        }, 100);
        var divEtapasCadenciaP = $('#idEtapasCadP');
        var divUsuariosCadenciaP = $('#idUsuariosCadP');
        $scope.msgChat = { texto: '', chatChecked: false };
        $scope.msgAgenda = { texto: '', agendaChecked: false };
        $scope.novaCadencia = {
            nome: '',
            tipoCadencia: '',
            alteracao: false,
            segmentacao: {
                _id: null,
                alta: false,
                media: false,
                baixa: false,
                sem: false
            },
            disparoSequencias: {
                sequencias: [
                //
                ]
            },
            automacoes: [
            //
            ]
        };
        $scope.envioBuffer = {
            sequencia: null,
            testeAB: null,
            diasEnvio: {
                1: false,
                2: false,
                3: false,
                4: false,
                5: false,
                6: false,
                7: false
            },
            horaInicio: null,
            horaFim: null,
            dataInicio: null,
            enviosPorDia: null,
            intervaloDias: null,
            template: null,
            emailDeEnvio: null,
            enviadoComo: null,
            quemRecebe: null,
            assunto: null,
            mensagem: null
        };
        $scope.sequenciaExtenso = 'Primeiro';
        $scope.testeAB = 'B';
        $scope.listaContatos = [];
        $scope.listaSegmentacoesPadrao = [];
        $scope.listaSegmentacoes = [];
        $scope.listaSegmentacoesDisp = [];
        $scope.listaSegmentacoesEmailNaoResp = [];
        $scope.listaUsuarios = [];
        $scope.listaContatosPaginada = [];
        $scope.listaIntegracoes = [];
        $rootScope.mostrarMenu = true;
        $scope.popupNovoTemplate = false;
        $scope.semCadencias = false;
        $scope.semCadenciasFiltro = false;
        $scope.semCadenciasBusca = false;
        $scope.listaCadencias = null;
        $scope.listaSequencias = null;
        $scope.templates = [];
        $scope.pastasTemplates = [];
        $scope.emails = [];
        $scope.mostrarCheckConfiabilidade = false;
        $scope.cadenciaComMesmoParametro = false;
        $scope.botaoBuscarVisivel = false;
        $scope.conteudoTemplate = '';
        $scope.conteudoTemplateOriginal = '';
        $scope.emCasoBounce = false;
        $('#menuCadencias').addClass('nav-item-active');
        $('#subMenuCadenciasDestaque').addClass('nav-item-active');
        $scope.arraySegmentacoesLinkedin = ['Administração agrícola', 'Administração de serviços', 'Administração governamental', 'Agricultura', 'Alimentos e bebidas', 'Animação', 'Armazenagem',
            'Arquitetura e planejamento', 'Arrecadação de recursos', 'Artes cênicas', 'Artes e artesanato', 'Atacado', 'Atendimento ao consumidor', 'Atendimento médico e hospitalar', 'Atendimento médico e psiquiátrico',
            'Atividades parlamentares', 'Automação industrial', 'Aviação e aeroespacial', 'Bancos', 'Bancos de investimento', 'Belas-artes', 'Bens de consumo', 'Bibliotecas', 'Biotecnologia', 'Capital de risco e participações privadas',
            'Comércio e desenvolvimento internacional', 'Composição e revisão de textos', 'Construção', 'Construção de ferrovia', 'Construção naval', 'Consultoria de gerenciamento', 'Contabilidade', 'Cosmética', 'Defesa e espaço',
            'Desenvolvimento de programas', 'Design', 'Design gráfico', 'Dispositivos médicos', 'Editoração', 'Educação à distância', 'Embalagens e recipientes', 'Engenharia civil', 'Engenharia mecânica ou industrial',
            'Ensino fundamental/médio', 'Ensino superior', 'Entrega de encomendas e fretes', 'Entretenimento', 'Esportes', 'Execução da lei', 'Filantropia', 'Fotografia', 'Gabinete presidencial', 'Gestão de investimentos',
            'Gestão de organização sem fins lucrativos', 'Gestão educacional', 'Hardware', 'Hotelaria', 'Imobiliária', 'Imóveis comerciais', 'Importação e exportação', 'Impressão', 'Indústria automativa', 'Indústria farmacêutica',
            'Indústria química', 'Indústria têxtil', 'Instalações e serviços de recreação', 'Instituições religiosas', 'Internet', 'Jogos de azar', 'Jogos de computador', 'Jornais', 'Judiciário', 'Laticínios', 'Lazer, viagens e turismo',
            'Linhas aéreas/Aviação', 'Logística e cadeia de suprimentos', 'Manufatura de eletrônicos', 'Maquinário', 'Marketing e publicidade', 'Materiais de construção', 'Materiais esportivos', 'Medicina alternativa', 'Mercados de capital',
            'Mídia de difusão', 'Mídia online', 'Militar', 'Mineração e metais', 'Móveis', 'Museus e instituições', 'Música', 'Nanotecnologia', 'Organização cívica e social', 'Organização política', 'Organizações de pesquisa e orientação',
            'Papel e produtos florestais', 'Pesca', 'Pesquisa', 'Pesquisa de mercado', 'Petróleo e energia', 'Plástico', 'Política pública', 'Prática jurídica', 'Prática médica', 'Produção de mídia', 'Produtos alimentícios', 'Produtos de luxo e jóias',
            'Produtos eletrônicos', 'Recrutamento e seleção', 'Recursos humanos', 'Recursos renováveis e meio ambiente', 'Redes', 'Redes sem fio', 'Relações governamentais', 'Relações internacionais', 'Relações públicas e comunicações',
            'Resolução alternativa de litígios', 'Restaurantes', 'Roupas e moda', 'Saúde, bem-estar e educação física', 'Segurança de redes e computadores', 'Segurança e invetigações', 'Segurança pública', 'Seguros', 'Semicondutores',
            'Serviços ambientais', 'Serviços da informação', 'Serviços financeiros', 'Serviços individuais e familiares', 'Serviços jurídicos', 'Serviços para eventos', 'Serviços públicos', 'Software', 'Supermercados', 'Suprimentos e equipamentos comerciais',
            'Tabaco', 'Tecnologia da informação e serviços', 'Telecomunicações', 'Terceirização e offshoring', 'Tradução e localização', 'Transporte/caminhões/trens', 'Transporte marítimo', 'Treinamento e orientação profissional',
            'Varejo', 'Veterinária', 'Vidro, cerâmica e concreto', 'Vinhos e destilados'];
        $scope.arrayCaptura = ['15', '30', '45'];
        $scope.listaEstados = [
            { uf: 'AC', nome: 'Acre' },
            { uf: 'AL', nome: 'Alagoas' },
            { uf: 'AP', nome: 'Amapá' },
            { uf: 'AM', nome: 'Amazonas' },
            { uf: 'BA', nome: 'Bahia' },
            { uf: 'CE', nome: 'Ceará' },
            { uf: 'DF', nome: 'Distrito Federal' },
            { uf: 'ES', nome: 'Espirito Santo' },
            { uf: 'GO', nome: 'Goiás' },
            { uf: 'MA', nome: 'Maranhão' },
            { uf: 'MS', nome: 'Mato Grosso do Sul' },
            { uf: 'MT', nome: 'Mato Grosso' },
            { uf: 'MG', nome: 'Minas Gerais' },
            { uf: 'PA', nome: 'Pará' },
            { uf: 'PB', nome: 'Paraíba' },
            { uf: 'PR', nome: 'Paraná' },
            { uf: 'PE', nome: 'Pernambuco' },
            { uf: 'PI', nome: 'Piauí' },
            { uf: 'RJ', nome: 'Rio de Janeiro' },
            { uf: 'RN', nome: 'Rio Grande do Norte' },
            { uf: 'RS', nome: 'Rio Grande do Sul' },
            { uf: 'RO', nome: 'Rondônia' },
            { uf: 'RR', nome: 'Roraima' },
            { uf: 'SC', nome: 'Santa Catarina' },
            { uf: 'SP', nome: 'São Paulo' },
            { uf: 'SE', nome: 'Sergipe' },
            { uf: 'TO', nome: 'Tocantins' }
        ];
        $scope.qtdCapturasSelecionada = null;
        $scope.estadoSelecionado = null;
        $scope.segmentacaoICP = null;
        $scope.inputCargoICP = null;
        $scope.idICPSelecionado = null;
        $scope.acessoEngage = {
            engageAtivo: false,
            cadenciaEngageAtiva: false
        };
        $scope.ultimaNovaCadEscolhida = null;
        $scope.colapseOrExpandMenu = function (menu) {
            if (menu == 'filtros') {
                $scope.menuNavAberto = !$scope.menuNavAberto;
                if ($scope.menuNavAberto) {
                    if ($scope.mostrarAnalytics == true) {
                        $scope.mostrarAnalytics = false;
                        $scope.fecharAnalytics();
                    }
                    $("#expandOrCollapseSideMenu").addClass("collapseMenuNavFiltro");
                    $("#menuNavs").removeClass("menuNavCol");
                    $("#expandOrCollapseSideMenu").removeClass("expandMenuNavFiltro");
                    setTimeout(function () {
                        $("#tituloMenuLateral").removeClass("escondeMenuLateral");
                        $("#conteudoMenuLateral").removeClass("escondeMenuLateral");
                    }, 300);
                }
                else {
                    $("#expandOrCollapseSideMenu").removeClass("collapseMenuNavFiltro");
                    $("#menuNavs").addClass("menuNavCol");
                    $("#tituloMenuLateral").addClass("escondeMenuLateral");
                    $("#conteudoMenuLateral").addClass("escondeMenuLateral");
                    $("#expandOrCollapseSideMenu").addClass("expandMenuNavFiltro");
                }
            }
        };
        $scope.verificarFiltros = function () {
            if ($scope.filtroHabilitado == true) {
                $scope.recarregarPagina();
            }
            else {
                $scope.removerFiltros();
            }
        };
        $scope.removerFiltros = function () {
            $('#sltDataAnalytics')[0].value = null;
            $scope.dataInicioFiltro = null;
            $scope.dataFinalFiltro = null;
            $scope.segmentoFiltro = null;
            $scope.usuarioFiltroCad = null;
            $scope.sequenciaFiltro = null;
            $scope.statusFiltro = null;
            document.getElementById("idUsuariosCadFiltro").innerText = 'Usuários';
            document.getElementById("idStatusCadFiltro").innerText = 'Status';
            document.getElementById("idSegmentacaoFiltro").innerText = 'Segmentação';
            $scope.buscarUsuariosCad = '';
            $scope.buscarFiltroSegmentacao = '';
            $scope.statusCadFiltroSelecionado = null;
            $scope.segmentacaoFiltroSelecionado = null;
            $scope.nomeUsuarioFiltroCad = null;
            $scope.nomeSegmentacaoFiltroSelecionado = null;
            $('#sltData')[0].value = null;
            $('#sltDataFiltroCadencia')[0].value = null;
            setTimeout(function () { $('#sltDataFiltroCadencia').data('DRPEx').clear(); }, 10);
            _limite = 10;
            _filtros = {
                buscar: '',
                objectId: '',
                tipoDaChave: '',
                dataInicio: '',
                dataFim: '',
                status: '',
                segmentacao: '',
                campoOrdem: 'disparosNome',
                ordem: '1'
            };
            setTimeout(function () {
                $scope.consultarSegmentacoesComContatos(true, false);
            }, 10);
        };
        $scope.removerFiltrosContatos = function () {
            $('#sltDataFiltroContatosCadencia')[0].value = null;
            $scope.dataInicioFiltroContato = null;
            $scope.dataFinalFiltroContato = null;
            $scope.contatoFiltroCad = '';
            $('#bolinhaSimFiltroContato').removeClass('corImgOriginalResp').addClass('corImgClasResp');
            $('#bolinhaNeutraFiltroContato').removeClass('corImgOriginalResp').addClass('corImgClasResp');
            $('#bolinhaNaoFiltroContato').removeClass('corImgOriginalResp').addClass('corImgClasResp');
            $('#cbAltaContatosInput').prop('checked', false);
            $('#cbMediaContatosInput').prop('checked', false);
            $('#cbBaixaContatosInput').prop('checked', false);
            $('#cbSemContatosInput').prop('checked', false);
            $("#enviadosCad").candlestick('reset');
            $("#abertosCad").candlestick('reset');
            $("#cliquesCad").candlestick('reset');
            _filtroResposta = null;
        };
        $scope.consultarCadencias = function (pFiltros) {
            $scope.ultimoStatusFiltrado = pFiltros.status;
            $scope.totalSelecionados = 0;
            _clickCheckTodos = false;
            $('#checkCadencias').prop('checked', false);
            $rootScope.monsterShowLoading = true;
            consultarTemplates();
            if (!$scope.listaIntegracoes.length)
                $scope.consultarIntegracoes();
            $scope.consultarSegmentacoesComContatos(true, false);
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/disparos/consultarCadenciasTela"),
                headers: {
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                params: {
                    objectId: pFiltros.objectId,
                    buscar: pFiltros.buscar,
                    ordem: "empresa",
                    ordemAscDesc: 1,
                    tipoDaConsulta: 'C',
                    dataInicio: pFiltros.dataInicio,
                    dataFim: pFiltros.dataFim,
                    status: pFiltros.status,
                    segmentacao: pFiltros.segmentacao,
                    origem: pFiltros.origem
                }
            }).then(function (rConsultaCadencias) {
                var validarConfigEmail = 0;
                var contagemCadArray = 0;
                $scope.listaCadencias = [];
                $scope.total = rConsultaCadencias.data.length;
                if (rConsultaCadencias.data.length > 0) {
                    rConsultaCadencias.data.forEach(function (cadencia) {
                        if (cadencia.mensagemErroConfiguracaoEmail && $rootScope.administrativoOuVisao === true) {
                            $scope.mensagemErroConfigEmailCadenciaAdm = "na configuração de e-mail";
                        }
                        cadencia.txtMais = 'mais';
                        cadencia.controleInfos = 0;
                        cadencia.cadSelecionada = '';
                        cadencia.qtdSequenciasVistas = 0;
                        cadencia.checkSelecionado = false;
                        cadencia.linkWebhook = 'https://reengajamento.ramper.com.br/contatos/reengajamentoInbound?objectIdWebHook=' + cadencia.idWebhook;
                        if ((!cadencia.configuracoesEmailValida) && (cadencia.statusOrdem != 3))
                            validarConfigEmail++;
                        if (_contPagina == 1) {
                            if (cadencia.status == 'Ativa') {
                                $scope.listaCadencias.push(cadencia);
                            }
                            contagemCadArray++;
                        }
                        else {
                            $scope.listaCadencias.push(cadencia);
                            contagemCadArray++;
                        }
                        if (rConsultaCadencias.data.length == contagemCadArray) {
                            $scope.esconderEmptyStates();
                            if (_contPagina == 1) {
                                $scope.ultimoStatusFiltrado = 'A';
                                $scope.statusCadFiltroSelecionado = 'A';
                                document.getElementById("idStatusCadFiltro").innerText = 'Ativa';
                                $scope.filtroHabilitado = true;
                                $('#idBtnFiltroCadencias').removeClass().addClass('btnFiltroTempNovoHabilitado');
                                $('#idImgFiltrarCad').attr('src', "".concat($rootScope.bucket, "/filtro-wt-novo.svg"));
                                $scope.total = $scope.listaCadencias.length;
                                $scope.montarNomeFiltros();
                            }
                            ;
                            if ($scope.listaCadencias.length == 0) {
                                $scope.semCadenciasAtivas = true;
                                $scope.total = 0;
                            }
                            ;
                            $rootScope.monsterShowLoading = false;
                            setTimeout(function () { $scope.listaCadencias.forEach(function (cadencia) { $('#link' + cadencia._id).hide(); }); }, 100);
                            /*if (!$rootScope.configuracaoEmailValida && $rootScope.mensagemErroConfiguracaoEmail !== '' && $rootScope.mostrarMensagemErroConfigEmail) {
                                $('.cardInternoNovo').css('height', 'calc(100% - 166px)');
                            }*/
                        }
                    });
                    $scope.configuracaoEmailErrada = validarConfigEmail > 0;
                    _contPagina++;
                }
                else {
                    $rootScope.monsterShowLoading = false;
                    if ($scope.listaCadencias.length <= 0) {
                        if (rConsultaCadencias.config.params.origem == 'campoBuscar') {
                            $scope.esconderEmptyStates();
                            $scope.semCadenciasBusca = true;
                            $scope.buscaOuFiltro = 'Seu busca';
                        }
                        if (rConsultaCadencias.config.params.origem == 'campoFiltrar') {
                            $scope.esconderEmptyStates();
                            $scope.semCadenciasFiltro = true;
                            $scope.buscaOuFiltro = 'Seu filtro';
                        }
                        if (rConsultaCadencias.config.params.origem == '' || rConsultaCadencias.config.params.origem == null) {
                            $scope.esconderEmptyStates();
                            $scope.semCadencias = true;
                        }
                    }
                }
            });
        };
        $scope.identifInputVazio = function (keyEvent) {
            var input = keyEvent.target.value.trim();
            $scope.botaoBuscarVisivel = Boolean(input);
        };
        $scope.consultarCadenciasEstatisticas = function (pFiltros, callback) {
            _cadenciaSelecionada.controleInfos = 0;
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/disparos/consultarCadenciasEstatisticas"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                params: {
                    objectId: pFiltros.objectId,
                    tipoDaChave: 'C',
                    tipoDaConsulta: 'C',
                    dataInicio: pFiltros.dataInicio,
                    dataFim: pFiltros.dataFim
                }
            }).then(function (rConsultaCadenciasEstatisticas) {
                $scope.listaCadencias.forEach(function (cadencia) {
                    if (cadencia._id == rConsultaCadenciasEstatisticas.data._id) {
                        cadencia.sequencias = rConsultaCadenciasEstatisticas.data.sequencias;
                        cadencia.infoEstatistica = rConsultaCadenciasEstatisticas.data.infoEstatistica;
                        cadencia.controleInfos = 1;
                    }
                });
                callback("Ok");
            });
        };
        $scope.abrirAnalytics = function () {
            $scope.mostrarAnalytics = !$scope.mostrarAnalytics;
            if ($scope.mostrarAnalytics) {
                $scope.menuNavAberto = true;
                $scope.colapseOrExpandMenu('filtros'),
                    $scope.idUsuarioSel = '';
                $('#divAnalytics').fadeIn();
                $('.divGeralTelaCadencia').css('position', 'relative');
                $('#divPrincipalCadencia').addClass('scrollPadrao').css('overflow-y', 'auto');
                $('#tabelaPrincipalCadencia').removeClass('scrollPadrao');
                $('.divTabelaNovaCad').css('height', 'auto');
                $scope.loaderGraficoAnalytics = true;
                $scope.consultarTotaisEnviosCadencias();
                $scope.fazerConsultaGrafico();
                $scope.consultarCadenciasAnalytics();
                if ($rootScope.permissoes.tipoUsuario != "U") {
                    $http({
                        method: 'GET',
                        url: "".concat($rootScope.ramperV3ApiBaseUrl, "/filtros/usuarios"),
                        headers: {
                            'ramp3r-auth': 'ramp3r-authorization',
                            'ramp3r-l': $rootScope.IDCrypto,
                            'Authorization': 'Bearer ' + $rootScope.Token
                        }
                    }).then(function (response) {
                        $scope.listaUsuariosAnalytics = [];
                        response.data.forEach(function (time) {
                            time.loginsAtivos.forEach(function (usuario) {
                                $scope.listaUsuariosAnalytics.push(usuario);
                                if (usuario.fotoPerfil)
                                    usuario.fotoPerfil = "".concat($rootScope.bucketUserPhotos, "/usuariosRamper/") + usuario._id.toString() + "/" + usuario.fotoPerfil;
                                else
                                    usuario.fotoPerfil = "".concat($rootScope.bucket, "/avatarPadrao.jpg");
                            });
                        });
                        $scope.listaUsuariosAnalytics.sort(function (a, b) {
                            return a.nome < b.nome ? -1 : a.nome > b.nome ? 1 : 0;
                        });
                    });
                }
            }
            else {
                $scope.menuNavAberto = false;
                $scope.colapseOrExpandMenu('filtros'),
                    $scope.fecharAnalytics();
            }
        };
        $scope.abrirFecharFiltroUsuario = function () {
            $scope.filtroUsuarioAberto = !$scope.filtroUsuarioAberto;
            if ($scope.filtroUsuarioAberto) {
                $('#idFiltroUsuarioAberto').show();
                setTimeout(function () {
                    document.getElementById("buscarUsuarios").focus();
                }, 10);
            }
            else {
                $('#idFiltroUsuarioAberto').hide();
            }
        };
        $scope.selecionarUsuarioFiltrar = function (pDados) {
            _usuarioSelecionadoFiltroAnalytics = true;
            $scope.usuArioSelecionadoFiltro = pDados;
            $('#dataFiltroDefinida').show();
            $scope.nomeFiltroUsuAnalytics = pDados.nome;
            $scope.filtroUsuarioAberto = false;
            $scope.nomeFiltroCadAnalytics = '';
            $scope.consultarCadenciasAnalytics(pDados._id);
        };
        $scope.fazerConsultaAnalytics = function () {
            _usuarioSelecionadoFiltroAnalytics ? $scope.idUsuarioSel = $scope.usuArioSelecionadoFiltro._id : $scope.idUsuarioSel = '';
            $scope.consultarTotaisEnviosCadencias();
            $scope.fazerConsultaGrafico();
            $scope.buscarUsuarios = '';
        };
        $scope.consultarTotaisEnviosCadencias = function () {
            $scope.dataFinalFiltroAnalytics.setHours(23);
            $scope.dataFinalFiltroAnalytics.setMinutes(59);
            $scope.loaderIndicadores = true;
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/disparos/consultarTotaisCadencias"),
                headers: {
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                params: {
                    dataInicio: $scope.dataInicioFiltroAnalytics,
                    dataFim: $scope.dataFinalFiltroAnalytics,
                    objectIdDisparo: $scope.cadAnalyticsSelecionadoFiltro
                }
            }).then(function (rTotais) {
                var totalIndicadores = rTotais.data;
                $scope.enviosAnalytics = 0;
                $scope.bouncesAnalytics = 0;
                $scope.percentualBouncesAnalytics = 0;
                $scope.entreguesAnalytics = 0;
                $scope.percentualEntreguesAnalytics = 0;
                $scope.abertosAnalytics = 0;
                $scope.percentualAbertosAnalytics = 0;
                $scope.respondidosAnalytics = 0;
                $scope.percentualRespondidosAnalytics = 0;
                $scope.interessesAnalytics = 0;
                $scope.percentualInteressesAnalytics = 0;
                if (totalIndicadores.quantidadeTotalEnvios > 0) {
                    $scope.enviosAnalytics = totalIndicadores.quantidadeTotalEnvios;
                    $scope.bouncesAnalytics = totalIndicadores.quantidadeTotalBounces;
                    $scope.percentualBouncesAnalytics = Math.round(totalIndicadores.percentualTotalBounces);
                    $scope.entreguesAnalytics = totalIndicadores.quantidadeTotalEntregues;
                    $scope.percentualEntreguesAnalytics = Math.round(totalIndicadores.percentualTotalEntregues);
                    $scope.abertosAnalytics = totalIndicadores.quantidadeTotalAbertos;
                    $scope.percentualAbertosAnalytics = Math.round(totalIndicadores.percentualTotalAbertos);
                    $scope.respondidosAnalytics = totalIndicadores.quantidadeTotalRespostas;
                    $scope.percentualRespondidosAnalytics = Math.round(totalIndicadores.percentualTotalRespostas);
                    $scope.interessesAnalytics = totalIndicadores.quantidadeTotalInteresses;
                    $scope.percentualInteressesAnalytics = Math.round(totalIndicadores.percentualTotalInteresses);
                }
                else {
                    $scope.loaderIndicadores = false;
                    $scope.emptyAnalytics = true;
                    $scope.loaderGraficoAnalytics = false;
                }
                $scope.loaderIndicadores = false;
            });
        };
        $scope.fecharAnalytics = function () {
            $scope.menuNavAberto = false;
            $scope.colapseOrExpandMenu('filtros'),
                $scope.mostrarAnalytics = false;
            $('.divGeralTelaCadencia').css('position', '');
            $('#divPrincipalCadencia').removeClass('scrollPadrao').css('overflow-y', 'inherit');
            $('#tabelaPrincipalCadencia').addClass('scrollPadrao');
            $('.menuNav').css('height', 'calc(100% - 152px)');
            document.getElementById("sltDataAnalytics").innerHTML = "Filtrar por período";
            $('#sltDataAnalytics').data('DRPEx').clear();
            $('#dataFiltroDefinida').hide();
            $scope.dataFinalFiltroAnalytics = new Date();
            $scope.dataInicioFiltroAnalytics = new Date();
            $scope.dataInicioFiltroAnalytics.setDate($scope.dataInicioFiltroAnalytics.getDate() - 30);
            $scope.usuArioSelecionadoFiltro = '';
            $scope.cadAnalyticsSelecionadoFiltro = '';
            $scope.nomeFiltroCadAnalytics = '';
            $scope.buscarFiltroCadAnalytics = '';
            $scope.buscarUsuarios = '';
            _usuarioSelecionadoFiltroAnalytics = false;
            $scope.nomeFiltroUsuAnalytics = '';
        };
        $scope.mudarGrafico = function (pDados) {
            removerCoresIndicadores();
            $scope.IndicadorGrafico = pDados;
            if (pDados == 'entregues') {
                $scope.indicadorGraficoUm = 'Envios';
                $scope.indicadorGraficoDois = 'Entregues';
                $('#divEntreguesA').addClass('fundoVerde');
                $('#totalEntreguesA').addClass('corSpanBranco');
                $('#porcenEntreguesA').addClass('corSpanBranco');
                $scope.imgEntregues = '-selecionado';
            }
            else if (pDados == 'abertos') {
                $scope.indicadorGraficoUm = 'Entregues';
                $scope.indicadorGraficoDois = 'Abertos';
                $('#divAbertosA').addClass('fundoVerde');
                $('#totalAbertosA').addClass('corSpanBranco');
                $('#porcenAbertosA').addClass('corSpanBranco');
                $scope.imgAbertos = '-selecionado';
            }
            else if (pDados == 'respondidos') {
                $scope.indicadorGraficoUm = 'Abertos';
                $scope.indicadorGraficoDois = 'Respondidos';
                $('#divRespondidosA').addClass('fundoVerde');
                $('#totalRespondidosA').addClass('corSpanBranco');
                $('#porcenRespondidosA').addClass('corSpanBranco');
                $scope.imgRespondidos = '-selecionado';
            }
            else if (pDados == 'interesses') {
                $scope.indicadorGraficoUm = 'Respondidos';
                $scope.indicadorGraficoDois = 'Interesses';
                $('#divInteressesA').addClass('fundoVerde');
                $('#totalInteressesA').addClass('corSpanBranco');
                $('#porcenInteressesA').addClass('corSpanBranco');
                $scope.imgInteresses = '-selecionado';
            }
            else if (pDados == 'bounces') {
                $scope.indicadorGraficoUm = 'Enviados';
                $scope.indicadorGraficoDois = 'Bounces';
                $scope.imgBounces = '-selecionado';
                $('#divBouncesA').addClass('fundoVerde');
                $('#totalBouncesA').addClass('corSpanBranco');
                $('#porcenBouncesA').addClass('corSpanBranco');
            }
            $scope.montarGraficoAnalytics($scope.arrayInfosGrafico);
        };
        $scope.fazerConsultaGrafico = function () {
            $scope.arrayInfosGrafico = [];
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/disparos/consultarGraficoAnalytics"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                params: {
                    tipoDaChave: $rootScope.permissoes.tipoUsuario,
                    objectId: $scope.idUsuarioSel,
                    dataInicio: $scope.dataInicioFiltroAnalytics,
                    dataFim: $scope.dataFinalFiltroAnalytics,
                    tipoConsulta: 'entregues',
                    origem: 'c',
                    objectIdDisparo: $scope.cadAnalyticsSelecionadoFiltro
                }
            }).then(function (rTotais) {
                $scope.loaderGraficoAnalytics = false;
                if (rTotais.data.length > 0) {
                    $scope.arrayInfosGrafico = rTotais.data;
                    $scope.emptyAnalytics = false;
                    $scope.emptyGraficoAnalytics = false;
                    $scope.mudarGrafico('entregues');
                }
                else
                    $scope.emptyGraficoAnalytics = true;
            });
        };
        removerCoresIndicadores = function () {
            $('#divEntreguesA').removeClass('fundoVerde');
            $('#totalEntreguesA').removeClass('corSpanBranco');
            $('#porcenEntreguesA').removeClass('corSpanBranco');
            $('#divAbertosA').removeClass('fundoVerde');
            $('#totalAbertosA').removeClass('corSpanBranco');
            $('#porcenAbertosA').removeClass('corSpanBranco');
            $('#divRespondidosA').removeClass('fundoVerde');
            $('#totalRespondidosA').removeClass('corSpanBranco');
            $('#porcenRespondidosA').removeClass('corSpanBranco');
            $('#divInteressesA').removeClass('fundoVerde');
            $('#totalInteressesA').removeClass('corSpanBranco');
            $('#porcenInteressesA').removeClass('corSpanBranco');
            $('#divBouncesA').removeClass('fundoVerde');
            $('#totalBouncesA').removeClass('corSpanBranco');
            $('#porcenBouncesA').removeClass('corSpanBranco');
            $scope.imgEntregues = '';
            $scope.imgAbertos = '';
            $scope.imgRespondidos = '';
            $scope.imgInteresses = '';
            $scope.imgBounces = '';
        };
        $scope.atualizarPagina = function () {
            $scope.esconderEmptyStates();
            $scope.consultarCadencias();
        };
        $scope.consultarTotaisParaContatos = function () {
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/login/consultarTotais"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                params: {
                    tipoDaChave: $rootScope.permissoes.tipoUsuario,
                    totalEspecifico: {
                        tela: 'ContatosEmCadencias',
                        tipoDaChave: $scope.usuarioFiltro ? "U" : $rootScope.permissoes.tipoUsuario,
                        objectId: _cadenciaSelecionada.objectIdLogin,
                        _id: _cadenciaSelecionada._id,
                        skip: _skipContatos,
                        limit: 10,
                        ordem: _campoOrdem,
                        ordemAscDesc: _ordem,
                        buscaTexto: $scope.buscarContato,
                        dataInicio: $scope.dataInicioFiltroContato ? new Date($scope.dataInicioFiltroContato) : '',
                        dataFim: $scope.dataFinalFiltroContato ? new Date($scope.dataFinalFiltroContato) : '',
                        status: verificaStatusContatoERetorna($scope.contatoFiltroCad),
                        statusResposta: _filtroResposta,
                        alta: $('#cbAltaContatosInput').is(':checked'),
                        media: $('#cbMediaContatosInput').is(':checked'),
                        baixa: $('#cbBaixaContatosInput').is(':checked'),
                        sem: $('#cbSemContatosInput').is(':checked'),
                        enviados: $("#enviadosCad")[0].value,
                        abertos: $("#abertosCad")[0].value,
                        cliques: $("#cliquesCad")[0].value
                    }
                }
            }).then(function (rConsulta) {
                if (rConsulta.data.length > 0)
                    rConsulta.data.forEach(function (total) {
                        $scope.qtdContatosCadencia = total.contatos;
                    });
                else
                    $scope.qtdContatosCadencia = 0;
            });
        };
        $scope.qtdErroEAvancar = function () {
            var qtdErros = 0;
            $scope.popupTresMeses = false;
            $scope.avancaETiraErro = true;
            $scope.divSegundaTelaPopupSeq = true;
            $scope.popupTresMeses = false;
            $scope.divSegundaTelaPopupSeqB = false;
            $('#dvMensagemDiasSemana').hide();
            $('#dvMensagemDataInicioCadencia').hide();
            $('#dvMensagemDataInicioCadenciaB').hide();
            $('#dvMensagemDataInicioCadenciaEngage').hide();
            $('#dvMensagemDataInicioCadenciaEngageB').hide();
            $('#dvMensagemHorarioInicial').hide();
            $('.divPopupTesteAB').removeClass('blur');
        };
        $scope.ficarNaTela = function () {
            $scope.popupTresMeses = false;
            $('.divPopupTesteAB').removeClass('blur');
        };
        $scope.abrirPopupFiltrar = function (pDados) {
            if (pDados == 'co') {
                $('#idFundoPopupCadencica').fadeIn();
                $scope.popupFiltrarContatosCadencias = true;
                setTimeout(function () {
                    $("#enviadosCad").candlestick({ swipe: false, on: 's', off: 'n', default: '' });
                    $("#abertosCad").candlestick({ swipe: false, on: 's', off: 'n', default: '' });
                    $("#cliquesCad").candlestick({ swipe: false, on: 's', off: 'n', default: '' });
                }, 10);
            }
            else if (pDados = 'ca') {
                if (!$scope.listaSegmentacoes.length)
                    $scope.consultarSegmentacoesComContatos(false, false);
                if (!$scope.listaUsuarios.length)
                    $scope.consultarUsuarios();
                $('#idPopupFiltroCad').show();
                $scope.popupFiltrarCadencias = true;
            }
        };
        $scope.abrirSubMenu = function (pDados) {
            if (pDados == 'G') {
                $('#abrirSubMenuCadencias').show();
                $rootScope.subMenuDeCadencias = true;
            }
            else if (pDados == 'E') {
                $('#abrirSubMenuCadenciasEspecifica').show();
                $rootScope.subMenuCadenciasEspecifica = true;
            }
        };
        $scope.fecharPopupFiltrar = function (pDados) {
            if (pDados == 'co' || pDados == 'cox') {
                $scope.esconderEmptyStates();
                $('#idFundoPopupCadencica').fadeOut();
                $scope.popupFiltrarContatosCadencias = false;
                $scope.buscaDetalheContato = false;
                $scope.semContatos = false;
                if (pDados == 'co') {
                    $scope.filtroDetalheContato = true;
                    _skipContatos = -10;
                    _campoOrdem = 'nomeContato';
                    _ordem = 1;
                    $scope.buscarContato = '';
                    $scope.consultarContatosPorCadencia();
                }
            }
            else if (pDados == 'ca' || pDados == 'cax') {
                $('#idPopupFiltroCad').hide();
                $scope.popupFiltrarCadencias = false;
                $scope.esconderEmptyStates();
                _filtros = {
                    buscar: '',
                    objectId: '',
                    tipoDaChave: '',
                    dataInicio: '',
                    dataFim: '',
                    status: '',
                    segmentacao: '',
                    campoOrdem: 'disparosNome',
                    ordem: '1'
                };
                if (pDados == 'ca') {
                    $scope.botaoBuscarVisivel = false;
                    $scope.buscarCadencia = '';
                    $scope.zerarCamposOrdenacao();
                    var segmentacaoSelecionada = '';
                    try {
                        segmentacaoSelecionada = $scope.segmentacaoFiltroSelecionado;
                    }
                    catch (e) {
                        segmentacaoSelecionada = '';
                    }
                    var usuarioSelecionado = '';
                    try {
                        usuarioSelecionado = $scope.usuarioFiltroCad;
                    }
                    catch (e) {
                        usuarioSelecionado = '';
                    }
                    _filtros = {
                        buscar: '',
                        objectId: usuarioSelecionado != null ? usuarioSelecionado : '',
                        tipoDaChave: usuarioSelecionado != null ? 'U' : '',
                        dataInicio: $scope.dataInicioFiltro ? new Date($scope.dataInicioFiltro) : '',
                        dataFim: $scope.dataFinalFiltro ? new Date($scope.dataFinalFiltro) : '',
                        status: $scope.statusCadFiltroSelecionado,
                        segmentacao: segmentacaoSelecionada != null ? segmentacaoSelecionada : '',
                        campoOrdem: 'nome',
                        ordem: '1',
                        origem: 'campoFiltrar'
                    };
                    if ($('#sltDataFiltroCadencia')[0].value == '' && ($scope.usuarioFiltroCad == null || $scope.usuarioFiltroCad == undefined) && document.getElementById("idStatusCadFiltro").innerText == 'Status' && ($scope.segmentacaoFiltroSelecionado == null || $scope.segmentacaoFiltroSelecionado == undefined)) {
                        $scope.filtroHabilitado = false;
                        $('#idBtnFiltroCadencias').removeClass().addClass('btnVoltarPadroNovoFiltro');
                        $('#idImgFiltrarCad').attr('src', "".concat($rootScope.bucket, "/filtro.png"));
                    }
                    else {
                        $scope.filtroHabilitado = true;
                        $('#idBtnFiltroCadencias').removeClass().addClass('btnFiltroTempNovoHabilitado');
                        $('#idImgFiltrarCad').attr('src', "".concat($rootScope.bucket, "/filtro-wt-novo.svg"));
                    }
                    $scope.montarNomeFiltros();
                    $scope.fecharAnalytics();
                    $scope.consultarCadencias(_filtros);
                }
            }
        };
        $(document).mouseup(function (e) {
            var popUpCadenciaSequencia = $('#sequenciaCadenciaGeral');
            var popupNovaSegmentacao = $('#popupNovaSegmentacaoNaoRespondidos');
            var filtroUsuarioAnalytics = $('#idFiltroUsuarioAberto');
            var btnFiltroUsuario = $('#sltUsuarioAnalytics');
            var filtroCadAnalytics = $('#idFiltroCadAnalytics');
            var popupFiltroCad = $('#idPopupFiltroCad');
            var filtroPeriodo = $('#containerPeriodo');
            var divOrdenar = $('#idOrdenarCad');
            if (filtroPeriodo.has(e.target).length === 0 &&
                popupFiltroCad.has(e.target).length === 0) {
                popupFiltroCad.hide();
                $scope.popupFiltrarCadencias = false;
            }
            if (popUpCadenciaSequencia.is(e.target)) {
                $scope.fecharPopupDetalhesContatos();
                popUpCadenciaSequencia.fadeOut();
                $rootScope.removerBlur();
            }
            if (popupNovaSegmentacao.is(e.target)) {
                $scope.fecharPopupNovaSegmentacao(false);
                popupNovaSegmentacao.fadeOut();
            }
            if ($scope.mostrarAnalytics) {
                if (!filtroUsuarioAnalytics.is(e.target) && filtroUsuarioAnalytics.has(e.target).length === 0) {
                    if (!btnFiltroUsuario.is(e.target) && btnFiltroUsuario.has(e.target).length === 0) {
                        $scope.filtroUsuarioAberto = false;
                        filtroUsuarioAnalytics.hide();
                    }
                }
                if ($scope.filtroCadAnalyticsAberto) {
                    if (!filtroCadAnalytics.is(e.target) && filtroCadAnalytics.has(e.target).length === 0) {
                        $scope.abrirFecharFiltroCadAnalytics();
                    }
                }
            }
            if (!divOrdenar.is(e.target) && divOrdenar.has(e.target).length === 0) {
                $scope.ordenarCadenciaAberto = false;
                divOrdenar.hide();
            }
        });
        $scope.detalhesCadencias = function (pCadencia) {
            $rootScope.tipoCadenciaConsultada = pCadencia.tipoCadencia;
            $rootScope.linkWebhookCadenciaconsultada = pCadencia.linkWebhook;
            if (pCadencia.status == 'Ativa') {
                $scope.cadenciaAtiva = true;
                $('#abrirSubMenuCadenciasEspecifica').addClass('subMenuCadenciasEspecifica');
                $('#abrirSubMenuCadenciasEspecifica').removeClass('subMenuCadenciasEspecificaPausada');
                $('#abrirSubMenuCadenciasEspecifica').removeClass('tamanhoSubMenuCadencia');
            }
            else {
                $scope.cadenciaAtiva = false;
                $('#abrirSubMenuCadenciasEspecifica').removeClass('tamanhoSubMenuCadencia');
                $('#abrirSubMenuCadenciasEspecifica').removeClass('subMenuCadenciasEspecifica');
                $('#abrirSubMenuCadenciasEspecifica').addClass('subMenuCadenciasEspecificaPausada');
            }
            if (pCadencia.status == 'Finalizada') {
                $scope.cadenciaFinalizada = true;
                $('#abrirSubMenuCadenciasEspecifica').removeClass('subMenuCadenciasEspecificaPausada');
                $('#abrirSubMenuCadenciasEspecifica').addClass('tamanhoSubMenuCadencia');
            }
            else
                $scope.cadenciaFinalizada = false;
            $scope.showBrowserContatos = [];
            _skipContatos = -10;
            _cadenciaSelecionada = pCadencia;
            $scope.cadenciaDetalheAberta = pCadencia;
            $scope.cadenciaShow = _cadenciaSelecionada.disparosNome;
            esconderTodasDivsDetalhes();
            removerDestaqueMenu();
            $scope.removerFiltrosContatos();
            $scope.clickMenuDetalhes('c');
            $('#dvDetalhesGeral').fadeIn();
            $scope.mudarStatusCadenciaEspecifica = function (pStatus) {
                $rootScope.monsterShowLoading = true;
                $http({
                    method: 'POST',
                    url: "".concat($rootScope.ramperV3ApiBaseUrl, "/disparos/mudarStatusCadencia"),
                    headers: {
                        'ramp3r-auth': 'ramp3r-authorization',
                        'ramp3r-l': $rootScope.IDCrypto,
                        'Authorization': 'Bearer ' + $rootScope.Token
                    },
                    params: {
                        objectIdDisparo: pCadencia._id,
                        status: pStatus
                    }
                }).then(function () {
                    if (pStatus == 'P')
                        mixpanel.track("Cadência | Pausou uma cadência", { "Local_IP": $rootScope.ip });
                    if (pStatus == 'F')
                        mixpanel.track("Cadência | Finalizou uma cadência", { "Local_IP": $rootScope.ip });
                    if (pStatus == 'A')
                        mixpanel.track("Cadência | Ativou uma cadência", { "Local_IP": $rootScope.ip });
                    $rootScope.monsterShowLoading = false;
                    $scope.fecharPopupFinalizarCad();
                    $('#abrirSubMenuCadencias').hide();
                    $rootScope.subMenuDeCadencias = false;
                    $scope.listaCadencias.checkSelecionado = false;
                    _contPagina = 1;
                    $scope.consultarCadencias(_filtros);
                    $scope.fecharDetalhes();
                }, function () {
                    $rootScope.monsterShowLoading = false;
                });
            };
        };
        $scope.fRetornarAB = function (obj) {
            var ab = obj.testeAB;
            var seq = obj.sequencia;
            try {
                return obj.infoEstatistica.filter(function (item) {
                    return item.testeAB == ab && item.sequencia == seq;
                })[0];
            }
            catch (e) {
                return {
                    qtdEnvioSequencia: 0,
                    qtdBounceSequencia: 0,
                    quantidadeEntregues: 0,
                    quantidadeBounces: 0,
                    quantidadeAbertos: 0,
                    quantidadeRespondidos: 0,
                    quantidadeNeutros: 0,
                    quantidadeInteresses: 0,
                    quantidadeEnvios: 0,
                    percentualEntregues: 0,
                    percentualBounces: 0,
                    percentualAbertos: 0,
                    percentualRespondidos: 0,
                    percentualNeutros: 0,
                    percentualInteresses: 0,
                };
            }
        };
        esconderTodasDivsDetalhes = function () {
            $('#dvDetalhesDados').hide();
            $('#dvDetalhesMetricas').hide();
            $('#dvDetalhesContatos').hide();
            $('#dvDetalhesHistorico').hide();
            $('#dvDetalhesResumo').hide();
            $('#dvDetalhesSequencia').hide();
            $('#dvDetalhesAutomacoes').hide();
        };
        removerDestaqueMenu = function () {
            $('#liDetalhesDados').removeClass('liMenuDetalhesActive');
            $('#liDetalhesMetricas').removeClass('liMenuDetalhesActive');
            $('#liDetalhesContatos').removeClass('liMenuDetalhesActive');
            $('#liDetalhesHistorico').removeClass('liMenuDetalhesActive');
            $('#liDetalhesResumoCadencias').removeClass('liMenuDetalhesActive');
            $('#liDetalhesSequenciaCadencias').removeClass('liMenuDetalhesActive');
            $('#liDetalhesAutomacoes').removeClass('liMenuDetalhesActive');
        };
        $scope.fecharDetalhes = function () {
            $rootScope.subMenuCadenciasEspecifica = false;
            $scope.buscarContato = '';
            $('#dvDetalhesGeral').fadeOut();
        };
        $scope.clickMenuDetalhes = function (pDetalhe, pEmpresa) {
            esconderTodasDivsDetalhes();
            removerDestaqueMenu();
            if (pDetalhe == 'm') {
                $rootScope.monsterShowLoading = true;
                $('#liDetalhesMetricas').addClass('liMenuDetalhesActive');
                $('#dvDetalhesMetricas').fadeIn();
                $('#labe1').addClass('active');
                $('#labe2').removeClass('active');
                $('#labe3').removeClass('active');
                $scope.$broadcast('getMetricas', { tela: "C", objectId: _cadenciaSelecionada._id });
                $rootScope.monsterShowLoading = false;
            }
            else if (pDetalhe == 'c') {
                $scope.showBrowserContatos = [];
                $scope.semContatos = false;
                _skipContatos = -10;
                $scope.consultarContatosPorCadencia();
                $('#liDetalhesContatos').addClass('liMenuDetalhesActive');
                $('#dvDetalhesContatos').fadeIn();
            }
            else if (pDetalhe == 'h') {
                $('#liDetalhesHistorico').addClass('liMenuDetalhesActive');
                $('#dvDetalhesHistorico').fadeIn();
            }
            else if (pDetalhe == 'r') {
                $rootScope.monsterShowLoading = true;
                if (_cadenciaSelecionada.tipoCadencia === 'Reengajamento') {
                    $rootScope.cadenciaComReengajamentoRetroativo = _cadenciaSelecionada.dadosReengajamento.retroativo;
                    $rootScope.diasRetroativosCadEspecifica = $rootScope.cadenciaComReengajamentoRetroativo ? _cadenciaSelecionada.dadosReengajamento.diasEmLost : null;
                    var dadosReengajamento = _cadenciaSelecionada.dadosReengajamento;
                    var funis = 'Funil: ';
                    var estagios = 'Estagio: ';
                    var motivos = 'Motivo: ';
                    var usuarios = 'Usuario: ';
                    dadosReengajamento.funil.forEach(function (funil) {
                        $scope.listaFunisCadP.forEach(function (funilCadencia) {
                            if (funilCadencia.id === funil) {
                                funis += funilCadencia.name + '; ';
                            }
                        });
                    });
                    dadosReengajamento.motivo.forEach(function (motivo) {
                        $scope.listaMotivosCadP.forEach(function (motivoCadencia) {
                            if (motivoCadencia.id === motivo) {
                                motivos += motivoCadencia.label + '; ';
                            }
                        });
                    });
                    dadosReengajamento.estagio.forEach(function (estagio) {
                        $scope.listaEtapasCadP.forEach(function (etapaCadencia) {
                            if (etapaCadencia.id === estagio) {
                                estagios += etapaCadencia.name + '; ';
                            }
                        });
                    });
                    dadosReengajamento.usuario.forEach(function (usuario) {
                        $scope.listaUsuariosCadP.forEach(function (usuarioCadencia) {
                            if (usuarioCadencia.id === usuario) {
                                usuarios += usuarioCadencia.name + '; ';
                            }
                        });
                    });
                    $rootScope.resumoPipedriveCadEspecifica = funis + motivos + estagios + usuarios;
                    $scope.reengajamento.checkDiasRetroativos = dadosReengajamento.retroativo;
                    $scope.reengajamento.diasRetroativos = dadosReengajamento.diasEmLost ? dadosReengajamento.diasEmLost : 1;
                    $http({
                        method: 'GET',
                        url: "".concat($rootScope.ramperV3ApiBaseUrl, "/disparossequencias/intervaloDias"),
                        headers: {
                            'ramp3r-auth': 'ramp3r-authorization',
                            'ramp3r-l': $rootScope.IDCrypto,
                            'Authorization': 'Bearer ' + $rootScope.Token
                        },
                        params: {
                            objectIdDisparo: _cadenciaSelecionada._id,
                            sequencia: 1
                        }
                    }).then(function (response) {
                        $rootScope.intervaloDiasCadenciaEspecifica = response.data.intervaloDias;
                    });
                }
                $('#liDetalhesResumoCadencias').addClass('liMenuDetalhesActive');
                $('#dvDetalhesResumo').fadeIn();
                $rootScope.zerarVariaveisResumoCadencia();
                $scope.$broadcast('getResumo', {
                    tela: "C",
                    objectId: _cadenciaSelecionada._id,
                    dataInicial: _cadenciaSelecionada.disparosInicio,
                    objectIdLogin: _cadenciaSelecionada.objectIdLogin
                });
                $rootScope.monsterShowLoading = false;
            }
            else if (pDetalhe == 's') {
                $scope.consultarCadenciasComSequencia();
                $('#liDetalhesSequenciaCadencias').addClass('liMenuDetalhesActive');
                $('#dvDetalhesSequencia').fadeIn();
            }
            else if (pDetalhe == 'a') {
                $scope.consultarAutomacoes(_cadenciaSelecionada);
                $('#liDetalhesAutomacoes').addClass('liMenuDetalhesActive');
                $('#dvDetalhesAutomacoes').fadeIn();
            }
        };
        $('#sltDataSequencia').dateRangePicker({
            startOfWeek: 0,
            weekStart: '0',
            format: 'DD/MM/YY',
            separator: ' até ',
            shortcuts: {
                'prev-days': [1, 7, 15],
                'prev': ['week', 'month', 'year']
            },
            title: 'Respondido de: ',
            animationTime: 200
        })
            .bind('DRPEx-change', function (event, obj) {
            $scope.dataInicioFiltro = obj.start;
            $scope.dataInicioFiltro.setHours(0);
            $scope.dataInicioFiltro.setMinutes(0);
            $scope.dataInicioFiltro.setSeconds(0);
            $scope.dataFinalFiltro = obj.end;
            $scope.dataFinalFiltro.setHours(23);
            $scope.dataFinalFiltro.setMinutes(59);
            $scope.dataFinalFiltro.setSeconds(59);
        });
        $('#sltDataFiltroCadencia').dateRangePicker({
            startOfWeek: 0,
            weekStart: '0',
            format: 'DD/MM/YY',
            separator: ' até ',
            shortcuts: {
                'prev-days': [1, 7, 15],
                'prev': ['week', 'month', 'year']
            },
            title: 'Respondido de: ',
            animationTime: 200
        })
            .bind('DRPEx-change', function (event, obj) {
            $scope.dataInicioFiltro = obj.start;
            $scope.dataInicioFiltro.setHours(0);
            $scope.dataInicioFiltro.setMinutes(0);
            $scope.dataInicioFiltro.setSeconds(0);
            $scope.dataFinalFiltro = obj.end;
            $scope.dataFinalFiltro.setHours(23);
            $scope.dataFinalFiltro.setMinutes(59);
            $scope.dataFinalFiltro.setSeconds(59);
        });
        $('#sltDataAnalytics').dateRangePicker({
            startOfWeek: 0,
            format: 'DD/MM/YYYY',
            separator: ' > ',
            singleMonth: true,
            customTopBar: 'Selecione um período',
            customArrowPrevSymbol: "<img src=\"".concat($rootScope.bucket, "/seta-clara.png\" class=\"imgEsquerdaCalendario\"/>"),
            customArrowNextSymbol: "<img src=\"".concat($rootScope.bucket, "/seta-clara.png\" class=\"imgDireitaCalendario\"/>"),
            getValue: function () {
                return this.innerHTML;
            },
            setValue: function (s) {
                this.innerHTML = s;
            },
            shortcuts: {
                custom: {
                    'Hoje': function () {
                        var start = new Date(Date.now());
                        var end = new Date(Date.now());
                        end.setHours(0, 0, 0, 0);
                        return [start, end];
                    },
                    'Semana': function () {
                        var start = new Date(Date.now());
                        var end = new Date(Date.now());
                        end.setDate(end.getDate() - 7);
                        end.setHours(0, 0, 0, 0);
                        return [start, end];
                    },
                    'Mensal': function () {
                        var start = new Date(Date.now());
                        var end = new Date(Date.now());
                        end.setDate(end.getDate() - 30);
                        end.setHours(0, 0, 0, 0);
                        return [start, end];
                    }
                }
            }
        })
            .bind('DRPEx-change', function (event, obj) {
            $scope.dataInicioFiltroAnalytics = obj.start;
            $scope.dataFinalFiltroAnalytics = obj.end;
        })
            .bind('DRPEx-apply', function (event, obj) {
            $('#dataFiltroDefinida').show();
        });
        $('#sltDataFiltroContatosCadencia').dateRangePicker({
            startOfWeek: 0,
            weekStart: '0',
            format: 'DD/MM/YY',
            separator: ' até ',
            shortcuts: {
                'prev-days': [1, 7, 15],
                'prev': ['week', 'month', 'year']
            },
            title: 'Respondido de: ',
            animationTime: 200
        })
            .bind('DRPEx-change', function (event, obj) {
            $scope.dataInicioFiltroContato = obj.start;
            $scope.dataFinalFiltroContato = obj.end;
        });
        $('#sltEditarDataDisparo').dateRangePicker({
            startOfWeek: 0,
            singleDate: true,
            format: 'DD/MM/YYYY',
            shortcuts: {
                'prev-days': [1, 7, 15],
                'prev': ['week', 'month', 'year']
            }
        })
            .bind('DRPEx-change', function (event, obj) {
            $scope.dataInicioFiltro = obj.date;
            $scope.dataInicioFiltro.setHours(0);
            $scope.dataInicioFiltro.setMinutes(0);
            $scope.dataInicioFiltro.setSeconds(0);
        });
        $('#sltDataInicioCadencia_B').dateRangePicker({
            startOfWeek: 0,
            singleDate: true,
            format: 'DD/MM/YYYY',
            dateRange: {
                start: _dataHoje
            },
            shortcuts: {
                'prev-days': [1, 7, 15],
                'prev': ['week', 'month', 'year']
            }
        })
            .bind('DRPEx-change', function (event, obj) {
            $scope.dataInicioCadencia = obj.date;
            $scope.dataInicioCadencia.setHours(0);
            $scope.dataInicioCadencia.setMinutes(0);
            $scope.dataInicioCadencia.setSeconds(0);
        });
        $('#sltDataInicioCadencia_A').dateRangePicker({
            startOfWeek: 0,
            singleDate: true,
            format: 'DD/MM/YYYY',
            dateRange: {
                start: _dataHoje
            },
            shortcuts: {
                'prev-days': [1, 7, 15],
                'prev': ['week', 'month', 'year']
            }
        })
            .bind('DRPEx-change', function (event, obj) {
            $scope.dataInicioCadencia = obj.date;
            $scope.dataInicioCadencia.setHours(0);
            $scope.dataInicioCadencia.setMinutes(0);
            $scope.dataInicioCadencia.setSeconds(0);
        });
        $scope.alterarTemplateDisaro = function () {
            $rootScope.editarTemplateDisparo = true;
            $rootScope.btnEditarDataTemplate = true;
        };
        $scope.consultarCadenciasComSequencia = function () {
            $rootScope.monsterShowLoading = true;
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/disparos/consultarCadenciasComSequencia"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                params: {
                    tipoDaChave: 'C',
                    tipoDaConsulta: 'C',
                    objectId: _cadenciaSelecionada._id,
                }
            }).then(function (rConsultaSequencias) {
                $scope.listaSequencias = [];
                if (rConsultaSequencias.data.length > 0) {
                    $scope.semCadencias = false;
                    rConsultaSequencias.data.forEach(function (sequencia) {
                        $scope.listaSequencias.push(sequencia);
                    });
                }
                $rootScope.monsterShowLoading = false;
            });
        };
        $scope.consultarContatosPorCadencia = function () {
            $rootScope.monsterShowLoading = true;
            if (_skipContatos == -10)
                $scope.showBrowserContatos = [];
            _skipContatos += 10;
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/contatos/consultarContatosPorEtapa"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                params: {
                    objectId: _cadenciaSelecionada._id,
                    tipoDaChave: 'C',
                    skip: _skipContatos,
                    limit: 10,
                    ordem: _campoOrdem,
                    ordemAscDesc: _ordem,
                    buscaTexto: $scope.buscarContato,
                    dataInicio: $scope.dataInicioFiltroContato ? new Date($scope.dataInicioFiltroContato) : '',
                    dataFim: $scope.dataFinalFiltroContato ? new Date($scope.dataFinalFiltroContato) : '',
                    status: verificaStatusContatoERetorna($scope.contatoFiltroCad),
                    statusResposta: _filtroResposta,
                    alta: $('#cbAltaContatosInput').is(':checked'),
                    media: $('#cbMediaContatosInput').is(':checked'),
                    baixa: $('#cbBaixaContatosInput').is(':checked'),
                    sem: $('#cbSemContatosInput').is(':checked'),
                    enviados: $("#enviadosCad")[0].value,
                    abertos: $("#abertosCad")[0].value,
                    cliques: $("#cliquesCad")[0].value
                }
            }).then(function (rConsulta) {
                $scope.consultarTotaisParaContatos();
                if (rConsulta.data.length > 0) {
                    $scope.semContatosFiltro = false;
                    $('#tbleContatosCadencia').removeClass('emptyAtivo');
                    $scope.semContatosBusca = false;
                    $scope.semContatos = false;
                    rConsulta.data.forEach(function (contato) {
                        if (contato._id.foto) {
                            contato._id.foto = "".concat($rootScope.bucketUserPhotos, "/usuariosRamper/") + contato._id.objectIdLogin.toString() + "/" + contato._id.foto;
                        }
                        else {
                            contato._id.foto = "".concat($rootScope.bucket, "/avatarPadrao.jpg");
                        }
                        contato.dados.forEach(function (iDados) {
                            contato.qtSequenciasDetalhe = 0;
                            if ((iDados.dataResposta) && (iDados.dataResposta != null) && (iDados.dataResposta != ''))
                                iDados.cor = '#2f6d6c';
                            else if ((iDados.statusEnvio != null) && (iDados.dataResposta == null || !iDados.dataResposta))
                                iDados.cor = 'green';
                            else
                                iDados.cor = '#b5b5b5';
                        });
                        $scope.showBrowserContatos.push(contato);
                    });
                }
                else {
                    if ($scope.showBrowserContatos.length <= 0) {
                        if ($scope.buscaDetalheContato) {
                            $scope.semContatosBusca = true;
                            $scope.buscaOuFiltro = 'Sua busca';
                            $('#tbleContatosCadencia').addClass('emptyAtivo');
                            $scope.semContatosFiltro = false;
                            $scope.semContatos = false;
                        }
                        else if ($scope.filtroDetalheContato) {
                            $scope.semContatosFiltro = true;
                            $scope.buscaOuFiltro = 'Seu filtro';
                            $('#tbleContatosCadencia').addClass('emptyAtivo');
                            $scope.semContatosBusca = false;
                            $scope.semContatos = false;
                        }
                        else {
                            $scope.semContatos = true;
                            $('#tbleContatosCadencia').addClass('emptyAtivo');
                            $scope.semContatosFiltro = false;
                            $scope.semContatosBusca = false;
                        }
                    }
                    else {
                        $scope.semContatosFiltro = false;
                        $scope.semContatosBusca = false;
                        $scope.semContatos = false;
                    }
                }
                $rootScope.monsterShowLoading = false;
            });
        };
        $scope.consultarAutomacoes = function () {
            $rootScope.monsterShowLoading = true;
            $scope.semAutomacaoDetalhe = true;
            $scope.descricaoAcaoDetalhe = '';
            $scope.acaoDetalhe = '';
            $scope.diasAguardarDetalhe = '';
            $scope.segmentacaoNaoRespDetalhe = '';
            $scope.integracaoNaoRespDetalhe = '';
            $scope.cadenciaDetalhe = '';
            $scope.integracaoDetalhe = '';
            $scope.semAutomacaoDetalhe = true;
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/automacoes/consultarAutomacoes"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                params: {
                    objectIdDisparo: _cadenciaSelecionada._id,
                }
            }).then(function (rConsultaAutomacoes) {
                rConsultaAutomacoes.data.forEach(function (automacao) {
                    $scope.semAutomacaoDetalhe = false;
                    if (automacao.situacao == 'B') { // bounce
                        if (automacao.parametros.acao && automacao.parametros.acao == 'D') {
                            $scope.acaoDetalhe = 'Apagar o contato';
                            $scope.descricaoAcaoDetalhe = '';
                            document.getElementById("areaMsgAutomacao").innerHTML = '';
                        }
                        else {
                            $scope.descricaoAcaoDetalhe = 'Enviar mensagem para ';
                            $scope.acaoDetalhe = automacao.parametros.acao == "E" ? 'e-mail padrão da empresa' : 'e-mail de alta confiabilidade da empresa';
                            document.getElementById("areaMsgAutomacao").innerHTML = automacao.parametros.texto;
                        }
                    }
                    if (automacao.situacao == 'N') { // não responder
                        $scope.diasAguardarDetalhe = automacao.parametros.diasAguardar;
                        try {
                            $scope.segmentacaoNaoRespDetalhe = automacao.segmentacoes[0].nome;
                        }
                        catch (e) {
                            //$scope.segmentacaoNaoRespDetalhe = 'não encontrada';
                        }
                        try {
                            $scope.integracaoNaoRespDetalhe = automacao.integracoes[0].application == "CIO" ? "Close.io" :
                                automacao.integracoes[0].application == "TV" ? "Total Voice" :
                                    automacao.integracoes[0].application == "R" ? "RD Station" :
                                        automacao.integracoes[0].application == "P" ? "Pipedrive" :
                                            automacao.integracoes[0].application == "U" ? "Plug" :
                                                automacao.integracoes[0].application == "I" ? "PipeRun" :
                                                    automacao.integracoes[0].application == "A" ? "Agendor" :
                                                        automacao.integracoes[0].application == "E" ? "Exact Sales" :
                                                            automacao.integracoes[0].application == "S" ? "Fresh Sales" :
                                                                automacao.integracoes[0].application == "F" ? "Funil de Vendas" :
                                                                    automacao.integracoes[0].application == "N" ? "Nectar CRM" :
                                                                        automacao.integracoes[0].application == "Z" ? "Zoho" :
                                                                            automacao.integracoes[0].application;
                        }
                        catch (e) {
                            //$scope.integracaoNaoRespDetalhe = 'não encontrada';
                        }
                    }
                });
                $rootScope.monsterShowLoading = false;
            });
        };
        $scope.ordecacaoContatos = function () {
            if ($('#ordemContatos')[0].selectedOptions[0].text == "A-Z")
                _ordem = 1;
            else
                _ordem = -1;
            _skipContatos = -10;
            _campoOrdem = $('#ordemContatos')[0].selectedOptions[0].value;
            $scope.consultarContatosPorCadencia();
        };
        $("#ordemContatos").on('change', function () {
            $scope.ordecacaoContatos();
        });
        //#region Etapa 1
        $scope.consultarUsuarios = function () {
            if ($scope.listaUsuarios.length == 0) {
                if ($rootScope.permissoes.tipoUsuario != "U") {
                    $http({
                        method: 'GET',
                        url: "".concat($rootScope.ramperV3ApiBaseUrl, "/filtros/usuarios"),
                        headers: {
                            'ramp3r-auth': 'ramp3r-authorization',
                            'ramp3r-l': $rootScope.IDCrypto,
                            'Authorization': 'Bearer ' + $rootScope.Token
                        }
                    }).then(function (response) {
                        response.data.forEach(function (time) {
                            time.loginsAtivos.forEach(function (usuario) {
                                $scope.listaUsuarios.push(usuario);
                                if (usuario.fotoPerfil)
                                    usuario.fotoPerfil = "".concat($rootScope.bucketUserPhotos, "/usuariosRamper/") + usuario._id.toString() + "/" + usuario.fotoPerfil;
                                else
                                    usuario.fotoPerfil = "".concat($rootScope.bucket, "/avatarPadrao.jpg");
                            });
                        });
                        $scope.listaUsuarios.sort(function (a, b) {
                            return a.nome < b.nome ? -1 : a.nome > b.nome ? 1 : 0;
                        });
                    });
                }
                else {
                    var usuario = {
                        nome: $rootScope.nomeUsuarioLogado,
                        _id: ""
                    };
                    $scope.listaUsuarios.push(usuario);
                }
            }
        };
        //#endregion
        //#region Etapa 2
        $scope.consultarSegmentacoesComContatos = function (integracaoSite, removeLoader) {
            var contatoSelecionado = '';
            try {
                contatoSelecionado = $scope.usuarioFiltroCad;
            }
            catch (e) {
                contatoSelecionado = '';
            }
            $scope.listaSegmentacoesPadrao = [];
            $scope.listaSegmentacoes = [];
            $scope.listaSegmentacoesDisp = [];
            $scope.listaSegmentacoesEmailNaoResp = [];
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/segmentacoes/consultarSegmentacaoAgrupandoContatos"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                params: {
                    tipoDaChave: $rootScope.permissoes.tipoUsuario,
                    objectId: contatoSelecionado,
                    integracaoSite: integracaoSite
                }
            }).then(function (rConsultaSegmentacoes) {
                $scope.listaSegmentacoesPadrao = rConsultaSegmentacoes.data;
                $scope.listaSegmentacoes = rConsultaSegmentacoes.data;
                $scope.listaSegmentacoes.sort(function (a, b) {
                    return a._id.nomeSegmentacao < b._id.nomeSegmentacao ? -1 : a._id.nomeSegmentacao > b._id.nomeSegmentacao ? 1 : 0;
                });
                $scope.listaSegmentacoesEmailNaoResp = $scope.listaSegmentacoes.filter(function (segment) {
                    if (!segment._id.integracaoSite) {
                        return segment;
                    }
                });
                if ($scope.novaCadencia.tipoCadencia === 'Engage') {
                    $scope.listaSegmentacoes = $scope.listaSegmentacoes.filter(function (segment) {
                        if (segment._id.integracaoSite) {
                            return segment;
                        }
                    });
                }
                $scope.setarOption(1);
                if (removeLoader) {
                    $rootScope.monsterShowLoading = false;
                }
            });
        };
        $scope.consultarIntegracoes = function () {
            if ($scope.listaIntegracoes.length == 0) {
                $http({
                    method: 'GET',
                    url: "".concat($rootScope.ramperV3ApiBaseUrl, "/integracoes/consultarIntegracoes"),
                    headers: {
                        'ramp3r-auth': 'ramp3r-authorization',
                        'ramp3r-l': $rootScope.IDCrypto,
                    }
                }).then(function (rConsultaIntegracoes) {
                    $scope.listaIntegracoes = rConsultaIntegracoes.data;
                    $scope.listaIntegracoes.sort(function (a, b) {
                        return a.nomeAplicao < b.nomeAplicao ? -1 : a.nomeAplicao > b.nomeAplicao ? 1 : 0;
                    });
                    var integracaoPipedrive = $scope.listaIntegracoes.filter(function (integracao) {
                        return integracao.application === "P";
                    });
                    $scope.integracaoReengajamento = integracaoPipedrive;
                    if (integracaoPipedrive.length > 0) {
                        PipedriveService.getAll(integracaoPipedrive[0].apiKey)
                            .then(function (data) {
                            $scope.listaFunisCadP = data.pipelines;
                            $scope.listaFunisCadP.sort(function (a, b) {
                                return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
                            });
                            $scope.listaFunisCadP[$scope.listaFunisCadP.length - 1].classe = 'hoverUltimaDiv';
                            $scope.listaEtapasOriginal = data.estagios;
                            $scope.listaEtapasOriginal.sort(function (a, b) {
                                return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
                            });
                            $scope.listaEtapasCadP = $scope.listaEtapasOriginal;
                            $scope.montarArrayNovo();
                            $scope.listaUsuariosCadP = data.usuarios;
                            $scope.listaUsuariosCadP.sort(function (a, b) {
                                return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
                            });
                            $scope.listaUsuariosCadP[$scope.listaUsuariosCadP.length - 1].classeUsuario = 'hoverUltimaDiv';
                        }).catch(function (err) { });
                        PipedriveService.getMotivos(integracaoPipedrive[0].apiKey)
                            .then(function (data) {
                            if (data) {
                                $scope.listaMotivosCadP = data;
                                $scope.listaMotivosCadP.sort(function (a, b) {
                                    return a.label < b.label ? -1 : a.label > b.label ? 1 : 0;
                                });
                                $scope.listaMotivosCadP[$scope.listaMotivosCadP.length - 1].classe = 'hoverUltimaDiv';
                            }
                        }).catch(function (err) { });
                    }
                });
            }
        };
        $scope.populaTotalContatos = function () {
            if ($('#segmentacaoFiltro')[0].selectedOptions[0].value) {
                var segmentacaoSelecionada = JSON.parse($('#segmentacaoFiltro')[0].selectedOptions[0].value);
                $scope.novaCadencia.segmentacao = {
                    _id: segmentacaoSelecionada._id.objectIdSegmentacao,
                    nome: segmentacaoSelecionada._id.nomeSegmentacao,
                    alta: $('#checkboxAltaInput')[0].checked || ($scope.novaCadencia.segmentacao.alta && $scope.novaCadencia.alteracao),
                    media: $('#checkboxMediaInput')[0].checked || ($scope.novaCadencia.segmentacao.media && $scope.novaCadencia.alteracao),
                    baixa: $('#checkboxBaixaInput')[0].checked || ($scope.novaCadencia.segmentacao.baixa && $scope.novaCadencia.alteracao),
                    sem: $('#checkboxSemInput')[0].checked || ($scope.novaCadencia.segmentacao.sem && $scope.novaCadencia.alteracao)
                };
                $scope.totalContatos = 0;
                if ($('#checkboxAltaInput')[0].checked)
                    $scope.totalContatos += segmentacaoSelecionada.totalAlta;
                if ($('#checkboxMediaInput')[0].checked)
                    $scope.totalContatos += segmentacaoSelecionada.totalMedia;
                if ($('#checkboxBaixaInput')[0].checked)
                    $scope.totalContatos += segmentacaoSelecionada.totalBaixa;
                if ($('#checkboxSemInput')[0].checked)
                    $scope.totalContatos += segmentacaoSelecionada.totalSem;
                $scope.totalContatos > 1 ? $('#txtTotalContatosSegmentacao')[0].innerText = formatarValor($scope.totalContatos) + ' contatos ' : $('#txtTotalContatosSegmentacao')[0].innerText = formatarValor($scope.totalContatos) + ' contato ';
            }
        };
        $("#segmentacaoFiltro").on('change', function () {
            $scope.populaTotalContatos();
        });
        $('#checkboxAltaInput').click(function () {
            $scope.populaTotalContatos();
        });
        $('#checkboxMediaInput').click(function () {
            $scope.populaTotalContatos();
        });
        $('#checkboxBaixaInput').click(function () {
            $scope.populaTotalContatos();
        });
        $('#checkboxSemInput').click(function () {
            $scope.populaTotalContatos();
        });
        //#endregion
        //#region Etapa 3
        $scope.clickWeek = function (ab, weekDay) {
            if ($('#' + ab + 'week' + weekDay).hasClass('btnWeekMarcado')) {
                $('#' + ab + 'week' + weekDay).removeClass('btnWeekMarcado');
            }
            else {
                $('#' + ab + 'week' + weekDay).addClass('btnWeekMarcado');
            }
            for (var weekDay = 1; weekDay <= 7; weekDay++)
                $('#' + $scope.testeAB + 'week' + weekDay).removeClass('bordaDiasSemanaErro');
            $('#dvMensagemDiasSemana').fadeOut();
            $('#dvMensagemDiasSemanaB').fadeOut();
        };
        $scope.clickMetadado = function (ab) {
            if (_inptAssunto) {
                if ($scope.envioBuffer.sequencia > 1 && $scope.enviarComoSelecionado_A === 'E') {
                    //
                }
                else {
                    if ($scope['inptData_' + ab] == '' || $scope['inptData_' + ab] == null || !$scope['inptData_' + ab])
                        $scope['inptData_' + ab] = "/*" + $scope['metadadoSelecionado_' + ab] + "*/";
                    else
                        $scope['inptData_' + ab] += " /*" + $scope['metadadoSelecionado_' + ab] + "*/";
                }
            }
            else {
                var newNode = tinymce.activeEditor.getDoc().createElement("span");
                newNode.textContent = " /*" + $scope['metadadoSelecionado_' + ab] + "*/";
                var range = tinymce.activeEditor.selection.getRng();
                range.insertNode(newNode);
            }
            $scope['metadadoSelecionado_' + ab] = undefined;
        };
        $(document).ready(function () {
            tinymce.editors = [];
            setTimeout(function () {
                tinymce.init({
                    selector: '#txtAreaNovoTemplate',
                    language: 'pt_BR',
                    menubar: false,
                    statusbar: false,
                    remove_redundant_brs: false,
                    force_br_newlines: false,
                    force_p_newlines: false,
                    forced_root_block: 'div',
                    height: 200,
                    width: 718,
                    left: 4,
                    invalid_elements: "img",
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table contextmenu paste code',
                        'textcolor',
                        'preview',
                        'table'
                    ],
                    toolbar: 'undo redo | bold italic | alignleft aligncenter alignright alignjustify | link ',
                    target_list: false,
                    content_css: [
                        '//www.tinymce.com/css/codepen.min.css'
                    ]
                });
                tinymce.init({
                    selector: '#txtAreaTemplate_A',
                    language: 'pt_BR',
                    menubar: false,
                    statusbar: false,
                    remove_redundant_brs: false,
                    force_br_newlines: false,
                    force_p_newlines: false,
                    forced_root_block: 'div',
                    height: 160,
                    width: 719,
                    left: 4,
                    invalid_elements: "img",
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table contextmenu paste code',
                        'textcolor',
                        'preview',
                        'table'
                    ],
                    toolbar: 'undo redo | bold italic | alignleft aligncenter alignright alignjustify | link ',
                    target_list: false,
                    content_css: [
                        '//www.tinymce.com/css/codepen.min.css'
                    ]
                });
                tinymce.init({
                    selector: '#txtAreaTemplate_B',
                    language: 'pt_BR',
                    menubar: false,
                    statusbar: false,
                    remove_redundant_brs: false,
                    force_br_newlines: false,
                    force_p_newlines: false,
                    forced_root_block: 'div',
                    height: 160,
                    width: 719,
                    left: 4,
                    invalid_elements: "img",
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table contextmenu paste code',
                        'textcolor',
                        'preview',
                        'table'
                    ],
                    toolbar: 'undo redo | bold italic | alignleft aligncenter alignright alignjustify | link ',
                    target_list: false,
                    content_css: [
                        '//www.tinymce.com/css/codepen.min.css'
                    ]
                });
                tinymce.init({
                    selector: '#txtEditarTemplate',
                    language: 'pt_BR',
                    menubar: false,
                    statusbar: false,
                    remove_redundant_brs: false,
                    force_br_newlines: false,
                    force_p_newlines: false,
                    forced_root_block: 'div',
                    height: 150,
                    invalid_elements: "img",
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table contextmenu paste code',
                        'textcolor',
                        'preview',
                        'table'
                    ],
                    toolbar: 'undo redo | bold italic | alignleft aligncenter alignright alignjustify | link ',
                    target_list: false,
                    content_css: [
                        '//www.tinymce.com/css/codepen.min.css'
                    ]
                });
            }, 500);
        });
        consultarEmails = function () {
            if ($scope.emails.length == 0) {
                if ($rootScope.permissoes.tipoUsuario != "U") {
                    consultarEmailsTime();
                }
                else {
                    $scope.emails.push({ _id: $rootScope.DadosCadastrais.objectIdLogin, email: $rootScope.emailUsuario, nomeCompleto: $rootScope.nomeUsuarioLogado });
                }
            }
        };
        consultarTemplates = function (pObjectIdTemplate) {
            if ($scope.templates.length == 0) {
                $rootScope.monsterShowLoading = true;
                $http({
                    method: 'GET',
                    url: "".concat($rootScope.ramperV3ApiBaseUrl, "/templates/consultarTemplates"),
                    headers: {
                        'Authorization': 'Bearer ' + $rootScope.Token
                    },
                    params: {
                        ordem: 'templateNome',
                        ordemAscDesc: 1
                    }
                }).then(function (rConsulta) {
                    $scope.templates = rConsulta.data;
                    $scope.templatesPastaEspecifica = rConsulta.data;
                    if (pObjectIdTemplate) {
                        setTimeout(function () {
                            if ($scope.divSegundaTelaPopupSeq) {
                                for (var i = 0; i < $('#templateSelecionado_A')[0].options.length; i++) {
                                    try {
                                        var objetoTemp = JSON.parse($('#templateSelecionado_A')[0].options[i].value);
                                        if (objetoTemp.objectIdTemplate == pObjectIdTemplate) {
                                            $('#templateSelecionado_A')[0].selectedIndex = i;
                                            break;
                                        }
                                    }
                                    catch (e) { }
                                }
                                ;
                            }
                            else if ($scope.divSegundaTelaPopupSeqB) {
                                for (var i = 0; i < $('#templateSelecionado_B')[0].options.length; i++) {
                                    try {
                                        var objetoTemp = JSON.parse($('#templateSelecionado_B')[0].options[i].value);
                                        if (objetoTemp.objectIdTemplate == pObjectIdTemplate) {
                                            $('#templateSelecionado_B')[0].selectedIndex = i;
                                            break;
                                        }
                                    }
                                    catch (e) { }
                                }
                                ;
                            }
                            else {
                                for (var i = 0; i < $('#templateSelecionadoAutomacaoBounce')[0].options.length; i++) {
                                    try {
                                        var objetoTemp = JSON.parse($('#templateSelecionadoAutomacaoBounce')[0].options[i].value);
                                        if (objetoTemp.objectIdTemplate == pObjectIdTemplate) {
                                            $('#templateSelecionadoAutomacaoBounce')[0].selectedIndex = i;
                                            break;
                                        }
                                    }
                                    catch (e) { }
                                }
                                ;
                            }
                        }, 1000);
                    }
                });
            }
        };
        consultarPastasTemplatesPorUsuario = function () {
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/pastas/consultarPastasTemplatesPorUsuario"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                params: {
                    tipoDaChave: $rootScope.permissoes.tipoUsuario
                }
            }).then(function (response) {
                $scope.pastasTemplates = response.data;
                $scope.pastasTemplates.sort(function (a, b) {
                    return a.nomePasta < b.nomePasta ? -1 : a.nomePasta > b.nomePasta ? 1 : 0;
                });
            });
        };
        consultarEmailsTime = function () {
            $rootScope.monsterShowLoading = true;
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/filtros/usuarios"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                }
            }).then(function (rConsulta) {
                function isUser(value) {
                    return (value.tipo.substring(0, 1) == $rootScope.permissoes.tipoUsuario) || ($rootScope.permissoes.tipoUsuario == 'A');
                }
                rConsulta.data[0].loginsAtivos.filter(isUser).forEach(function (usuarioTime) {
                    if (usuarioTime.nome && usuarioTime.email) {
                        if (usuarioTime.tipo != "Visão") {
                            var existeUser = false;
                            $scope.emails.forEach(function (emailUsuario) {
                                if (emailUsuario._id === usuarioTime._id) {
                                    existeUser = true;
                                }
                            });
                            if (!existeUser) {
                                $scope.emails.push({ _id: usuarioTime._id, email: usuarioTime.email, nomeCompleto: usuarioTime.nome });
                            }
                        }
                    }
                });
            });
        };
        $scope.clickTemplate = function (ab) {
            $('#dvMensagemSalvarTemplateA').fadeOut();
            $('#dvMensagemSalvarTemplateB').fadeOut();
            $('.bttnNovoTemplateAB').removeClass('corTextoErro');
            $('#dvMensagemSelecionarTemplateA').fadeOut();
            $('#dvMensagemSelecionarTemplateB').fadeOut();
            $('#templateSelecionado_A').removeClass('corTextoErro');
            $('#templateSelecionado_B').removeClass('corTextoErro');
            if ($('#templateSelecionado_' + ab)[0].selectedOptions[0].value) {
                var objTemp = JSON.parse($('#templateSelecionado_' + ab)[0].selectedOptions[0].value);
                if ($('#enviarComoSelecionado_A')[0].selectedOptions[0].value == 'E' || $('#enviarComoSelecionado_B')[0].selectedOptions[0].value == 'E')
                    $scope['inptData_' + ab] = $scope['inptData_' + ab];
                else
                    $scope['inptData_' + ab] = objTemp.assunto;
                tinymce.editors["txtAreaTemplate_" + ab].setContent(objTemp.template);
                $scope.conteudoTemplate = tinymce.editors["txtAreaTemplate_" + ab].getContent();
                $scope.getInfoChat();
                $scope.getInfoAgenda();
            }
            $('#inptData_A').removeClass('bordaDiasSemanaErro');
            $('#divInptData_A').removeClass('bordaDiasSemanaErro');
            $('#inptData_B').removeClass('bordaDiasSemanaErro');
            $('#divInptData_B').removeClass('bordaDiasSemanaErro');
            $('#dvMensagemAssunto').fadeOut();
            $('#dvMensagemAssuntoB').fadeOut();
            $('#dvMensagemEmail').fadeOut();
            $('#dvMensagemEmailB').fadeOut();
            $('.mce-tinymce').css('border', '1px solid rgba(0,0,0,0.2)');
        };
        $scope.habilitarBtnSalvarTemplate = function () {
            if ($('#inptData_A') != null || $('#inptData_A') != '')
                $rootScope.habilitarBtnSalvarTemplate = true;
        };
        $scope.clickTemplateBounce = function () {
            $('#dvMensagemSalvarTemplateBounce').fadeOut();
            $('#spanNovoTemplateAutBounce').removeClass('corTextoErro');
            $('#dvMensagemSelecionarTemplateBounce').fadeOut();
            $('#templateSelecionadoAutomacaoBounce').removeClass('corTextoErro');
            if ($('#templateSelecionadoAutomacaoBounce')[0].selectedOptions[0].value) {
                var objTemp = JSON.parse($('#templateSelecionadoAutomacaoBounce')[0].selectedOptions[0].value);
                if ($('#templateSelecionadoAutomacaoBounce')[0].selectedOptions[0].value == 'E')
                    $scope['assuntoEnvioBounce'] = $scope['assuntoEnvioBounce'];
                else
                    $scope['assuntoEnvioBounce'] = objTemp.assunto;
                tinymce.editors["txtAreaBounce"].setContent(objTemp.template);
            }
            $('#divTxtAssuntoBounce').removeClass('corTextoErro');
            $('#inptAssuntoBounce').removeClass('divErroAssunto');
            $('#dvMensagemAssuntoBounce').fadeOut();
            $('#dvMensagemEmailBounce').fadeOut();
            $('.mce-tinymce').css('border', '1px solid rgba(0,0,0,0.2)');
        };
        $scope.atualizarNomeCadencia = function () {
            if ($scope.nomeNovaCadencia != null || $scope.nomeNovaCadencia) {
                $('#txtNomeNovaCadencia').removeClass('corTextoErro');
                $('#dvMensagemNomeCadencia').fadeOut();
                $('#idNomeNovaCadencia').removeClass('bBottomTextAreaErro');
            }
            $scope.novaCadencia.nome = $scope.nomeNovaCadencia;
        };
        $('#inptData_A').focusin(function () {
            _inptAssunto = true;
        });
        $('#inptData_B').focusin(function () {
            _inptAssunto = true;
        });
        $('#inptAssuntoBounce').focusin(function () {
            _inptAssuntoAutomacao = true;
        });
        $scope.salvarTemplate = function (pDados) {
            $('#dvMensagemSalvarTemplateBounce').fadeOut();
            $('#dvMensagemSalvarTemplateA').fadeOut();
            $('#dvMensagemSalvarTemplateB').fadeOut();
            $('#spanNovoTemplateAutBounce').removeClass('corTextoErro');
            $('.bttnNovoTemplateAB').removeClass('corTextoErro');
            var qtdErroAssuntoTexto = 0;
            $scope.removerDivsErro();
            if (pDados == 'A') {
                if ($scope.envioBuffer.testeAB == 'A') {
                    if ($scope.inptData_A == null || $scope.inptData_A == '') {
                        $('#inptData_A').addClass('bordaDiasSemanaErro');
                        $('#divInptData_A').addClass('bordaDiasSemanaErro');
                        $('#dvMensagemAssunto').fadeIn();
                        qtdErroAssuntoTexto++;
                    }
                    else {
                        $('#inptData_A').removeClass('bordaDiasSemanaErro');
                        $('#divInptData_A').removeClass('bordaDiasSemanaErro');
                        $('#dvMensagemAssunto').hide();
                    }
                    if (tinymce.editors["txtAreaTemplate_A"].getContent() == null || tinymce.editors["txtAreaTemplate_A"].getContent() == '<div>&nbsp;</div>') {
                        $('#dvMensagemEmail').fadeIn();
                        $('.mce-tinymce').css('border', '1px solid #E04747');
                        qtdErroAssuntoTexto++;
                    }
                    else {
                        $('#dvMensagemEmail').fadeOut();
                        $('.mce-tinymce').css('border', '1px solid rgba(0,0,0,0.2)');
                    }
                }
                else {
                    if ($scope.inptData_B == null || $scope.inptData_B == '') {
                        $('#inptData_B').addClass('bordaDiasSemanaErro');
                        $('#divInptData_B').addClass('bordaDiasSemanaErro');
                        $('#dvMensagemAssuntoB').fadeIn();
                        qtdErroAssuntoTexto++;
                    }
                    else {
                        $('#inptData_B').removeClass('bordaDiasSemanaErro');
                        $('#divInptData_B').removeClass('bordaDiasSemanaErro');
                        $('#dvMensagemAssuntoB').hide();
                    }
                    if (tinymce.editors["txtAreaTemplate_B"].getContent() == null || tinymce.editors["txtAreaTemplate_B"].getContent() == '<div>&nbsp;</div>') {
                        $('#dvMensagemEmailB').fadeIn();
                        $('.mce-tinymce').css('border', '1px solid #E04747');
                        qtdErroAssuntoTexto++;
                    }
                    else {
                        $('#dvMensagemEmailB').fadeOut();
                        $('.mce-tinymce').css('border', '1px solid rgba(0,0,0,0.2)');
                    }
                }
                if (qtdErroAssuntoTexto == 0) {
                    $('#dvMensagemEmail').fadeOut();
                    $('#dvMensagemEmailB').fadeOut();
                    $('.mce-tinymce').css('border', '1px solid rgba(0,0,0,0.2)');
                    $('.divPopupTesteAB').addClass('blur');
                    $('#idPopupNovoTemplate').show();
                    $scope.popupNovoTemplate = true;
                }
            }
            else if (pDados == 'B') {
                $('.divPopupAutomacaoBounce').addClass('blur');
                $('#idPopupNovoTemplate').show();
                $scope.popupNovoTemplate = true;
            }
            $scope.conteudoTemplateOriginal = $scope.conteudoTemplate;
            if (!$scope.popupRespostas) {
                $scope.conteudoTemplate = tinymce.editors["txtAreaTemplate_" + pDados].getContent();
            }
        };
        $scope.fecharPopupNovoTemplate = function () {
            $scope.conteudoTemplate = $scope.conteudoTemplateOriginal;
            $('.divPopupTesteAB').removeClass('blur');
            $('.divPopupAutomacaoBounce').removeClass('blur');
            $scope.popupNovoTemplate = false;
        };
        $scope.salvarNovoTemplate = function () {
            var erroTemplate = 0;
            $('#dvMensagemSalvarTemplateBounce').fadeOut();
            $('#dvMensagemSalvarTemplateA').fadeOut();
            $('#dvMensagemSalvarTemplateB').fadeOut();
            $('#spanNovoTemplateAutBounce').removeClass('corTextoErro');
            $('.bttnNovoTemplateAB').removeClass('corTextoErro');
            if ($scope.nomeTemplate == null || $scope.nomeTemplate == '') {
                $('#dvMensagemNomeTemplate').fadeIn();
                $('#txtNomeNovoTemplate').addClass('corTextoErro');
                $('#idNomeTemplate').addClass('bBottomTextAreaErro');
                erroTemplate++;
            }
            if ($scope.listaPastasCriacaoCadFiltro == null || $scope.listaPastasCriacaoCadFiltro == '' || !$scope.listaPastasCriacaoCadFiltro) {
                $('#listaPastasCriacaoCad').addClass('corTextoErro');
                $('#dvMensagemPastaNovoTemplate').fadeIn();
                erroTemplate++;
            }
            if ($scope['assuntoEnvioBounce'] === '' || !$scope['assuntoEnvioBounce'] && $scope.popupAutomacaoBounce) {
                $('#divTxtAssuntoBounce').addClass('corTextoErro');
                $('#inptAssuntoBounce').addClass('divErroAssunto');
                $('#dvMensagemAssuntoBounce').fadeIn();
                $('#dvMensagemAssuntoTemplate').fadeIn();
                erroTemplate++;
            }
            if (erroTemplate == 0) {
                var conteudo = '';
                var conteudoText = '';
                var assuntoTemp = '';
                if ($scope.divSegundaTelaPopupSeq) {
                    conteudo = tinymce.editors["txtAreaTemplate_A"].getContent();
                    conteudoText = tinymce.editors["txtAreaTemplate_A"].getContent({ format: 'text' });
                    assuntoTemp = $scope.inptData_A;
                }
                else if ($scope.divSegundaTelaPopupSeqB) {
                    conteudo = tinymce.editors["txtAreaTemplate_B"].getContent();
                    conteudoText = tinymce.editors["txtAreaTemplate_B"].getContent({ format: 'text' });
                    assuntoTemp = $scope.inptData_B;
                    $scope.conteudoTemplate = conteudo;
                }
                else {
                    conteudo = tinymce.editors["txtAreaBounce"].getContent();
                    conteudoText = tinymce.editors["txtAreaBounce"].getContent({ format: 'text' });
                    assuntoTemp = $scope.assuntoEnvioBounce;
                }
                var listaLinks = conteudo.split('href="');
                var listaLinksSalvar = [];
                if (listaLinks.length > 1) {
                    var i = 0;
                    listaLinks.forEach(function (iLink) {
                        if (i > 0)
                            listaLinksSalvar.push(iLink.split('"')[0]);
                        i++;
                    });
                }
                $rootScope.monsterShowLoading = true;
                $http({
                    method: 'POST',
                    url: "".concat($rootScope.ramperV3ApiBaseUrl, "/templates/salvar"),
                    headers: {
                        'Authorization': 'Bearer ' + $rootScope.Token
                    },
                    data: {
                        nome: $scope.nomeTemplate,
                        assunto: assuntoTemp,
                        template: encodeURI(conteudo),
                        templateText: conteudoText,
                        listaLinks: listaLinksSalvar,
                        tipoTemplate: "A",
                        objectIdPastaTemplate: $('#listaPastasCriacaoCad')[0].selectedOptions[0].value != '' ? $('#listaPastasCriacaoCad')[0].selectedOptions[0].value : null,
                        dataInclusao: new Date()
                    }
                }).then(function (retorno) {
                    $scope.popupNovoTemplate = false;
                    $('.divPopupTesteAB').removeClass('blur');
                    $('.divPopupAutomacaoBounce').removeClass('blur');
                    MonsterToasterService.showSuccessToaster("", "Template salvo com sucesso!", 6000, 350);
                    $scope.templates = [];
                    consultarTemplates(retorno.data._id);
                    $rootScope.monsterShowLoading = false;
                });
            }
        };
        $scope.clickAcao = function () {
            if (($scope.acaoSelecionada == "E") || ($scope.acaoSelecionada == "B")) {
                $scope.emCasoBounce = true;
            }
            else {
                $scope.emCasoBounce = false;
            }
        };
        $scope.clickAll = function (pCadenciaContato) {
            $scope.itemCadencia.forEach(function (item) {
                item.check = $('#selectAll').is(":checked");
            });
        };
        //#endregion
        //#region Etapa 4
        $scope.clickMetadadoBounce = function () {
            if (tinymce.activeEditor) {
                if ($scope.assuntoEnvioBounce && _inptAssuntoAutomacao) {
                    $scope.assuntoEnvioBounce += " /*" + $scope.metadadoSelecionadoAutomacaoBounce + "*/";
                }
                else {
                    var newNode = tinymce.activeEditor.getDoc().createElement("span");
                    newNode.textContent = "/*" + $scope.metadadoSelecionadoAutomacaoBounce + "*/";
                    var range = tinymce.activeEditor.selection.getRng();
                    range.insertNode(newNode);
                }
                $scope.metadadoSelecionadoAutomacaoBounce = undefined;
            }
        };
        $(document).ready(function () {
            tinymce.editors = [];
            setTimeout(function () {
                tinymce.init({
                    selector: '#txtAreaBounce',
                    language: 'pt_BR',
                    menubar: false,
                    statusbar: false,
                    remove_redundant_brs: false,
                    force_br_newlines: false,
                    force_p_newlines: false,
                    forced_root_block: 'div',
                    height: 200,
                    width: 619,
                    left: 4,
                    invalid_elements: "img",
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table contextmenu paste code',
                        'textcolor',
                        'preview',
                        'table'
                    ],
                    toolbar: 'undo redo | bold italic | alignleft aligncenter alignright alignjustify | link ',
                    target_list: false,
                    content_css: [
                        '//www.tinymce.com/css/codepen.min.css'
                    ]
                });
            }, 500);
        });
        //#endregion
        //#region Geral Etapas
        inicializarBuffer = function () {
            $scope.envioBuffer = {
                sequencia: 1,
                testeAB: 'A',
                diasEnvio: {
                    1: false,
                    2: false,
                    3: false,
                    4: false,
                    5: false,
                    6: false,
                    7: false
                },
                horaInicio: null,
                horaFim: null,
                dataInicio: null,
                enviosPorDia: null,
                intervaloDias: null,
                template: null,
                emailDeEnvio: null,
                enviadoComo: null,
                quemRecebe: null,
                assunto: null,
                mensagem: null
            };
        };
        $scope.abrirFormNovaCadencia = function (pAlterar) {
            if ($scope.novaCadencia.alteracao && $scope.novaCadencia.segmentacao._id != null) {
                document.getElementById("segmentacaoFiltro").disabled = true;
                document.getElementById("segmentacaoFiltro").enabled = false;
                document.getElementById("checkboxAltaInput").setAttribute('disabled', true);
                document.getElementById("checkboxMediaInput").setAttribute('disabled', true);
                document.getElementById("checkboxBaixaInput").setAttribute('disabled', true);
                document.getElementById("checkboxSemInput").setAttribute('disabled', true);
                $scope.spanCriacaoEdicao = 'editada';
                $scope.criacaoCadenciaAB = false;
            }
            else {
                document.getElementById("segmentacaoFiltro").disabled = false;
                document.getElementById("segmentacaoFiltro").enabled = true;
                document.getElementById("checkboxAltaInput").removeAttribute('disabled');
                document.getElementById("checkboxMediaInput").removeAttribute('disabled');
                document.getElementById("checkboxBaixaInput").removeAttribute('disabled');
                document.getElementById("checkboxSemInput").removeAttribute('disabled');
                $scope.spanCriacaoEdicao = 'criada';
                $scope.criacaoCadenciaAB = true;
            }
            $scope.nomeBtnAvancar = 'AVANÇAR';
            $scope.nomeBtnVoltar = 'Voltar';
            $('#voltar').hide();
            $('#filtroConfiabilidadeCadencia').hide();
            $scope.slideUp = true;
            $scope.novaCadencia.tipoCadencia === 'Outbound' ? $('#labelErroValueEmail').css('transform', 'translateY(22px) translateX(405px)') : $('#labelErroValueEmail').css('transform', 'translateY(22px) translateX(75px)');
            _countDiv = 1;
            showEtapa(_countDiv);
            $('#formNovaCadencia').fadeIn();
            $('#formNovaCadencia').removeClass('blur');
            consultarTemplates();
            consultarPastasTemplatesPorUsuario();
            consultarEmails();
            $scope.mostrarFiltroConfiabilidade();
            setTimeout(function () { $('#idNomeNovaCadencia').focus(); }, 90);
            $rootScope.monsterShowLoading = false;
        };
        $scope.novoTesteAB = function (sequenciaSelecionada) {
            var objEnvio = sequenciaSelecionada.envios[sequenciaSelecionada.envios.length - 1];
            $scope.envioBuffer = {
                sequencia: sequenciaSelecionada.nroSeq,
                testeAB: String.fromCharCode(objEnvio.testeAB.charCodeAt(objEnvio.testeAB.length - 1) + 1),
                diasEnvio: {
                    1: objEnvio.diasEnvio['1'],
                    2: objEnvio.diasEnvio['2'],
                    3: objEnvio.diasEnvio['3'],
                    4: objEnvio.diasEnvio['4'],
                    5: objEnvio.diasEnvio['5'],
                    6: objEnvio.diasEnvio['6'],
                    7: objEnvio.diasEnvio['7']
                },
                horaInicio: objEnvio.horaInicio.toString(),
                horaFim: objEnvio.horaFim.toString(),
                dataInicio: new Date(objEnvio.dataInicio),
                enviosPorDia: objEnvio.enviosPorDia.toString(),
                intervaloDias: parseInt(objEnvio.intervaloDias),
                template: objEnvio.template.toString(),
                emailDeEnvio: objEnvio.emailDeEnvio.toString(),
                enviadoComo: objEnvio.enviadoComo.toString(),
                quemRecebe: objEnvio.quemRecebe.toString(),
                assunto: objEnvio.assunto.toString(),
                mensagem: objEnvio.mensagem.toString()
            };
            $scope.assuntoComparativo = objEnvio.assunto.toString();
            carregarTelaComDadosEnvio();
        };
        $scope.novaSequencia = function () {
            $scope.envioBuffer = {
                sequencia: $scope.novaCadencia.disparoSequencias.sequencias.length + 1,
                testeAB: 'A',
                diasEnvio: {
                    1: false,
                    2: true,
                    3: true,
                    4: true,
                    5: true,
                    6: true,
                    7: false
                },
                horaInicio: null,
                horaFim: null,
                dataInicio: null,
                enviosPorDia: null,
                intervaloDias: null,
                template: null,
                emailDeEnvio: null,
                enviadoComo: $scope.novaCadencia.disparoSequencias.sequencias.length + 1 > 1 ? 'E' : 'N',
                quemRecebe: null,
                assunto: null,
                mensagem: null
            };
            carregarTelaComDadosEnvio();
            for (var i = 0; i < $('#emailSelecionado_A')[0].options.length; i++) {
                try {
                    var emailDeEnvioUsuario = JSON.parse($('#emailSelecionado_A')[0].options[i].value).email;
                    if (emailDeEnvioUsuario == $rootScope.emailUsuario) {
                        $('#emailSelecionado_A')[0].selectedIndex = i;
                        break;
                    }
                }
                catch (e) {
                }
            }
            ;
        };
        $scope.editarEnvioDaSequencia = function (envioSelecionado) {
            $('#idFundoPopupCadencica').fadeIn();
            $scope.envioBuffer = {
                sequencia: envioSelecionado.sequencia,
                testeAB: envioSelecionado.testeAB,
                diasEnvio: envioSelecionado.diasEnvio,
                horaInicio: envioSelecionado.horaInicio,
                horaFim: envioSelecionado.horaFim,
                dataInicio: envioSelecionado.dataInicio,
                enviosPorDia: envioSelecionado.enviosPorDia,
                intervaloDias: envioSelecionado.intervaloDias,
                template: envioSelecionado.template,
                emailDeEnvio: envioSelecionado.emailDeEnvio,
                enviadoComo: envioSelecionado.enviadoComo,
                quemRecebe: envioSelecionado.quemRecebe,
                assunto: envioSelecionado.assunto,
                mensagem: envioSelecionado.mensagem
            };
            carregarTelaComDadosEnvio();
            if (envioSelecionado.testeAB == 'A') {
                $scope.popupTesteAB = true;
                $scope.popupRespostas = false;
                $scope.divSegundaTelaPopupSeq = false;
                $scope.selecionarTemplateAut = true;
                $scope.consultarPastaTemplateEspecifico();
            }
            else {
                $scope.popupRespostas = true;
                $scope.popupTesteAB = false;
                $scope.divSegundaTelaPopupSeqB = true;
                document.getElementById("templateSelecionado_B").disabled = true;
                tinymce.editors["txtAreaTemplate_B"].setMode('readonly');
                $('#btnVoltarTesteAB').hide();
            }
            $scope.conteudoTemplate = tinymce.editors["txtAreaTemplate_" + envioSelecionado.testeAB].getContent();
        };
        $scope.removerEnvioDaSequencia = function (envioSelecionado) {
            var indexSequencia = envioSelecionado.sequencia - 1;
            var indexEnvio = $scope.novaCadencia.disparoSequencias.sequencias[indexSequencia].envios.findIndex(function (x) { return x.testeAB === envioSelecionado.testeAB; });
            if ($scope.criacaoCadenciaAB == true && envioSelecionado != 1) {
                $scope.exibeVariacaoTesteAB = true;
            }
            for (var i = indexEnvio + 1; i < $scope.novaCadencia.disparoSequencias.sequencias[indexSequencia].envios.length; i++) {
                var testeAB = $scope.novaCadencia.disparoSequencias.sequencias[indexSequencia].envios[i].testeAB;
                testeAB = String.fromCharCode(testeAB.charCodeAt(testeAB.length - 1) - 1);
                $scope.novaCadencia.disparoSequencias.sequencias[indexSequencia].envios[i].testeAB = testeAB;
            }
            $scope.novaCadencia.disparoSequencias.sequencias[indexSequencia].envios.splice(indexEnvio, 1);
            if ($scope.novaCadencia.disparoSequencias.sequencias[indexSequencia].envios.length == 0) {
                for (var i = indexSequencia + 1; i < $scope.novaCadencia.disparoSequencias.sequencias.length; i++) {
                    $scope.novaCadencia.disparoSequencias.sequencias[i].nroSeq = $scope.novaCadencia.disparoSequencias.sequencias[i].nroSeq - 1;
                    for (var j = 0; j < $scope.novaCadencia.disparoSequencias.sequencias[i].envios.length; j++) {
                        $scope.novaCadencia.disparoSequencias.sequencias[i].envios[j].sequencia = $scope.novaCadencia.disparoSequencias.sequencias[i].envios[j].sequencia - 1;
                        if ($scope.novaCadencia.disparoSequencias.sequencias[i].envios[j].sequencia == 1) {
                            $scope.novaCadencia.disparoSequencias.sequencias[i].envios[j].dataInicio = new Date();
                            $scope.novaCadencia.disparoSequencias.sequencias[i].envios[j].dataInicio
                                .setDate($scope.novaCadencia.disparoSequencias.sequencias[i].envios[j].dataInicio.getDate() +
                                $scope.novaCadencia.disparoSequencias.sequencias[i].envios[j].intervaloDias);
                            $scope.novaCadencia.disparoSequencias.sequencias[i].envios[j].intervaloDias = 0;
                        }
                    }
                }
                $scope.novaCadencia.disparoSequencias.sequencias.splice(indexSequencia, 1);
                $scope.novaCadencia.disparoSequencias.sequencias.forEach(function (item) {
                    item.envios.sort(function (a, b) {
                        return a.testeAB < b.testeAB ? -1 : a.testeAB > b.testeAB ? 1 : 0;
                    });
                });
                $scope.qtdSequenciaCadencia = $scope.novaCadencia.disparoSequencias.sequencias.length;
                if ($scope.qtdSequenciaCadencia == 0)
                    $('.divGeralBotaoAdicionarCad').css('top', '80px');
                if ($scope.novaCadencia.disparoSequencias.sequencias.length == 0) {
                    $scope.sequenciaExtenso = 'Primeiro';
                    $scope.testeAB = 'A';
                    $scope.valueInicio = '';
                    $scope.valueFim = '';
                    $scope.valueDias = '';
                    $scope.valueEmails = '';
                    $scope.templateSelecionado = '';
                    $scope.emailSelecionado = '';
                    $scope.enviarComoSelecionado = '';
                    $scope.quemRecebeSelecionado = '';
                    $scope.assuntoNovo = '';
                    tinymce.editors["txtAreaNovoTemplate"].setContent('');
                    $scope.envioBuffer = {
                        sequencia: null,
                        testeAB: null,
                        diasEnvio: {
                            1: false,
                            2: false,
                            3: false,
                            4: false,
                            5: false,
                            6: false,
                            7: false
                        },
                        horaInicio: null,
                        horaFim: null,
                        dataInicio: null,
                        enviosPorDia: null,
                        intervaloDias: null,
                        template: null,
                        emailDeEnvio: null,
                        enviadoComo: null,
                        quemRecebe: null,
                        assunto: null,
                        mensagem: null
                    };
                }
                limparCampos();
            }
        };
        carregarTelaComDadosEnvio = function () {
            $scope.selectQuemRecebe = $scope.envioBuffer.sequencia > 1;
            $scope.sequenciaExtenso = numeroOrdinalPorExtenso($scope.envioBuffer.sequencia);
            $scope.testeAB = $scope.envioBuffer.testeAB;
            var ab = $scope.testeAB;
            for (var weekDay = 1; weekDay <= 7; weekDay++)
                if ($scope.envioBuffer.diasEnvio[weekDay])
                    $('#' + ab + 'week' + weekDay).addClass('btnWeekMarcado');
                else
                    $('#' + ab + 'week' + weekDay).removeClass('btnWeekMarcado');
            if ($scope.envioBuffer.horaInicio == null)
                $scope['valueInicio_' + ab] = '08:00';
            else
                $scope['valueInicio_' + ab] = $scope.envioBuffer.horaInicio;
            if ($scope.envioBuffer.horaFim == null)
                $scope['valueFim_' + ab] = '18:00';
            else
                $scope['valueFim_' + ab] = $scope.envioBuffer.horaFim;
            if ($scope.envioBuffer.sequencia > 1) {
                $scope['valueDias_' + ab] = $scope.envioBuffer.intervaloDias;
            }
            else {
                $scope.dataInicioCadencia = $scope.envioBuffer.dataInicio;
                if ($scope.envioBuffer.dataInicio != null) {
                    var data = new Date($scope.envioBuffer.dataInicio);
                    var dia = data.getDate();
                    if (dia.toString().length == 1)
                        dia = "0" + dia;
                    var mes = data.getMonth() + 1;
                    if (mes.toString().length == 1)
                        mes = "0" + mes;
                    var ano = data.getFullYear();
                    data = dia + "/" + mes + "/" + ano;
                    $('#sltDataInicioCadencia_' + ab).val(data);
                }
            }
            if ($scope.envioBuffer.enviosPorDia == null)
                $scope['valueEmails_' + ab] = 25; //$('#valueEmails_'+ab).val(25);
            else
                $scope['valueEmails_' + ab] = $scope.envioBuffer.enviosPorDia; //$('#valueEmails_'+ab).val($scope.envioBuffer.enviosPorDia);
            if ($scope.novaCadencia.tipoCadencia === 'Engage')
                $scope['valueEmails_' + ab] = 9999;
            $scope.setarOption(ab.toUpperCase()); //e-mail e template
            $scope['enviarComoSelecionado_' + ab] = $scope.envioBuffer.enviadoComo;
            if ($scope.envioBuffer.quemRecebe === null) {
                $scope['quemRecebeSelecionado_' + ab] = "N";
            }
            else {
                $scope['quemRecebeSelecionado_' + ab] = $scope.envioBuffer.quemRecebe;
            }
            $scope['inptData_' + ab] = $scope.envioBuffer.assunto;
            try {
                tinymce.editors["txtAreaTemplate_" + ab].setContent($scope.envioBuffer.mensagem);
            }
            catch (e) {
                tinymce.editors["txtAreaTemplate_" + ab].setContent('');
            }
            if ($scope.envioBuffer.sequencia < 2) {
                $scope.novaCadencia.tipoCadencia === 'Outbound' ?
                    $('#divComCalendario').css('display', 'block') :
                    $scope.novaCadencia.tipoCadencia === 'Engage' ?
                        $('#divComCalendario').css('display', 'block') :
                        $('#divComCalendario').css('display', 'none');
                $scope.mostrarCalendario = true;
                $('#dvMensagemIntervaloDiasB').hide();
                $('#dvMensagemIntervaloDias').hide();
            }
            else
                $scope.mostrarCalendario = false;
        };
        atualizarBuffer = function (ab) {
            $scope.envioBuffer.sequencia = numeroPorExtensoOrdinal($scope.sequenciaExtenso);
            $scope.envioBuffer.testeAB = ab;
            for (var weekDay = 1; weekDay <= 7; weekDay++) {
                $scope.envioBuffer.diasEnvio[weekDay] = $('#' + ab + 'week' + weekDay).hasClass('btnWeekMarcado');
                $('#' + ab + 'week' + weekDay).removeClass('btnWeekMarcado');
            }
            $scope.envioBuffer.horaInicio = $scope['valueInicio_' + ab];
            $scope.envioBuffer.horaFim = $scope['valueFim_' + ab];
            if ($scope.envioBuffer.sequencia > 1) {
                $scope.envioBuffer.dataInicio = null;
                $scope.envioBuffer.intervaloDias = $scope['valueDias_' + ab];
            }
            else {
                $scope.envioBuffer.dataInicio = $scope.dataInicioCadencia; //new Date();
                $scope.envioBuffer.intervaloDias = 0;
            }
            $scope.envioBuffer.enviosPorDia = $scope['valueEmails_' + ab]; //$('#valueEmails_'+ab)[0].selectedOptions[0].value;
            var templateSel = JSON.parse($('#templateSelecionado_' + ab)[0].selectedOptions[0].value);
            $scope.envioBuffer.template = templateSel.objectIdTemplate;
            $scope.envioBuffer.templateNome = templateSel.templateNome;
            var emailSel = JSON.parse($('#emailSelecionado_' + ab)[0].selectedOptions[0].value);
            $scope.envioBuffer.emailDeEnvio = emailSel._id;
            $scope.envioBuffer.emailDeEnvioNome = emailSel.nomeCompleto;
            $scope.envioBuffer.enviadoComo = $scope.envioBuffer.sequencia > 1 ? $scope['enviarComoSelecionado_' + ab] : 'N';
            $scope.envioBuffer.quemRecebe = $scope.envioBuffer.sequencia > 1 ? $scope['quemRecebeSelecionado_' + ab] : 'T';
            $scope.envioBuffer.assunto = $scope['inptData_' + ab];
            $scope.envioBuffer.mensagem = tinymce.editors["txtAreaTemplate_" + ab].getContent();
            popularObjetoPrincipal();
            limparCampos();
        };
        numeroOrdinalPorExtenso = function (value) {
            var _retorno = '';
            switch (value) {
                case 1:
                    _retorno = 'Primeiro';
                    break;
                case 2:
                    _retorno = 'Segundo';
                    break;
                case 3:
                    _retorno = 'Terceiro';
                    break;
                case 4:
                    _retorno = 'Quarto';
                    break;
                case 5:
                    _retorno = 'Quinto';
                    break;
                case 6:
                    _retorno = 'Sexto';
                    break;
                case 7:
                    _retorno = 'Sétimo';
                    break;
                case 8:
                    _retorno = 'Oitavo';
                    break;
                case 9:
                    _retorno = 'Nono';
                    break;
                case 10:
                    _retorno = 'Décimo';
                    break;
                default:
                    _retorno = '';
            }
            return _retorno;
        };
        numeroPorExtensoOrdinal = function (value) {
            var _retorno = '';
            switch (value) {
                case 'Primeiro':
                    _retorno = 1;
                    break;
                case 'Segundo':
                    _retorno = 2;
                    break;
                case 'Terceiro':
                    _retorno = 3;
                    break;
                case 'Quarto':
                    _retorno = 4;
                    break;
                case 'Quinto':
                    _retorno = 5;
                    break;
                case 'Sexto':
                    _retorno = 6;
                    break;
                case 'Sétimo':
                    _retorno = 7;
                    break;
                case 'Oitavo':
                    _retorno = 8;
                    break;
                case 'Nono':
                    _retorno = 9;
                    break;
                case 'Décimo':
                    _retorno = 10;
                    break;
                default:
                    _retorno = '';
            }
            return _retorno;
        };
        popularObjetoPrincipal = function () {
            if ($scope.novaCadencia.disparoSequencias.sequencias[$scope.envioBuffer.sequencia - 1]) {
                if ($scope.novaCadencia.disparoSequencias.sequencias[$scope.envioBuffer.sequencia - 1].envios.find(function (x) { return x.testeAB === $scope.envioBuffer.testeAB; })) {
                    var index = $scope.novaCadencia.disparoSequencias.sequencias[$scope.envioBuffer.sequencia - 1].envios.findIndex(function (x) { return x.testeAB === $scope.envioBuffer.testeAB; });
                    $scope.novaCadencia.disparoSequencias.sequencias[$scope.envioBuffer.sequencia - 1].envios.splice(index, 1);
                }
                $scope.novaCadencia.disparoSequencias.sequencias[$scope.envioBuffer.sequencia - 1].envios.push($scope.envioBuffer);
            }
            else {
                $scope.novaCadencia.disparoSequencias.sequencias.push({
                    nroSeq: $scope.envioBuffer.sequencia,
                    envios: [$scope.envioBuffer]
                });
            }
            if ($scope.envioBuffer.testeAB === 'A') {
                var envioTesteB = $scope.novaCadencia.disparoSequencias.sequencias[$scope.envioBuffer.sequencia - 1].envios.find(function (x) { return x.testeAB === 'B'; });
                if (envioTesteB) {
                    var indexB = $scope.novaCadencia.disparoSequencias.sequencias[$scope.envioBuffer.sequencia - 1].envios.findIndex(function (x) { return x.testeAB === 'B'; });
                    $scope.novaCadencia.disparoSequencias.sequencias[$scope.envioBuffer.sequencia - 1].envios.splice(indexB, 1);
                    $scope.novaCadencia.disparoSequencias.sequencias[$scope.envioBuffer.sequencia - 1].envios.push({
                        sequencia: $scope.envioBuffer.sequencia,
                        testeAB: envioTesteB.testeAB,
                        diasEnvio: $scope.envioBuffer.diasEnvio,
                        horaInicio: $scope.envioBuffer.horaInicio,
                        horaFim: $scope.envioBuffer.horaFim,
                        dataInicio: $scope.envioBuffer.dataInicio,
                        enviosPorDia: $scope.envioBuffer.enviosPorDia,
                        intervaloDias: $scope.envioBuffer.intervaloDias,
                        template: envioTesteB.template,
                        templateNome: envioTesteB.templateNome,
                        emailDeEnvio: $scope.envioBuffer.emailDeEnvio,
                        emailDeEnvioNome: $scope.envioBuffer.emailDeEnvioNome,
                        enviadoComo: $scope.envioBuffer.enviadoComo,
                        quemRecebe: $scope.envioBuffer.quemRecebe,
                        assunto: envioTesteB.assunto,
                        mensagem: envioTesteB.mensagem
                    });
                }
            }
            ;
            $scope.novaCadencia.disparoSequencias.sequencias.forEach(function (item) {
                item.envios.sort(function (a, b) {
                    return a.testeAB < b.testeAB ? -1 : a.testeAB > b.testeAB ? 1 : 0;
                });
            });
            $scope.envioBuffer = {
                sequencia: null,
                testeAB: null,
                diasEnvio: {
                    1: false,
                    2: false,
                    3: false,
                    4: false,
                    5: false,
                    6: false,
                    7: false
                },
                horaInicio: null,
                horaFim: null,
                dataInicio: null,
                enviosPorDia: null,
                intervaloDias: null,
                template: null,
                emailDeEnvio: null,
                enviadoComo: null,
                quemRecebe: null,
                assunto: null,
                mensagem: null
            };
        };
        var nomeCadenciaMixPanel = '';
        var idCadenciaMixPanel = '';
        $scope.avancar = function () {
            var qtdErros = 0;
            if (_countDiv == 1) {
                if ($scope.novaCadencia.tipoCadencia === 'Engage') {
                    $scope.novaCadencia.segmentacao.alta = true;
                    $scope.novaCadencia.segmentacao.media = true;
                    $scope.novaCadencia.segmentacao.baixa = true;
                    $scope.novaCadencia.segmentacao.sem = true;
                }
                nomeCadenciaMixPanel = $scope.novaCadencia.nome;
                idCadenciaMixPanel = $scope.novaCadencia._id;
                if (!$scope.novaCadencia.nome || $scope.novaCadencia.nome == null || $scope.novaCadencia.nome == '') {
                    $('#txtNomeNovaCadencia').addClass('corTextoErro');
                    $('#dvMensagemNomeCadencia').fadeIn();
                    $('#idNomeNovaCadencia').addClass('bBottomTextAreaErro');
                    qtdErros++;
                }
                if ($scope.novaCadencia.tipoCadencia === 'Outbound' || $scope.novaCadencia.tipoCadencia === 'Engage') {
                    if ($scope.segmentacaoFiltro == null || $scope.segmentacaoFiltro == '' || !$scope.segmentacaoFiltro) {
                        $('#segmentacaoFiltro').addClass('corTextoErro');
                        $scope.dvMensagemSegCad = true;
                        qtdErros++;
                    }
                }
                else if ($scope.novaCadencia.tipoCadencia === 'Reengajamento') {
                    var contagem = { funis: 0, etapas: 0, motivos: 0, usuarios: 0 };
                    $scope.listaFunisCadP.forEach(function (funil) {
                        if (funil.checkSelecionado) {
                            contagem.funis++;
                        }
                    });
                    $scope.listaMotivosCadP.forEach(function (motivo) {
                        if (motivo.checkMotivoSelecionado) {
                            contagem.motivos++;
                        }
                    });
                    $scope.listaEtapasCadP.forEach(function (etapa) {
                        if (etapa.checkEtapaSelecionado) {
                            contagem.etapas++;
                        }
                    });
                    $scope.listaUsuariosCadP.forEach(function (usuario) {
                        if (usuario.checkUsuarioSelecionado) {
                            contagem.usuarios++;
                        }
                    });
                    if (contagem.funis === 0) {
                        $scope.txtMsgErroFunilCadP = true;
                        $scope.corTxtFunilCadP = '#E04747';
                        qtdErros++;
                    }
                    if (contagem.motivos === 0) {
                        $scope.txtMsgErroMotivoCadP = true;
                        $scope.corTxtMotivoCadP = '#E04747';
                        qtdErros++;
                    }
                    if (contagem.etapas === 0) {
                        $scope.txtMsgErroEtapaCadP = true;
                        $scope.corTxtEtapaCadP = '#E04747';
                        qtdErros++;
                    }
                    if (contagem.usuarios === 0) {
                        $scope.txtMsgErroUsuarioCadP = true;
                        $scope.corTxtUsuarioCadP = '#E04747';
                        qtdErros++;
                    }
                    if ($scope.inicioDisparosCadP < 1 || !$scope.inicioDisparosCadP) {
                        $('#idInptInicioDisparosCadP').addClass('corTextoErro');
                        $scope.txtMsgErroInicioCadP = true;
                        qtdErros++;
                    }
                    if ($scope.reengajamento.checkDiasRetroativos && ($scope.reengajamento.diasRetroativos < 1 || !$scope.reengajamento.diasRetroativos)) {
                        $('#idInptDiasRetroativos').addClass('corTextoErro');
                        $scope.txtMsgErroDiasRetroativos = true;
                        qtdErros++;
                    }
                    $http({
                        method: 'POST',
                        url: "".concat($rootScope.ramperV3ApiBaseUrl, "/reengajamento/check-cadencias-existentes"),
                        headers: {
                            'ramp3r-auth': 'ramp3r-authorization',
                            'ramp3r-l': $rootScope.IDCrypto,
                            'Authorization': 'Bearer ' + $rootScope.Token
                        },
                        data: {
                            cadencia: {
                                _id: $scope.novaCadencia._id ? $scope.novaCadencia._id : null,
                                dadosReengajamento: {
                                    funil: $scope.listaFunisCadP
                                        .filter(function (funil) {
                                        return funil.checkSelecionado === true;
                                    })
                                        .map(function (funil) {
                                        return funil.id;
                                    }),
                                    estagio: $scope.listaEtapasCadP
                                        .filter(function (etapa) {
                                        return etapa.checkEtapaSelecionado === true;
                                    })
                                        .map(function (etapa) {
                                        return etapa.id;
                                    }),
                                    usuario: $scope.listaUsuariosCadP
                                        .filter(function (usuario) {
                                        return usuario.checkUsuarioSelecionado === true;
                                    })
                                        .map(function (usuario) {
                                        return usuario.id;
                                    }),
                                    motivo: $scope.listaMotivosCadP
                                        .filter(function (motivo) {
                                        return motivo.checkMotivoSelecionado === true;
                                    })
                                        .map(function (motivo) {
                                        return motivo.id;
                                    })
                                }
                            }
                        }
                    }).then(function (rConsulta) {
                        $scope.cadenciaComMesmoParametro = rConsulta.data.cadenciaSimilarExiste;
                        if ($scope.cadenciaComMesmoParametro) {
                            qtdErros++;
                        }
                        if (qtdErros == 0) {
                            _countDiv++;
                            $('#voltar').show();
                            showEtapa(_countDiv);
                            $scope.qtdSequenciaCadencia = $scope.novaCadencia.disparoSequencias.sequencias.length;
                        }
                    });
                }
                else if ($scope.novaCadencia.tipoCadencia === 'Inbound') {
                    if ($scope.inicioDisparosCadP < 1 || !$scope.inicioDisparosCadP) {
                        $('#idInptInicioDisparosCadP').addClass('corTextoErro');
                        $scope.txtMsgErroInicioCadP = true;
                        qtdErros++;
                    }
                }
                if ($scope.novaCadencia.tipoCadencia !== "Reengajamento") {
                    if (qtdErros == 0) {
                        _countDiv++;
                        $('#voltar').show();
                        showEtapa(_countDiv);
                        $scope.qtdSequenciaCadencia = $scope.novaCadencia.disparoSequencias.sequencias.length;
                    }
                }
            }
            else {
                if (_countDiv < 5) {
                    if ($scope.novaCadencia.tipoCadencia !== 'Inbound') {
                        if (_countDiv == 2) {
                            $scope.nomeBtnAvancar = 'ATIVAR';
                        }
                        if (_countDiv == 3) {
                            _countDiv++;
                        }
                    }
                    else {
                        if (_countDiv == 2) {
                            $scope.nomeBtnAvancar = 'CRIAR';
                        }
                        if (_countDiv == 3) {
                            salvarNovaCadencia();
                        }
                    }
                    _countDiv++;
                    $('#voltar').show();
                    if (_countDiv == 4) {
                        $scope.nomeBtnAvancar = $scope.novaCadencia.tipoCadencia !== 'Inbound' ? 'ATIVAR' : 'AVANÇAR';
                    }
                    if (_countDiv == 5) {
                        $('#voltar').hide();
                        $scope.nomeBtnAvancar = 'FECHAR';
                    }
                    showEtapa(_countDiv);
                }
                else {
                    if ($scope.spanCriacaoEdicao === 'criada') {
                        if ($scope.novaCadencia && $scope.novaCadencia.tipoCadencia == 'Inbound') {
                            mixpanel.track("Cadência | Criou uma cadência (Inbound Ativo)", { "Local_IP": $rootScope.ip });
                        }
                    }
                    else {
                        mixpanel.track("Cadência | Editou a cadência", { "Local_IP": $rootScope.ip });
                    }
                    $scope.fecharFormNovaCadencia();
                    _contPagina = 1;
                    $scope.consultarCadencias(_filtros);
                }
            }
        };
        $scope.validarSegmentacao = function () {
            if ($scope.segmentacaoFiltro != null || $scope.segmentacaoFiltro) {
                $('#segmentacaoFiltro').removeClass('corTextoErro');
                $scope.dvMensagemSegCad = false;
            }
        };
        $scope.selecionaCapturasDiarias = function () {
        };
        $scope.mostrarFiltroConfiabilidade = function () {
            $('#filtroConfiabilidadeCadencia').show();
            $scope.slideUp = !$scope.slideUp;
            $scope.populaTotalContatos();
        };
        $scope.voltar = function () {
            if (_countDiv == 5 && $scope.novaCadencia.disparoSequencias.sequencias[0])
                _countDiv--;
            if (_countDiv == 6)
                $scope.fecharFormNovaCadencia();
            else if (_countDiv > 1) {
                _countDiv--;
                $scope.nomeBtnAvancar = 'AVANÇAR';
            }
            if (_countDiv == 1) {
                $('#voltar').hide();
                if ($scope.qtdSequenciaCadencia < 1)
                    $scope.qtdSequenciaCadencia = 1;
            }
            showEtapa(_countDiv);
        };
        showEtapa = function (etapa) {
            $('#dvEtapa1').hide();
            $('#dvEtapa2').hide();
            $('#dvEtapa3').hide();
            $('#dvEtapa4').hide();
            $('#dvEtapa5').hide();
            $('#dvEtapa6').hide();
            var x = etapa + 1;
            switch (etapa) {
                case 1:
                    if (!$scope.listaSegmentacoes.length)
                        $scope.consultarSegmentacoesComContatos(false, false);
                    if (!$scope.listaFunisCadP.length) {
                        $scope.consultarListaFunis();
                    }
                    document.getElementById('dvTraco1').className = 'dvTracoAzulPassoPasso tracoCadencia';
                    document.getElementById('spanInicio').className = 'txtAzulPassoPasso txtPassoPassoInicio';
                    $('#imgPasso1').attr('src', "".concat($rootScope.bucket, "/ramper-v-escuro-15.png"));
                    document.getElementById('dvTraco2').className = 'dvTracoCinzaPassoPasso tracoCadencia';
                    document.getElementById('spanCriacao').className = 'txtCinzaPassoPasso txtPassoPassoApiKey';
                    $('#imgPasso2').attr('src', "".concat($rootScope.bucket, "/ramper-cinza-15.png"));
                    break;
                case 2:
                    setInterval(function () {
                        $(".divBordaPiscante").animate({ opacity: 'toggle' });
                    }, 1000);
                    document.getElementById('dvTraco1').className = 'dvTracoVerdePassoPasso tracoCadencia';
                    document.getElementById('spanInicio').className = 'txtVerdePassoPasso txtPassoPassoInicio';
                    $('#imgPasso1').attr('src', "".concat($rootScope.bucket, "/ramper-v-claro-15.png"));
                    document.getElementById('dvTraco2').className = 'dvTracoAzulPassoPasso tracoCadencia';
                    document.getElementById('spanCriacao').className = 'txtAzulPassoPasso txtPassoPassoApiKey';
                    $('#imgPasso2').attr('src', "".concat($rootScope.bucket, "/ramper-v-escuro-15.png"));
                    document.getElementById('dvTraco3').className = 'dvTracoCinzaPassoPasso tracoCadencia';
                    document.getElementById('spanResumo').className = 'txtCinzaPassoPasso txtPassoPassoFinal';
                    $('#imgPasso3').attr('src', "".concat($rootScope.bucket, "/ramper-cinza-15.png"));
                    break;
                case 3:
                    document.getElementById('dvTraco2').className = 'dvTracoVerdePassoPasso tracoCadencia';
                    document.getElementById('spanCriacao').className = 'txtVerdePassoPasso txtPassoPassoApiKey';
                    $('#imgPasso2').attr('src', "".concat($rootScope.bucket, "/ramper-v-claro-15.png"));
                    document.getElementById('dvTraco3').className = 'dvTracoAzulPassoPasso tracoCadencia';
                    document.getElementById('spanResumo').className = 'txtAzulPassoPasso txtPassoPassoFinal';
                    $('#imgPasso3').attr('src', "".concat($rootScope.bucket, "/ramper-v-escuro-15.png"));
                    document.getElementById('dvTraco4').className = 'dvTracoCinzaPassoPasso tracoCadencia';
                    document.getElementById('spanGeracao').className = 'txtCinzaPassoPasso txtPassoPassoFinal';
                    $('#imgPasso4').attr('src', "".concat($rootScope.bucket, "/ramper-cinza-15.png"));
                    break;
                case 4:
                    document.getElementById('dvTraco3').className = 'dvTracoVerdePassoPasso tracoCadencia';
                    document.getElementById('spanResumo').className = 'txtVerdePassoPasso txtPassoPassoFinal';
                    $('#imgPasso3').attr('src', "".concat($rootScope.bucket, "/ramper-v-claro-15.png"));
                    document.getElementById('dvTraco4').className = 'dvTracoAzulPassoPasso tracoCadencia';
                    document.getElementById('spanGeracao').className = 'txtAzulPassoPasso txtPassoPassoFinal';
                    $('#imgPasso4').attr('src', "".concat($rootScope.bucket, "/ramper-v-escuro-15.png"));
                    document.getElementById('dvTraco5').className = 'dvTracoCinzaPassoPasso tracoCadencia';
                    document.getElementById('spanFinalizacao').className = 'txtCinzaPassoPasso txtPassoPassoFinal';
                    $('#imgPasso5').attr('src', "".concat($rootScope.bucket, "/ramper-cinza-15.png"));
                    break;
                case 5:
                    if ($scope.novaCadencia.tipoCadencia !== 'Inbound') {
                        salvarNovaCadencia();
                        if ($scope.novaCadencia.tipoCadencia == 'Outbound') {
                            mixpanel.track("Cadência | Criou uma cadência (Outbound)", { "Local_IP": $rootScope.ip });
                        }
                        if ($scope.novaCadencia.tipoCadencia == 'Reengajamento') {
                            mixpanel.track("Cadência | Criou uma cadência (Reengajamento)", { "Local_IP": $rootScope.ip });
                        }
                    }
                    document.getElementById('dvTraco3').className = 'dvTracoVerdePassoPasso tracoCadencia';
                    document.getElementById('spanResumo').className = 'txtVerdePassoPasso txtPassoPassoFinal';
                    $('#imgPasso3').attr('src', "".concat($rootScope.bucket, "/ramper-v-claro-15.png"));
                    document.getElementById('dvTraco4').className = 'dvTracoVerdePassoPasso tracoCadencia';
                    document.getElementById('spanGeracao').className = 'txtVerdePassoPasso txtPassoPassoFinal';
                    $('#imgPasso4').attr('src', "".concat($rootScope.bucket, "/ramper-v-claro-15.png"));
                    document.getElementById('dvTraco5').className = 'dvTracoAzulPassoPasso tracoCadencia';
                    document.getElementById('spanFinalizacao').className = 'txtAzulPassoPasso txtPassoPassoFinal';
                    $('#imgPasso5').attr('src', "".concat($rootScope.bucket, "/ramper-v-escuro-15.png"));
                    break;
                default: return;
            }
            $('#dvEtapa' + etapa.toString()).fadeIn();
        };
        $scope.fecharFormNovaCadencia = function () {
            window.location.reload();
        };
        $scope.abrirPopupAutomacaoBounce = function () {
            if ($scope.novaCadencia.automacoes.length > 0) {
                $scope.novaCadencia.automacoes.forEach(function (aut) {
                    if (aut.situacao == 'B') {
                        $scope.templateSelecionadoAutBounce = aut.parametros.objectIdTemplate;
                        $http({
                            method: 'GET',
                            url: "".concat($rootScope.ramperV3ApiBaseUrl, "/pastas/consultarPastaTemplateEspecifico"),
                            headers: {
                                'ramp3r-auth': 'ramp3r-authorization',
                                'ramp3r-l': $rootScope.IDCrypto,
                                'Authorization': 'Bearer ' + $rootScope.Token
                            },
                            params: {
                                objectIdTemplate: aut.parametros.objectIdTemplate
                            }
                        }).then(function (rConsulta) {
                            $scope.pastaSelecionadaBounce = rConsulta.data._id;
                            $scope.selecionarTemplateAut = true;
                            $scope.selecionarPasta('Bounce', 'edicao');
                        });
                    }
                });
            }
            $('#idFundoPopupCadencica').fadeIn();
            $('.mce-tinymce').css('border', '1px solid rgba(0,0,0,0.2)');
            $scope.popupAutomacaoBounce = true;
            $scope.acaoEnvioBounce = 'E';
            $scope.divSegundaTelaPopupSeq = false;
            $scope.divSegundaTelaPopupSeqB = false;
            tinymce.editors["txtAreaBounce"].on('focus', function (e) {
                _inptAssuntoAutomacao = false;
            });
            if ($scope.emailPara == 'E-mail de alta confiabilidade da empresa')
                $scope.acaoEnvioBounce = 'B';
        };
        $scope.fecharPopupBounce = function () {
            $('#idFundoPopupCadencica').fadeOut();
            $scope.popupAutomacaoBounce = false;
        };
        $scope.abrirPopupExcluirBounce = function () {
            $('#idFundoPopupCadencica').fadeIn();
            $('#idPopupExcluirBounce').show();
            $scope.popupExcluirBounce = true;
        };
        $scope.fecharPopupExcluirBounce = function () {
            $('#idFundoPopupCadencica').fadeOut();
            $scope.popupExcluirBounce = false;
        };
        $scope.abrirPopupAutNaoResp = function (desmarcaSegmentacao) {
            if (!$scope.automacaoDiasNaoResp)
                $scope.automacaoDiasNaoResp = 30;
            if (!$scope.listaIntegracoes.length)
                $scope.consultarIntegracoes();
            if (desmarcaSegmentacao) {
                $('#segmentacaoFiltroNaoRespondido').val('');
            }
            $('#idFundoPopupCadencica').fadeIn();
            $scope.popupAutNaoResp = true;
            $scope.botaoSalvarAutomacaoNaoRespondidos = false;
            $('#popupAutomacaoNaoRespondidos').fadeIn();
        };
        $scope.fecharPopupAutNaoResp = function () {
            $('#idFundoPopupCadencica').fadeOut();
            $scope.popupAutNaoResp = false;
            $scope.integracaoNaoRespondidos = null;
            $scope.segmentacaoFiltroNaoRespondido = null;
        };
        $scope.abrirPopupAutResp = function () {
            if (!$scope.listaIntegracoes.length)
                $scope.consultarIntegracoes();
            $('#idFundoPopupCadencica').fadeIn();
            $rootScope.popupAutResp = true;
        };
        $scope.fecharPopupAutResp = function () {
            $('#idFundoPopupCadencica').fadeOut();
            $rootScope.popupAutResp = false;
        };
        $scope.addNovoTemplate = function () {
            $('#emailSelecionado_A')[0].selectedIndex = 1;
            $('#emailSelecionado_B')[0].selectedIndex = 1;
            $scope.novaSequencia();
            $('#idFundoPopupCadencica').fadeIn();
            $scope.divSegundaTelaPopupSeq = false;
            $scope.popupTesteAB = true;
        };
        $scope.fecharPopupTesteAB = function () {
            limparCampos();
            $('#idFundoPopupCadencica').fadeOut();
            $scope.popupRespostas = false;
            $scope.popupTesteAB = false;
        };
        $scope.imgNaoRespondido = function () {
            $rootScope.imgNaoRespondidoCad = true;
        };
        $scope.divEnviarBounce = function () {
            $rootScope.enviarBounce = true;
            $('.linhaBaixoMeio').css('display', 'none');
            $('.divGeralBotaoAdicionarCadCentro').css('display', 'none');
            $('.dvBolinhaImgCardMeioEmpty').css('display', 'none');
        };
        $scope.salvarSequenciaCadencia = function (ab) {
            $scope.msgChat.chatChecked = false;
            $scope.msgChat.texto = "";
            $scope.msgAgenda.agendaChecked = false;
            $scope.msgAgenda.texto = "";
            var qtdErrosSegundtaTela = 0;
            if ($('#templateSelecionado_' + ab)[0].value == '') {
                $('#dvMensagemSelecionarTemplateA').fadeIn();
                $('#dvMensagemSelecionarTemplateB').fadeIn();
                $('#templateSelecionado_A').addClass('corTextoErro');
                $('#templateSelecionado_B').addClass('corTextoErro');
                qtdErrosSegundtaTela++;
            }
            else {
                if ($scope['inptData_' + ab] == null || $scope['inptData_' + ab] == '') {
                    $('#inptData_A').addClass('bordaDiasSemanaErro');
                    $('#divInptData_A').addClass('bordaDiasSemanaErro');
                    $('#inptData_B').addClass('bordaDiasSemanaErro');
                    $('#divInptData_B').addClass('bordaDiasSemanaErro');
                    $('#dvMensagemAssunto').fadeIn();
                    $('#dvMensagemAssuntoB').fadeIn();
                    qtdErrosSegundtaTela++;
                }
                else if (document.getElementById('inptData_B').value == $scope.assuntoComparativo) {
                    $('#mensagemAssuntoB').innerText = 'Você deve editar o assunto do e-mail a ser enviado.';
                    $('#inptData_B').addClass('bordaDiasSemanaErro');
                    $('#divInptData_B').addClass('bordaDiasSemanaErro');
                    $('#dvMensagemAssuntoB').fadeIn();
                    qtdErrosSegundtaTela++;
                }
                else {
                    $('#inptData_A').removeClass('bordaDiasSemanaErro');
                    $('#divInptData_A').removeClass('bordaDiasSemanaErro');
                    $('#inptData_B').removeClass('bordaDiasSemanaErro');
                    $('#divInptData_B').removeClass('bordaDiasSemanaErro');
                }
                if (tinymce.editors["txtAreaTemplate_" + ab].getContent() == null || tinymce.editors["txtAreaTemplate_" + ab].getContent() == '<div>&nbsp;</div>') {
                    $('#dvMensagemEmail').fadeIn();
                    $('#dvMensagemEmailB').fadeIn();
                    $('.mce-tinymce').css('border', '1px solid #E04747');
                    qtdErrosSegundtaTela++;
                }
                else {
                    $('#dvMensagemEmail').fadeOut();
                    $('#dvMensagemEmailB').fadeOut();
                    $('.mce-tinymce').css('border', '1px solid rgba(0,0,0,0.2)');
                }
            }
            if ($('#templateSelecionado_' + ab)[0].selectedOptions[0].value) {
                var objTemp = JSON.parse($('#templateSelecionado_' + ab)[0].selectedOptions[0].value);
                if (($scope['inptData_' + ab] != objTemp.assunto && $scope['enviarComoSelecionado_' + ab] != 'E') ||
                    (tinymce.editors["txtAreaTemplate_" + ab].getContent() != $scope.conteudoTemplate)) {
                    $('#dvMensagemSalvarTemplateA').fadeIn();
                    $('#dvMensagemSalvarTemplateB').fadeIn();
                    $('.bttnNovoTemplateAB').addClass('corTextoErro');
                    qtdErrosSegundtaTela++;
                }
            }
            if (qtdErrosSegundtaTela == 0) {
                if (ab == 'B')
                    $scope.exibeVariacaoTesteAB = false;
                atualizarBuffer(ab);
                $scope.fecharPopupTesteAB();
                $scope.qtdSequenciaCadencia++;
                $rootScope.seqCriacaoCad = true;
                $('.mce-tinymce').css('border', '1px solid rgba(0,0,0,0.2)');
                $('.divGeralBotaoAdicionarCad').css('top', '53px');
            }
            $scope.getInfoAgenda();
            $scope.getInfoChat();
        };
        $scope.assuntoEmBranco_A = function () {
            _inptAssunto = true;
            if ($('#inptData_A') != null || $('#inptData_A') != '') {
                $('#inptData_A').removeClass('bordaDiasSemanaErro');
                $('#divInptData_A').removeClass('bordaDiasSemanaErro');
                $('#dvMensagemAssunto').fadeOut();
            }
        };
        $scope.assuntoEmBranco_B = function () {
            _inptAssunto = true;
            if ($('#inptData_B') != null || $('#inptData_B') != '') {
                $('#inptData_B').removeClass('bordaDiasSemanaErro');
                $('#divInptData_B').removeClass('bordaDiasSemanaErro');
                $('#dvMensagemAssuntoB').fadeOut();
            }
        };
        $scope.divExcluirBounce = function () {
            $rootScope.excluirBounce = true;
            $('.linhaBaixoMeio').css('display', 'none');
            $('.divGeralBotaoAdicionarCadCentro').css('display', 'none');
            $('.dvBolinhaImgCardMeioEmpty').css('display', 'none');
        };
        $scope.fecharExcluirAutBounce = function () {
            $scope.novaCadencia.automacoes = $scope.novaCadencia.automacoes.filter(function (item) {
                return item.situacao !== 'B';
            });
            $rootScope.excluirBounce = false;
            $('.linhaBaixoMeio').css('display', '');
            $('.divGeralBotaoAdicionarCadCentro').css('display', '');
            $('.dvBolinhaImgCardMeioEmpty').css('display', '');
        };
        $scope.fecharEnviarAutBounce = function () {
            $scope.novaCadencia.automacoes = $scope.novaCadencia.automacoes.filter(function (item) {
                return item.situacao !== 'B';
            });
            $scope.emailPara = '';
            $scope.assuntoEnvioBounce = '';
            $scope.pastaSelecionadaBounce = '';
            $scope.templatesPastaEspecifica = $scope.templates;
            $rootScope.enviarBounce = false;
            $('#templateSelecionadoAutomacaoBounce')[0].value = '';
            tinymce.editors["txtAreaBounce"].setContent('');
            $('.linhaBaixoMeio').css('display', '');
            $('.divGeralBotaoAdicionarCadCentro').css('display', '');
            $('.dvBolinhaImgCardMeioEmpty').css('display', '');
        };
        $scope.salvarAutomacaoBounce = function () {
            var qtdErros = 0;
            if ($('#templateSelecionadoAutomacaoBounce')[0].value == '') {
                $('#dvMensagemSelecionarTemplateBounce').fadeIn();
                $('#templateSelecionadoAutomacaoBounce').addClass('corTextoErro');
            }
            else {
                if (tinymce.editors["txtAreaBounce"].getContent() == null || tinymce.editors["txtAreaBounce"].getContent() == '<div>&nbsp;</div>') {
                    $('#dvMensagemEmailBounce').fadeIn();
                    $('.mce-tinymce').css('border', '1px solid #E04747');
                    qtdErros++;
                }
                else {
                    $('#dvMensagemEmailBounce').fadeOut();
                    $('.mce-tinymce').css('border', '1px solid rgba(0,0,0,0.2)');
                }
                if ($scope['assuntoEnvioBounce'] == null || $scope['assuntoEnvioBounce'] == '') {
                    $('#divTxtAssuntoBounce').addClass('corTextoErro');
                    $('#inptAssuntoBounce').addClass('divErroAssunto');
                    $('#dvMensagemAssuntoBounce').fadeIn();
                    qtdErros++;
                }
                var objTemp = JSON.parse($('#templateSelecionadoAutomacaoBounce')[0].selectedOptions[0].value);
                if (($scope['assuntoEnvioBounce'] != objTemp.assunto && $scope['templateSelecionadoAutomacaoBounce'] != 'E') ||
                    (tinymce.editors["txtAreaBounce"].getContent() != objTemp.template)) {
                    $('#dvMensagemSalvarTemplateBounce').fadeIn();
                    $('#spanNovoTemplateAutBounce').addClass('corTextoErro');
                    qtdErros++;
                }
                if (qtdErros === 0) {
                    $scope.novaCadencia.automacoes = $scope.novaCadencia.automacoes.filter(function (item) {
                        return item.situacao !== 'B';
                    });
                    var templateSel = JSON.parse($('#templateSelecionadoAutomacaoBounce')[0].selectedOptions[0].value);
                    $scope.novaCadencia.automacoes.push({
                        situacao: 'B',
                        parametros: {
                            acao: $('#acaoEnvioBounce')[0].selectedOptions[0].value,
                            assunto: $scope.assuntoEnvioBounce,
                            objectIdTemplate: templateSel.objectIdTemplate,
                            texto: tinymce.editors["txtAreaBounce"].getContent({ format: 'text' }),
                            textoHTML: tinymce.editors["txtAreaBounce"].getContent()
                        }
                    });
                    $scope.emailPara = $('#acaoEnvioBounce')[0].selectedOptions[0].text;
                    $('#dvMensagemSelecionarTemplateBounce').fadeOut();
                    $('#templateSelecionadoAutomacaoBounce').removeClass('corTextoErro');
                    $scope.fecharPopupBounce();
                    $rootScope.enviarBounce = true;
                    $('.linhaBaixoMeio').css('display', 'none');
                    $('.divGeralBotaoAdicionarCadCentro').css('display', 'none');
                    $('.dvBolinhaImgCardMeioEmpty').css('display', 'none');
                }
            }
        };
        $scope.inptMudarCorAssuntoBounce = function () {
            _inptAssuntoAutomacao = true;
            if ($scope['assuntoEnvioBounce'] != null || $scope['assuntoEnvioBounce'] != '') {
                $('#divTxtAssuntoBounce').removeClass('corTextoErro');
                $('#inptAssuntoBounce').removeClass('divErroAssunto');
                $('#dvMensagemAssuntoBounce').fadeOut();
            }
        };
        $scope.salvarExclusaoBounce = function () {
            $scope.novaCadencia.automacoes = $scope.novaCadencia.automacoes.filter(function (item) {
                return item.situacao !== 'B';
            });
            $scope.novaCadencia.automacoes.push({
                situacao: 'B',
                parametros: {
                    acao: 'D'
                }
            });
            $scope.fecharPopupExcluirBounce();
            $rootScope.excluirBounce = true;
            $('.linhaBaixoMeio').css('display', 'none');
            $('.divGeralBotaoAdicionarCadCentro').css('display', 'none');
            $('.dvBolinhaImgCardMeioEmpty').css('display', 'none');
        };
        $scope.abrirPopupTesteABSegundo = function (sequencia) {
            document.getElementById("templateSelecionado_B").disabled = true;
            tinymce.editors["txtAreaTemplate_B"].setMode('readonly');
            $scope.novoTesteAB(sequencia);
            $('#idFundoPopupCadencica').fadeIn();
            $scope.popupRespostas = true;
            $scope.divSegundaTelaPopupSeqB = true;
            $scope.divSegundaTelaPopupSeq = false;
            $('#btnVoltarTesteAB').hide();
        };
        $scope.fExibeTesteAB = function (sequencia) {
            return sequencia == 1 && $scope.exibeVariacaoTesteAB;
        };
        $scope.abrirDivAutRespondidos = function () {
            $scope.novaCadencia.automacoes = $scope.novaCadencia.automacoes.filter(function (item) {
                return item.situacao !== 'R';
            });
            var intgracaoSelecionada = $('#integracoesRespondidos')[0].selectedOptions[0].value;
            if (intgracaoSelecionada != '') {
                intgracaoSelecionada = JSON.parse(intgracaoSelecionada);
                $scope.divAutRespIntegracao = true;
            }
            else
                $scope.divAutRespIntegracao = false;
            var disparoSelecionado = $('#cadenciaRespondidos')[0].selectedOptions[0].value;
            if (disparoSelecionado != '') {
                disparoSelecionado = JSON.parse(disparoSelecionado);
                $scope.divAutRespDisparo = true;
            }
            else
                $scope.divAutRespDisparo = false;
            $scope.novaCadencia.automacoes.push({
                situacao: 'R',
                parametros: {
                    objectIdIntegracoes: intgracaoSelecionada._id,
                    objectIdDisparos: disparoSelecionado._id
                }
            });
            $scope.automacaoIntgracaoRespondido = intgracaoSelecionada.application;
            $scope.automacaoDisparoRespondido = disparoSelecionado.disparosNome;
            if ($('#checkCalendar').is(':checked'))
                $scope.alertaCalendar = 'ativado';
            else
                $scope.alertaCalendar = 'desativado';
            $scope.fecharPopupAutResp();
            $("#imgAutResp").attr('src', "".concat($rootScope.bucket, "/auto_adicionada.png"));
            if ($rootScope.divAutNaoRespondidos == false) {
                $('.linhaBaixoDireita').css('top', '198px');
                $('.divGeralBotaoAdicionarCadDireita').css('top', '32px');
                $('#imgRespondido').css('display', 'none');
                $('.lblNaoRespondido').css('left', '75px');
                $('.lblNaoRespondido').css('top', '30px');
                $rootScope.divAutRespondidos = true;
            }
            else {
                $('.cardNaoRespondido').css('top', '40px');
                $('.linhaBaixoDireita').css('top', '198px');
                $rootScope.addAutRespostas = false;
                $rootScope.divAutRespondidos = true;
            }
        };
        $scope.fecharDivAutRespondidos = function () {
            $scope.novaCadencia.automacoes = $scope.novaCadencia.automacoes.filter(function (item) {
                return item.situacao !== 'R';
            });
            $scope.automacaoDisparoRespondido = '';
            $scope.automacaoIntgracaoRespondido = '';
            if ($rootScope.divAutNaoRespondidos == false) {
                $('.linhaBaixoDireita').css('top', '67px');
                $('.divGeralBotaoAdicionarCadDireita').css('top', '55px');
                $('#imgRespondido').css('display', '');
                $('.lblNaoRespondido').css('left', '90px');
                $('.lblNaoRespondido').css('top', '55px');
                $('.lblRespondido').css('left', '44px');
                $('.lblRespondido').css('top', '55px');
                $("#imgAutResp").attr('src', "".concat($rootScope.bucket, "/auto_nao_adicionada.png"));
                $rootScope.addAutRespostas = true;
                $rootScope.divAutRespondidos = false;
            }
            else {
                $('.linhaBaixoDireita').css('top', '178px');
                $('.divGeralBotaoAdicionarCadDireita').css('top', '32px');
                $('#imgRespondido').css('display', '');
                $('#imgNaoRespondido').css('display', 'none');
                $('.cardNaoRespondido').css('top', '-21px');
                $rootScope.addAutRespostas = true;
                $rootScope.divAutRespondidos = false;
            }
        };
        $scope.abrirDivAutNaoRespondidos = function () {
            $scope.novaCadencia.automacoes = $scope.novaCadencia.automacoes.filter(function (item) {
                return item.situacao !== 'N';
            });
            var intgracaoSelecionada = $('#integracaoNaoRespondidos')[0].selectedOptions[0].value;
            if (intgracaoSelecionada != '') {
                intgracaoSelecionada = JSON.parse(intgracaoSelecionada);
                $scope.divAutNaoRespIntegracao = true;
            }
            else
                $scope.divAutNaoRespIntegracao = false;
            var segmentacaoSelecionada = $('#segmentacaoFiltroNaoRespondido')[0].selectedOptions[0].value;
            if (segmentacaoSelecionada != '') {
                segmentacaoSelecionada = JSON.parse(segmentacaoSelecionada);
                $scope.divAutNaoRespSegmentacao = true;
            }
            else
                $scope.divAutNaoRespSegmentacao = false;
            $scope.novaCadencia.automacoes.push({
                situacao: 'N',
                parametros: {
                    objectIdIntegracoes: intgracaoSelecionada._id,
                    objectIdSegmentacao: segmentacaoSelecionada._id ? segmentacaoSelecionada._id.objectIdSegmentacao : segmentacaoSelecionada._id,
                    diasAguardar: $scope.automacaoDiasNaoResp
                }
            });
            $scope.automacaoIntgracaoNaoRespondido = intgracaoSelecionada.nomeAplicao;
            $scope.automacaoSegmentacaoNaoRespondido = segmentacaoSelecionada._id ? segmentacaoSelecionada._id.nomeSegmentacao : segmentacaoSelecionada._id;
            $("#imgAutResp").attr('src', "".concat($rootScope.bucket, "/auto_adicionada.png"));
            $('#idFundoPopupCadencica').fadeOut();
            $scope.popupAutNaoResp = false;
            $rootScope.divAutNaoRespondidos = true;
            $rootScope.addAutRespostas = false;
            $('.linhaBaixoDireita').hide();
        };
        $scope.diasCadenciaNaoRespondidos = function () {
            if ((($scope.integracaoNaoRespondidos != null) || ($scope.segmentacaoFiltroNaoRespondido != null))) {
                $scope.botaoSalvarAutomacaoNaoRespondidos = true;
            }
        };
        $scope.validaBotaoSelectNaoRespondidos = function () {
            if (!$scope.integracaoNaoRespondidos && !$scope.segmentacaoFiltroNaoRespondido) {
                $scope.botaoSalvarAutomacaoNaoRespondidos = false;
                return;
            }
            $scope.botaoSalvarAutomacaoNaoRespondidos = true;
        };
        $scope.fecharDivAutNaoRespondidos = function () {
            $scope.novaCadencia.automacoes = $scope.novaCadencia.automacoes.filter(function (item) {
                return item.situacao !== 'N';
            });
            $scope.automacaoSegmentacaoNaoRespondido = '';
            $scope.automacaoIntgracaoNaoRespondido = '';
            $("#imgAutResp").attr('src', "".concat($rootScope.bucket, "/auto_nao_adicionada.png"));
            $('.linhaBaixoDireita').show();
            $rootScope.divAutNaoRespondidos = false;
            $rootScope.addAutRespostas = true;
            $scope.integracaoNaoRespondidos = '';
            $scope.segmentacaoFiltroNaoRespondido = '';
        };
        $scope.VoltarCriarCadencia = function () {
            if ($scope.novaCadencia.tipoCadencia === 'Reengajamento') {
                $scope.zerarSelectsCadenciaReengajamento();
                $scope.reengajamento = { checkDiasRetroativos: false, diasRetroativos: 1 };
            }
            limparVariaveis();
            $scope.listaSegmentacoes = $scope.listaSegmentacoesPadrao;
            $('#formNovaCadencia').fadeOut();
            $('#dvEtapa1').fadeOut();
            $('#dvEtapa2').fadeOut();
            $('#dvEtapa3').fadeOut();
            $('#dvEtapa4').fadeOut();
            $('#dvEtapa5').fadeOut();
            $('#dvEtapa6').fadeOut();
            $('#checkboxAltaInput')[0].checked = true;
            $('#checkboxMediaInput')[0].checked = true;
            $('#checkboxBaixaInput')[0].checked = false;
            $('#checkboxSemInput')[0].checked = true;
            $scope.integracaoNaoRespondidos = '';
            $scope.segmentacaoFiltroNaoRespondido = '';
            $scope.inicioDisparosCadP = 1;
            $("#imgAutResp").attr('src', "".concat($rootScope.bucket, "/auto_nao_adicionada.png"));
            $('.linhaBaixoDireita').show();
            $rootScope.divAutNaoRespondidos = false;
            $rootScope.addAutRespostas = true;
            document.getElementById('dvTraco1').className = 'dvTracoAzulPassoPasso tracoCadencia';
            document.getElementById('spanInicio').className = 'txtAzulPassoPasso txtPassoPassoInicio';
            $('#imgPasso1').attr('src', "".concat($rootScope.bucket, "/ramper-v-escuro-15.png"));
            document.getElementById('dvTraco2').className = 'dvTracoCinzaPassoPasso tracoCadencia';
            document.getElementById('spanCriacao').className = 'txtCinzaPassoPasso txtPassoPassoApiKey';
            $('#imgPasso2').attr('src', "".concat($rootScope.bucket, "/ramper-cinza-15.png"));
            document.getElementById('dvTraco3').className = 'dvTracoCinzaPassoPasso tracoCadencia';
            document.getElementById('spanResumo').className = 'txtCinzaPassoPasso txtPassoPassoFinal';
            $('#imgPasso3').attr('src', "".concat($rootScope.bucket, "/ramper-cinza-15.png"));
            document.getElementById('dvTraco4').className = 'dvTracoCinzaPassoPasso tracoCadencia';
            document.getElementById('spanGeracao').className = 'txtCinzaPassoPasso txtPassoPassoFinal';
            $('#imgPasso4').attr('src', "".concat($rootScope.bucket, "/ramper-cinza-15.png"));
            document.getElementById('dvTraco5').className = 'dvTracoCinzaPassoPasso tracoCadencia';
            document.getElementById('spanFinalizacao').className = 'txtCinzaPassoPasso txtPassoPassoFinal';
            $('#imgPasso5').attr('src', "".concat($rootScope.bucket, "/ramper-cinza-15.png"));
            $scope.escolherTipoCadencia = false;
            $scope.novaCadencia = {
                nome: '',
                tipoCadencia: 'Outbound',
                alteracao: false,
                segmentacao: {
                    _id: null,
                    alta: false,
                    media: false,
                    baixa: false,
                    sem: false
                },
                disparoSequencias: {
                    sequencias: []
                },
                automacoes: []
            };
            if ($scope.ultimaNovaCadEscolhida === 'Engage') {
                $scope.acessoEngage.cadenciaEngageAtiva = true;
            }
            $scope.criarNovaCadencia();
        };
        var _arraySequenciasContatos = [];
        var _sequenciaContatoPopup = 0;
        var _dadosSequenciaContato = null;
        $scope.abrirPopupDetalhesContatos = function (pItemContato, pSequencia, pCor) {
            _arraySequenciasContatos = pItemContato.dados;
            _sequenciaContatoPopup = pSequencia;
            $scope.corIndicadorSeqPopup = pCor;
            $scope.mostrarDadosPopupSequenciaContato();
            $('#idFundoPopupCadencica').fadeIn();
            $scope.popupDetalhesContatos = true;
            $('#sequenciaCadenciaGeral').fadeIn();
        };
        $scope.contadorCarrosselContatos = function (contadorDados) {
            if ((contadorDados.dados.length - contadorDados.qtSequenciasDetalhe) > 7) {
                contadorDados.qtSequenciasDetalhe += 7;
                $('#divSequenciasDetalhes').hide();
                $('#divSequenciasDetalhes').fadeIn();
            }
            var valor = contadorDados.dados.length - contadorDados.qtdSequenciasVistas;
            if (valor < 7)
                $('#setaDireitaContato' + contadorDados._id.objectIdContato).hide();
        };
        $scope.voltarCarrosselContatos = function (contadorDados) {
            $('#divSequenciasDetalhes').hide();
            contadorDados.qtSequenciasDetalhe -= 7;
            $('#divSequenciasDetalhes').fadeIn();
            $('#setaEsquerdaContato' + contadorDados._id.objectIdContato).show();
        };
        $scope.avancarPopupSequenciaContato = function (pAdicionaOuDiminui) {
            if (pAdicionaOuDiminui == 'me')
                _sequenciaContatoPopup--;
            else if (pAdicionaOuDiminui == 'ma')
                _sequenciaContatoPopup++;
            $scope.mostrarDadosPopupSequenciaContato();
        };
        $scope.editarDisparoFuturo = function () {
            $rootScope.editarDataDisparo = false;
            $rootScope.editarTemplateDisparo = false;
            $rootScope.btnEditarDataTemplate = false;
        };
        $scope.filtrarRespContato = function (pDados) {
            if (pDados == 'sim') {
                _filtroResposta = 'P';
                if ($('#bolinhaSimFiltroContato').hasClass('corImgClasResp'))
                    $('#bolinhaSimFiltroContato').removeClass('corImgClasResp').addClass('corImgOriginalResp');
                else
                    $('#bolinhaSimFiltroContato').removeClass('corImgOriginalResp').addClass('corImgClasResp');
                $('#bolinhaNeutraFiltroContato').removeClass('corImgOriginalResp').addClass('corImgClasResp');
                $('#bolinhaNaoFiltroContato').removeClass('corImgOriginalResp').addClass('corImgClasResp');
            }
            else if (pDados == 'neutra') {
                _filtroResposta = 'S';
                if ($('#bolinhaNeutraFiltroContato').hasClass('corImgClasResp'))
                    $('#bolinhaNeutraFiltroContato').removeClass('corImgClasResp').addClass('corImgOriginalResp');
                else
                    $('#bolinhaNeutraFiltroContato').removeClass('corImgOriginalResp').addClass('corImgClasResp');
                $('#bolinhaSimFiltroContato').removeClass('corImgOriginalResp').addClass('corImgClasResp');
                $('#bolinhaNaoFiltroContato').removeClass('corImgOriginalResp').addClass('corImgClasResp');
            }
            else if (pDados == 'nao') {
                _filtroResposta = 'N';
                if ($('#bolinhaNaoFiltroContato').hasClass('corImgClasResp'))
                    $('#bolinhaNaoFiltroContato').removeClass('corImgClasResp').addClass('corImgOriginalResp');
                else
                    $('#bolinhaNaoFiltroContato').removeClass('corImgOriginalResp').addClass('corImgClasResp');
                $('#bolinhaNeutraFiltroContato').removeClass('corImgOriginalResp').addClass('corImgClasResp');
                $('#bolinhaSimFiltroContato').removeClass('corImgOriginalResp').addClass('corImgClasResp');
            }
        };
        $scope.mostrarDadosPopupSequenciaContato = function () {
            _dadosSequenciaContato = _arraySequenciasContatos[_sequenciaContatoPopup - 1];
            document.getElementById("campoRespostaCadencia").innerHTML = _dadosSequenciaContato.respostaHTML;
            $scope.sequenciaSelecionadaPopupContato = _sequenciaContatoPopup;
            $scope.totalSequenciaSelecionadaPopupContato = _dadosSequenciaContato.totalSequencia;
            $scope.totalRegistrosPopupContatos = _arraySequenciasContatos.length;
            $scope.dataAberturaDetalhes = _dadosSequenciaContato.dataAbertura;
            $scope.dataEnvioDetalhes = _dadosSequenciaContato.dataEnvio;
            $scope.qtdAberturasDetalhes = _dadosSequenciaContato.qtdAberturas;
            $scope.dataRespostaDetalhes = _dadosSequenciaContato.dataResposta;
            $scope.statusEnvioSelecionada = _dadosSequenciaContato.statusEnvio;
            $scope.nomeCadenciaSeqSel = _dadosSequenciaContato.nomeCadencia;
            $scope.nomeTemplateSeqSel = _dadosSequenciaContato.nomeTemplate;
            $scope.assuntoRespostaSeqSel = _dadosSequenciaContato.assunto; //mudar para assunto da resposta
            $scope.mensagemRespostaSeqSel = _dadosSequenciaContato.respostaTexto;
            $scope.assuntoSeqSel = _dadosSequenciaContato.assunto;
            $scope.mensagemSeqSel = _dadosSequenciaContato.mensagem;
            $scope.deviceSeqSel = _dadosSequenciaContato.device;
            $scope.corIndicadorSeqPopup = _dadosSequenciaContato.cor;
            if (_dadosSequenciaContato.statusResposta == 'N')
                $scope.ativarClassificacaoNao();
            else if (_dadosSequenciaContato.statusResposta == 'P')
                $scope.ativarClassificacaoSim();
            else
                $scope.ativarClassificacaoNeutra();
            $rootScope.editarDataDisparo = false;
            $rootScope.editarTemplateDisparo = false;
            $rootScope.btnEditarDataTemplate = false;
            if ((_dadosSequenciaContato.device == 'PC') && (_dadosSequenciaContato.so == 'Windows')) {
                $('#imgDevice').attr('src', "".concat($rootScope.bucket, "/pc-windows.png"));
                $('#imgDevice').removeClass('imgDispositivoPhoneResposta').addClass('imgDispositivoPcResposta');
            }
            else if ((_dadosSequenciaContato.device == 'PC') && ((_dadosSequenciaContato.so == 'MacOS') || (_dadosSequenciaContato.so == 'Unknown OS'))) {
                $('#imgDevice').attr('src', "".concat($rootScope.bucket, "/pc-apple.png"));
                $('#imgDevice').removeClass('imgDispositivoPhoneResposta').addClass('imgDispositivoPcResposta');
            }
            else if ((_dadosSequenciaContato.device == 'iOS') && (_dadosSequenciaContato.so == 'MacOS')) {
                $('#imgDevice').attr('src', "".concat($rootScope.bucket, "/phone-iphone.png"));
                $('#imgDevice').removeClass('imgDispositivoPcResposta').addClass('imgDispositivoPhoneResposta');
            }
            else if (_dadosSequenciaContato.device == 'Android') {
                $('#imgDevice').attr('src', "".concat($rootScope.bucket, "/phone-android.png"));
                $('#imgDevice').removeClass('imgDispositivoPcResposta').addClass('imgDispositivoPhoneResposta');
            }
            else {
                $('#imgDevice').attr('src', "".concat($rootScope.bucket, "/device-empty.png"));
                $('#imgDevice').removeClass('imgDispositivoPhoneResposta').addClass('imgDispositivoPcResposta');
            }
        };
        $scope.fecharPopupDetalhesContatos = function () {
            $('#idFundoPopupCadencica').fadeOut();
            $scope.popupDetalhesContatos = false;
            $('.sequenciaCadenciaGeral').fadeOut();
        };
        $scope.ativarClassificacaoSim = function () {
            $('#bolinhaSim').removeClass('corImgClasResp').addClass('corImgOriginalResp');
            $('#bolinhaNeutra').removeClass('corImgOriginalResp').addClass('corImgClasResp');
            $('#bolinhaNao').removeClass('corImgOriginalResp').addClass('corImgClasResp');
        };
        $scope.ativarClassificacaoNeutra = function () {
            $('#bolinhaNeutra').removeClass('corImgClasResp').addClass('corImgOriginalResp');
            $('#bolinhaSim').removeClass('corImgOriginalResp').addClass('corImgClasResp');
            $('#bolinhaNao').removeClass('corImgOriginalResp').addClass('corImgClasResp');
        };
        $scope.ativarClassificacaoNao = function () {
            $('#bolinhaNao').removeClass('corImgClasResp').addClass('corImgOriginalResp');
            $('#bolinhaNeutra').removeClass('corImgOriginalResp').addClass('corImgClasResp');
            $('#bolinhaSim').removeClass('corImgOriginalResp').addClass('corImgClasResp');
        };
        //#endregion
        $scope.alterarCadencia = function (pDados) {
            if (pDados) {
                _cadenciaSelecionada = pDados;
                if (_cadenciaSelecionada.tipoCadencia === 'Outbound' || _cadenciaSelecionada.tipoCadencia === undefined) {
                    $scope.mostrarCheckConfiabilidade = true;
                }
            }
            _filtrosEstatisticas = {
                objectId: _cadenciaSelecionada._id,
                dataInicio: $scope.dataInicioFiltro ? new Date($scope.dataInicioFiltro) : '',
                dataFim: $scope.dataFinalFiltro ? new Date($scope.dataFinalFiltro) : ''
            };
            if (pDados.tipoCadencia === 'Reengajamento') {
                var dadosReengajamento = pDados.dadosReengajamento;
                dadosReengajamento.funil.forEach(function (funil) {
                    $scope.listaFunisCadP.forEach(function (funilCadencia) {
                        if (funilCadencia.id === funil) {
                            $scope.selecionarFunilCadP(funilCadencia);
                        }
                    });
                });
                dadosReengajamento.motivo.forEach(function (motivo) {
                    $scope.listaMotivosCadP.forEach(function (motivoCadencia) {
                        if (motivoCadencia.id === motivo) {
                            $scope.selecionarMotivosCadP(motivoCadencia);
                        }
                    });
                });
                dadosReengajamento.estagio.forEach(function (estagio) {
                    $scope.listaEtapasCadP.forEach(function (etapaCadencia) {
                        if (etapaCadencia.id === estagio) {
                            $scope.selecionarEtapasCadP(etapaCadencia);
                        }
                    });
                });
                dadosReengajamento.usuario.forEach(function (usuario) {
                    $scope.listaUsuariosCadP.forEach(function (usuarioCadencia) {
                        if (usuarioCadencia.id === usuario) {
                            $scope.selecionarUsuariosCadP(usuarioCadencia);
                        }
                    });
                });
                $scope.reengajamento.checkDiasRetroativos = dadosReengajamento.retroativo;
                $scope.reengajamento.diasRetroativos = dadosReengajamento.diasEmLost ? dadosReengajamento.diasEmLost : 1;
            }
            if (pDados.tipoCadencia === 'Prospeccao_ICP') {
                var dadosCadenciaICP = pDados.dadosICP;
                $scope.segmentacaoICP = dadosCadenciaICP[0].segmento;
                $scope.estadoSelecionado = dadosCadenciaICP[0].estado;
                $scope.inputCargoICP = dadosCadenciaICP[0].cargo;
                $scope.idICPSelecionado = dadosCadenciaICP[0]._id;
                $scope.qtdCapturasSelecionada = dadosCadenciaICP[0].quantidadeCapturas.toString();
            }
            $scope.exibeVariacaoTesteAB = false;
            if ($('#dvDetalhesGeral').is(':visible')) {
                $('.dvDetalhes').addClass('blur');
            }
            else {
                $('#menuPrincipal').addClass('blur');
                $('#pagCadencias').addClass('blur');
            }
            $rootScope.monsterShowLoading = true;
            mostrarSegmentacoesPermitidas(pDados.tipoCadencia, 'edicao');
            consultarTemplates();
            consultarPastasTemplatesPorUsuario();
            if (!$scope.listaIntegracoes.length)
                $scope.consultarIntegracoes();
            setTimeout(function () {
                if (_cadenciaSelecionada.tipoDisparo == 'C') {
                    $rootScope.monsterShowLoading = false;
                    $rootScope.monsterShowLoading = false;
                    $('#tipoCadenciaCampanha').show();
                    $('#idFundoPopupCadencica').fadeIn();
                    $scope.popupTipoCadenciaCampanha = true;
                }
                else {
                    $scope.consultarCadenciasEstatisticas(_filtrosEstatisticas, function () {
                        $http({
                            method: 'GET',
                            url: "".concat($rootScope.ramperV3ApiBaseUrl, "/disparos/alterarCadencia"),
                            headers: {
                                'ramp3r-auth': 'ramp3r-authorization',
                                'ramp3r-l': $rootScope.IDCrypto,
                                'Authorization': 'Bearer ' + $rootScope.Token
                            },
                            params: {
                                objectIdDisparo: _cadenciaSelecionada._id
                            }
                        }).then(function (rCadencia) {
                            if (_cadenciaSelecionada.sequencias.map(function (x) { return x.qtdBounceSequencia + x.qtdEnvioSequencia; }).reduce(function (a, b) { return a + b; }, 0) > 0) {
                                $scope.exibeVariacaoTesteAB = false;
                            }
                            $scope.inicioDisparosCadP = rCadencia.data.disparoSequencias.sequencias[0].envios[0].intervaloDias;
                            $scope.novaCadencia = {
                                _id: rCadencia.data._id,
                                alteracao: rCadencia.data.alteracao,
                                nome: rCadencia.data.nome,
                                segmentacao: rCadencia.data.segmentacao,
                                disparoSequencias: rCadencia.data.disparoSequencias,
                                automacoes: rCadencia.data.automacoes,
                                tipoDisparo: rCadencia.data.tipoDisparo,
                                tipoCadencia: rCadencia.data.tipoCadencia ? rCadencia.data.tipoCadencia : "Outbound",
                            };
                            var automacaoB = $scope.novaCadencia.automacoes.find(function (x) { return x.situacao == "B"; });
                            if (automacaoB != undefined) {
                                if (automacaoB.parametros.acao == 'D') {
                                    $rootScope.excluirBounce = true;
                                    $('.linhaBaixoMeio').css('display', 'none');
                                    $('.divGeralBotaoAdicionarCadCentro').css('display', 'none');
                                    $('.dvBolinhaImgCardMeioEmpty').css('display', 'none');
                                }
                                else {
                                    $('#acaoEnvioBounce')[0].value = automacaoB.parametros.acao;
                                    $scope.emailPara = $('#acaoEnvioBounce')[0].selectedOptions[0].text;
                                    $scope['assuntoEnvioBounce'] = automacaoB.parametros.assunto;
                                    tinymce.editors["txtAreaBounce"].setContent(automacaoB.parametros.textoHTML);
                                    for (var i = 0; i < $('#templateSelecionadoAutomacaoBounce')[0].options.length; i++) {
                                        try {
                                            var objetoTemp = JSON.parse($('#templateSelecionadoAutomacaoBounce')[0].options[i].value);
                                            if (objetoTemp.objectIdTemplate == automacaoB.parametros.objectIdTemplate) {
                                                $('#templateSelecionadoAutomacaoBounce')[0].selectedIndex = i;
                                                break;
                                            }
                                        }
                                        catch (e) { }
                                    }
                                    ;
                                    $rootScope.enviarBounce = true;
                                    $('.linhaBaixoMeio').css('display', 'none');
                                    $('.divGeralBotaoAdicionarCadCentro').css('display', 'none');
                                    $('.dvBolinhaImgCardMeioEmpty').css('display', 'none');
                                }
                            }
                            var automacaoR = $scope.novaCadencia.automacoes.find(function (x) { return x.situacao == "R"; });
                            if (automacaoR != undefined) {
                                for (var i = 0; i < $('#integracoesRespondidos')[0].options.length; i++) {
                                    try {
                                        var objetoTemp = JSON.parse($('#integracoesRespondidos')[0].options[i].value);
                                        if (objetoTemp._id == automacaoR.parametros.objectIdIntegracoes) {
                                            $('#integracoesRespondidos')[0].selectedIndex = i;
                                        }
                                    }
                                    catch (e) {
                                    }
                                }
                                ;
                                for (var i = 0; i < $('#cadenciaRespondidos')[0].options.length; i++) {
                                    try {
                                        var objetoTemp = JSON.parse($('#cadenciaRespondidos')[0].options[i].value);
                                        if (objetoTemp._id == automacaoR.parametros.objectIdDisparos) {
                                            $('#cadenciaRespondidos')[0].selectedIndex = i;
                                        }
                                    }
                                    catch (e) {
                                    }
                                }
                                $scope.abrirDivAutRespondidos();
                            }
                            var automacaoN = $scope.novaCadencia.automacoes.find(function (x) { return x.situacao == "N"; });
                            if (automacaoN != undefined) {
                                for (var i = 0; i < $('#integracaoNaoRespondidos')[0].options.length; i++) {
                                    try {
                                        var objetoTemp = JSON.parse($('#integracaoNaoRespondidos')[0].options[i].value);
                                        if (objetoTemp._id == automacaoN.parametros.objectIdIntegracoes) {
                                            $('#integracaoNaoRespondidos')[0].selectedIndex = i;
                                        }
                                    }
                                    catch (e) {
                                    }
                                }
                                ;
                                for (var i = 0; i < $('#segmentacaoFiltroNaoRespondido')[0].options.length; i++) {
                                    try {
                                        var objetoTemp = JSON.parse($('#segmentacaoFiltroNaoRespondido')[0].options[i].value);
                                        if (objetoTemp._id.objectIdSegmentacao == automacaoN.parametros.objectIdSegmentacao) {
                                            $('#segmentacaoFiltroNaoRespondido')[0].selectedIndex = i;
                                        }
                                    }
                                    catch (e) {
                                    }
                                }
                                $scope.automacaoDiasNaoResp = automacaoN.parametros.diasAguardar;
                                $scope.abrirDivAutNaoRespondidos();
                            }
                            $scope.nomeNovaCadencia = $scope.novaCadencia.nome;
                            $rootScope.seqCriacaoCad = true;
                            $('#checkboxAltaInput')[0].checked = $scope.novaCadencia.segmentacao.alta;
                            $('#checkboxMediaInput')[0].checked = $scope.novaCadencia.segmentacao.media;
                            $('#checkboxBaixaInput')[0].checked = $scope.novaCadencia.segmentacao.baixa;
                            $('#checkboxSemInput')[0].checked = $scope.novaCadencia.segmentacao.sem;
                            $scope.setarOption(1);
                            $scope.abrirFormNovaCadencia(true);
                        });
                    });
                }
            }, 1500);
        };
        $scope.fecharPopupTipoCadenciaCampanha = function () {
            $('#tipoCadenciaCampanha').fadeOut();
            $('#idFundoPopupCadencica').fadeOut();
            $scope.popupTipoCadenciaCampanha = false;
        };
        $(document).mouseup(function (e) {
            var popUpSegmentacao = $('#tipoCadenciaCampanha');
            if (popUpSegmentacao.is(e.target)) {
                $scope.fecharPopupTipoCadenciaCampanha();
            }
        });
        var idCadenciaMixPanelPausado = '';
        var idCadenciaMixPanelFinalizado = '';
        $scope.mudarStatusCadencia = function (pStatus, pDados) {
            $rootScope.monsterShowLoading = true;
            if ($scope.cadenciaSelecionadaPausar) {
                idCadenciaMixPanelPausado = $scope.cadenciaSelecionadaPausar._id;
            }
            if ($scope.cadenciaSelecionadaFinalizar) {
                idCadenciaMixPanelFinalizado = $scope.cadenciaSelecionadaFinalizar._id;
            }
            if ($scope.cadenciaSelecionadaAtivar) {
                idCadenciaMixPanelAtivado = $scope.cadenciaSelecionadaAtivar._id;
            }
            var arrayCadencias = [];
            if (pStatus == 'F') {
                arrayCadencias.push($scope.cadenciaSelecionadaFinalizar._id);
            }
            else if (pStatus == 'P') {
                arrayCadencias.push($scope.cadenciaSelecionadaPausar._id);
            }
            else if (pStatus == 'A') {
                arrayCadencias.push($scope.cadenciaSelecionadaAtivar._id);
            }
            $http({
                method: 'POST',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/disparos/mudarStatusCadencia"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                params: {
                    objectIdDisparo: arrayCadencias,
                    status: pStatus
                }
            }).then(function () {
                if (pStatus == 'P')
                    mixpanel.track("Cadência | Pausou uma cadência", {
                        "ID Cadencia": idCadenciaMixPanelPausado._id,
                        "Local_IP": $rootScope.ip
                    });
                if (pStatus == 'F')
                    mixpanel.track("Cadência | Finalizou uma cadência", {
                        "ID Cadencia": idCadenciaMixPanelFinalizado,
                        "Local_IP": $rootScope.ip
                    });
                if (pStatus == 'A')
                    mixpanel.track("Cadência | Ativou uma cadência", {
                        "ID Cadencia": idCadenciaMixPanelFinalizado,
                        "Local_IP": $rootScope.ip
                    });
                $rootScope.monsterShowLoading = false;
                $scope.consultarAcessoCadenciaEngage();
                $scope.fecharPopupFinalizarCad();
                $('#abrirSubMenuCadencias').hide();
                $rootScope.subMenuDeCadencias = false;
                $scope.listaCadencias.checkSelecionado = false;
                $scope.totalSelecionados = 0;
                _contPagina = 1;
                $scope.consultarCadencias(_filtros);
                $scope.fecharDetalhes();
            }, function () {
                $rootScope.monsterShowLoading = false;
            });
        };
        $scope.excluirCadencia = function () {
            $scope.popupRemove = true;
            $('#idFundoPopupCadencica').fadeIn();
            setTimeout(function () { document.getElementById("idInptExcluirCad").focus(); }, 10);
            if ($('#dvDetalhesGeral').is(':visible')) {
                $scope.txtPlural = '';
                $scope.totalSelecionados = 1;
            }
            else {
                if ($scope.totalSelecionados == 1)
                    $scope.txtPlural = '';
                else
                    $scope.txtPlural = 's';
            }
        };
        $scope.fecharPopupRemover = function () {
            $scope.popupRemove = false;
            $('#idFundoPopupCadencica').fadeOut();
            $scope.digiteExcluir = '';
        };
        $scope.confirmarExclusaoCadencia = function () {
            $scope.digiteExcluir = '';
            var arrayCadencias = [];
            var nomeCadenciasArray = [];
            if ($('#dvDetalhesContatos').is(':visible')) {
                arrayCadencias.push(_cadenciaSelecionada._id);
                nomeCadenciasArray.push(_cadenciaSelecionada.disparosNome);
            }
            else {
                $scope.listaCadencias.forEach(function (item) {
                    if (item.checkSelecionado) {
                        arrayCadencias.push(item._id);
                        nomeCadenciasArray.push(item.disparosNome);
                    }
                });
            }
            $http({
                method: 'DELETE',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/disparos/excluirCadencia"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                params: {
                    objectIdDisparo: arrayCadencias
                }
            }).then(function () {
                mixpanel.track("Cadência | Excluiu uma cadência", {
                    "Cadências": nomeCadenciasArray,
                    "Local_IP": $rootScope.ip
                });
                _contPagina = 1;
                $scope.consultarCadencias(_filtros);
                $scope.fecharDetalhes();
                $scope.fecharPopupRemover();
                $scope.consultarAcessoCadenciaEngage();
                $scope.totalSelecionados = 0;
            });
        };
        salvarNovaCadencia = function () {
            $scope.novaCadencia.disparoSequencias.sequencias.forEach(function (sequencia) {
                sequencia.envios.forEach(function (envio) {
                    envio.mensagem = '';
                    envio.assunto = encodeURIComponent(envio.assunto);
                    envio.templateNome = encodeURIComponent(envio.templateNome);
                    envio.emailDeEnvioNome = encodeURIComponent(envio.emailDeEnvioNome);
                });
            });
            $scope.novaCadencia.automacoes.forEach(function (automacao) {
                if (automacao.parametros.assunto)
                    automacao.parametros.assunto = '';
                if (automacao.parametros.texto)
                    automacao.parametros.texto = '';
                if (automacao.parametros.textoHTML)
                    automacao.parametros.textoHTML = '';
            });
            $scope.novaCadencia.segmentacao.nome = '';
            $scope.novaCadencia.nome = encodeURIComponent($scope.novaCadencia.nome);
            if ($scope.novaCadencia.tipoCadencia === 'Reengajamento') {
                $scope.novaCadencia.disparoSequencias.sequencias[0].envios[0].intervaloDias =
                    $scope.inicioDisparosCadP;
                var motivo = $scope.listaMotivosCadP
                    .filter(function (motivo) {
                    return motivo.checkMotivoSelecionado === true;
                })
                    .map(function (motivo) {
                    return motivo.id;
                });
                var estagio = $scope.listaEtapasCadP
                    .filter(function (etapa) {
                    return etapa.checkEtapaSelecionado === true;
                })
                    .map(function (etapa) {
                    return etapa.id;
                });
                var usuario = $scope.listaUsuariosCadP
                    .filter(function (usuario) {
                    return usuario.checkUsuarioSelecionado === true;
                })
                    .map(function (usuario) {
                    return usuario.id;
                });
                var funil = $scope.listaFunisCadP
                    .filter(function (funil) {
                    return funil.checkSelecionado === true;
                })
                    .map(function (funil) {
                    return funil.id;
                });
                $scope.novaCadencia.dadosReengajamento = {
                    funil: funil,
                    estagio: estagio,
                    usuario: usuario,
                    motivo: motivo,
                    retroativo: $scope.reengajamento.checkDiasRetroativos
                };
                if ($scope.novaCadencia.alteracao) {
                    $http({
                        method: 'PUT',
                        url: "".concat($rootScope.ramperV3ApiBaseUrl, "/disparos/cadencia/reengajamento"),
                        headers: {
                            'ramp3r-auth': 'ramp3r-authorization',
                            'ramp3r-l': $rootScope.IDCrypto,
                            'Authorization': 'Bearer ' + $rootScope.Token
                        },
                        params: {
                            novaCadencia: $scope.novaCadencia
                        }
                    }).then(function (rRetorno) {
                        getContatosRetroativos(rRetorno);
                    });
                }
                else {
                    $http({
                        method: 'POST',
                        url: "".concat($rootScope.ramperV3ApiBaseUrl, "/disparos/cadencia/reengajamento"),
                        headers: {
                            'ramp3r-auth': 'ramp3r-authorization',
                            'ramp3r-l': $rootScope.IDCrypto,
                            'Authorization': 'Bearer ' + $rootScope.Token
                        },
                        params: {
                            novaCadencia: $scope.novaCadencia
                        }
                    }).then(function (rRetorno) {
                        $http({
                            method: 'POST',
                            url: "".concat($rootScope.ramperV3ApiBaseUrl, "/reengajamento/pipedrive/webhook"),
                            headers: {
                                'ramp3r-auth': 'ramp3r-authorization',
                                'ramp3r-l': $rootScope.IDCrypto,
                            }
                        }).then(function (rWebhook) { });
                        getContatosRetroativos(rRetorno);
                    });
                }
            }
            else if ($scope.novaCadencia.tipoCadencia === 'Inbound') {
                $scope.novaCadencia.disparoSequencias.sequencias[0].envios[0].dataInicio =
                    new Date();
                $scope.novaCadencia.disparoSequencias.sequencias[0].envios[0].intervaloDias =
                    $scope.inicioDisparosCadP;
                $http({
                    method: 'POST',
                    url: "".concat($rootScope.ramperV3ApiBaseUrl, "/disparos/salvarCadencia"),
                    headers: {
                        'ramp3r-auth': 'ramp3r-authorization',
                        'ramp3r-l': $rootScope.IDCrypto,
                        'Authorization': 'Bearer ' + $rootScope.Token
                    },
                    data: {
                        novaCadencia: $scope.novaCadencia
                    }
                }).then(function (rRetorno) {
                    $scope.linkInboudGerado = true;
                    $scope.linkWebhook = $scope.novaCadencia.alteracao ?
                        _cadenciaSelecionada.linkWebhook : rRetorno.data.replace(/["]/g, '');
                });
            }
            else if ($scope.novaCadencia.tipoCadencia === 'Prospeccao_ICP') {
                var capturas;
                if ($scope.qtdCapturasSelecionada == '15') {
                    capturas = 15;
                }
                else if ($scope.qtdCapturasSelecionada == '30') {
                    capturas = 30;
                }
                else {
                    capturas = 45;
                }
                $scope.novaCadencia.dadosICP = {
                    segmento: $scope.segmentacaoICP,
                    estado: $scope.estadoSelecionado,
                    quantidadeCapturas: capturas,
                    cargo: $scope.inputCargoICP,
                    idCadenciaICPSelecionada: $scope.idICPSelecionado
                };
                $scope.novaCadencia.disparoSequencias.sequencias[0].envios[0].dataInicio =
                    new Date();
                $scope.novaCadencia.disparoSequencias.sequencias[0].envios[0].intervaloDias =
                    $scope.inicioDisparosCadP;
                $http({
                    method: 'POST',
                    url: "".concat($rootScope.ramperV3ApiBaseUrl, "/disparos/salvarCadencia"),
                    headers: {
                        'ramp3r-auth': 'ramp3r-authorization',
                        'ramp3r-l': $rootScope.IDCrypto,
                        'Authorization': 'Bearer ' + $rootScope.Token
                    },
                    params: {
                        novaCadencia: $scope.novaCadencia
                    }
                }).then(function (rRetorno) {
                    $scope.linkInboudGerado = true;
                    $scope.linkWebhook = $scope.novaCadencia.alteracao ? _cadenciaSelecionada.linkWebhook : rRetorno.data;
                });
            }
            else {
                $http({
                    method: 'POST',
                    url: "".concat($rootScope.ramperV3ApiBaseUrl, "/disparos/salvarCadencia"),
                    headers: {
                        'ramp3r-auth': 'ramp3r-authorization',
                        'ramp3r-l': $rootScope.IDCrypto,
                        'Authorization': 'Bearer ' + $rootScope.Token
                    },
                    data: {
                        novaCadencia: $scope.novaCadencia
                    }
                }).then(function (rRetorno) { });
            }
            if ($scope.novaCadencia.tipoCadencia === 'Engage') {
                mixpanel.track("Engage | Ativou a cadência");
            }
        };
        $('#dvDetalhesGeral').hide();
        $('#formNovaCadencia').hide();
        infiniteScrolling('#tbleContatos', function () {
            var alreadyReturnedAllContacts = $scope.showBrowserContatos ? $scope.showBrowserContatos.length === $scope.qtdContatosCadencia : false;
            if (!alreadyReturnedAllContacts) {
                $scope.consultarContatosPorCadencia();
            }
        });
        $scope.adicionarClasses = function (marcado) {
            if (marcado)
                return 'btn btn-block mt-3 btnWeekCad btnWeekMarcado';
            else
                return 'btn btn-block mt-3 btnWeekCad';
        };
        $scope.exibirDiasDaSemana = function (array) {
            var resultado = '';
            try {
                if (array[1])
                    resultado += 'D ';
                if (array[2])
                    resultado += '2ª ';
                if (array[3])
                    resultado += '3ª ';
                if (array[4])
                    resultado += '4ª ';
                if (array[5])
                    resultado += '5ª ';
                if (array[6])
                    resultado += '6ª ';
                if (array[7])
                    resultado += 'S ';
            }
            catch (e) {
                resultado = '';
            }
            return resultado;
        };
        $(document).mouseup(function (e) {
            var container = $('#abrirSubMenuCadencias');
            if (!container.is(e.target) && container.has(e.target).length === 0)
                container.hide();
        });
        $(document).mouseup(function (e) {
            var subMenu = $('#abrirSubMenuCadenciasEspecifica');
            if (!subMenu.is(e.target) && subMenu.has(e.target).length === 0)
                subMenu.hide();
        });
        $scope.clickCheckTodos = function () {
            _clickCheckTodos = !_clickCheckTodos;
            $scope.listaCadencias.forEach(function (item) {
                item.checkSelecionado = _clickCheckTodos;
            });
            $scope.contadorSelecionados();
        };
        $scope.clickCheck = function (pItemCadencia) {
            if (!pItemCadencia.checkSelecionado) {
                pItemCadencia.checkSelecionado = false;
            }
            else {
                pItemCadencia.checkSelecionado = true;
                if (pItemCadencia.status == 'Ativa') {
                    $scope.cadenciaAtiva = true;
                    $('#abrirSubMenuCadenciasEspecifica').addClass('tamanhoSubMenuCadencia');
                    $('.abrirSubMenuCadenciasEspecifica').addClass('subMenuCadenciasEspecifica');
                }
                else
                    $scope.cadenciaAtiva = false;
            }
            $scope.contadorSelecionados();
        };
        $scope.avancarSegundaTela = function (pDados) {
            var qtdErros = 0;
            var qtdDias = 0;
            var idHoraInicio = parseInt(document.getElementById("valueInicio_A").selectedOptions[0].id);
            var idHoraFim = parseInt(document.getElementById("valueFim_A").selectedOptions[0].id);
            tinymce.editors["txtAreaTemplate_A"].on('focus', function (e) {
                _inptAssunto = false;
            });
            qtdEnviosPorDia = $rootScope.Login.qtdEnvioDiario ? $rootScope.Login.qtdEnvioDiario : 200;
            if (parseInt($scope.valueEmails_A) > parseInt(qtdEnviosPorDia) && $scope.novaCadencia.tipoCadencia !== 'Engage') {
                document.getElementById("valueEmails_A").classList.add('corTextoErro', 'bordaEmbaixoTextAreaErro');
                $('#labelErroValueEmail').fadeIn();
                qtdErros++;
            }
            else {
                document.getElementById("valueEmails_A").classList.remove('corTextoErro', 'bordaEmbaixoTextAreaErro');
                $('#labelErroValueEmail').fadeOut();
            }
            if (idHoraFim < idHoraInicio) {
                $('#valueInicio_A').addClass('divErroAssunto');
                $('#valueFim_A').addClass('divErroAssunto');
                $('#dvMensagemHorarioInicial').fadeIn();
                qtdErros++;
            }
            else {
                $('#valueInicio_A').removeClass('divErroAssunto');
                $('#valueFim_A').removeClass('divErroAssunto');
                $('#dvMensagemHorarioInicial').fadeOut();
            }
            for (var weekDay = 1; weekDay <= 7; weekDay++)
                if ($('#' + $scope.testeAB + 'week' + weekDay).hasClass('btnWeekMarcado'))
                    qtdDias++;
            if (qtdDias == 0) {
                for (var weekDay = 1; weekDay <= 7; weekDay++)
                    $('#' + $scope.testeAB + 'week' + weekDay).addClass('bordaDiasSemanaErro');
                $('#dvMensagemDiasSemana').fadeIn();
                $('#dvMensagemDiasSemanaB').fadeIn();
                qtdErros++;
            }
            else {
                for (var weekDay = 1; weekDay <= 7; weekDay++)
                    $('#' + $scope.testeAB + 'week' + weekDay).removeClass('bordaDiasSemanaErro');
                $('#dvMensagemDiasSemana').fadeOut();
                $('#dvMensagemDiasSemanaB').fadeOut();
            }
            if ($('#emailSelecionado_' + pDados)[0].selectedOptions[0].value == '') {
                $('#emailSelecionado_A').addClass('corTextoErro');
                $('#emailSelecionado_B').addClass('corTextoErro');
                qtdErros++;
            }
            else {
                $('#emailSelecionado_A').removeClass('corTextoErro');
                $('#emailSelecionado_B').removeClass('corTextoErro');
            }
            if ($scope.envioBuffer.sequencia < 2) {
                var dataComparacao = new Date();
                dataComparacao.setHours(0);
                dataComparacao.setMinutes(0);
                dataComparacao.setSeconds(-1);
                $scope['enviarComoSelecionado_' + pDados] = 'N';
                document.getElementById("inptData_A").disabled = false;
                if ($scope.novaCadencia.tipoCadencia === 'Outbound') {
                    if ($scope.dataInicioCadencia < dataComparacao) {
                        $('#sltDataInicioCadencia_A').addClass('corTextoErro');
                        $('#sltDataInicioCadencia_B').addClass('corTextoErro');
                        $('#dvMensagemDataInicioCadencia').fadeIn();
                        $('#dvMensagemDataInicioCadenciaB').fadeIn();
                        qtdErros++;
                    }
                    else {
                        $('#sltDataInicioCadencia_A').removeClass('corTextoErro');
                        $('#sltDataInicioCadencia_B').removeClass('corTextoErro');
                        $('#dvMensagemDataInicioCadencia').fadeOut();
                        $('#dvMensagemDataInicioCadenciaB').fadeOut();
                    }
                }
                if ($scope.novaCadencia.tipoCadencia === 'Engage') {
                    if ($scope.dataInicioCadencia < dataComparacao) {
                        $('#sltDataInicioCadencia_A').addClass('corTextoErro');
                        $('#sltDataInicioCadencia_B').addClass('corTextoErro');
                        $('#dvMensagemDataInicioCadenciaEngage').fadeIn();
                        $('#dvMensagemDataInicioCadenciaEngageB').fadeIn();
                        qtdErros++;
                    }
                    else {
                        $('#sltDataInicioCadencia_A').removeClass('corTextoErro');
                        $('#sltDataInicioCadencia_B').removeClass('corTextoErro');
                        $('#dvMensagemDataInicioCadenciaEngage').fadeOut();
                        $('#dvMensagemDataInicioCadenciaEngageB').fadeOut();
                    }
                }
            }
            else {
                if (enviarComoSelecionado_A.selectedOptions[0].value == 'E' || $('#enviarComoSelecionado_B')[0].selectedOptions[0].value == 'E') {
                    document.getElementById("inptData_A").disabled = true;
                    if ($scope.novaCadencia.disparoSequencias.sequencias[$scope.envioBuffer.sequencia - 2].envios.length > 1)
                        $scope['inptData_' + $scope.testeAB] = 'Encaminhado pelo anterior';
                    else {
                        if ($scope.novaCadencia.disparoSequencias.sequencias[$scope.envioBuffer.sequencia - 2].envios[0].assunto == 'Encaminhado pelo anterior')
                            $scope['inptData_' + $scope.testeAB] = $scope.novaCadencia.disparoSequencias.sequencias[$scope.envioBuffer.sequencia - 2].envios[0].assunto;
                        else
                            $scope['inptData_' + $scope.testeAB] = 'ENC: ' + $scope.novaCadencia.disparoSequencias.sequencias[$scope.envioBuffer.sequencia - 2].envios[0].assunto;
                    }
                }
                else {
                    document.getElementById("inptData_A").disabled = false;
                    document.getElementById("inptData_B").disabled = false;
                }
                if ($scope['valueDias_' + $scope.testeAB] == null || $scope['valueDias_' + $scope.testeAB] < 1) {
                    $('#valueDias_A').addClass('bordaDiasSemanaErro');
                    $('#valueDias_B').addClass('bordaDiasSemanaErro');
                    $('#dvMensagemIntervaloDias').fadeIn();
                    $('#dvMensagemIntervaloDiasB').fadeIn();
                    qtdErros++;
                }
                else {
                    $('#valueDias_A').removeClass('bordaDiasSemanaErro');
                    $('#valueDias_B').removeClass('bordaDiasSemanaErro');
                    $('#dvMensagemIntervaloDias').fadeOut();
                    $('#dvMensagemIntervaloDiasB').fadeOut();
                }
            }
            if (($scope.dataInicioCadencia >= _tresMesesCalculo) || (new Date($scope.envioBuffer.dataInicio) >= _tresMesesCalculo) && ($scope.mostrarCalendario == true)) {
                if ($scope.dataInicioCadencia >= _tresMesesCalculo) {
                    var dataSelecionada = $scope.dataInicioCadencia.getTime();
                }
                else if ((new Date($scope.envioBuffer.dataInicio) >= _tresMesesCalculo)) {
                    $scope.dataEditadaSelecionada = (new Date($scope.envioBuffer.dataInicio));
                    var dataSelecionada = $scope.dataEditadaSelecionada.getTime();
                }
                var dateDeHoje = _dataHoje.getTime();
                var umDia = 1000 * 60 * 60 * 24;
                var diferenca = Math.abs(dateDeHoje - dataSelecionada);
                var diferencaMeses = Math.floor(diferenca / umDia);
                var divisaoDiferenca = Math.floor((diferencaMeses / 30));
                $scope.mesesDiferenca = divisaoDiferenca;
                $scope.abrirPopUpTresMeses();
                qtdErros++;
                if ($scope.avancaETiraErro) {
                    qtdErros = 0;
                    $('.divPopupTesteAB').removeClass('blur');
                }
                else {
                    qtdErros++;
                }
            }
            if (qtdErros == 0) {
                $('#dvMensagemHorarioInicial').hide();
                if (pDados == 'A') {
                    $scope.divSegundaTelaPopupSeq = true;
                    $scope.divSegundaTelaPopupSeqB = false;
                    $scope.popupTresMeses = false;
                    $('#dvMensagemDiasSemana').hide();
                    $('#dvMensagemDataInicioCadencia').hide();
                    $('#dvMensagemDataInicioCadenciaB').hide();
                    $('#dvMensagemDataInicioCadenciaEngage').hide();
                    $('#dvMensagemDataInicioCadenciaEngageB').hide();
                }
                else if (pDados == 'B') {
                    $scope.divSegundaTelaPopupSeq = false;
                    $scope.divSegundaTelaPopupSeqB = true;
                    $scope.popupTresMeses = false;
                    $('#dvMensagemDiasSemana').hide();
                    $('#dvMensagemDataInicioCadencia').hide();
                    $('#dvMensagemDataInicioCadenciaB').hide();
                    $('#dvMensagemDataInicioCadenciaEngage').hide();
                    $('#dvMensagemDataInicioCadenciaEngageB').hide();
                }
            }
            $scope.getInfoChat();
            $scope.getInfoAgenda();
            tinymce.editors["txtAreaTemplate_A"].focus();
        };
        $scope.abrirPopUpTresMeses = function () {
            $scope.popupTresMeses = true;
            $('.divPopupTesteAB').addClass('blur');
        };
        $scope.voltarSegundaTela = function (pDados) {
            if (pDados == 'A') {
                $scope.divSegundaTelaPopupSeq = false;
                $scope.avancaETiraErro = false;
            }
            else if (pDados == 'B')
                $scope.divSegundaTelaPopupSeqB = false;
        };
        $scope.contadorSelecionados = function () {
            $scope.totalSelecionados =
                $scope.listaCadencias.filter(function (obj) {
                    return obj.checkSelecionado;
                }).length;
            $scope.cadenciaFinalizada =
                $scope.listaCadencias.filter(function (obj) {
                    return obj.checkSelecionado;
                });
            try {
                $scope.cadenciaFinalizada = $scope.cadenciaFinalizada.map(function (x) { return x.status; });
                $scope.cadenciaAtiva = $scope.cadenciaFinalizada[0].map(function (x) { return x._id.statusCadencia; }).includes('Ativa');
                $scope.cadenciaFinalizada = $scope.cadenciaFinalizada[0].map(function (x) { return x._id.statusCadencia; }).includes('Finalizada');
            }
            catch (e) { }
            if ($scope.totalSelecionados > 1) {
                $scope.nomeSelecionado = 'selecionados';
                $scope.nomeBtnExcluirCad = 'Excluir selecionadas';
            }
            else {
                $scope.nomeSelecionado = 'selecionado';
                $scope.nomeBtnExcluirCad = 'Excluir';
            }
            if ($scope.totalSelecionados < $scope.listaCadencias.length) {
                $('#checkCadencias').prop('checked', false);
                _clickCheckTodos = false;
            }
            else if ($scope.totalSelecionados = $scope.listaCadencias.length) {
                $('#checkCadencias').prop('checked', true);
                _clickCheckTodos = true;
            }
        };
        $scope.showResumoAutomacao = function (opcao) {
            $rootScope.semAutomacoes = $scope.novaCadencia.automacoes.length == 0;
            if (opcao == 'B') {
                return (($scope.emailPara != undefined && $scope.emailPara != null && $scope.emailPara != '') ||
                    ($scope.assuntoEnvioBounce != undefined && $scope.assuntoEnvioBounce != null && $scope.assuntoEnvioBounce != '') ||
                    ($rootScope.excluirBounce != undefined && $rootScope.excluirBounce));
            }
            if (opcao == 'N') {
                return ($scope.automacaoSegmentacaoNaoRespondido != undefined && $scope.automacaoSegmentacaoNaoRespondido != null && $scope.automacaoSegmentacaoNaoRespondido != '') ||
                    ($scope.automacaoIntgracaoNaoRespondido != undefined && $scope.automacaoIntgracaoNaoRespondido != null && $scope.automacaoIntgracaoNaoRespondido != '');
            }
            if (opcao == 'N1') {
                return ($scope.automacaoSegmentacaoNaoRespondido != undefined && $scope.automacaoSegmentacaoNaoRespondido != null && $scope.automacaoSegmentacaoNaoRespondido != '');
            }
            if (opcao == 'N2') {
                return ($scope.automacaoIntgracaoNaoRespondido != undefined && $scope.automacaoIntgracaoNaoRespondido != null && $scope.automacaoIntgracaoNaoRespondido != '');
            }
            if (opcao == 'R') {
                return ($scope.automacaoDisparoRespondido != undefined && $scope.automacaoDisparoRespondido != null && $scope.automacaoDisparoRespondido != '') ||
                    ($scope.automacaoIntgracaoRespondido != undefined && $scope.automacaoIntgracaoRespondido != null && $scope.automacaoIntgracaoRespondido != '');
            }
            if (opcao == 'R1') {
                return ($scope.automacaoDisparoRespondido != undefined && $scope.automacaoDisparoRespondido != null && $scope.automacaoDisparoRespondido != '');
            }
            if (opcao == 'R2') {
                return ($scope.automacaoIntgracaoRespondido != undefined && $scope.automacaoIntgracaoRespondido != null && $scope.automacaoIntgracaoRespondido != '');
            }
            if (opcao == 'A') {
                return $scope.alertaCalendar != undefined && $scope.alertaCalendar != null && $scope.alertaCalendar != '';
            }
        };
        $scope.setarOption = function (opcao) {
            if (opcao == 1) {
                for (var i = 0; i < $('#segmentacaoFiltro')[0].options.length; i++) {
                    try {
                        var objetoTemp = JSON.parse($('#segmentacaoFiltro')[0].options[i].value);
                        if (objetoTemp._id.objectIdSegmentacao == $scope.novaCadencia.segmentacao._id) {
                            $('#segmentacaoFiltro')[0].selectedIndex = i;
                            $scope.segmentacaoFiltro = JSON.stringify(objetoTemp);
                        }
                    }
                    catch (e) {
                    }
                }
            }
            else {
                for (var i = 0; i < $('#templateSelecionado_' + opcao)[0].options.length; i++) {
                    try {
                        var objetoTemp = JSON.parse($('#templateSelecionado_' + opcao)[0].options[i].value);
                        if (objetoTemp.objectIdTemplate == $scope.envioBuffer.template) {
                            //$scope['templateSelecionado_' + opcao] = objetoTemp;
                            $('#templateSelecionado_' + opcao)[0].selectedIndex = i;
                        }
                    }
                    catch (e) {
                    }
                }
                for (var i = 0; i < $('#emailSelecionado_' + opcao)[0].options.length; i++) {
                    try {
                        var objetoTemp = JSON.parse($('#emailSelecionado_' + opcao)[0].options[i].value);
                        if (objetoTemp._id == $scope.envioBuffer.emailDeEnvio) {
                            //$scope['emailSelecionado_' + opcao] = objetoTemp;
                            $('#emailSelecionado_' + opcao)[0].selectedIndex = i;
                        }
                    }
                    catch (e) {
                    }
                }
            }
        };
        var limparCampos = function () {
            $scope['valueInicio_A'] = '08:00';
            $scope['valueFim_A'] = '18:00';
            $scope['valueDias_A'] = '';
            $scope.valueEmails_A = '';
            $scope['templateSelecionado_A'] = '';
            $('#templateSelecionado_A')[0].selectedIndex = 0;
            $scope['emailSelecionado_A'] = '';
            $('#emailSelecionado_A')[0].selectedIndex = 0;
            $scope['enviarComoSelecionado_A'] = '';
            $scope['quemRecebeSelecionado_A'] = '';
            $scope['inptData_A'] = '';
            tinymce.editors["txtAreaTemplate_A"].setContent('');
            $scope['valueInicio_B'] = '08:00';
            $scope['valueFim_B'] = '18:00';
            $scope['valueDias_B'] = '';
            $scope.valueEmails_B = '';
            $scope['templateSelecionado_B'] = '';
            $('#templateSelecionado_B')[0].selectedIndex = 0;
            $scope['emailSelecionado_B'] = '';
            $('#emailSelecionado_B')[0].selectedIndex = 0;
            $scope['enviarComoSelecionado_B'] = '';
            $scope['quemRecebeSelecionado_B'] = '';
            $scope['inptData_B'] = '';
            tinymce.editors["txtAreaTemplate_B"].setContent('');
            $('#sltDataInicioCadencia_A')[0].value = '';
            $('#sltDataInicioCadencia_B')[0].value = '';
            $('#dvMensagemSalvarTemplateA').fadeOut();
            $('#dvMensagemSalvarTemplateB').fadeOut();
            $('.bttnNovoTemplateAB').removeClass('corTextoErro');
            $('#dvMensagemSelecionarTemplateA').fadeOut();
            $('#dvMensagemSelecionarTemplateB').fadeOut();
            $('#templateSelecionado_A').removeClass('corTextoErro');
            $('#templateSelecionado_B').removeClass('corTextoErro');
            $('#inptData_B').removeClass('bordaDiasSemanaErro');
            $('#divInptData_B').removeClass('bordaDiasSemanaErro');
            document.getElementById("valueEmails_A").classList.remove('corTextoErro', 'bordaEmbaixoTextAreaErro');
            $('#labelErroValueEmail').hide();
            $('#dvMensagemAssuntoB').hide();
            $scope.pastaSelecionadaA = '';
            $scope.pastaSelecionadaBounce = '';
            $scope.templatesPastaEspecifica = $scope.templates;
        };
        var limparVariaveis = function () {
            $scope.novaCadencia = {
                nome: '',
                alteracao: false,
                segmentacao: {
                    _id: null,
                    alta: false,
                    media: false,
                    baixa: false,
                    sem: false
                },
                disparoSequencias: {
                    sequencias: []
                },
                automacoes: []
            };
            $('#segmentacaoFiltro')[0].selectedIndex = 0;
            $rootScope.semAutomacoes = true;
            $scope.nomeNovaCadencia = '';
            $scope.emailPara = '';
            $scope.assuntoEnvioBounce = '';
            $scope.automacaoIntgracaoNaoRespondido = '';
            $scope.automacaoIntgracaoRespondido = '';
            $scope.automacaoSegmentacaoNaoRespondido = '';
            $scope.automacaoDisparoRespondido = '';
        };
        $scope.getTimestampTela = function (_id) {
            return getTimestamp(_id);
        };
        $scope.recarregarPagina = function () {
            $scope.botaoBuscarVisivel = false;
            $scope.zerarCamposOrdenacao();
            $scope.filtroHabilitado = false;
            $('#idBtnFiltroCadencias').removeClass().addClass('btnVoltarPadroNovoFiltro');
            $('#idImgFiltrarCad').attr('src', "".concat($rootScope.bucket, "/filtro.png"));
            $scope.removerFiltros();
            $scope.buscarCadencia = '';
            $scope.consultarCadencias(_filtros);
            $scope.semCadenciasFiltro = false;
            $scope.semCadenciasBusca = false;
            $scope.fecharAnalytics();
            $scope.montarNomeFiltros();
        };
        $scope.recarregarDetalheContatos = function () {
            $scope.buscarContato = '';
            $scope.semContatosBusca = false;
            $scope.semContatosFiltro = false;
            $scope.removerFiltrosContatos();
            $scope.clickMenuDetalhes('c');
            $('#tbleContatosCadencia').removeClass('emptyAtivo');
        };
        $scope.fPercentual = function (itemStatus) {
            var resultado = null;
            try {
                resultado = ((itemStatus.qtdEnvioSequencia + itemStatus.qtdBounceSequencia) * 100 / itemStatus.qtdSequencia).toString() + "%";
            }
            catch (e) {
                resultado = "0%";
            }
            return resultado;
        };
        $scope.fSomaEnvios = function (itemStatus) {
            var resultado = null;
            try {
                resultado = (itemStatus.qtdEnvioSequencia + itemStatus.qtdBounceSequencia);
            }
            catch (e) {
                resultado = 0;
            }
            return resultado;
        };
        $scope.abrirPopupComparar = function () {
            $('#menuPrincipal').addClass('blur');
            $('#pagCadencias').addClass('blur');
            $('#sub-navbar').addClass('blur');
            $rootScope.popupCompararCadencias = true;
        };
        $scope.fecharPopupComparar = function () {
            $('#menuPrincipal').removeClass('blur');
            $('#pagCadencias').removeClass('blur');
            $('#sub-navbar').removeClass('blur');
            $rootScope.popupCompararCadencias = false;
        };
        $scope.esconderEmptyStates = function () {
            $scope.semCadencias = false;
            $scope.semCadenciasFiltro = false;
            $scope.semCadenciasBusca = false;
            $scope.filtroDetalheContato = false;
            $scope.semContatosFiltro = false;
            $scope.semContatos = false;
            $scope.semCadenciasAtivas = false;
        };
        $scope.removerDivsErro = function () {
            $scope.nomeTemplate = null;
            $('#dvMensagemNomeTemplate').hide();
            $('#dvMensagemPastaNovoTemplate').hide();
            $('#dvMensagemAssuntoTemplate').hide();
            $('#dvMensagemSelecionarTemplateA').hide();
            $('#dvMensagemSelecionarTemplateB').hide();
            $('#templateSelecionado_A').removeClass('corTextoErro');
            $('#templateSelecionado_B').removeClass('corTextoErro');
            $('#txtNomeNovoTemplate').removeClass('corTextoErro');
            $('#idNomeTemplate').removeClass('bBottomTextAreaErro');
            $('#listaPastasCriacaoCad').removeClass('corTextoErro');
        };
        $scope.atualizarNomoTemplate = function () {
            if ($scope.nomeTemplate != null || $scope.nomeTemplate != '') {
                $('#dvMensagemNomeTemplate').fadeOut();
                $('#txtNomeNovoTemplate').removeClass('corTextoErro');
                $('#idNomeTemplate').removeClass('bBottomTextAreaErro');
            }
        };
        $scope.validarPastaNovoTemplate = function () {
            if ($scope.listaPastasCriacaoCadFiltro != null || $scope.listaPastasCriacaoCadFiltro) {
                $('#listaPastasCriacaoCad').removeClass('corTextoErro');
                $('#dvMensagemPastaNovoTemplate').fadeOut();
            }
        };
        $scope.abrirPopupFinalizarCad = function (pDados, pStatus) {
            if ($('#dvDetalhesGeral').is(':visible')) {
                $scope.cadenciaForaDetalhe = false;
                if (pDados == 'P') {
                    $scope.acaoCadencia = 'Pausar';
                    $scope.cadenciaSelecionadaFinalizar = null;
                    $scope.cadenciaSelecionadaAtivar = null;
                    $scope.cadenciaSelecionadaPausar = _cadenciaSelecionada;
                }
                if (pDados == 'A') {
                    $scope.acaoCadencia = 'Ativar';
                    $scope.cadenciaSelecionadaFinalizar = null;
                    $scope.cadenciaSelecionadaPausar = null;
                    $scope.cadenciaSelecionadaAtivar = _cadenciaSelecionada;
                }
                if (pDados == 'F') {
                    $scope.acaoCadencia = 'Finalizar';
                    $scope.cadenciaSelecionadaPausar = null;
                    $scope.cadenciaSelecionadaAtivar = null;
                    $scope.cadenciaSelecionadaFinalizar = _cadenciaSelecionada;
                }
            }
            else {
                $scope.cadenciaForaDetalhe = true;
                if (pStatus == 'P') {
                    $scope.acaoCadencia = 'Pausar';
                    $scope.cadenciaSelecionadaFinalizar = null;
                    $scope.cadenciaSelecionadaAtivar = null;
                    $scope.cadenciaSelecionadaPausar = pDados;
                }
                if (pStatus == 'A') {
                    $scope.acaoCadencia = 'Ativar';
                    $scope.cadenciaSelecionadaFinalizar = null;
                    $scope.cadenciaSelecionadaPausar = null;
                    $scope.cadenciaSelecionadaAtivar = pDados;
                }
                if (pStatus == 'F') {
                    $scope.acaoCadencia = 'Finalizar';
                    $scope.cadenciaSelecionadaPausar = null;
                    $scope.cadenciaSelecionadaAtivar = null;
                    $scope.cadenciaSelecionadaFinalizar = pDados;
                }
            }
            $scope.popupFinalizarCadencia = true;
        };
        $scope.fecharPopupFinalizarCad = function () {
            $scope.popupFinalizarCadencia = false;
            $('#idFundoPopupCadencica').fadeOut();
        };
        $scope.consultarCadencias(_filtros);
        $scope.enter = function (keyEvent) {
            if (keyEvent.which === 13) {
                if ($scope.popupRemove) {
                    if ($scope.digiteExcluir == 'EXCLUIR')
                        $scope.confirmarExclusaoCadencia();
                }
                else if ($('#dvDetalhesContatos').is(':visible')) {
                    $scope.buscaDetalheContato = true;
                    _skipContatos = -10;
                    _campoOrdem = 'nomeContato';
                    _ordem = 1;
                    $scope.consultarContatosPorCadencia();
                }
                else if ($scope.popupTesteAB) {
                    $scope.avancarSegundaTela('A');
                }
                else {
                    $scope.removerFiltros();
                    _filtros = {
                        buscar: $scope.buscarCadencia,
                        objectId: '',
                        tipoDaChave: '',
                        dataInicio: '',
                        dataFim: '',
                        status: '',
                        segmentacao: '',
                        campoOrdem: 'disparosNome',
                        ordem: '1',
                        origem: 'campoBuscar'
                    };
                    $scope.zerarCamposOrdenacao();
                    $scope.filtroHabilitado = false;
                    $('#idBtnFiltroCadencias').removeClass().addClass('btnVoltarPadroNovoFiltro');
                    $('#idImgFiltrarCad').attr('src', "".concat($rootScope.bucket, "/filtro.png"));
                    $scope.txtNomeFiltros = '';
                    $scope.fecharAnalytics();
                    $scope.consultarCadencias(_filtros);
                }
            }
        };
        $(document).mouseup(function (e) {
            var popUpCadenciaAutomacao = $('#popupAutomacaoNaoRespondidos');
            if (popUpCadenciaAutomacao.is(e.target)) {
                $scope.fecharPopupAutNaoResp();
                popUpCadenciaAutomacao.fadeOut();
            }
        });
        $(document).mouseup(function (e) {
            var fundoPopupNovo = $('#idFundoPopupCadencica');
            var varFunilCadP = $('#idFunilCadP');
            var varMotivoCadP = $('#idMotivosCadP');
            var varEtapaCadP = $('#idEtapasCadP');
            var varUsuarioCadP = $('#idUsuariosCadP');
            if (fundoPopupNovo.is(e.target)) {
                if ($scope.popupRemove) {
                    $scope.fecharPopupRemover();
                }
                else if ($scope.popupFinalizarCadencia) {
                    $scope.fecharPopupFinalizarCad();
                }
                else if ($scope.popupTesteAB) {
                    $scope.fecharPopupTesteAB('A');
                }
                else if ($scope.popupRespostas) {
                    $scope.fecharPopupTesteAB('B');
                }
                else if ($scope.popupAutomacaoBounce) {
                    $scope.fecharPopupBounce();
                }
                else if ($scope.popupFiltrarContatosCadencias) {
                    $scope.fecharPopupFiltrar('cox');
                }
                $scope.$apply();
            }
            else {
                if (!varFunilCadP.is(e.target) && varFunilCadP.has(e.target).length === 0) {
                    $scope.divFunilCadP = false;
                    varFunilCadP.hide();
                }
                if (!varMotivoCadP.is(e.target) && varMotivoCadP.has(e.target).length === 0) {
                    $scope.divMotivosCadP = false;
                    varMotivoCadP.hide();
                }
                if (!varEtapaCadP.is(e.target) && varEtapaCadP.has(e.target).length === 0) {
                    $scope.divEtapasCadP = false;
                    varEtapaCadP.hide();
                }
                if (!varUsuarioCadP.is(e.target) && varUsuarioCadP.has(e.target).length === 0) {
                    $scope.divUsuariosCadP = false;
                    varUsuarioCadP.hide();
                }
            }
        });
        $(document).keydown(function (e) {
            if (e.keyCode == 27) {
                if ($scope.popupRemove) {
                    $scope.fecharPopupRemover();
                }
                else if ($scope.popupFinalizarCadencia) {
                    $scope.fecharPopupFinalizarCad();
                }
                else if ($scope.popupTesteAB) {
                    if (!$scope.popupNovoTemplate)
                        $scope.fecharPopupTesteAB('A');
                    else {
                        $('#idPopupNovoTemplate').fadeOut();
                        $scope.fecharPopupNovoTemplate();
                    }
                }
                else if ($scope.popupRespostas) {
                    if (!$scope.popupNovoTemplate)
                        $scope.fecharPopupTesteAB('B');
                    else {
                        $('#idPopupNovoTemplate').fadeOut();
                        $scope.fecharPopupNovoTemplate();
                    }
                }
                else if ($scope.popupAutomacaoBounce) {
                    if (!$scope.popupNovoTemplate)
                        $scope.fecharPopupBounce();
                    else {
                        $('#idPopupNovoTemplate').fadeOut();
                        $scope.fecharPopupNovoTemplate();
                    }
                }
                else if ($scope.popupExcluirBounce) {
                    $('#idPopupExcluirBounce').fadeOut();
                    $scope.fecharPopupExcluirBounce();
                }
                else if ($scope.popupAutNaoResp) {
                    if (!$scope.popupNovaSegmentacaoCad) {
                        $('#popupAutomacaoNaoRespondidos').fadeOut();
                        $scope.fecharPopupAutNaoResp();
                    }
                    else {
                        $('#popupNovaSegmentacaoNaoRespondidos').fadeOut();
                        $scope.fecharPopupNovaSegmentacao(false);
                    }
                }
                else if ($scope.popupFiltrarContatosCadencias) {
                    $scope.fecharPopupFiltrar('cox');
                }
                else if ($scope.popupDetalhesContatos) {
                    $scope.fecharPopupDetalhesContatos();
                }
                $scope.$apply();
            }
        });
        $(document).mouseup(function (e) {
            var popupNovoTemplateCad = $('#idPopupNovoTemplate');
            if (popupNovoTemplateCad.is(e.target)) {
                popupNovoTemplateCad.fadeOut();
                $scope.fecharPopupNovoTemplate();
            }
        });
        $(document).mouseup(function (e) {
            var popupExcluirBounceCad = $('#idPopupExcluirBounce');
            if (popupExcluirBounceCad.is(e.target)) {
                popupExcluirBounceCad.fadeOut();
                $scope.fecharPopupExcluirBounce();
            }
        });
        $scope.avancarCarrossel = function (pDados) {
            if ((pDados.sequencias.length - pDados.qtdSequenciasVistas) > 6) {
                pDados.qtdSequenciasVistas += 6;
                $('#divBarraSequencias' + pDados._id).hide();
                $('#divBarraSequencias' + pDados._id).fadeIn();
            }
            var valor = pDados.sequencias.length - pDados.qtdSequenciasVistas;
            if (valor <= 6)
                $('#setaDireita' + pDados._id).hide();
        };
        $scope.voltarCarrossel = function (pDados) {
            $('#divBarraSequencias' + pDados._id).hide();
            pDados.qtdSequenciasVistas -= 6;
            $('#divBarraSequencias' + pDados._id).fadeIn();
            $('#setaDireita' + pDados._id).show();
        };
        $scope.abirPopupNovaSegmentacao = function () {
            $scope.popupNovaSegmentacaoCad = true;
            $('#popupNovaSegmentacaoNaoRespondidos').show();
            $('#idPopupAutNaoResp').addClass('blur');
            $scope.nomeNovaSegmentacaoNaoResp = '';
        };
        $scope.fecharPopupNovaSegmentacao = function (pFechar) {
            if (pFechar) {
                $http({
                    method: 'POST',
                    url: "".concat($rootScope.ramperV3ApiBaseUrl, "/segmentacoes/salvarSegmentacao"),
                    headers: {
                        'ramp3r-auth': 'ramp3r-authorization',
                        'ramp3r-l': $rootScope.IDCrypto,
                        'Authorization': 'Bearer ' + $rootScope.Token
                    },
                    params: {
                        nome: $scope.nomeNovaSegmentacaoNaoResp,
                        origem: "N"
                    }
                }).then(function (rRetorno) {
                    var optVal = { "_id": {
                            "objectIdSegmentacao": rRetorno.data._id,
                            "nomeSegmentacao": rRetorno.data.nome,
                            "objectIdLogin": rRetorno.data.objectIdLogin
                        }
                    };
                    $('#segmentacaoFiltroNaoRespondido').append('<option ng-repeat="c in listaSegmentacoesEmailNaoResp" value=\'' + JSON.stringify(optVal) + '\' selected>' + rRetorno.data.nome + '</option>');
                    $scope.botaoSalvarAutomacaoNaoRespondidos = true;
                });
            }
            else
                $scope.nomeNovaSegmentacaoNaoResp = '';
            $scope.popupNovaSegmentacaoCad = false;
            $('#popupNovaSegmentacaoNaoRespondidos').fadeOut();
            $('#idPopupAutNaoResp').removeClass('blur');
        };
        $scope.limparPeriodoAnalytics = function () {
            $('#spanEmptyAnalytics').hide();
            document.getElementById("sltDataAnalytics").innerHTML = "Filtrar por período";
            $('#sltDataAnalytics').data('DRPEx').clear();
            $('#dataFiltroDefinida').hide();
            $scope.dataFinalFiltroAnalytics = new Date();
            $scope.dataInicioFiltroAnalytics = new Date();
            $scope.dataInicioFiltroAnalytics.setDate($scope.dataInicioFiltroAnalytics.getDate() - 30);
            $scope.usuArioSelecionadoFiltro = '';
            $scope.buscarUsuarios = '';
            _usuarioSelecionadoFiltroAnalytics = false;
            $scope.filtroUsuarioAberto = false;
            $('#idFiltroUsuarioAberto').hide();
            $scope.nomeFiltroUsuAnalytics = '';
            $scope.abrirAnalytics();
        };
        var chart = null;
        $scope.montarGraficoAnalytics = function (response) {
            var arrayUm = [];
            var arrayDois = [];
            var arrayData = [];
            if ($scope.IndicadorGrafico == 'entregues') {
                response.forEach(function (item) {
                    arrayUm.push(item.quantidadeEnvios);
                    arrayDois.push(item.quantidadeEntregues);
                    arrayData.push(item._id.dia_mes + ' ' + trocarDiaNomeMes(item._id.mes));
                });
            }
            else if ($scope.IndicadorGrafico == 'abertos') {
                response.forEach(function (item) {
                    arrayUm.push(item.quantidadeEntregues);
                    arrayDois.push(item.quantidadeAbertos);
                    arrayData.push(item._id.dia_mes + ' ' + trocarDiaNomeMes(item._id.mes));
                });
            }
            else if ($scope.IndicadorGrafico == 'respondidos') {
                response.forEach(function (item) {
                    arrayUm.push(item.quantidadeAbertos);
                    arrayDois.push(item.quantidadeRespostas);
                    arrayData.push(item._id.dia_mes + ' ' + trocarDiaNomeMes(item._id.mes));
                });
            }
            else if ($scope.IndicadorGrafico == 'interesses') {
                response.forEach(function (item) {
                    arrayUm.push(item.quantidadeRespostas);
                    arrayDois.push(item.quantidadeInteresses);
                    arrayData.push(item._id.dia_mes + ' ' + trocarDiaNomeMes(item._id.mes));
                });
            }
            else if ($scope.IndicadorGrafico == 'bounces') {
                response.forEach(function (item) {
                    arrayUm.push(item.quantidadeEnvios);
                    arrayDois.push(item.quantidadeBounce);
                    arrayData.push(item._id.dia_mes + ' ' + trocarDiaNomeMes(item._id.mes));
                });
            }
            var options = {
                chart: {
                    height: 230,
                    width: '100%',
                    type: "area",
                    toolbar: {
                        show: false,
                    },
                },
                series: [
                    {
                        name: $scope.indicadorGraficoUm,
                        data: arrayUm
                    },
                    {
                        name: $scope.indicadorGraficoDois,
                        data: arrayDois
                    }
                ],
                dataLabels: {
                    enabled: false,
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shadeIntensity: 1,
                        opacityFrom: 0.5,
                        opacityTo: 0,
                        stops: [0, 90, 100]
                    }
                },
                stroke: {
                    curve: 'straight',
                    width: 1,
                },
                markers: {
                    size: 3
                },
                xaxis: {
                    lines: {
                        show: true,
                    },
                    categories: arrayData,
                    labels: {
                        style: {
                            fontSize: '10px',
                            colors: '#adadad',
                        }
                    }
                },
                yaxis: {
                    lines: {
                        show: true,
                    },
                    min: 0,
                    show: true,
                    floating: false,
                    labels: {
                        style: {
                            fontSize: '10px',
                            color: '#adadad',
                        }
                    }
                },
                legend: {
                    position: 'bottom',
                    horizontalAlign: 'right',
                    offsetY: -10,
                    fontSize: '10px',
                    labels: {
                        colors: '#adadad',
                    },
                    markers: {
                        width: 8,
                        height: 8,
                    }
                },
                grid: {
                    borderColor: '#e8e8e8',
                    xaxis: {
                        lines: {
                            show: true
                        }
                    },
                    yaxis: {
                        lines: {
                            show: true
                        }
                    }
                },
                tooltip: {
                    marker: {
                        show: true,
                    },
                    followCursor: true,
                    x: {
                        show: false
                    },
                    y: {
                        formatter: function (y) {
                            return formatarValor(y);
                        }
                    }
                },
                colors: ['#33ab59', '#176360']
            };
            if (chart != null)
                chart.destroy();
            chart = new ApexCharts(document.querySelector("#chart_div"), options);
            chart.render();
        };
        trocarDiaNomeMes = function (pMes) {
            var _meses = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
            return _meses[pMes - 1];
        };
        $scope.mostrarSegundaLinha = function (pCad) {
            _cadenciaSelecionada = pCad;
            _filtrosEstatisticas = {
                objectId: _cadenciaSelecionada._id,
                dataInicio: $scope.dataInicioFiltro ? new Date($scope.dataInicioFiltro) : '',
                dataFim: $scope.dataFinalFiltro ? new Date($scope.dataFinalFiltro) : ''
            };
            if (_cadenciaSelecionada.cadSelecionada == 'Selecionada') {
                _cadenciaSelecionada.cadSelecionada = '';
                _cadenciaSelecionada.txtMais = 'mais';
                $('#linha' + pCad._id).addClass('subirListaFora');
                $('#img' + pCad._id).removeClass('classeVirarImagem');
                setTimeout(function () { $('#linha' + pCad._id).removeClass('subirListaFora').addClass('classeInvisivelCad'); }, 300);
            }
            else {
                _cadenciaSelecionada.cadSelecionada = 'Selecionada';
                $('#linha' + pCad._id).removeClass('classeInvisivelCad').addClass('descerLinhaDentro');
                setTimeout(function () { $('#linha' + pCad._id).removeClass('descerLinhaDentro'); }, 500);
                $('#img' + pCad._id).addClass('classeVirarImagem');
                _cadenciaSelecionada.txtMais = 'menos';
                if (_cadenciaSelecionada.controleInfos == 0) {
                    buscarMaisDadosDaCadencia(_cadenciaSelecionada._id);
                }
            }
        };
        function buscarMaisDadosDaCadencia(objectIdDisparo) {
            $http({
                method: "GET",
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/disparos/detalhes/").concat(objectIdDisparo),
                headers: {
                    "ramp3r-auth": "ramp3r-authorization",
                    "ramp3r-l": $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
            }).then(function (detalhesDaCadencia) {
                $scope.listaCadencias.forEach(function (cadencia) {
                    if (cadencia._id == detalhesDaCadencia.data._id) {
                        cadencia.sequencias = detalhesDaCadencia.data.sequencias;
                        cadencia.infoEstatistica = detalhesDaCadencia.data.infoEstatistica;
                        cadencia.controleInfos = 1;
                    }
                });
            });
        }
        $scope.arrayHorarios = [
            { id: 0, value: '00:00', txt: '00' },
            { id: 1, value: '01:00', txt: '01' },
            { id: 2, value: '02:00', txt: '02' },
            { id: 3, value: '03:00', txt: '03' },
            { id: 4, value: '04:00', txt: '04' },
            { id: 5, value: '05:00', txt: '05' },
            { id: 6, value: '06:00', txt: '06' },
            { id: 7, value: '07:00', txt: '07' },
            { id: 8, value: '08:00', txt: '08' },
            { id: 9, value: '09:00', txt: '09' },
            { id: 10, value: '10:00', txt: '10' },
            { id: 11, value: '11:00', txt: '11' },
            { id: 12, value: '12:00', txt: '12' },
            { id: 13, value: '13:00', txt: '13' },
            { id: 14, value: '14:00', txt: '14' },
            { id: 15, value: '15:00', txt: '15' },
            { id: 16, value: '16:00', txt: '16' },
            { id: 17, value: '17:00', txt: '17' },
            { id: 18, value: '18:00', txt: '18' },
            { id: 19, value: '19:00', txt: '19' },
            { id: 20, value: '20:00', txt: '20' },
            { id: 21, value: '21:00', txt: '21' },
            { id: 22, value: '22:00', txt: '22' },
            { id: 23, value: '23:00', txt: '23' }
        ];
        $scope.arrayHorariosFim = [
            { id: 0, value: '00:00', txt: '00' },
            { id: 1, value: '01:00', txt: '01' },
            { id: 2, value: '02:00', txt: '02' },
            { id: 3, value: '03:00', txt: '03' },
            { id: 4, value: '04:00', txt: '04' },
            { id: 5, value: '05:00', txt: '05' },
            { id: 6, value: '06:00', txt: '06' },
            { id: 7, value: '07:00', txt: '07' },
            { id: 8, value: '08:00', txt: '08' },
            { id: 9, value: '09:00', txt: '09' },
            { id: 10, value: '10:00', txt: '10' },
            { id: 11, value: '11:00', txt: '11' },
            { id: 12, value: '12:00', txt: '12' },
            { id: 13, value: '13:00', txt: '13' },
            { id: 14, value: '14:00', txt: '14' },
            { id: 15, value: '15:00', txt: '15' },
            { id: 16, value: '16:00', txt: '16' },
            { id: 17, value: '17:00', txt: '17' },
            { id: 18, value: '18:00', txt: '18' },
            { id: 19, value: '19:00', txt: '19' },
            { id: 20, value: '20:00', txt: '20' },
            { id: 21, value: '21:00', txt: '21' },
            { id: 22, value: '22:00', txt: '22' },
            { id: 23, value: '23:00', txt: '23' }
        ];
        $scope.abrirFiltroUsuarios = function () {
            $scope.filtroUsuariosCadAberto = !$scope.filtroUsuariosCadAberto;
            if (!$scope.listaUsuarios.length) {
                $scope.consultarUsuarios();
            }
            if ($scope.filtroUsuariosCadAberto) {
                $('#idFiltroUsuariosCad').show();
                $('#idListaUsuCad').animate({ scrollTop: 0 }, 10);
                setTimeout(function () { document.getElementById("buscarUsuariosCad").focus(); }, 10);
            }
            else
                $('#idFiltroUsuariosCad').hide();
        };
        $scope.selecionarUsuarioCad = function (pDados) {
            $scope.usuarioFiltroCad = pDados._id;
            $scope.nomeUsuarioFiltroCad = pDados.nome;
            document.getElementById("idUsuariosCadFiltro").innerText = pDados.nome;
            $scope.filtroUsuariosCadAberto = false;
            $('#idFiltroUsuariosCad').hide();
            $scope.consultarSegmentacoesComContatos(false, false);
            $scope.nomeSegmentacaoFiltroSelecionado = '';
            $scope.segmentacaoFiltroSelecionado = null;
            document.getElementById("idSegmentacaoFiltro").innerText = 'Segmentação';
        };
        $(document).mouseup(function (e) {
            var filtroUsuario = $('#idFiltroUsuariosCad');
            var inputFiltroUsuario = $('#buscarUsuariosCad');
            if (!filtroUsuario.is(e.target) && filtroUsuario.has(e.target).length === 0) {
                $scope.filtroUsuariosCadAberto = false;
                filtroUsuario.hide();
            }
            if (inputFiltroUsuario.is(e.target)) {
                $scope.filtroUsuariosCadAberto = true;
            }
        });
        $scope.abrirFiltroStatusCadencias = function () {
            $scope.filtroStatusCadAberto = !$scope.filtroStatusCadAberto;
            if ($scope.filtroStatusCadAberto)
                $('#idFiltroStatusCad').show();
            else
                $('#idFiltroStatusCad').hide();
        };
        $scope.filtrarStatusCad = function (pDados) {
            if (pDados == 'A')
                document.getElementById("idStatusCadFiltro").innerText = 'Ativa';
            else if (pDados == 'F')
                document.getElementById("idStatusCadFiltro").innerText = 'Finalizada';
            else if (pDados == 'P')
                document.getElementById("idStatusCadFiltro").innerText = 'Pausada';
            else
                document.getElementById("idStatusCadFiltro").innerText = 'Todas';
            $scope.abrirFiltroStatusCadencias();
            $scope.statusCadFiltroSelecionado = pDados;
            $scope.nomeStatusCadFiltroSel = document.getElementById("idStatusCadFiltro").innerText;
        };
        $(document).mouseup(function (e) {
            var filtroStatus = $('#idFiltroStatusCad');
            if (!filtroStatus.is(e.target) && filtroStatus.has(e.target).length === 0) {
                $scope.filtroStatusCadAberto = false;
                filtroStatus.hide();
            }
        });
        $scope.abrirFiltroSegmentacao = function () {
            $scope.filtroSegmentacaoAberto = !$scope.filtroSegmentacaoAberto;
            if ($scope.filtroSegmentacaoAberto) {
                $('#idFiltroSegmentacao').show();
                $('#idListaSegmentacao').animate({ scrollTop: 0 }, 10);
                setTimeout(function () { document.getElementById("idInptFiltroSegmentacao").focus(); }, 10);
            }
            else {
                $('#idFiltroSegmentacao').hide();
            }
        };
        $scope.selecionarSegmentacao = function (pDados) {
            if (pDados || pDados != null) {
                $scope.segmentacaoFiltroSelecionado = pDados._id.objectIdSegmentacao;
                document.getElementById("idSegmentacaoFiltro").innerText = pDados._id.nomeSegmentacao;
            }
            else {
                $scope.segmentacaoFiltroSelecionado = null;
                document.getElementById("idSegmentacaoFiltro").innerText = 'Sem segmentação';
            }
            $scope.nomeSegmentacaoFiltroSelecionado = pDados != undefined ? pDados._id.nomeSegmentacao : 'Sem segmentação';
            $scope.abrirFiltroSegmentacao();
        };
        $(document).mouseup(function (e) {
            var filtroCad = $('#idFiltroSegmentacao');
            if (!filtroCad.is(e.target) && filtroCad.has(e.target).length === 0) {
                $scope.filtroSegmentacaoAberto = false;
                filtroCad.hide();
            }
        });
        $scope.montarNomeFiltros = function () {
            var contagem = 0;
            $scope.txtNomeFiltros = '';
            if ($('#sltDataFiltroCadencia')[0].value != '' && $('#sltDataFiltroCadencia')[0].value != null) {
                $scope.txtNomeFiltros = 'Filtrado por ' + $('#sltDataFiltroCadencia')[0].value;
                contagem++;
            }
            if ($scope.nomeUsuarioFiltroCad != '' && $scope.nomeUsuarioFiltroCad != null) {
                contagem == 0 ? $scope.txtNomeFiltros = 'Filtrado por ' + $scope.nomeUsuarioFiltroCad : $scope.txtNomeFiltros += ' > ' + $scope.nomeUsuarioFiltroCad;
                contagem++;
            }
            if (document.getElementById("idStatusCadFiltro").innerText != 'Status') {
                contagem == 0 ? $scope.txtNomeFiltros = 'Filtrado por ' + document.getElementById("idStatusCadFiltro").innerText : $scope.txtNomeFiltros += ' > ' + document.getElementById("idStatusCadFiltro").innerText;
                contagem++;
            }
            if ($scope.nomeSegmentacaoFiltroSelecionado != '' && $scope.nomeSegmentacaoFiltroSelecionado != null) {
                contagem == 0 ? $scope.txtNomeFiltros = 'Filtrado por ' + $scope.nomeSegmentacaoFiltroSelecionado : $scope.txtNomeFiltros += ' > ' + $scope.nomeSegmentacaoFiltroSelecionado;
                contagem++;
            }
        };
        $scope.abrirOrdenar = function () {
            $scope.ordenarCadenciaAberto = !$scope.ordenarCadenciaAberto;
            if ($scope.ordenarCadenciaAberto) {
                $('#idOrdenarCad').show();
            }
            else
                $('#idOrdenarCad').hide();
        };
        var ordemNome = false;
        var ordemData = false;
        $scope.ordenarCadencia = function (pDados, pValue) {
            $scope.trocarOrdem = false;
            var textoOrd = document.getElementById("idTxtOrdenarCad");
            document.getElementById("txtOrdenacaoAlfabeticaCad").innerText = 'A-Z';
            document.getElementById("txtOrdenacaoDataCad").innerText = 'Data de disparo';
            if (pDados == 'n') {
                if (!ordemNome) {
                    $scope.reverse = false;
                    $('#imgOrdenacaoCadencia').attr('src', "".concat($rootScope.bucket, "/imgOrdemCrescente.png"));
                    textoOrd.innerText = 'A-Z';
                    document.getElementById("txtOrdenacaoAlfabeticaCad").innerText = 'Z-A';
                }
                else {
                    $scope.reverse = true;
                    $('#imgOrdenacaoCadencia').attr('src', "".concat($rootScope.bucket, "/imgOrdemDecrescente.png"));
                    textoOrd.innerText = 'Z-A';
                    document.getElementById("txtOrdenacaoAlfabeticaCad").innerText = 'A-Z';
                }
                ordemData = false;
                ordemNome = !ordemNome;
            }
            else if (pDados == 'd') {
                if (!ordemData) {
                    $scope.reverse = false;
                    $('#imgOrdenacaoCadencia').attr('src', "".concat($rootScope.bucket, "/imgOrdemCrescente.png"));
                    textoOrd.innerText = 'Disparos antigos primeiro';
                    document.getElementById("txtOrdenacaoDataCad").innerText = 'Disparos novos';
                }
                else {
                    $scope.reverse = true;
                    $('#imgOrdenacaoCadencia').attr('src', "".concat($rootScope.bucket, "/imgOrdemDecrescente.png"));
                    textoOrd.innerText = 'Disparos novos primeiro';
                    document.getElementById("txtOrdenacaoDataCad").innerText = 'Disparos antigos';
                }
                ordemNome = false;
                ordemData = !ordemData;
            }
            $scope.tituloOrdenacao = textoOrd.innerText;
            $scope.dadosParaOrdenacao = pDados;
            $scope.propertyName = pValue;
            $scope.ordenarCadenciaAberto = false;
            $('#idOrdenarCad').hide();
        };
        $scope.alterarOrdenacaoCadencia = function () {
            $scope.ordenarCadencia($scope.dadosParaOrdenacao, $scope.propertyName);
        };
        $scope.zerarCamposOrdenacao = function () {
            $scope.reverse = false;
            $scope.trocarOrdem = true;
            $scope.tituloOrdenacao = '';
            document.getElementById("idTxtOrdenarCad").innerText = 'Ordenar';
            document.getElementById("txtOrdenacaoAlfabeticaCad").innerText = 'A-Z';
            document.getElementById("txtOrdenacaoDataCad").innerText = 'Data de disparo';
        };
        $scope.selecionarPasta = function (ab, pEdicao) {
            if (pEdicao)
                $scope.loaderCarregarSequencia = true;
            else
                $scope.selecionarTemplateAut = false;
            if ($scope["pastaSelecionada" + ab] || $scope["pastaSelecionada" + ab] != '') {
                $scope.templatesPastaEspecifica = [];
                $http({
                    method: 'GET',
                    url: "".concat($rootScope.ramperV3ApiBaseUrl, "/templates/consultarPastasComTemplates"),
                    headers: {
                        'Authorization': 'Bearer ' + $rootScope.Token
                    },
                    params: {
                        objectIdPastaTemplate: $scope["pastaSelecionada" + ab],
                        ordem: 'templateNome',
                        ordemAscDesc: 1,
                        dataInicio: '',
                        dataFim: ''
                    }
                }).then(function (rConsulta) {
                    rConsulta.data[0].objectIdTemplates.forEach(function (rTemplates) {
                        $scope.templatesPastaEspecifica.push(rTemplates.template);
                    });
                    if ($scope.templatesPastaEspecifica.length == rConsulta.data[0].objectIdTemplates.length && $scope.selecionarTemplateAut) {
                        setTimeout(function () {
                            if (ab == 'A') {
                                for (var i = 0; i < $('#templateSelecionado_A')[0].options.length; i++) {
                                    try {
                                        var objetoTemp = JSON.parse($('#templateSelecionado_A')[0].options[i].value);
                                        if (objetoTemp.objectIdTemplate == $scope.envioBuffer.template) {
                                            $('#templateSelecionado_A')[0].selectedIndex = i;
                                            break;
                                        }
                                    }
                                    catch (e) { }
                                }
                                ;
                            }
                            else {
                                for (var i = 0; i < $('#templateSelecionadoAutomacaoBounce')[0].options.length; i++) {
                                    try {
                                        var objetoTemp = JSON.parse($('#templateSelecionadoAutomacaoBounce')[0].options[i].value);
                                        if (objetoTemp.objectIdTemplate == $scope.templateSelecionadoAutBounce) {
                                            $('#templateSelecionadoAutomacaoBounce')[0].selectedIndex = i;
                                            break;
                                        }
                                    }
                                    catch (e) { }
                                }
                                ;
                            }
                        }, 100);
                        $scope.loaderCarregarSequencia = false;
                    }
                });
            }
            else
                $scope.templatesPastaEspecifica = $scope.templates;
            if (ab == 'A') {
                if ($('#enviarComoSelecionado_A')[0].selectedOptions[0].value == 'E' || $('#enviarComoSelecionado_B')[0].selectedOptions[0].value == 'E')
                    $scope['inptData_' + ab] = $scope['inptData_' + ab];
                else if (!pEdicao) {
                    $scope['inptData_' + ab] = '';
                }
                if (!pEdicao) {
                    tinymce.editors["txtAreaTemplate_" + ab].setContent('');
                }
                $('.bttnNovoTemplateAB').removeClass('corTextoErro');
                $('#templateSelecionado_' + ab).removeClass('corTextoErro');
                $('#inptData_' + ab).removeClass('bordaDiasSemanaErro');
                $('#divInptData_' + ab).removeClass('bordaDiasSemanaErro');
                $('#dvMensagemSalvarTemplate' + ab).fadeOut();
                $('#dvMensagemSelecionarTemplate' + ab).fadeOut();
                $('#dvMensagemAssunto').fadeOut();
                $('#dvMensagemEmail').fadeOut();
            }
            else {
                if (!pEdicao) {
                    tinymce.editors["txtAreaBounce"].setContent('');
                    $scope.assuntoEnvioBounce = '';
                }
                $('#templateSelecionadoAutomacaoBounce').removeClass('corTextoErro');
                $('#spanNovoTemplateAutBounce').removeClass('corTextoErro');
                $('#divTxtAssuntoBounce').removeClass('corTextoErro');
                $('#inptAssuntoBounce').removeClass('divErroAssunto');
                $('#dvMensagemSelecionarTemplateBounce').fadeOut();
                $('#dvMensagemAssuntoBounce').fadeOut();
                $('#dvMensagemEmailBounce').fadeOut();
                $('#dvMensagemSalvarTemplateBounce').fadeOut();
            }
            $('.mce-tinymce').css('border', '1px solid rgba(0,0,0,0.2)');
        };
        $scope.consultarPastaTemplateEspecifico = function () {
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/pastas/consultarPastaTemplateEspecifico"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                params: {
                    objectIdTemplate: $scope.envioBuffer.template
                }
            }).then(function (rConsulta) {
                $scope.pastaSelecionadaA = rConsulta.data._id;
                $scope.selecionarPasta('A', 'edicao');
            });
        };
        $scope.fecharAlertaMensagemConfiguracaoEmail = function () {
            $rootScope.mostrarMensagemErroConfigEmail = false;
            $('.cardInternoNovo').css('height', 'calc(100% - 75px)');
        };
        $scope.abrirFecharFiltroCadAnalytics = function () {
            $scope.filtroCadAnalyticsAberto = !$scope.filtroCadAnalyticsAberto;
            if ($scope.filtroCadAnalyticsAberto) {
                $('#idFiltroCadAnalytics').show();
                setTimeout(function () { document.getElementById("idInptFiltroCadAnalytics").focus(); }, 10);
            }
            else
                $('#idFiltroCadAnalytics').hide();
        };
        $scope.selecionarCadAnalytics = function (pDados) {
            $scope.cadAnalyticsSelecionadoFiltro = pDados._id;
            $('#dataFiltroDefinida').show();
            $scope.nomeFiltroCadAnalytics = pDados.nome;
            $scope.abrirFecharFiltroCadAnalytics();
        };
        $scope.consultarCadenciasAnalytics = function (pDados) {
            $scope.listaCadsAnalytics = [];
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/disparos/consultarCadenciasSimples"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                params: {
                    tipoDaChave: pDados ? "U" : $rootScope.permissoes.tipoUsuario,
                    objectId: pDados ? pDados : ''
                }
            }).then(function (response) {
                $scope.listaCadsAnalytics = response.data;
            });
        };
        function configAddCadenciaEngage() {
            if (($scope.acessoEngage.engageAtivo || PermissionamentoService.allowAccessToScreenAction('cadencias', 'createEngage'))
                && PermissionamentoService.allowAccessToScreenAction('cadencias', 'createReengagement')
                && PermissionamentoService.allowAccessToScreenAction('cadencias', 'createInbound')) {
                setTimeout(function () {
                    $("#dvContainerNovaCadencia").addClass("divEscolherTipoCadenciaComEngage");
                    $("#dvContainerNovaCadencia").removeClass("divEscolherTipoCadencia");
                }, 100);
            }
        }
        $scope.consultarAcessoCadenciaEngage = function () {
            // COMENTADO ATÉ O ENGAGE VOLTAR A SER UTILIZADO!!!
            // $http({
            //     method: 'GET',
            //     url: '/disparos/checkEngageActivation',
            //     headers: {
            //         'ramp3r-auth': 'ramp3r-authorization',
            //         'ramp3r-l': $rootScope.IDCrypto
            //     }
            // }).then(function (response) {
            //     $scope.acessoEngage.engageAtivo = response.data.engageAtivo;
            //     $scope.acessoEngage.cadenciaEngageAtiva = response.data.cadenciaEngageAtiva;
            // });
            $scope.acessoEngage.engageAtivo = false;
            $scope.acessoEngage.cadenciaEngageAtiva = false;
        };
        function mostrarSegmentacoesPermitidas(tipoCadencia, edicaoOuCriacaoCad) {
            $scope.listaSegmentacoesDisp = [];
            if (tipoCadencia === 'Outbound') {
                $scope.listaSegmentacoes.forEach(function (segm) {
                    if (!segm._id.integracaoSite) {
                        $scope.listaSegmentacoesDisp.push(segm);
                    }
                });
            }
            if (tipoCadencia === 'Engage') {
                $scope.listaSegmentacoes.forEach(function (segm) {
                    if (!segm._id.integracaoSite) {
                        return;
                    }
                    if (edicaoOuCriacaoCad === 'edicao' || ($rootScope.permissoes.tipoUsuario === 'A' && $rootScope.Login._id === segm._id.objectIdLogin) || $rootScope.permissoes.tipoUsuario === 'U') {
                        $scope.listaSegmentacoesDisp.push(segm);
                    }
                });
                mixpanel.track("Engage | Selecionou a cadência");
            }
            $scope.listaSegmentacoes = $scope.listaSegmentacoesDisp;
        }
        $('#valueEmails_A').bind('input propertychange', function () {
            var texto = $(this).val();
            texto = texto.replace(/[^\d]/g, '');
            $(this).val(texto);
            document.getElementById("valueEmails_A").classList.remove('corTextoErro', 'bordaEmbaixoTextAreaErro');
            $('#labelErroValueEmail').hide();
        });
        $('#idInptInicioDisparosCadP').bind('input propertychange', function () {
            var texto = $(this).val();
            texto = texto.replace(/[^\d]/g, '');
            $(this).val(texto);
            document.getElementById("idInptInicioDisparosCadP").classList.remove('corTextoErro');
            $scope.txtMsgErroInicioCadP = false;
        });
        $('#idInptDiasRetroativos').bind('input propertychange', function () {
            var texto = $(this).val();
            texto = texto.replace(/[^\d]/g, '');
            $(this).val(texto);
        });
        $scope.criarNovaCadencia = function () {
            $('#formNovaCadencia').fadeIn();
            $('#formNovaCadencia').removeClass('blur');
            $('#dvEtapa1').hide();
            $('#dvEtapa2').hide();
            $('#dvEtapa3').hide();
            $('#dvEtapa4').hide();
            $('#dvEtapa5').hide();
            $scope.nomeNovaCadencia = '';
            $scope.inicioDisparosCadP = 1;
            $scope.escolherTipoCadencia = PermissionamentoService.allowAccessToScreenAction('cadencias', 'createReengagement')
                || PermissionamentoService.allowAccessToScreenAction('cadencias', 'createInbound')
                || PermissionamentoService.allowAccessToScreenAction('cadencias', 'createEngage')
                || $scope.acessoEngage.engageAtivo;
            var existeApenasCadenciaOutboundAtiva = !$scope.escolherTipoCadencia && PermissionamentoService.allowAccessToScreenAction('cadencias', 'createOubound');
            var possuiCadenciaSelecionada = $scope.ultimaNovaCadEscolhida !== null;
            $scope.mostrarCheckConfiabilidade = !possuiCadenciaSelecionada && existeApenasCadenciaOutboundAtiva;
            $scope.mostrarBtnInteresseCrm = (!PermissionamentoService.allowAccessToScreenAction('cadencias', 'createReengagement')) ? true : false;
            $scope.mostrarBtnInteresseInbound = (!PermissionamentoService.allowAccessToScreenAction('cadencias', 'createInbound')) ? true : false;
            if (existeApenasCadenciaOutboundAtiva) {
                $scope.novaCadencia = {
                    nome: '',
                    tipoCadencia: 'Outbound',
                    alteracao: false,
                    segmentacao: {
                        _id: null,
                        alta: false,
                        media: false,
                        baixa: false,
                        sem: false
                    },
                    disparoSequencias: {
                        sequencias: []
                    },
                    automacoes: []
                };
                $scope.abrirFormNovaCadencia(false);
            }
            if ($scope.acessoEngage.engageAtivo || PermissionamentoService.allowAccessToScreenAction('cadencias', 'createEngage')) {
                configAddCadenciaEngage();
            }
        };
        $scope.tipoNovaCadencia = function (pDados) {
            $scope.ultimaNovaCadEscolhida = pDados;
            if (pDados === 'Outbound') {
                $scope.mostrarCheckConfiabilidade = true;
            }
            else {
                $scope.mostrarCheckConfiabilidade = false;
            }
            if (pDados === 'Outbound' || (pDados === 'Reengajamento' && !$scope.mostrarBtnInteresseCrm) || (pDados === 'Prospeccao_ICP') || (pDados === 'Inbound' && !$scope.mostrarBtnInteresseInbound) || (pDados === 'Engage')) {
                $scope.escolherTipoCadencia = false;
                $scope.novaCadencia = {
                    nome: '',
                    tipoCadencia: pDados,
                    alteracao: false,
                    segmentacao: {
                        _id: null,
                        alta: false,
                        media: false,
                        baixa: false,
                        sem: false
                    },
                    disparoSequencias: {
                        sequencias: []
                    },
                    automacoes: []
                };
                $scope.abrirFormNovaCadencia(false);
                mostrarSegmentacoesPermitidas(pDados, 'criacao');
            }
        };
        $scope.abrirDivFunilCadP = function () {
            $scope.divFunilCadP = !$scope.divFunilCadP;
            $scope.cadenciaComMesmoParametro = false;
            if ($scope.divFunilCadP) {
                $('#idFunilCadP').show();
                $('#idListaFunilCadP').animate({ scrollTop: 0 }, 10);
                setTimeout(function () { document.getElementById("idInptBuscarFunilCadP").focus(); }, 10);
            }
            else {
                $('#idFunilCadP').hide();
            }
        };
        $scope.selecionarFunilCadP = function (pDados) {
            pDados.checkSelecionado = !pDados.checkSelecionado;
            var contagem = 0;
            if (pDados || pDados != null) {
                $scope.listaEtapasCadP = [];
                $scope.txtMsgErroFunilCadP = false;
                $scope.corTxtFunilCadP = '#616160';
                $scope.listaFunisCadP.forEach(function (funil) {
                    if (funil.checkSelecionado) {
                        $scope.listaEtapasOriginal.forEach(function (etapa) {
                            if (etapa.pipeline_id === funil.id) {
                                $scope.listaEtapasCadP.push(etapa);
                            }
                        });
                        contagem++;
                    }
                });
                $scope.slcTodasEtapas = false;
                if ($scope.listaEtapasCadP.length === 0) {
                    $scope.listaEtapasCadP = $scope.listaEtapasOriginal;
                }
                ;
                $scope.montarArrayNovo();
                $scope.montarNomesSlcts('funil');
                $scope.montarNomesSlcts('etapa');
                $scope.slcTodosFunis = contagem === $scope.listaFunisCadP.length ? true : false;
            }
            else {
                $scope.funilCadP = null;
            }
            $scope.filtrarIdFunisSelecionados();
        };
        $scope.abrirDivMotivosCadP = function () {
            $scope.divMotivosCadP = !$scope.divMotivosCadP;
            $scope.cadenciaComMesmoParametro = false;
            if ($scope.divMotivosCadP) {
                $('#idMotivosCadP').show();
                $('#idListaMotivosCadP').animate({ scrollTop: 0 }, 10);
                setTimeout(function () { document.getElementById("idInptBuscarMotivosCadP").focus(); }, 10);
            }
            else {
                $('#idMotivosCadP').hide();
            }
        };
        $scope.selecionarMotivosCadP = function (pDados) {
            pDados.checkMotivoSelecionado = !pDados.checkMotivoSelecionado;
            var contagem = 0;
            if (pDados || pDados != null) {
                $scope.txtMsgErroMotivoCadP = false;
                $scope.corTxtMotivoCadP = '#616160';
                $scope.listaMotivosCadP.forEach(function (motivo) {
                    if (motivo.checkMotivoSelecionado) {
                        contagem++;
                    }
                });
                $scope.montarNomesSlcts('motivo');
                $scope.slcTodosMotivos = contagem === $scope.listaMotivosCadP.length ? true : false;
            }
            else {
                $scope.motivosCadP = null;
            }
        };
        $scope.abrirDivEtapasCadP = function () {
            $scope.divEtapasCadP = !$scope.divEtapasCadP;
            $scope.cadenciaComMesmoParametro = false;
            if ($scope.divEtapasCadP) {
                setTimeout(function () {
                    var altura = (divEtapasCadenciaP.height() + 9) * -1;
                    divEtapasCadenciaP.css('margin-top', altura);
                    divEtapasCadenciaP.show();
                    $('#idListaUsuariosCadP').animate({ scrollTop: 0 }, 10);
                    document.getElementById("idInptBuscarEtapasCadP").focus();
                }, 50);
            }
            else {
                $('#idEtapasCadP').hide();
            }
        };
        $scope.selecionarEtapasCadP = function (pDados) {
            pDados.checkEtapaSelecionado = !pDados.checkEtapaSelecionado;
            var contagem = 0;
            if (pDados || pDados != null) {
                $scope.txtMsgErroEtapaCadP = false;
                $scope.corTxtEtapaCadP = '#616160';
                $scope.listaEtapasCadP.forEach(function (etapa) {
                    if (etapa.checkEtapaSelecionado) {
                        contagem++;
                    }
                });
                $scope.montarNomesSlcts('etapa');
                $scope.slcTodasEtapas = contagem === $scope.listaEtapasCadP.length ? true : false;
            }
            else {
                $scope.etapasCadP = null;
            }
        };
        $scope.abrirDivUsuariosCadP = function () {
            $scope.divUsuariosCadP = !$scope.divUsuariosCadP;
            $scope.cadenciaComMesmoParametro = false;
            if ($scope.divUsuariosCadP) {
                setTimeout(function () {
                    var altura = (divUsuariosCadenciaP.height() + 9) * -1;
                    divUsuariosCadenciaP.css('margin-top', altura);
                    divUsuariosCadenciaP.show();
                    $('#idListaUsuariosCadP').animate({ scrollTop: 0 }, 10);
                    document.getElementById("idInptBuscarUsuariosCadP").focus();
                }, 50);
            }
            else {
                $('#idUsuariosCadP').hide();
            }
        };
        $scope.selecionarUsuariosCadP = function (pDados) {
            pDados.checkUsuarioSelecionado = !pDados.checkUsuarioSelecionado;
            var contagem = 0;
            if (pDados || pDados != null) {
                $scope.txtMsgErroUsuarioCadP = false;
                $scope.corTxtUsuarioCadP = '#616160';
                $scope.listaUsuariosCadP.forEach(function (usuario) {
                    if (usuario.checkUsuarioSelecionado) {
                        contagem++;
                    }
                });
                $scope.montarNomesSlcts('usuario');
                $scope.slcTodosUsuarios = contagem === $scope.listaUsuariosCadP.length ? true : false;
            }
            else {
                $scope.usuariosCadP = null;
            }
        };
        $scope.consultarListaFunis = function () {
            var contatoSelecionado = '';
            $scope.listaFunisCadP = [];
            try {
                contatoSelecionado = $scope.usuarioFiltroCad;
            }
            catch (e) {
                contatoSelecionado = '';
            }
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/segmentacoes/consultarSegmentacaoAgrupandoContatos"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                params: {
                    tipoDaChave: $rootScope.permissoes.tipoUsuario,
                    objectId: contatoSelecionado
                }
            }).then(function (rConsultaSegmentacoes) {
                rConsultaSegmentacoes.data.forEach(function (funil) {
                    funil.checkSelecionado = false;
                    funil.checkMotivoSelecionado = false;
                    funil.checkEtapaSelecionado = false;
                    funil.checkUsuarioSelecionado = false;
                });
            });
        };
        $scope.selecionarTodosChecks = function (pDados) {
            if (pDados === 'funil') {
                $scope.slcTodosFunis = !$scope.slcTodosFunis;
                for (var i = 0; i < $scope.listaFunisCadP.length; i++) {
                    $scope.listaFunisCadP[i].checkSelecionado = $scope.slcTodosFunis;
                }
                if ($scope.slcTodosFunis) {
                    $scope.txtMsgErroFunilCadP = false;
                    $scope.corTxtFunilCadP = '#616160';
                    $scope.montarNomesSlcts(pDados);
                }
                else {
                    $scope.funilCadP = null;
                }
                $scope.listaEtapasCadP = $scope.listaEtapasOriginal;
                $scope.montarArrayNovo();
                $scope.filtrarIdFunisSelecionados();
            }
            else if (pDados === 'motivo') {
                $scope.slcTodosMotivos = !$scope.slcTodosMotivos;
                for (var i = 0; i < $scope.listaMotivosCadP.length; i++) {
                    $scope.listaMotivosCadP[i].checkMotivoSelecionado = $scope.slcTodosMotivos;
                }
                if ($scope.slcTodosMotivos) {
                    $scope.txtMsgErroMotivoCadP = false;
                    $scope.corTxtMotivoCadP = '#616160';
                    $scope.montarNomesSlcts(pDados);
                }
                else {
                    $scope.motivosCadP = null;
                }
            }
            else if (pDados === 'etapa') {
                $scope.slcTodasEtapas = !$scope.slcTodasEtapas;
                for (var i = 0; i < $scope.listaEtapasCadP.length; i++) {
                    $scope.listaEtapasCadP[i].checkEtapaSelecionado = $scope.slcTodasEtapas;
                }
                if ($scope.slcTodasEtapas) {
                    $scope.txtMsgErroEtapaCadP = false;
                    $scope.corTxtEtapaCadP = '#616160';
                    $scope.montarNomesSlcts(pDados);
                }
                else {
                    $scope.etapasCadP = null;
                }
            }
            else if (pDados === 'usuario') {
                $scope.slcTodosUsuarios = !$scope.slcTodosUsuarios;
                for (var i = 0; i < $scope.listaUsuariosCadP.length; i++) {
                    $scope.listaUsuariosCadP[i].checkUsuarioSelecionado = $scope.slcTodosUsuarios;
                }
                if ($scope.slcTodosUsuarios) {
                    $scope.txtMsgErroUsuarioCadP = false;
                    $scope.corTxtUsuarioCadP = '#616160';
                    $scope.montarNomesSlcts(pDados);
                }
                else {
                    $scope.usuariosCadP = null;
                }
            }
        };
        $scope.montarNomesSlcts = function (pDados) {
            var primeiroTrue = '';
            if (pDados === 'funil') {
                $scope.funilCadP = 'Funis';
                primeiroTrue = $scope.listaFunisCadP.find(function (element) { return element.checkSelecionado; });
                for (var i = 0; i < $scope.listaFunisCadP.length; i++) {
                    if ($scope.listaFunisCadP[i].checkSelecionado) {
                        $scope.listaFunisCadP[i].name === primeiroTrue.name ? $scope.funilCadP = $scope.listaFunisCadP[i].name : $scope.funilCadP += (' + ' + $scope.listaFunisCadP[i].name);
                    }
                }
            }
            else if (pDados === 'motivo') {
                $scope.motivosCadP = 'Motivos';
                primeiroTrue = $scope.listaMotivosCadP.find(function (element) { return element.checkMotivoSelecionado; });
                for (var i = 0; i < $scope.listaMotivosCadP.length; i++) {
                    if ($scope.listaMotivosCadP[i].checkMotivoSelecionado) {
                        $scope.listaMotivosCadP[i].label === primeiroTrue.label ? $scope.motivosCadP = $scope.listaMotivosCadP[i].label : $scope.motivosCadP += (' + ' + $scope.listaMotivosCadP[i].label);
                    }
                }
            }
            else if (pDados === 'etapa') {
                $scope.etapasCadP = 'Etapas';
                primeiroTrue = $scope.listaEtapasCadP.find(function (element) { return element.checkEtapaSelecionado; });
                for (var i = 0; i < $scope.listaEtapasCadP.length; i++) {
                    if ($scope.listaEtapasCadP[i].checkEtapaSelecionado) {
                        $scope.listaEtapasCadP[i].name === primeiroTrue.name ? $scope.etapasCadP = $scope.listaEtapasCadP[i].name : $scope.etapasCadP += (' + ' + $scope.listaEtapasCadP[i].name);
                    }
                }
            }
            else if (pDados === 'usuario') {
                $scope.usuariosCadP = 'Usuários';
                primeiroTrue = $scope.listaUsuariosCadP.find(function (element) { return element.checkUsuarioSelecionado; });
                for (var i = 0; i < $scope.listaUsuariosCadP.length; i++) {
                    if ($scope.listaUsuariosCadP[i].checkUsuarioSelecionado) {
                        $scope.listaUsuariosCadP[i].name === primeiroTrue.name ? $scope.usuariosCadP = $scope.listaUsuariosCadP[i].name : $scope.usuariosCadP += (' + ' + $scope.listaUsuariosCadP[i].name);
                    }
                }
            }
        };
        $scope.zerarSelectsCadenciaReengajamento = function () {
            $scope.funilCadP = 'Funis';
            $scope.slcTodosFunis = false;
            for (var i = 0; i < $scope.listaFunisCadP.length; i++) {
                $scope.listaFunisCadP[i].checkSelecionado = false;
            }
            $scope.motivosCadP = 'Motivos';
            $scope.slcTodosMotivos = false;
            for (var i = 0; i < $scope.listaMotivosCadP.length; i++) {
                $scope.listaMotivosCadP[i].checkMotivoSelecionado = false;
            }
            $scope.etapasCadP = 'Etapas';
            $scope.slcTodasEtapas = false;
            $scope.listaEtapasCadP = $scope.listaEtapasOriginal;
            for (var i = 0; i < $scope.listaEtapasCadP.length; i++) {
                $scope.listaEtapasCadP[i].checkEtapaSelecionado = false;
            }
            $scope.usuariosCadP = 'Usuários';
            $scope.slcTodosUsuarios = false;
            for (var i = 0; i < $scope.listaUsuariosCadP.length; i++) {
                $scope.listaUsuariosCadP[i].checkUsuarioSelecionado = false;
            }
        };
        $scope.copiarLinkWebhook = function (pId) {
            if (pId) {
                document.getElementById('idInptLinkWebhook' + pId).select();
                document.execCommand('copy');
                $('#link' + pId).fadeIn();
                setTimeout(function () { $('#link' + pId).fadeOut(); }, 1000);
            }
            else {
                document.getElementById('idInptWebhook').select();
                document.execCommand('copy');
                $('#linkGlobalWebhook').fadeIn();
                setTimeout(function () { $('#linkGlobalWebhook').fadeOut(); }, 1000);
            }
        };
        $scope.montarArrayNovo = function () {
            var arrNomeUnico = [];
            $scope.listaEtapasCadP.forEach(function (etapa) {
                arrNomeUnico.push(etapa.pipeline_name);
            });
            var novaArray = arrNomeUnico.filter(function (este, i) {
                return arrNomeUnico.indexOf(este) == i;
            });
            $scope.listaFunilUnico = novaArray;
        };
        $scope.filtrarIdFunisSelecionados = function () {
            $scope.funisSelecionados = $scope.listaFunisCadP
                .filter(function (funil) {
                return true && funil.checkSelecionado;
            })
                .map(function (funil) {
                return funil.id;
            });
            $scope.filtrarEtapasDeFunisSelecionado();
        };
        $scope.filtrarEtapasDeFunisSelecionado = function () {
            $scope.listaEtapasCadP.forEach(function (etapa) {
                var matchEtapaFunil = $scope.funisSelecionados.some(function (funil) {
                    return funil === etapa.pipeline_id;
                });
                if (etapa.checkEtapaSelecionado && matchEtapaFunil) {
                    etapa.checkEtapaSelecionado = false;
                }
                else {
                    etapa.checkEtapaSelecionado = true;
                }
                $scope.selecionarEtapasCadP(etapa);
            });
        };
        var getContatosRetroativos = function (cadencia) {
            if ($scope.reengajamento.checkDiasRetroativos) {
                var dadosRetroativo = {
                    idCadencia: cadencia.data.id,
                    teste: $scope.integracaoReengajamento[0],
                    apiKey: $scope.integracaoReengajamento[0].apiKey,
                    diasEmLost: $scope.reengajamento.diasRetroativos
                };
                $http({
                    method: 'POST',
                    url: "".concat($rootScope.ramperV3ApiBaseUrl, "/reengajamento/pipedrive/retroativo"),
                    headers: {
                        'ramp3r-auth': 'ramp3r-authorization',
                        'ramp3r-l': $rootScope.IDCrypto,
                    },
                    data: JSON.stringify(dadosRetroativo)
                }).then(function (rRetroativo) { });
            }
        };
        $scope.atualizarDataDiasRetroativos = function () {
            $scope.dataContatosRetroativos = new Date();
            $scope.dataContatosRetroativos.setDate($scope.dataContatosRetroativos.getDate() - $scope.reengajamento.diasRetroativos);
            document.getElementById("idInptDiasRetroativos").classList.remove('corTextoErro');
            $scope.txtMsgErroDiasRetroativos = false;
        };
        $scope.inserirLinkChat = function (chatChecked) {
            var tempChatA = $('#templateSelecionado_A')[0].selectedOptions[0].value;
            var tempChatB = $('#templateSelecionado_B')[0].selectedOptions[0].value;
            var tempChat = JSON.parse((tempChatA.length == 0 ? tempChatB : tempChatA) || null);
            if (chatChecked) {
                if (tempChat) {
                    mixpanel.track("Janela de conversação  | Inseriu link no template via cadencia", {
                        "ID pasta": $scope.pastaSelecionadaA,
                        "ID template": tempChat._id,
                        "Nome template": tempChat.templateNome,
                        "Local_IP": $rootScope.ip
                    });
                }
                else {
                    mixpanel.track("Janela de conversação  | Inseriu link no template via cadencia (criou template na cadência)", {
                        "Local_IP": $rootScope.ip
                    });
                }
                if (!$scope.msgChat.texto) {
                    $scope.msgChat.texto = 'Este é um link para falar direto comigo via Chat';
                }
                var newNode = tinymce.activeEditor.getDoc().createElement('a');
                newNode.setAttribute('id', 'msgChat');
                newNode.href = '\//*chat*/';
                var range = tinymce.activeEditor.selection.getRng();
                range.insertNode(newNode);
                $scope.inserirMsgChat($scope.msgChat.chatChecked, $scope.msgChat.texto);
            }
            else {
                var child = tinymce.activeEditor.getDoc().getElementById('msgChat');
                if (child) {
                    child.parentNode.removeChild(child);
                }
            }
        };
        $scope.inserirMsgChat = function (chatChecked, texto) {
            if (chatChecked) {
                var child = tinymce.activeEditor.getDoc().getElementById('msgChat');
                if (child === null) {
                    $scope.inserirLinkChat(true);
                    child = tinymce.activeEditor.getDoc().getElementById('msgChat');
                }
                child.textContent = texto;
                $scope.msgChat.texto = texto;
            }
        };
        $scope.getInfoChat = function () {
            var chatA = tinymce.editors["txtAreaTemplate_A"].getDoc().getElementById('msgChat');
            var chatB = tinymce.editors["txtAreaTemplate_B"].getDoc().getElementById('msgChat');
            var chat = chatA == null ? chatB : chatA;
            if (chat) {
                $scope.msgChat.chatChecked = true;
                $scope.msgChat.texto = chat.textContent;
            }
            else {
                $scope.msgChat.chatChecked = false;
                $scope.msgChat.texto = "";
            }
        };
        $scope.inserirLinkAgenda = function (agendaChecked) {
            var tempAgendaA = $('#templateSelecionado_A')[0].selectedOptions[0].value;
            var tempAgendaB = $('#templateSelecionado_B')[0].selectedOptions[0].value;
            var tempAgenda = JSON.parse((tempAgendaA.length == 0 ? tempAgendaB : tempAgendaA) || null);
            if (agendaChecked) {
                if (tempAgenda) {
                    mixpanel.track("Agenda Inteligente | Inseriu link da agenda no template via cadencia", {
                        "ID pasta": $scope.pastaSelecionadaA,
                        "ID template": tempAgenda._id,
                        "Nome template": tempAgenda.templateNome,
                        "Local_IP": $rootScope.ip
                    });
                }
                else {
                    mixpanel.track("Agenda Inteligente | Inseriu link da agenda no template via cadencia (criou template na cadência)", {
                        "Local_IP": $rootScope.ip
                    });
                }
                if (!$scope.msgAgenda.texto) {
                    $scope.msgAgenda.texto = 'Este é o link da minha agenda';
                }
                var newNode = tinymce.activeEditor.getDoc().createElement('a');
                newNode.setAttribute('id', 'msgAgenda');
                newNode.href = '\//*agenda*/';
                var range = tinymce.activeEditor.selection.getRng();
                range.insertNode(newNode);
                $scope.inserirMsgAgenda(agendaChecked, $scope.msgAgenda.texto);
            }
            else {
                var child = tinymce.activeEditor.getDoc().getElementById('msgAgenda');
                if (child)
                    child.parentNode.removeChild(child);
            }
        };
        $scope.inserirMsgAgenda = function (agendaChecked, texto) {
            if (agendaChecked) {
                var child = tinymce.activeEditor.getDoc().getElementById('msgAgenda');
                if (child === null) {
                    $scope.inserirLinkAgenda(true);
                    child = tinymce.activeEditor.getDoc().getElementById('msgAgenda');
                }
                child.textContent = texto;
                $scope.msgAgenda.texto = texto;
            }
        };
        $scope.getInfoAgenda = function () {
            var agendaA = tinymce.editors["txtAreaTemplate_A"].getDoc().getElementById('msgAgenda');
            var agendaB = tinymce.editors["txtAreaTemplate_B"].getDoc().getElementById('msgAgenda');
            var agenda = agendaA == null ? agendaB : agendaA;
            if (agenda) {
                $scope.msgAgenda.agendaChecked = true;
                $scope.msgAgenda.texto = agenda.textContent;
            }
            else {
                $scope.msgAgenda.agendaChecked = false;
                $scope.msgAgenda.texto = "";
            }
        };
        $scope.consultarCapturasHoje = function () {
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/login/consultarContatosMixpanel"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                }
            }).then(function (rConsultarContatosMixpanel) {
                if (rConsultarContatosMixpanel.data) {
                    rConsultarContatosMixpanel.data = false;
                    mixpanel.track("Captura Extensão | O usuario capturou contatos hoje", {});
                }
            });
        };
        $scope.usuarioPodeAdicionarCadencia = function () {
            var temPermissaoVisao = $rootScope.permissoes.tipoUsuario == "V";
            var temAcessoParaCriar = PermissionamentoService.allowAccessToScreenAction('cadencias', 'createReengagement')
                || PermissionamentoService.allowAccessToScreenAction('cadencias', 'createInbound')
                || PermissionamentoService.allowAccessToScreenAction('cadencias', 'createOubound')
                || PermissionamentoService.allowAccessToScreenAction('cadencias', 'createEngage');
            return (!temPermissaoVisao && temAcessoParaCriar);
        };
        $scope.subMenuEstaDisponivel = function () {
            var temPermissaoVisao = $rootScope.permissoes.tipoUsuario == "V";
            var possuiPermissaoParaAcionar = PermissionamentoService.allowAccessToScreenAction('cadencias', 'delete')
                || PermissionamentoService.allowAccessToScreenAction('cadencias', 'edit')
                || PermissionamentoService.allowAccessToScreenAction('cadencias', 'pause')
                || PermissionamentoService.allowAccessToScreenAction('cadencias', 'finish');
            return (!temPermissaoVisao && possuiPermissaoParaAcionar);
        };
        $scope.consultarAcessoCadenciaEngage();
        $scope.consultarCapturasHoje();
    }]);
