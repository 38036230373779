angular.module("ramperv3").controller("MonsterAlterarMinhaSenhaController", [
    "$rootScope",
    "$scope",
    "$location",
    "MonsterToasterService",
    "MonsterAlterarMinhaSenhaService",
    function ($rootScope, $scope, $location, MonsterToasterService, MonsterAlterarMinhaSenhaService) {
        /**
         * Google Analytics
         */
        ga("set", "page", document.location.hash);
        ga("send", "pageview");
        /**
         * Estado inicial da página $rootScope
         */
        $rootScope.monsterShowLoading = true;
        setTimeout(function () {
            $rootScope.monsterShowLoading = false;
            $rootScope.$apply();
        }, 1000);
        /**
         * Estado inicial da página $scope
         */
        $scope.user = { senha: "", senhaConfirmacao: "" };
        /**
         * Deve abrir a tela 'login'
         */
        function redirecionarLogin() {
            $location.path("/v2/login");
        }
        /**
         * Deve consumir o serviço para realizar o login na aplicação
         */
        $scope.onAlterarSenha = function () {
            $rootScope.monsterShowLoading = true;
            MonsterAlterarMinhaSenhaService.alterarMinhaSenha($scope.user).then(function () {
                redirecionarLogin();
                $rootScope.monsterShowLoading = false;
            }, function () {
                MonsterToasterService.showErrorToaster("", "Desculpe, ocorreu um erro ao alterar a senha. Tente novamente mais tarde.", 6000);
                $rootScope.monsterShowLoading = false;
            });
        };
        /**
         * Evento disparado quando o controlador for destruído
         */
        $scope.$on("$destroy", function () {
            $rootScope.monsterShowLoading = false;
            MonsterToasterService.removeToaster();
        });
    },
]);
