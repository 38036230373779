angular.module("ramperv3").controller("MonsterLoginController", [
    "$rootScope",
    "$scope",
    "$window",
    "$location",
    "MonsterLoginService",
    "MonsterToasterService",
    function ($rootScope, $scope, $window, $location, MonsterLoginService, MonsterToasterService) {
        /**
         * Estado inicial da página $rootScope
         */
        $rootScope.monsterShowLoading = true;
        setTimeout(function () {
            $rootScope.monsterShowLoading = false;
            $rootScope.$apply();
        }, 1000);
        /**
         * Estado inicial da página $scope
         */
        $scope.user = { email: "", senha: "", lembrarDeMim: false };
        $scope.userRamper = { email: "", senha: "", lembrarDeMim: false };
        $scope.validarAcessoSenhaRamper = false;
        /**
         *  Deve redirecionar para a tela home/login do produtos
         */
        $scope.onRedirecionarProduto = function (productName) {
            if (productName === "pipeline") {
                $window.location.href = "https://lscrm.com.br/";
            }
            if (productName === "marketing") {
                $window.location.href = "https://app.lahar.com.br/dashboard";
            }
        };
        /**
         * Deve abrir a tela 'esqueci minha senha'
         */
        $scope.onRedirecionarRecuperarSenha = function () {
            $location.path("/v2/esqueci-minha-senha");
        };
        /**
         * Deve voltar para o formulário de login principal
         */
        $scope.voltar = function () {
            $scope.validarAcessoSenhaRamper = false;
        };
        /**
         * Deve consumir o serviço para checar a permissão de acesso com senha Ramper
         */
        $scope.onObterPermissaoAcessoSenhaRamper = function () {
            $rootScope.monsterShowLoading = true;
            MonsterLoginService.obterPermissaoAcessoSenhaRamper($scope.userRamper).then(function () { }, function (response) { return callbackError(response); });
        };
        /**
         * Deve consumir o serviço para realizar o login na aplicação
         */
        $scope.onLogin = function () {
            $rootScope.monsterShowLoading = true;
            MonsterLoginService.login($scope.user).then(function (response) {
                var login = response.data.login;
                if (login.logaPorSso === true) {
                    $rootScope.monsterShowLoading = false;
                    MonsterToasterService.showErrorToaster("", "Para acessar sua conta, \u00E9 necess\u00E1rio utilizar a autentica\u00E7\u00E3o por Single Sign-On (SSO).", 6000);
                    return;
                }
                if (login.sramper === true) {
                    $rootScope.monsterShowLoading = false;
                    $scope.validarAcessoSenhaRamper = true;
                    return;
                }
            }, function (response) { return callbackError(response); });
        };
        /**
         * Callback de error ao realizar o login
         * @param {*} response dados da callback de erro
         */
        function callbackError(response) {
            $rootScope.monsterShowLoading = false;
            if (response.data.contaBloqueada == true) {
                MonsterToasterService.showErrorToaster("Conta bloqueada", "Sua conta foi bloqueada por medida de seguran\u00E7a. [Clique aqui](_self)(#/v2/esqueci-minha-senha) para redefinir uma nova senha.", 6000);
                return;
            }
            if (response.data.tentativasLogin >= 3) {
                MonsterToasterService.showWarningToaster("E-mail ou senha incorretos", "Por medida de seguran\u00E7a, sua conta ser\u00E1 bloqueada na pr\u00F3xima tentativa.", 6000);
                return;
            }
            MonsterToasterService.showWarningToaster("", "E-mail ou senha incorretos. Tente novamente", 6000);
        }
        /**
         * Evento disparado quando o controlador for destruído
         */
        $scope.$on("$destroy", function () {
            $rootScope.monsterShowLoading = false;
            MonsterToasterService.removeToaster();
        });
    },
]);
