angular.module("ramperv3").service("MonsterRedefinirSenhaService", [
    "$http",
    "$rootScope",
    function ($http, $rootScope) {
        /**
         * Deve consumir a api para realizar a alteração das credenciais do usuário
         * @param {senha, novaSenha, senhaConfirmacao } user credenciais do usuário
         */
        function redefinirSenha(user) {
            var headers = {
                "ramp3r-auth": "ramp3r-authorization",
                "ramp3r-l": $rootScope.IDCrypto,
                'Authorization': 'Bearer ' + $rootScope.Token
            };
            var data = {
                senha: user.novaSenha,
                senhaAntiga: user.senha
            };
            return $http({
                method: "POST",
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/login/alterarMinhaSenha"),
                headers: headers,
                data: data,
            });
        }
        return { redefinirSenha: redefinirSenha };
    },
]);
