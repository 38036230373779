angular.module('ramperv3')
    .controller('MetricasController', ['$rootScope', '$scope', '$http', '$locale', function ($rootScope, $scope, $http, $locale) {
        ga('set', 'page', document.location.hash);
        ga('send', 'pageview');
        var _objeto;
        var _estatisticas;
        var _filtros;
        $locale.NUMBER_FORMATS.GROUP_SEP = '.';
        var _pagina = -11;
        var _campoOrdem = 'nomeContato';
        var _ordem = 1;
        var _limite = 10;
        var endDate = new Date();
        var startDate = new Date();
        startDate.setDate(startDate.getDate() - 7);
        $scope.geoDescricao = 'Quantidade de aberturas por região';
        $scope.geoDescricaoSub = 'Localização dos e-mails abertos';
        $('#divEmptyGraficoAgrupado').hide();
        $('#idFundoPopupMetricas').hide();
        $rootScope.popupInfo = false;
        $rootScope.exibirGraficoMetricasContatos = true;
        $rootScope.exibirGraficoMetricasEmails = false;
        $rootScope.popupContatos = false;
        $scope.StatusFiltro = '1';
        $rootScope.mostrarSetaEsquerda = true;
        $rootScope.mostrarSetaDireita = true;
        $rootScope.semMetricas = false;
        $scope.$on('getMetricas', function (event, args) {
            $rootScope.popupInfo = false;
            _objeto = args.objeto;
            _filtros = {
                tipoDaChave: args.tela,
                objectId: args.objectId
            };
            $scope.mudarVisualizacaoMetricas('m');
            $scope.mudarGrafico('h');
            $scope.consultarEstatisticas(_filtros);
        });
        $scope.consultarEstatisticas = function (pTipo) {
            $scope.percentualEntregues = 0;
            $scope.percentualAbertos = 0;
            $scope.percentualSoftBounces = 0;
            $scope.percentualHardBounces = 0;
            $scope.percentualInteresses = 0;
            $scope.percentualNeutros = 0;
            $scope.percentualDesinteresses = 0;
            $scope.percentualRespondidos = 0;
            $scope.percentualFila = 0;
            $scope.percentualAbordados = 0;
            $scope.quantidadeEnvios = 0;
            $scope.quantidadeEntregues = 0;
            $scope.quantidadeAbertos = 0;
            $scope.quantidadeSoftBounces = 0;
            $scope.quantidadeHardBounces = 0;
            $scope.quantidadeInteresses = 0;
            $scope.quantidadeNeutros = 0;
            $scope.percentualDesinteresses = 0;
            $scope.quantidadeRespondidos = 0;
            $scope.quantidadeFila = 0;
            $scope.quantidadeAbordados = 0;
            $scope.quantidadeCliques = 0;
            $scope.quantidadeContatos = 0;
            $rootScope.loaderAtivo = true;
            $scope.mostrarEmptyStatesBuscaVazia = true;
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/time/consultarEstatisticas/"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                params: {
                    tipoDaChave: pTipo.tipoDaChave,
                    objectId: pTipo.objectId,
                    dataInicial: pTipo.dataInicioFiltro ? new Date(pTipo.dataInicioFiltro) : null,
                    dataFinal: pTipo.dataFinalFiltro ? new Date(pTipo.dataFinalFiltro) : null
                }
            }).then(function (response) {
                var _estatisticasShow = response.data[0];
                if (_estatisticasShow) {
                    $scope.mostrarEmptyStatesBuscaVazia = false;
                    $scope.quantidadeContatos = _estatisticasShow._id.contatos ? _estatisticasShow._id.contatos : _estatisticasShow.quantidadeEnvios;
                    $scope.percentualEntregues = Math.round(_estatisticasShow.percentualEntregues);
                    $scope.percentualAbertos = Math.round(_estatisticasShow.percentualAbertos);
                    $scope.percentualSoftBounces = Math.round(_estatisticasShow.percentualSoftBounces);
                    $scope.percentualHardBounces = Math.round(_estatisticasShow.percentualHardBounces);
                    $scope.percentualInteresses = Math.round(_estatisticasShow.percentualInteresses);
                    $scope.percentualNeutros = Math.round(_estatisticasShow.percentualNeutros);
                    $scope.percentualDesinteresses = Math.round(_estatisticasShow.percentualDesinteresses);
                    $scope.percentualRespondidos = Math.round(_estatisticasShow.percentualRespondidos);
                    $scope.quantidadeEnvios = _estatisticasShow.quantidadeEntregues + _estatisticasShow.quantidadeBounces;
                    $scope.quantidadeEntregues = _estatisticasShow.quantidadeEntregues;
                    $scope.quantidadeAbertos = _estatisticasShow.quantidadeAbertos;
                    $scope.quantidadeSoftBounces = _estatisticasShow.quantidadeSoftBounces;
                    $scope.quantidadeHardBounces = _estatisticasShow.quantidadeHardBounces;
                    $scope.quantidadeInteresses = _estatisticasShow.quantidadeInteresses;
                    $scope.quantidadeNeutros = _estatisticasShow.quantidadeNeutros;
                    $scope.quantidadeRespondidos = _estatisticasShow.quantidadeRespondidos;
                    if ($scope.quantidadeEnvios > 0) {
                        $scope.percentualTotalEntregues = Math.round((_estatisticasShow.quantidadeEntregues * 100) / $scope.quantidadeEnvios);
                        $scope.percentualTotalBounces = Math.round((_estatisticasShow.quantidadeSoftBounces + _estatisticasShow.quantidadeHardBounces) * 100 / $scope.quantidadeEnvios);
                    }
                    else {
                        $scope.percentualTotalEntregues = 0;
                        $scope.percentualTotalBounces = 0;
                    }
                    $http({
                        method: 'GET',
                        url: "".concat($rootScope.ramperV3ApiBaseUrl, "/disparos/consultarTotalCliquesMetrica/"),
                        headers: {
                            'ramp3r-auth': 'ramp3r-authorization',
                            'ramp3r-l': $rootScope.IDCrypto,
                            'Authorization': 'Bearer ' + $rootScope.Token
                        },
                        params: {
                            objectId: pTipo.objectId
                        }
                    }).then(function (response) {
                        $scope.quantidadeCliques == response.data || response.data > 0 ? $scope.quantidadeCliques = response.data : $scope.quantidadeCliques = 0;
                    });
                }
                $rootScope.loaderAtivo = false;
            });
        };
        $scope.indicadoresPerc = true;
        $scope.mudarVisualizacaoMetricas = function (pTipo) {
            $('#dvMetricasPrincipal').hide();
            $('#dvGraficosPrincipal').hide();
            $('#dvGeoPrincipal').hide();
            $('#divEmptyGraficoAgrupado').hide();
            if (pTipo == 'm') {
                $('#dvMetricasPrincipal').fadeIn();
                $('#btnMeMudarAberturasGeo').addClass('btnMetricasAberturasAtivo');
            }
            else if (pTipo == 'g') {
                $rootScope.loaderAtivo = true;
                $scope.graficos(_filtros);
                $scope.mudarGrafico('h');
                $scope.removerCoresBtns();
                $('#dvGraficosPrincipal').fadeIn();
                $('#label1').addClass('active');
                $('#label2').removeClass('active');
                $('#label3').removeClass('active');
                $('#btnMeMudarEntregues').addClass('btnMetricasEntreguesAtivo');
                $('#btnMeMudarAberturasGeo').addClass('btnMetricasAberturasAtivo');
                $rootScope.loaderAtivo = false;
            }
            else {
                $rootScope.loaderAtivo = true;
                $scope.graficos(_filtros);
                $scope.mudarGrafico('h');
                $('#dvGeoPrincipal').fadeIn();
                $('#btnMeMudarAberturasGeo').addClass('btnMetricasAberturasAtivo');
                $rootScope.loaderAtivo = false;
            }
        };
        $scope.mudarGrafico = function (pTipo) {
            $('#dvGraficoHistorico').hide();
            $('#dvGraficoAgrupado').hide();
            $('#dvGraficoCruzado').hide();
            if (pTipo == 'h') {
                $scope.graficoHistoricoHabilitado = true;
                $scope.tipoGrafico = 'h';
                $('#dvGraficoHistorico').fadeIn();
            }
            else if (pTipo == 'a') {
                $scope.graficoHistoricoHabilitado = false;
                $scope.tipoGrafico = 'a';
                $('#dvGraficoAgrupado').fadeIn();
            }
            else {
                $scope.graficoHistoricoHabilitado = false;
                $scope.tipoGrafico = 'c';
                $('#dvGraficoCruzado').fadeIn();
            }
        };
        $scope.graficos = function () {
            $rootScope.loaderAtivo = true;
            var parametros = {};
            parametros = {
                objectId: _filtros.objectId,
                tipoDaChave: _filtros.tipoDaChave,
                dataInicial: new Date('01-01-2018'),
                dataFinal: new Date(),
                sort: "_id"
            };
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/inicio/popularTela/"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                params: parametros
            }).then(function (response) {
                $rootScope.loaderAtivo = false;
                if (response.data.consultarEstatisticasHistorico.length > 0) {
                    $scope.montarChartHistorico(response.data.consultarEstatisticasHistorico);
                }
                if (response.data.consultarEstatisticasAgrupadoSemana.length > 0) {
                    dadosGraficoAgrupadoDiaSemana = response.data.consultarEstatisticasAgrupadoSemana;
                    $scope.montarChartAgrupado("W");
                }
                if (response.data.consultarEstatisticasAgrupadoHora.length > 0) {
                    dadosGraficoAgrupadoHora = response.data.consultarEstatisticasAgrupadoHora;
                    $scope.montarChartAgrupado("H");
                }
                if (response.data.consultarEstatisticasCruzado.length > 0) {
                    dadosGraficoCruzado = response.data.consultarEstatisticasCruzado;
                    $scope.montarChartCruzado();
                }
                if (response.data.consultarGeoLocalizacao.length > 0) {
                    dadosGraficoGeo = response.data.consultarGeoLocalizacao;
                    $scope.montarChartGeoLocalizacao();
                }
            });
        };
        var _graficoHistorico = null;
        $scope.montarChartHistorico = function (response) {
            $scope.tipoGraficoHistorico = $scope.tipoGraficoHistorico ? $scope.tipoGraficoHistorico : 'a';
            var arrayDescricao = ['Ano', 'Mês', 'Semana', 'Dia', 'Horário'];
            var array = null;
            response.forEach(function (item) {
                if (array == null)
                    array = [['Dia', 'Envios', 'Entregues', 'Abertos', 'Cliques', 'Respondidos', 'Interesses']];
                array.push([Object.values(item._id)[Object.values(item._id).length - 1], item.quantidadeEnvios, item.quantidadeEntregues, item.quantidadeAbertos, item.quantidadeClicks, item.quantidadeRespondidos, item.quantidadeInteresses]);
            });
            google.charts.setOnLoadCallback(function () {
                var data = google.visualization.arrayToDataTable(array);
                if ((!_graficoHistorico) || (_graficoHistorico == null))
                    _graficoHistorico = new google.visualization.ColumnChart(document.getElementById('chart_historico_diario'));
                _graficoHistorico.draw(data, {
                    width: 1100,
                    height: 400,
                    vAxis: {
                        gridlines: {
                            color: 'transparent'
                        }
                    },
                    hAxis: {
                        gridlines: {
                            color: 'transparent'
                        }
                    },
                    chartArea: { width: '100%', height: '80%' },
                    cht: 'bvs', colors: ['#A4A4A4', '#7a7a7a', '#1AB157', '#1FCC65', '#095957', '#095957'],
                    legend: { position: "none" },
                    animation: {
                        duration: 1000,
                        easing: 'out'
                    }
                });
            });
        };
        $scope.consultarChartHistorico = function (req) {
            var parametros = {};
            parametros = {
                objectId: _filtros.objectId,
                tipoDaChave: _filtros.tipoDaChave,
                tipoDaConsulta: req.toUpperCase(),
                dataInicial: _filtros.dataInicioFiltro ? new Date(_filtros.dataInicioFiltro) : null,
                dataFinal: _filtros.dataInicioFiltro ? new Date(_filtros.dataInicioFiltro) : null,
                sort: "_id"
            };
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/time/consultarEstatisticas/"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                params: parametros
            }).then(function (response) {
                $scope.montarChartHistorico(response.data);
            });
        };
        $scope.mudarGraficoHistorico = function () {
            var _valorTipoGrafico = $('#slctHistoricoMetricas')[0].value;
            if (_valorTipoGrafico == "a")
                $scope.consultarChartHistorico($scope.tipoGraficoHistorico.toUpperCase());
            if (_valorTipoGrafico == "m")
                $scope.consultarChartHistorico("AM");
            if (_valorTipoGrafico == "s")
                $scope.consultarChartHistorico("AS");
            if (_valorTipoGrafico == "d")
                $scope.consultarChartHistorico("AMSD");
        };
        var _graficoAgrupadoHora = null;
        var _graficoAgrupadoDia = null;
        var _iControlleGraficoAgrupado = 0;
        $scope.montarChartAgrupado = function (tipo) {
            if (tipo == "W") {
                google.charts.setOnLoadCallback(function () {
                    var data = google.visualization.arrayToDataTable(dadosGraficoAgrupadoDiaSemana[0]);
                    _graficoAgrupadoDia = new google.visualization.ColumnChart(document.getElementById('chart_agrupado_dia'));
                    _graficoAgrupadoDia.draw(data, {
                        width: 300,
                        height: 370,
                        vAxis: {
                            gridlines: {
                                color: 'transparent'
                            }
                        },
                        chartArea: { width: '100%', height: '80%' },
                        cht: 'bvs', chs: '300x125', colors: ['#A4A4A4'],
                        chds: '0,160',
                        bar: { groupWidth: "15%" },
                        legend: { position: "none" },
                        animation: {
                            duration: 1000,
                            easing: 'out',
                        }
                    });
                });
            }
            else if (tipo == "H") {
                google.charts.setOnLoadCallback(function () {
                    var data = google.visualization.arrayToDataTable(dadosGraficoAgrupadoHora[0]);
                    _graficoAgrupadoHora = new google.visualization.ColumnChart(document.getElementById('chart_agrupado_hora'));
                    _graficoAgrupadoHora.draw(data, {
                        width: 630,
                        height: 370,
                        vAxis: {
                            gridlines: {
                                color: 'transparent'
                            }
                        },
                        chartArea: { width: '100%', height: '80%' },
                        cht: 'bvs', chs: '300x125', colors: ['#A4A4A4'],
                        chds: '0,160',
                        bar: { groupWidth: "15%" },
                        legend: { position: "none" },
                        animation: {
                            duration: 1000,
                            easing: 'out',
                        }
                    });
                });
            }
        };
        $scope.removerCoresBtns = function () {
            $('#btnMeMudarEnvios').removeClass('btnMetricasEnviosAtivo');
            $('#btnMeMudarEntregues').removeClass('btnMetricasEntreguesAtivo');
            $('#btnMeMudarAberturas').removeClass('btnMetricasAberturasAtivo');
            $('#btnMeMudarCliques').removeClass('btnMetricasCliquesAtivo');
            $('#btnMeMudarRespostas').removeClass('btnMetricasVeAtivo');
            $('#btnMeMudarInteresses').removeClass('btnMetricasVeAtivo');
            $('#btnMeMudarEnviosGeo').removeClass('btnMetricasEnviosAtivo');
            $('#btnMeMudarEntreguesGeo').removeClass('btnMetricasEntreguesAtivo');
            $('#btnMeMudarAberturasGeo').removeClass('btnMetricasAberturasAtivo');
            $('#btnMeMudarCliquesGeo').removeClass('btnMetricasCliquesAtivo');
            $('#btnMeMudarRespostasGeo').removeClass('btnMetricasVeAtivo');
            $('#btnMeMudarInteressesGeo').removeClass('btnMetricasVeAtivo');
        };
        $scope.trocarGrafico = function (pDados) {
            if ($scope.tipoGrafico == 'a') {
                var _color = '';
                var dataDia = null;
                var dataHora = null;
                $scope.removerCoresBtns();
                if (pDados == 'envios') {
                    _iControlleGraficoAgrupado = 1;
                    $('#btnMeMudarEnvios').addClass('btnMetricasEnviosAtivo');
                    _color = '#A4A4A4';
                }
                if (pDados == 'entregues') {
                    _iControlleGraficoAgrupado = 2;
                    $('#btnMeMudarEntregues').addClass('btnMetricasEntreguesAtivo');
                    _color = '#7a7a7a';
                }
                if (pDados == 'aberturas') {
                    _iControlleGraficoAgrupado = 3;
                    $('#btnMeMudarAberturas').addClass('btnMetricasAberturasAtivo');
                    _color = '#1AB157';
                }
                if (pDados == 'cliques') {
                    _iControlleGraficoAgrupado = 4;
                    $('#btnMeMudarCliques').addClass('btnMetricasCliquesAtivo');
                    _color = '#1FCC65';
                }
                if (pDados == 'respostas') {
                    _iControlleGraficoAgrupado = 5;
                    $('#btnMeMudarRespostas').addClass('btnMetricasVeAtivo');
                    _color = '#095957';
                }
                if (pDados == 'interesses') {
                    _iControlleGraficoAgrupado = 6;
                    $('#btnMeMudarInteresses').addClass('btnMetricasVeAtivo');
                    _color = '#095957';
                }
                switch (_iControlleGraficoAgrupado) {
                    case 1:
                        if (dadosGraficoAgrupadoDiaSemana[0].length == 1) {
                            $('#dvGraficoAgrupado').hide();
                            $('#divEmptyGraficoAgrupado').fadeIn();
                            $scope.txtEmptyStatesGraficoAgrupado = 'Você ainda não possui envios';
                            $('#imgEmptyStatesGraficoAgrupado').attr('src', "".concat($rootScope.bucket, "/empty-popup-envios.png"));
                        }
                        else {
                            $('#divEmptyGraficoAgrupado').hide();
                            $('#dvGraficoAgrupado').fadeIn();
                        }
                        var dataDia = google.visualization.arrayToDataTable(dadosGraficoAgrupadoDiaSemana[0]);
                        var dataHora = google.visualization.arrayToDataTable(dadosGraficoAgrupadoHora[0]);
                        break;
                    case 2:
                        if (dadosGraficoAgrupadoDiaSemana[1].length == 1) {
                            $('#dvGraficoAgrupado').hide();
                            $('#divEmptyGraficoAgrupado').fadeIn();
                            $scope.txtEmptyStatesGraficoAgrupado = 'Você ainda não possui e-mails entregues';
                            $('#imgEmptyStatesGraficoAgrupado').attr('src', "".concat($rootScope.bucket, "/empty-popup-entregues.png"));
                        }
                        else {
                            $('#divEmptyGraficoAgrupado').hide();
                            $('#dvGraficoAgrupado').fadeIn();
                        }
                        var dataDia = google.visualization.arrayToDataTable(dadosGraficoAgrupadoDiaSemana[1]);
                        var dataHora = google.visualization.arrayToDataTable(dadosGraficoAgrupadoHora[1]);
                        break;
                    case 3:
                        if (dadosGraficoAgrupadoDiaSemana[2].length == 1) {
                            $('#dvGraficoAgrupado').hide();
                            $('#divEmptyGraficoAgrupado').fadeIn();
                            $scope.txtEmptyStatesGraficoAgrupado = 'Você ainda não possui e-mails abertos';
                            $('#imgEmptyStatesGraficoAgrupado').attr('src', "".concat($rootScope.bucket, "/empty-popup-aberturas.png"));
                        }
                        else {
                            $('#divEmptyGraficoAgrupado').hide();
                            $('#dvGraficoAgrupado').fadeIn();
                        }
                        var dataDia = google.visualization.arrayToDataTable(dadosGraficoAgrupadoDiaSemana[2]);
                        var dataHora = google.visualization.arrayToDataTable(dadosGraficoAgrupadoHora[2]);
                        break;
                    case 4:
                        if (dadosGraficoAgrupadoDiaSemana[3].length == 1) {
                            $('#dvGraficoAgrupado').hide();
                            $('#divEmptyGraficoAgrupado').fadeIn();
                            $scope.txtEmptyStatesGraficoAgrupado = 'Você ainda não possui cliques';
                            $('#imgEmptyStatesGraficoAgrupado').attr('src', "".concat($rootScope.bucket, "/empty-popup-cliques.png"));
                        }
                        else {
                            $('#divEmptyGraficoAgrupado').hide();
                            $('#dvGraficoAgrupado').fadeIn();
                        }
                        var dataDia = google.visualization.arrayToDataTable(dadosGraficoAgrupadoDiaSemana[3]);
                        var dataHora = google.visualization.arrayToDataTable(dadosGraficoAgrupadoHora[3]);
                        break;
                    case 5:
                        if (dadosGraficoAgrupadoDiaSemana[4].length == 1) {
                            $('#dvGraficoAgrupado').hide();
                            $('#divEmptyGraficoAgrupado').fadeIn();
                            $scope.txtEmptyStatesGraficoAgrupado = 'Você ainda não possui respostas';
                            $('#imgEmptyStatesGraficoAgrupado').attr('src', "".concat($rootScope.bucket, "/empty-popup-respostas.png"));
                        }
                        else {
                            $('#divEmptyGraficoAgrupado').hide();
                            $('#dvGraficoAgrupado').fadeIn();
                        }
                        var dataDia = google.visualization.arrayToDataTable(dadosGraficoAgrupadoDiaSemana[4]);
                        var dataHora = google.visualization.arrayToDataTable(dadosGraficoAgrupadoHora[4]);
                        break;
                    case 6:
                        if (dadosGraficoAgrupadoDiaSemana[5].length == 1) {
                            $('#dvGraficoAgrupado').hide();
                            $('#divEmptyGraficoAgrupado').fadeIn();
                            $scope.txtEmptyStatesGraficoAgrupado = 'Você ainda não possui contatos com interesse';
                            $('#imgEmptyStatesGraficoAgrupado').attr('src', "".concat($rootScope.bucket, "/empty-popup-interesse.png"));
                        }
                        else {
                            $('#divEmptyGraficoAgrupado').hide();
                            $('#dvGraficoAgrupado').fadeIn();
                        }
                        var dataDia = google.visualization.arrayToDataTable(dadosGraficoAgrupadoDiaSemana[5]);
                        var dataHora = google.visualization.arrayToDataTable(dadosGraficoAgrupadoHora[5]);
                        break;
                }
                _graficoAgrupadoDia.draw(dataDia, {
                    width: 300,
                    height: 370,
                    vAxis: {
                        gridlines: {
                            color: 'transparent'
                        }
                    },
                    chartArea: { width: '100%', height: '80%' },
                    cht: 'bvs', chs: '300x125', colors: [_color],
                    chds: '0,160',
                    bar: { groupWidth: "15%" },
                    legend: { position: "none" },
                    animation: {
                        duration: 1000,
                        easing: 'out',
                    }
                });
                _graficoAgrupadoHora.draw(dataHora, {
                    width: 630,
                    height: 370,
                    vAxis: {
                        gridlines: {
                            color: 'transparent'
                        }
                    },
                    chartArea: { width: '100%', height: '80%' },
                    cht: 'bvs', chs: '300x125', colors: [_color],
                    chds: '0,160',
                    bar: { groupWidth: "15%" },
                    legend: { position: "none" },
                    animation: {
                        duration: 1000,
                        easing: 'out',
                    }
                });
            }
            else if ($scope.tipoGrafico == 'c') {
                $scope.removerCoresBtns();
                var _color = '';
                if (pDados == 'envios') {
                    $('#btnMeMudarEnvios').addClass('btnMetricasEnviosAtivo');
                    _color = '#A4A4A4';
                }
                if (pDados == 'entregues') {
                    _iControlleGraficoCruzado = 1;
                    $('#btnMeMudarEntregues').addClass('btnMetricasEntreguesAtivo');
                    _color = '#7a7a7a';
                }
                if (pDados == 'aberturas') {
                    _iControlleGraficoCruzado = 2;
                    $('#btnMeMudarAberturas').addClass('btnMetricasAberturasAtivo');
                    _color = '#1AB157';
                }
                if (pDados == 'cliques') {
                    $('#btnMeMudarCliques').addClass('btnMetricasCliquesAtivo');
                    _color = '#1FCC65';
                }
                if (pDados == 'respostas') {
                    _iControlleGraficoCruzado = 4;
                    $('#btnMeMudarRespostas').addClass('btnMetricasVeAtivo');
                    _color = '#095957';
                }
                if (pDados == 'interesses') {
                    $('#btnMeMudarInteresses').addClass('btnMetricasVeAtivo');
                    _color = '#095957';
                }
                switch (_iControlleGraficoCruzado) {
                    case 1:
                        var data = google.visualization.arrayToDataTable(dadosGraficoCruzado[0]);
                        break;
                    case 2:
                        var data = google.visualization.arrayToDataTable(dadosGraficoCruzado[1]);
                        break;
                    case 4:
                        var data = google.visualization.arrayToDataTable(dadosGraficoCruzado[2]);
                        break;
                }
                _graficoCruzado.draw(data, {
                    width: 1100,
                    height: 400,
                    chartArea: { width: '80%', height: '80%' },
                    colors: [_color],
                    bubble: { textStyle: { auraColor: 'none', } },
                    legend: 'none',
                    bubble: { textStyle: { color: 'none' } },
                    animation: {
                        duration: 1000,
                        easing: 'out',
                    }
                });
            }
        };
        $('#sltDataMetricas').dateRangePicker({
            startOfWeek: 0,
            weekStart: '0',
            format: 'DD/MM/YY',
            separator: ' até ',
            shortcuts: {
                'prev-days': [1, 7, 15],
                'prev': ['week', 'month', 'year']
            },
            title: 'Respondido de: ',
            animationTime: 200
        })
            .bind('DRPEx-change', function (event, obj) {
            $scope.dataInicioFiltro = obj.start;
            $scope.dataInicioFiltro.setHours(0);
            $scope.dataInicioFiltro.setMinutes(0);
            $scope.dataInicioFiltro.setSeconds(0);
            $scope.dataFinalFiltro = obj.end;
            $scope.dataFinalFiltro.setHours(23);
            $scope.dataFinalFiltro.setMinutes(59);
            $scope.dataFinalFiltro.setSeconds(59);
        });
        _iControlleGraficoAgrupadoGeo = 'a';
        $scope.trocarGraficoGeo = function (pGeo) {
            $('#btnMeMudarAberturasGeo').addClass('btnMetricasAberturasAtivo');
            if (pGeo == 'envios') {
                $scope.removerCoresBtns();
                $('#btnMeMudarEnviosGeo').addClass('btnMetricasEnviosAtivo');
                _color = '#A4A4A4';
            }
            if (pGeo == 'entregues') {
                _iControlleGraficoAgrupadoGeo = 1;
                $scope.removerCoresBtns();
                $('#btnMeMudarEntreguesGeo').addClass('btnMetricasEntreguesAtivo');
                _color = '#7a7a7a';
            }
            if (pGeo == 'aberturas') {
                _iControlleGraficoAgrupadoGeo = 2;
                $scope.removerCoresBtns();
                $('#btnMeMudarAberturasGeo').addClass('btnMetricasAberturasAtivo');
                _color = '#1AB157';
            }
            if (pGeo == 'cliques') {
                $scope.removerCoresBtns();
                $('#btnMeMudarCliquesGeo').addClass('btnMetricasCliquesAtivo');
                _color = '#1FCC65';
            }
            if (pGeo == 'respostas') {
                _iControlleGraficoAgrupadoGeo = 4;
                $scope.removerCoresBtns();
                $('#btnMeMudarRespostasGeo').addClass('btnMetricasVeAtivo');
                _color = '#095957';
            }
            if (pGeo == 'interesses') {
                $scope.removerCoresBtns();
                $('#btnMeMudarInteressesGeo').addClass('btnMetricasVeAtivo');
                _color = '#095957';
            }
            var _data = null;
            var _color = '';
            if (_iControlleGraficoAgrupadoGeo == 'a')
                _iControlleGraficoAgrupadoGeo = 'r';
            else
                _iControlleGraficoAgrupadoGeo = 'a';
            if (_iControlleGraficoAgrupadoGeo == 'a') {
                _iControlleGraficoAgrupado = 'a';
                $scope.geoDescricao = 'Quantidade de aberturas por região';
                $scope.geoDescricaoSub = 'Localização dos e-mails abertos';
                _color = '#1BB258';
                _data = google.visualization.arrayToDataTable(dadosGraficoGeo[0]);
            }
            else {
                _iControlleGraficoAgrupadoGeo = "p";
                $scope.geoDescricao = 'Quantidade de respostas por região';
                $scope.geoDescricaoSub = 'Localização dos e-mails respondidos';
                _color = '#095957';
                _data = google.visualization.arrayToDataTable(dadosGraficoGeo[1]);
            }
            _graficoGeo = new google.visualization.GeoChart(document.getElementById('regions_div_aberturas'));
            _graficoGeo.draw(_data, {
                width: 1200,
                height: 380,
                chartArea: { width: '100%', height: '90%' },
                backgroundColor: "transparent",
                colorAxis: { colors: [_color] }
            });
        };
        var _graficoCruzado = null;
        var _iControlleGraficoCruzado = 0;
        $scope.montarChartCruzado = function () {
            _iControlleGraficoCruzado = 0;
            $scope.cruzadoDescricao = 'Entregues';
            google.charts.setOnLoadCallback(function () {
                var data = google.visualization.arrayToDataTable(dadosGraficoCruzado[0]);
                _graficoCruzado = new google.visualization.BubbleChart(document.getElementById('chart_cruzado'));
                _graficoCruzado.draw(data, {
                    width: 1200,
                    height: 400,
                    backgroundColor: "transparent",
                    chartArea: { width: '70%', height: '80%' },
                    hAxis: { gridlines: { color: 'rgba(112, 112, 112, 0.2)', count: 24 }, },
                    vAxis: { gridlines: { color: 'rgba(112, 112, 112, 0.2)', count: 7 }, },
                    colors: ['#A4A4A4'],
                    bubble: { textStyle: { auraColor: 'none', } },
                    legend: 'none',
                    bubble: { textStyle: { color: 'none' } },
                    animation: {
                        duration: 1000,
                        easing: 'out',
                    }
                });
            });
        };
        $scope.montarChartGeoLocalizacao = function () {
            if ((dadosGraficoGeo == null) || (dadosGraficoGeo.length == 0)) {
                google.charts.setOnLoadCallback(function () {
                    var data = google.visualization.arrayToDataTable([
                        ['País', 'Aberturas']
                    ]);
                    var options = {};
                    var countryChart = new google.visualization.GeoChart(document.getElementById('regions_div_aberturas'));
                    countryChart.draw(data, {
                        width: 900,
                        height: 380,
                        chartArea: { width: '120%', height: '90%' },
                    });
                });
            }
            else {
                google.charts.setOnLoadCallback(function () {
                    var data = google.visualization.arrayToDataTable(dadosGraficoGeo[0]);
                    var options = {
                        colorAxis: { colors: ['#1BB258'] }
                    };
                    _graficoGeo = new google.visualization.GeoChart(document.getElementById('regions_div_aberturas'));
                    _graficoGeo.draw(data, {
                        width: 1200,
                        height: 380,
                        backgroundColor: "transparent",
                        chartArea: { width: '120%', height: '90%' },
                    });
                });
            }
            ;
        };
        $scope.mouseValoresIndicadores = function (pMouse) {
            $scope.indicadoresPerc = (pMouse == 'l');
        };
        $rootScope.removerBlur = function () {
            $('#idFundoPopupMetricas').fadeOut();
        };
        $(document).mouseup(function (e) {
            var popUpInformacoes = $('#popupInfoGeral');
            if (popUpInformacoes.is(e.target)) {
                popUpInformacoes.fadeOut();
                $rootScope.removerBlur();
                $rootScope.popupInfo = false;
            }
        });
        $scope.clickDetalhesTotal = function (pTipo) {
            $rootScope.loaderAtivo = true;
            $rootScope.popupInfo = true;
            $('#popupInfoGeral').fadeIn();
            $('#idFundoPopupMetricas').fadeIn();
            var _imgBolinhas = "".concat($rootScope.bucket, "/");
            var _imgBolinhasEmpty = "".concat($rootScope.bucket, "/");
            $rootScope.tipoDescricao = '';
            $rootScope.detalhesDescricao = '';
            $rootScope.txtEmptyStates = '';
            switch (pTipo) {
                case 'env':
                    _popupAberto = 2;
                    _imgBolinhas += 'envio-gr-128.png';
                    $rootScope.txtEmptyStates = 'Você ainda não possui envios';
                    _imgBolinhasEmpty += 'empty-popup-envios.png';
                    $rootScope.tipoDescricao = 'Envios';
                    $rootScope.detalhesDescricao = 'Listagens dos e-mails enviados';
                    break;
                case 'ent':
                    _popupAberto = 3;
                    _imgBolinhas += 'entregue-gr-128.png';
                    _imgBolinhasEmpty += 'empty-popup-entregues.png';
                    $rootScope.txtEmptyStates = 'Você ainda não possui e-mails entregues';
                    $rootScope.tipoDescricao = 'Entregues';
                    $rootScope.detalhesDescricao = 'Listagens dos e-mails entregues';
                    break;
                case 'abe':
                    _popupAberto = 4;
                    _imgBolinhas += 'abertura-lt-128.png';
                    _imgBolinhasEmpty += 'empty-popup-aberturas.png';
                    $rootScope.txtEmptyStates = 'Você ainda não possui e-mails abertos';
                    $rootScope.tipoDescricao = 'Abertos';
                    $rootScope.detalhesDescricao = 'Listagens dos e-mails abertos';
                    break;
                case 'fil':
                    $scope.tipoDescricao = 'na fila';
                    $rootScope.txtEmptyStates = 'Você ainda não possui contatos na fila';
                    $rootScope.detalhesDescricao = 'Listagens dos contatos na fila';
                    break;
                case 'abo':
                    $scope.tipoDescricao = 'Abordados';
                    $rootScope.txtEmptyStates = 'Você ainda não possui contatos abordados';
                    $rootScope.detalhesDescricao = 'Listagens dos contatos abordados';
                    break;
                case 'sof':
                    $scope.tipoDescricao = 'Soft Bounces';
                    $rootScope.txtEmptyStates = 'Você não possui contatos marcados como Bounce';
                    $rootScope.detalhesDescricao = 'Listagens dos contatos marcados como Bounce';
                    _imgBolinhas += 'bounce-gr-128.png';
                    break;
                case 'har':
                    $scope.tipoDescricao = 'Hard Bounces';
                    $rootScope.txtEmptyStates = 'Você não possui contatos marcados como Bounce';
                    $rootScope.detalhesDescricao = 'Listagens dos contatos marcados como Bounce';
                    _imgBolinhas += 'bounce-gr-128.png';
                    break;
                case 'cli':
                    $scope.tipoDescricao = 'Cliques';
                    $rootScope.txtEmptyStates = 'Você ainda não possui cliques';
                    $rootScope.detalhesDescricao = 'Listagens dos cliques';
                    _imgBolinhas += 'clique-lt-128.png';
                    _imgBolinhasEmpty += 'empty-popup-cliques.png';
                    break;
                case 'con':
                    _popupAberto = 1;
                    $rootScope.tipoDescricao = 'Contatos';
                    $rootScope.txtEmptyStates = 'Você ainda não possui contatos';
                    $rootScope.detalhesDescricao = 'Listagens dos contatos';
                    _imgBolinhas += 'contato-gr-128.png';
                    _imgBolinhasEmpty += 'empty-popup-contatos.png';
                    break;
                case 'pos':
                    _popupAberto = 6;
                    $rootScope.tipoDescricao = 'Interesses';
                    $rootScope.txtEmptyStates = 'Você ainda não possui contatos com interesse';
                    $rootScope.detalhesDescricao = 'Listagens dos contatos com interesses';
                    _imgBolinhas += 'sim-bolinha-128.png';
                    _imgBolinhasEmpty += 'empty-popup-interesse.png';
                    break;
                case 'neu':
                    $rootScope.tipoDescricao = 'Neutra';
                    $rootScope.txtEmptyStates = 'Você ainda não possui respostas neutras';
                    $rootScope.detalhesDescricao = 'Listagens das respostas neutras';
                    _imgBolinhas += 'neutro-bolinha-128.png';
                    _imgBolinhasEmpty += 'empty-popup-interesse.png';
                    break;
                case 'neg':
                    $rootScope.tipoDescricao = 'Sem interesse';
                    $rootScope.txtEmptyStates = 'Você ainda não possui contatos sem interesse';
                    $rootScope.detalhesDescricao = 'Listagens dos contatos sem interesses';
                    _imgBolinhas += 'nao-bolinha-128.png';
                    _imgBolinhasEmpty += 'empty-popup-interesse.png';
                    break;
                case 'res':
                    _popupAberto = 5;
                    $rootScope.tipoDescricao = 'Respostas';
                    $rootScope.txtEmptyStates = 'Você ainda não possui respostas';
                    $rootScope.detalhesDescricao = 'Listagens das respostas';
                    _imgBolinhas += 'resposta-dk-128.png';
                    _imgBolinhasEmpty += 'empty-popup-respostas.png';
                    break;
                case 'bou':
                    $scope.tipoDescricao = 'Bounces';
                    $rootScope.txtEmptyStates = 'Você não possui contatos marcados como Bounce';
                    $rootScope.detalhesDescricao = 'Listagens dos contatos marcados como Bounce';
                    _imgBolinhas += 'bounce-gr-128.png';
                    _imgBolinhasEmpty += 'empty-popup-interesse.png';
                    break;
            }
            _campoOrdem = 'dataEnvioOrdem';
            _ordem = -1;
            var parametros = {
                dataInicial: _filtros.dataInicioFiltro ? new Date(_filtros.dataInicioFiltro) : null,
                dataFinal: _filtros.dataFinalFiltro ? new Date(_filtros.dataFinalFiltro) : null,
                ordem: _campoOrdem,
                ordemAscDesc: _ordem,
                skip: 0,
                limit: 10000,
                tipoDaChave: _filtros.tipoDaChave,
                objectId: _filtros.objectId,
                tipoMetrica: pTipo
            };
            $('#imgBolinhaImgPopupInfo').attr('src', _imgBolinhas);
            $('#imgEmptyStatesPopup').attr('src', _imgBolinhasEmpty);
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/dados/consultarDados"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                params: parametros
            }).then(function (rConsultaRespostas) {
                if (rConsultaRespostas.data.length == 0)
                    $rootScope.semContatosMetricas = true;
                else {
                    rConsultaRespostas.data.forEach(function (rInfos) {
                        rInfos.dataEnvioOrdem = rInfos.dataEnvio != null ? formatarDataHora(rInfos.dataEnvioOrdem) : null;
                        rInfos.dataOrd = rInfos.dataResposta != null ? formatarDataHora(rInfos.dataOrd) : null;
                        rInfos.dataAberturaSemFormatacao = rInfos.dataAbertura != null ? formatarDataHora(rInfos.dataAberturaSemFormatacao) : null;
                    });
                    $rootScope.semContatosMetricas = false;
                }
                $rootScope.listaContatosPopup = rConsultaRespostas.data;
                $rootScope.tipoClique = pTipo;
                $rootScope.loaderAtivo = false;
                $('#popupInfoGeral').removeClass('blur');
            });
            $("#dvFecharPopup").unbind("click");
            $('#dvFecharPopup').click(function () {
                $rootScope.removerBlur();
                $rootScope.popupInfo = false;
                $scope.$apply();
            });
        };
        $scope.mudarPopupsMetricas = function (pDados) {
            $rootScope.listaContatosPopup = [];
            if (pDados == 'mais')
                _popupAberto++;
            else
                _popupAberto--;
            if (_popupAberto == 1) {
                $rootScope.tipoClique = 'con';
            }
            else if (_popupAberto == 2) {
                $rootScope.popupInfo = false;
                $rootScope.tipoClique = 'env';
            }
            else if (_popupAberto == 3)
                $rootScope.tipoClique = 'ent';
            else if (_popupAberto == 4)
                $rootScope.tipoClique = 'abe';
            else if (_popupAberto == 5)
                $rootScope.tipoClique = 'res';
            else if (_popupAberto == 6)
                $rootScope.tipoClique = 'pos';
            $scope.clickDetalhesTotal($rootScope.tipoClique);
        };
        $scope.exibirGrafico = function () {
            if ($('#StatusFiltro')[0].selectedOptions[0].value == 1) {
                $rootScope.exibirGraficoMetricasContatos = true;
                $rootScope.exibirGraficoMetricasEmails = false;
            }
            if ($('#StatusFiltro')[0].selectedOptions[0].value == 2) {
                $rootScope.exibirGraficoMetricasContatos = false;
                $rootScope.exibirGraficoMetricasEmails = true;
            }
        };
        $(document).keydown(function (e) {
            if (e.keyCode == 27) {
                if ($scope.popupInfo) {
                    $('#popupInfoGeral').fadeOut();
                    $rootScope.removerBlur();
                    $rootScope.popupInfo = false;
                }
            }
        });
        $scope.consultarCapturasHoje = function () {
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/login/consultarContatosMixpanel"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto
                }
            }).then(function (rConsultarContatosMixpanel) {
                if (rConsultarContatosMixpanel.data) {
                    rConsultarContatosMixpanel.data = false;
                    mixpanel.track("Captura Extensão | O usuario capturou contatos hoje", {});
                }
            });
        };
        $scope.consultarCapturasHoje();
    }]);
