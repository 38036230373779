angular.module("ramperv3").controller("MonsterMfaController", [
    "$rootScope",
    "$scope",
    "MonsterToasterService",
    "MonsterMfaService",
    function ($rootScope, $scope, MonsterToasterService, MonsterMfaService) {
        /**
         * Google Analytics
         */
        ga("set", "page", document.location.hash);
        ga("send", "pageview");
        /**
         * Estado inicial da página $rootScope
         */
        $rootScope.mostrarMenu = true;
        $rootScope.loaderAtivo = false;
        $rootScope.monsterShowLoading = $rootScope.Login.email ? false : true;
        setTimeout(function () {
            $rootScope.monsterShowLoading = false;
            $rootScope.$apply();
        }, $rootScope.Login.email ? 0 : 2000);
        /**
         * Estado inicial da página $scope
         */
        $scope.loginPorSso = $rootScope.Login.sso ? false : true;
        $scope.abrirMenuSeguranca = true;
        $scope.mfa = { ativarMfa: false, token: "", qrCodeBase64: null };
        $scope.abrirDialog = { desativarMfa: false, excluirMfa: false };
        $scope.configuradoMfa = false;
        $scope.showLoadingQRCode = false;
        $scope.dataConfiguracaoMfa = 0;
        setTimeout(function () {
            if ($rootScope.Login.mfa) {
                $scope.mfa.ativarMfa = $rootScope.Login.mfa.active || false;
                $scope.configuradoMfa = $rootScope.Login.mfa.active || false;
                $scope.dataConfiguracaoMfa = Math.floor(Math.abs(new Date() - new Date($rootScope.Login.mfa.createdAt)) / (1000 * 60 * 60 * 24));
            }
            $scope.$apply();
        }, $rootScope.Login.email ? 0 : 1000);
        /**
         * Deve abrir o dialog de confirmação para desativar o mfa
         */
        $scope.onToggleMfa = function () {
            if ($scope.mfa.ativarMfa && $scope.configuradoMfa) {
                $scope.abrirDialog.desativarMfa = true;
            }
        };
        /**
         * Deve fechar os dialogs de confirmação e habilitar o tooltip
         */
        $scope.onFecharDialog = function () {
            $scope.abrirDialog.desativarMfa = false;
            $scope.abrirDialog.excluirMfa = false;
            $scope.mfa.ativarMfa = true;
        };
        /**
         * Deve consumir um serviço para gerar um QR code
         */
        $scope.gerarBase64Mfa = function () {
            $scope.showLoadingQRCode = true;
            MonsterMfaService.gerarBase64Mfa().then(function (response) {
                $scope.mfa.qrCodeBase64 = response.data.base64;
                $scope.showLoadingQRCode = false;
            }, function () {
                MonsterToasterService.showErrorToaster("", "Erro ao gerar QR code. Tente novamente.", 6000, 350);
                $scope.showLoadingQRCode = false;
            });
        };
        /**
         * Deve consumir um serviço para validar o código de autenticação
         */
        $scope.validarTokenMfa = function () {
            var token = $scope.mfa.token.replace(/[^0-9]/g, "");
            $rootScope.monsterShowLoading = true;
            MonsterMfaService.validarTokenMfa(token).then(function () {
                MonsterToasterService.showSuccessToaster("", "MFA ativado com sucesso!", 6000, 350);
                $scope.mfa = { ativarMfa: true, token: "", qrCodeBase64: null };
                $rootScope.Login.mfa = { active: true, createdAt: new Date().toISOString() };
                $rootScope.monsterShowLoading = false;
                $scope.configuradoMfa = true;
                mixpanel.track("MFA | Configurou a dupla autenticação", { Local_IP: $rootScope.ip });
            }, function (error) {
                var message = error.status === 400 ? "Gere o seu código QR e leia com seu aplicativo. Tente novamente." : "O código de autenticação digitado está incorreto. Tente novamente.";
                MonsterToasterService.showErrorToaster("", message, 6000, 450);
                $rootScope.monsterShowLoading = false;
            });
        };
        /**
         * Deve consumir um serviço para remover o mfa
         */
        $scope.onRemoverMfa = function (dialogAberto) {
            $rootScope.Login.mfa.active = false;
            $rootScope.Login.mfa.createdAt = null;
            $scope.abrirDialog.desativarMfa = false;
            $scope.abrirDialog.excluirMfa = false;
            $rootScope.monsterShowLoading = true;
            MonsterMfaService.removerMfa().then(function () {
                MonsterToasterService.showSuccessToaster("", "MFA removido com sucesso!", 6000, 350);
                $rootScope.monsterShowLoading = false;
                $scope.configuradoMfa = false;
                if (dialogAberto === 'excluirMfa')
                    $scope.mfa.ativarMfa = true;
                mixpanel.track("MFA | Removeu a dupla autenticação", { Local_IP: $rootScope.ip });
            }, function () {
                MonsterToasterService.showErrorToaster("", "Houve um erro ao remover mfa. Tente novamente.", 6000, 350);
                $rootScope.monsterShowLoading = false;
            });
        };
        /**
         * Deve restar o formulário;
         */
        $scope.onLimpar = function () {
            $scope.mfa = { ativarMfa: false, token: "", qrCodeBase64: null };
            var form = $scope.formMfa;
            angular.forEach(form.$$controls, function (control) {
                var fieldElement = angular.element(control.$$element);
                fieldElement.attr("dirty", "false");
            });
        };
        /**
         * Deve abrir e fechar o menu de segurança
         */
        $scope.toggleMenuSeguranca = function () {
            $scope.abrirMenuSeguranca = !$scope.abrirMenuSeguranca;
        };
        /**
         * Evento disparado quando o controlador for destruído
         */
        $scope.$on("$destroy", function () {
            $rootScope.monsterShowLoading = false;
            $scope.abrirDialog.desativarMfa = false;
            $scope.abrirDialog.excluirMfa = false;
            MonsterToasterService.removeToaster();
        });
    },
]);
