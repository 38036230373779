angular.module('ramperv3')
    .controller('InicioController', ['$rootScope', '$scope', '$http', '$filter', 'serviceWorkerService', '$window', function ($rootScope, $scope, $http, $filter, serviceWorkerService, $window) {
        serviceWorkerService.registerWorker()
            .then(function (swRegistration) {
            serviceWorkerService
                .subscribeUser(swRegistration, $rootScope.IDCrypto);
        });
        ga('set', 'page', document.location.hash);
        ga('send', 'pageview');
        $('#menuPrincipal').show();
        $rootScope.redirectInicio();
        var endDateDia = new Date();
        endDateDia.setHours(23);
        endDateDia.setMinutes(59);
        endDateDia.setSeconds(59);
        endDateDia.setMilliseconds(999);
        var startDateDia = new Date();
        startDateDia.setHours(0);
        startDateDia.setMinutes(0);
        startDateDia.setSeconds(0);
        startDateDia.setMilliseconds(0);
        $scope.textoEmpty1 = "Ainda não temos nenhum dado para preencher o dashboard.";
        $scope.textoEmpty2 = "É preciso possuir alguma cadência ativa para o Ramper analisar o desempenho. ";
        $scope.mostrarBotaoCadencia = true;
        $rootScope.mostrarMenu = true;
        $scope.loaderInicio = false;
        var graficoUm = false;
        var graficoDois = false;
        $scope.dataAtual = formatarData(new Date());
        var endDate = new Date();
        endDate.setHours(23);
        endDate.setMinutes(59);
        endDate.setSeconds(59);
        var startDate = new Date();
        startDate.setDate(startDate.getDate() - 5);
        var startDateInsightMensal = new Date();
        startDateInsightMensal.setHours(0);
        startDateInsightMensal.setMinutes(0);
        startDateInsightMensal.setSeconds(0);
        startDateInsightMensal.setDate(startDateInsightMensal.getDate() - 30);
        $scope.dataInicioInsightMensal = formatarData(startDateInsightMensal);
        $scope.dataAtual = formatarData(new Date());
        $rootScope.mostrarMenu = true;
        $rootScope.consultarStatusUsuario();
        var _usuarioSelecionadoFiltroInicio = false;
        $('#usuarioFiltroDefinido').hide();
        $scope.idUsuarioSel = '';
        $scope.buscarUsuariosInicio = '';
        $scope.inicio = function () {
            $scope.mostrarEmptyStatesNovaConta = false;
            $scope.loaderInicio = true;
            consultarResumos();
            $scope.filtrarDataFixa('7');
            consultarContatosUsuario();
            consultarUltimaMivimentacao();
            consultarUltimosDias();
        };
        $scope.consultarCardsDicasInicio = function () {
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/inicio/consultarCardsDicasInicio"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                }
            }).then(function (response) {
                $scope.arrayExploreRamper = response.data;
                if ($scope.arrayExploreRamper.length > 3) {
                    $scope.setaAvancarCarrossel = true;
                    $scope.qtdCardsVistos = 3;
                }
                else {
                    $scope.setaAvancarCarrossel = false;
                    $scope.qtdCardsVistos = $scope.arrayExploreRamper.length;
                }
            });
        }();
        $scope.clicouCardExploreORamper = function (card) {
            mixpanel.track('Clicou ' + '"explore o ramper" | ' + card.titulo, {});
            $window.open(card.url, "_blank");
        };
        function consultarResumos() {
            $scope.loaderInicio = true;
            $('#menuPrincipal').addClass('blur');
            $('#pagInicio').addClass('blur');
            $('#emptyTelasInicio').addClass('blur');
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/geral/resumos/dash"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                params: {
                    objectIdLogin: $scope.idUsuarioSel ? $scope.idUsuarioSel : null,
                    tipoDaChave: $rootScope.permissoes.tipoUsuario,
                    dataInicialDia: new Date(startDateDia),
                    dataFinalDia: new Date(endDateDia),
                    dataInicial: new Date(startDateInsightMensal),
                    dataFinal: new Date(endDateDia),
                    clientTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone
                }
            }).then(function (response) {
                $scope.resumo = response.data;
                if ($scope.resumo) {
                    //Total Envio
                    $scope.totalEnviosResumo = $scope.resumo.dataHora.totalEnvios;
                    $scope.tempoEconomizadoEnvios = ($scope.totalEnviosResumo * 7);
                    $scope.tempoEconomizadoEnviosHoras = ($scope.tempoEconomizadoEnvios / 60).toFixed(0);
                    //Total Abertura
                    $scope.percDataHoraMaisAbertoResumo = $scope.resumo.dataHora.maisAberto ? $scope.resumo.dataHora.maisAberto.percentual : 0;
                    $scope.quantidadeDiaSemanaMaisAbertoResumo = $scope.resumo.dataHora.maisAberto ? $scope.resumo.dataHora.maisAberto.quantidadeAbertos : 0;
                    //Total Resposta
                    $scope.percDataHoraMaisRespondidoResumo = $scope.resumo.dataHora.maisRespondido ? $scope.resumo.dataHora.maisRespondido.percentual : 0;
                    $scope.quantidadeDiaSemanaMaisRespondidoResumo = $scope.resumo.dataHora.maisRespondido ? $scope.resumo.dataHora.maisRespondido.quantidadeRespondidos : 0;
                    $scope.totalPrevistos = $scope.resumo.dia.totalPrevistos;
                    $scope.totalEnvios = $scope.resumo.dia.totalEnvios;
                    $scope.totalEntregues = $scope.resumo.dia.totalEntregues;
                    $scope.totalAbertos = $scope.resumo.dia.totalAbertos;
                    $scope.totalRespostas = $scope.resumo.dia.totalRespostas;
                    $scope.totalBounces = $scope.resumo.dia.totalBounces;
                    google.charts.setOnLoadCallback(drawChartEntregues);
                    google.charts.setOnLoadCallback(drawChartEnviados);
                    var verificarGraficos = setInterval(function () {
                        if (graficoUm && graficoDois) {
                            $scope.loaderInicio = false;
                            $('#menuPrincipal').removeClass('blur');
                            $('#pagInicio').removeClass('blur');
                            $('#emptyTelasInicio').removeClass('blur');
                            $scope.$apply();
                            clearInterval(verificarGraficos);
                        }
                    }, 1000);
                }
                else {
                    $scope.loaderInicio = false;
                    $('#menuPrincipal').removeClass('blur');
                    $('#pagInicio').removeClass('blur');
                    $('#emptyTelasInicio').removeClass('blur');
                }
            });
        }
        ;
        function consultarUltimaMivimentacao() {
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/disparosEmails/consultarUltimaMivimentacao"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                params: {
                    objectIdLogin: $scope.idUsuarioSel ? $scope.idUsuarioSel : null,
                    tipoDaChave: $rootScope.permissoes.tipoUsuario
                }
            }).then(function (response) {
                $scope.diasSemEnvio = (new Date(response.data.dataUltimoEnvio) < startDate);
                $scope.dataUltimoEnvio = formatarData(response.data.dataUltimoEnvio);
            });
        }
        ;
        function consultarUltimosDias() {
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/contatos/consultarUltimosDias"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                params: {
                    objectIdLogin: $scope.idUsuarioSel ? $scope.idUsuarioSel : null,
                    tipoDaChave: $rootScope.permissoes.tipoUsuario
                }
            }).then(function (response) {
                $scope.ultimasCapturas = response.data;
                if ($scope.ultimasCapturas) {
                    $scope.diasSemCaptura = false;
                }
                else {
                    $scope.diasSemCaptura = true;
                    $scope.dataUltimaInclusao = new Date();
                    $scope.dataUltimaInclusao.setDate($scope.dataUltimaInclusao.getDate() - 5);
                    $scope.dataUltimaInclusao = formatarData($scope.dataUltimaInclusao);
                }
            });
        }
        function consultarContatosUsuario() {
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/contatos/consultarContatosUsuario"),
                headers: {
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                params: {
                    objectIdLogin: $scope.idUsuarioSel ? $scope.idUsuarioSel : null,
                }
            }).then(function (response) {
                $scope.totaisContatos = response.data;
                if ($scope.totaisContatos) {
                    $scope.mostrarEmptyStatesNovaConta = false;
                    $('#idFiltroUsuarioInicio').removeClass('divFiltroListaUsuariosEmpty');
                    $scope.diasSemCaptura = (new Date($scope.totaisContatos.dataUltimaInclusao) < startDate);
                    $scope.dataUltimaInclusao = formatarData($scope.totaisContatos.dataUltimaInclusao);
                    google.charts.setOnLoadCallback(drawChartContatos);
                }
                else {
                    $scope.totaisContatos = {};
                    $scope.totaisContatos.totalContato = 0;
                    $scope.totaisContatos.totalAlta = 0;
                    $scope.totaisContatos.totalMedia = 0;
                    $scope.totaisContatos.totalBaixa = 0;
                    $scope.totaisContatos.totalSem = 0;
                    $scope.totaisContatos.percentualAlta = 0;
                    $scope.totaisContatos.percentualMedia = 0;
                    $scope.totaisContatos.percentualBaixa = 0;
                    $scope.totaisContatos.percentualSem = 0;
                    $scope.mostrarEmptyStatesNovaConta = false;
                    $('#idFiltroUsuarioInicio').removeClass('divFiltroListaUsuariosEmpty');
                    //$scope.diasSemCaptura = 1;
                    //$scope.dataUltimaInclusao = formatarData(new Date());
                    google.charts.setOnLoadCallback(drawChartContatos);
                    /*$scope.mostrarEmptyStatesNovaConta = true;

                    $('#idFiltroUsuarioInicio').addClass('divFiltroListaUsuariosEmpty');

                    if ($scope.outroUsuario) {

                        $scope.textoEmpty1 = "Ainda não temos nenhum dado para preencher o dashboard deste usuário.";
                        $scope.textoEmpty2 = "É preciso possuir alguma cadência ativa para o Ramper analisar o desempenho.";
                        $scope.mostrarBotaoCadencia = false;

                    } else {

                        $scope.textoEmpty1 = "Ainda não temos nenhum dado para preencher o dashboard";
                        $scope.textoEmpty2 = "É preciso possuir alguma cadência ativa para o Ramper analisar o desempenho ";
                        $scope.mostrarBotaoCadencia = true;
                    }*/
                }
            });
        }
        ;
        function drawChartEnviados() {
            var totalPrevistosVariavel = $scope.totalEnvios > $scope.totalPrevistos ? $scope.totalEnvios : $scope.totalPrevistos;
            var totalGrafico = totalPrevistosVariavel > 0 ? totalPrevistosVariavel - $scope.totalEnvios : 1;
            var data = google.visualization.arrayToDataTable([
                ['Content', 'Indicadores'],
                ['Entregues', ($scope.totalEnvios)],
                ['Previstos', (totalGrafico)]
            ]);
            var options = {
                pieHole: 0.8,
                width: 230,
                height: 230,
                legend: { position: "none" },
                pieSliceText: "none",
                tooltip: { trigger: "none" },
                slices: {
                    0: { color: '#75c18d' },
                    1: { color: '#f3f2f2' }
                }
            };
            var chart = new google.visualization.PieChart(document.getElementById('donut_enviados'));
            chart.draw(data, options);
            graficoUm = true;
        }
        ;
        function drawChartEntregues() {
            var totalEntreguesVariavel = $scope.totalEntregues > $scope.totalEnvios ? $scope.totalEntregues : $scope.totalEnvios;
            var totalGraficoEntregue = totalEntreguesVariavel > 0 ? totalEntreguesVariavel - $scope.totalEntregues : 1;
            var data = google.visualization.arrayToDataTable([
                ['Content', 'Indicadores'],
                ['Entregues', ($scope.totalEntregues)],
                ['Previstos', (totalGraficoEntregue)]
            ]);
            var options = {
                pieHole: 0.8,
                width: 230,
                height: 230,
                legend: { position: "none" },
                pieSliceText: "none",
                tooltip: { trigger: "none" },
                slices: {
                    0: { color: '#75c18d' },
                    1: { color: '#f3f2f2' }
                }
            };
            var chart = new google.visualization.PieChart(document.getElementById('donut_entregues'));
            chart.draw(data, options);
            graficoDois = true;
        }
        ;
        $scope.filtrarDataFixa = function (pDados) {
            $scope.numeroDiasGraficoDash = pDados;
            removerCoresDatas();
            $('#filtrarPeriodo' + pDados).addClass('menuAtivo');
            $scope.dataFinalFiltroAnalytics = new Date();
            $scope.dataFinalFiltroAnalytics.setHours(23);
            $scope.dataFinalFiltroAnalytics.setMinutes(59);
            $scope.dataFinalFiltroAnalytics.setSeconds(59);
            $scope.dataInicioFiltroAnalytics = new Date();
            $scope.dataInicioFiltroAnalytics.setDate($scope.dataInicioFiltroAnalytics.getDate() - pDados);
            $scope.dataInicioFiltroAnalytics.setHours(0);
            $scope.dataInicioFiltroAnalytics.setMinutes(0);
            $scope.dataInicioFiltroAnalytics.setSeconds(0);
            if (_usuarioSelecionadoFiltroInicio && $scope.usuArioSelecionadoFiltro) {
                $scope.idUsuarioSel = $scope.usuArioSelecionadoFiltro._id;
            }
            else {
                $scope.idUsuarioSel = '';
            }
            $scope.consultarTotaisEnviosCadencias();
            $scope.fazerConsultaGraficoAnalyticsInicio();
            setTimeout(function () {
                $scope.fazerConsultaGraficoAnalyticsInicio();
            }, 2000);
        };
        function removerCoresDatas() {
            $('#filtrarPeriodo7').removeClass('menuAtivo');
            $('#filtrarPeriodo15').removeClass('menuAtivo');
            $('#filtrarPeriodo30').removeClass('menuAtivo');
        }
        ;
        $scope.consultarTotaisEnviosCadencias = function () {
            $scope.loaderAnalyticsInicio = $scope.loaderInicio ? false : true;
            $scope.arrayCadenciasAtivas = [];
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/disparos/consultarTotaisCadencias"),
                headers: {
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                params: {
                    dataInicio: $scope.dataInicioFiltroAnalytics,
                    dataFim: $scope.dataFinalFiltroAnalytics
                }
            }).then(function (rTotais) {
                var totalIndicadores = rTotais.data;
                $scope.arrayCadenciasAtivas = $filter('orderBy')(rTotais.data.nomesCadenciasAtivas, 'nome');
                $scope.montarClassesDivListaCad();
                $scope.pluralCadenciaAtiva = '';
                $scope.pluralCadenciaAtivaPeriodo = '';
                $scope.qtdCadenciasAtivas = 0;
                $scope.qtdCadenciasAtivasPeriodo = 0;
                $scope.enviosAnalytics = 0;
                $scope.bouncesAnalytics = 0;
                $scope.percentualBouncesAnalytics = 0;
                $scope.entreguesAnalytics = 0;
                $scope.percentualEntreguesAnalytics = 0;
                $scope.abertosAnalytics = 0;
                $scope.percentualAbertosAnalytics = 0;
                $scope.respondidosAnalytics = 0;
                $scope.percentualRespondidosAnalytics = 0;
                $scope.interessesAnalytics = 0;
                $scope.percentualInteressesAnalytics = 0;
                $scope.qtdCadenciasAtivas = totalIndicadores.disparosAtivos;
                $scope.pluralCadenciaAtiva = totalIndicadores.disparosAtivos > 1 ? 'S' : '';
                if (totalIndicadores.quantidadeTotalEnvios) {
                    $scope.pluralCadenciaAtivaPeriodo = totalIndicadores.quantidadeTotalAtivas > 1 ? 'S' : '';
                    $scope.qtdCadenciasAtivasPeriodo = totalIndicadores.quantidadeTotalAtivas;
                    if (totalIndicadores.quantidadeTotalEnvios > 0) {
                        $scope.enviosAnalytics = totalIndicadores.quantidadeTotalEnvios;
                        $scope.bouncesAnalytics = totalIndicadores.quantidadeTotalBounces;
                        $scope.percentualBouncesAnalytics = Math.round(totalIndicadores.percentualTotalBounces);
                        $scope.entreguesAnalytics = totalIndicadores.quantidadeTotalEntregues;
                        $scope.percentualEntreguesAnalytics = Math.round(totalIndicadores.percentualTotalEntregues);
                        $scope.abertosAnalytics = totalIndicadores.quantidadeTotalAbertos;
                        $scope.percentualAbertosAnalytics = Math.round(totalIndicadores.percentualTotalAbertos);
                        $scope.respondidosAnalytics = totalIndicadores.quantidadeTotalRespostas;
                        $scope.percentualRespondidosAnalytics = Math.round(totalIndicadores.percentualTotalRespostas);
                        $scope.interessesAnalytics = totalIndicadores.quantidadeTotalInteresses;
                        $scope.percentualInteressesAnalytics = Math.round(totalIndicadores.percentualTotalInteresses);
                    }
                }
                else {
                    $scope.emptyGraficoAnalyticsInicio = true;
                }
                $scope.loaderAnalyticsInicio = false;
            });
        };
        $scope.fazerConsultaGraficoAnalyticsInicio = function () {
            $scope.arrayInfosGrafico = [];
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/disparos/metricas/dash"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                params: {
                    tipoDaChave: $rootScope.permissoes.tipoUsuario,
                    objectIdLogin: $scope.idUsuarioSel,
                    dataInicio: $scope.dataInicioFiltroAnalytics,
                    dataFim: $scope.dataFinalFiltroAnalytics,
                    tipoConsulta: 'entregues',
                    origem: 'i'
                }
            }).then(function (rTotais) {
                if (rTotais.data.length > 0 && $scope.qtdCadenciasAtivasPeriodo != 0) {
                    $scope.arrayInfosGrafico = rTotais.data;
                    $scope.emptyGraficoAnalyticsInicio = false;
                }
                else {
                    $scope.emptyGraficoAnalyticsInicio = true;
                }
                $scope.loaderAnalyticsInicio = false;
                $scope.mudarGrafico('entregues');
            });
        };
        $scope.mudarGrafico = function (pDados) {
            removerCoresIndicadores();
            $scope.IndicadorGrafico = pDados;
            if (pDados == 'entregues') {
                $scope.indicadorGraficoUm = 'Envios';
                $scope.indicadorGraficoDois = 'Entregues';
                $('#divEntreguesInicio').addClass('fundoVerde');
                $('#totalEntreguesInicio').addClass('corSpanBranco');
                $('#porcenEntreguesInicio').addClass('corSpanBranco');
                $scope.imgEntregues = '-selecionado';
            }
            else if (pDados == 'abertos') {
                $scope.indicadorGraficoUm = 'Entregues';
                $scope.indicadorGraficoDois = 'Abertos';
                $('#divAbertosInicio').addClass('fundoVerde');
                $('#totalAbertosInicio').addClass('corSpanBranco');
                $('#porcenAbertosInicio').addClass('corSpanBranco');
                $scope.imgAbertos = '-selecionado';
            }
            else if (pDados == 'respondidos') {
                $scope.indicadorGraficoUm = 'Abertos';
                $scope.indicadorGraficoDois = 'Respondidos';
                $('#divRespondidosInicio').addClass('fundoVerde');
                $('#totalRespondidosInicio').addClass('corSpanBranco');
                $('#porcenRespondidosInicio').addClass('corSpanBranco');
                $scope.imgRespondidos = '-selecionado';
            }
            else if (pDados == 'interesses') {
                $scope.indicadorGraficoUm = 'Respondidos';
                $scope.indicadorGraficoDois = 'Interesses';
                $('#divInteressesInicio').addClass('fundoVerde');
                $('#totalInteressesInicio').addClass('corSpanBranco');
                $('#porcenInteressesInicio').addClass('corSpanBranco');
                $scope.imgInteresses = '-selecionado';
            }
            else if (pDados == 'bounces') {
                $scope.indicadorGraficoUm = 'Enviados';
                $scope.indicadorGraficoDois = 'Bounces';
                $scope.imgBounces = '-selecionado';
                $('#divBouncesInicio').addClass('fundoVerde');
                $('#totalBouncesInicio').addClass('corSpanBranco');
                $('#porcenBouncesInicio').addClass('corSpanBranco');
            }
            $scope.montarGraficoAnalyticsInicio($scope.arrayInfosGrafico);
        };
        var chart = null;
        $scope.montarGraficoAnalyticsInicio = function (response) {
            moment.locale("pt-br");
            var arrayUm = [];
            var arrayDois = [];
            var arrayData = [];
            if ($scope.IndicadorGrafico == 'entregues') {
                response.forEach(function (item) {
                    arrayUm.push(item.quantidadeEnvios);
                    arrayDois.push(item.quantidadeEntregues);
                    arrayData.push(item._id.mes + "-" + item._id.dia_mes + "-" + item._id.ano);
                });
            }
            else if ($scope.IndicadorGrafico == 'abertos') {
                response.forEach(function (item) {
                    arrayUm.push(item.quantidadeEntregues);
                    arrayDois.push(item.quantidadeAbertos);
                    arrayData.push(item._id.mes + "-" + item._id.dia_mes + "-" + item._id.ano);
                });
            }
            else if ($scope.IndicadorGrafico == 'respondidos') {
                response.forEach(function (item) {
                    arrayUm.push(item.quantidadeAbertos);
                    arrayDois.push(item.quantidadeRespostas);
                    arrayData.push(item._id.mes + "-" + item._id.dia_mes + "-" + item._id.ano);
                });
            }
            else if ($scope.IndicadorGrafico == 'interesses') {
                response.forEach(function (item) {
                    arrayUm.push(item.quantidadeRespostas);
                    arrayDois.push(item.quantidadeInteresses);
                    arrayData.push(item._id.mes + "-" + item._id.dia_mes + "-" + item._id.ano);
                });
            }
            else if ($scope.IndicadorGrafico == 'bounces') {
                response.forEach(function (item) {
                    arrayUm.push(item.quantidadeEnvios);
                    arrayDois.push(item.quantidadeBounce);
                    arrayData.push(item._id.mes + "-" + item._id.dia_mes + "-" + item._id.ano);
                });
            }
            var dataInicioGraficoDash = moment($scope.dataInicioFiltroAnalytics).format("MM-DD-YYYY");
            var dataFimGraficoDash = moment($scope.dataFinalFiltroAnalytics).format("MM-DD-YYYY");
            if (arrayData.includes(dataFimGraficoDash) == false) {
                arrayUm.push(0);
                arrayDois.push(0);
                arrayData.push(dataFimGraficoDash);
            }
            if (arrayData.includes(dataInicioGraficoDash) == false) {
                arrayUm.unshift(0);
                arrayDois.unshift(0);
                arrayData.unshift(dataInicioGraficoDash);
            }
            var options = {
                chart: {
                    locales: [{
                            "name": "pt-br",
                            "options": {
                                "months": ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
                                "shortMonths": ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
                                "days": ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sabado"],
                                "shortDays": ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
                                "toolbar": {
                                    "exportToSVG": "Download SVG",
                                    "exportToPNG": "Download PNG",
                                    "menu": "Menu",
                                    "selection": "Selection",
                                    "selectionZoom": "Selection Zoom",
                                    "zoomIn": "Zoom In",
                                    "zoomOut": "Zoom Out",
                                    "pan": "Panning",
                                    "reset": "Reset Zoom"
                                }
                            }
                        }],
                    defaultLocale: "pt-br",
                    height: 230,
                    width: '106%',
                    type: "area",
                    toolbar: {
                        show: false,
                    },
                    zoom: {
                        enabled: false,
                    }
                },
                series: [
                    {
                        name: $scope.indicadorGraficoUm,
                        data: arrayUm
                    },
                    {
                        name: $scope.indicadorGraficoDois,
                        data: arrayDois
                    }
                ],
                dataLabels: {
                    enabled: false,
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shadeIntensity: 1,
                        opacityFrom: 0.5,
                        opacityTo: 0,
                        stops: [0, 90, 100]
                    }
                },
                stroke: {
                    curve: 'straight',
                    width: 1,
                },
                markers: {
                    size: 3
                },
                xaxis: {
                    type: "datetime",
                    lines: {
                        show: true,
                    },
                    categories: arrayData,
                    tickAmount: $scope.numeroDiasGraficoDash,
                    labels: {
                        style: {
                            fontSize: '10px',
                            colors: '#adadad',
                        },
                        formatter: function (value, timestamp) {
                            return moment(timestamp).format("DD MMM");
                        }
                    }
                },
                yaxis: {
                    lines: {
                        show: true,
                    },
                    min: 0,
                    show: true,
                    floating: false,
                    labels: {
                        style: {
                            fontSize: '10px',
                            color: '#adadad',
                        }
                    }
                },
                legend: {
                    position: 'bottom',
                    horizontalAlign: 'right',
                    offsetY: -10,
                    fontSize: '10px',
                    labels: {
                        colors: '#adadad',
                    },
                    markers: {
                        width: 8,
                        height: 8,
                    }
                },
                grid: {
                    borderColor: '#e8e8e8',
                    xaxis: {
                        lines: {
                            show: true
                        }
                    },
                    yaxis: {
                        lines: {
                            show: true
                        }
                    }
                },
                tooltip: {
                    marker: {
                        show: true,
                    },
                    followCursor: true,
                    x: {
                        show: false
                    },
                    y: {
                        formatter: function (y) {
                            try {
                                return formatarValor(y);
                            }
                            catch (error) {
                                return y;
                            }
                        }
                    }
                },
                colors: ['#33ab59', '#176360']
            };
            if (chart != null)
                chart.destroy();
            chart = new ApexCharts(document.querySelector("#chart_div_inicio"), options);
            chart.render();
        };
        function removerCoresIndicadores() {
            $('#divEntreguesInicio').removeClass('fundoVerde');
            $('#totalEntreguesInicio').removeClass('corSpanBranco');
            $('#porcenEntreguesInicio').removeClass('corSpanBranco');
            $('#divAbertosInicio').removeClass('fundoVerde');
            $('#totalAbertosInicio').removeClass('corSpanBranco');
            $('#porcenAbertosInicio').removeClass('corSpanBranco');
            $('#divRespondidosInicio').removeClass('fundoVerde');
            $('#totalRespondidosInicio').removeClass('corSpanBranco');
            $('#porcenRespondidosInicio').removeClass('corSpanBranco');
            $('#divInteressesInicio').removeClass('fundoVerde');
            $('#totalInteressesInicio').removeClass('corSpanBranco');
            $('#porcenInteressesInicio').removeClass('corSpanBranco');
            $('#divBouncesInicio').removeClass('fundoVerde');
            $('#totalBouncesInicio').removeClass('corSpanBranco');
            $('#porcenBouncesInicio').removeClass('corSpanBranco');
            $scope.imgEntregues = '';
            $scope.imgAbertos = '';
            $scope.imgRespondidos = '';
            $scope.imgInteresses = '';
            $scope.imgBounces = '';
        }
        ;
        var controleCarrocel = 0;
        $scope.contadorCarrosselExplore = function (acao) {
            if (acao == 'a') {
                if ($scope.qtdCardsVistos < $scope.arrayExploreRamper.length) {
                    controleCarrocel -= 405;
                    $('#carrosselInicio').animate({ 'left': controleCarrocel }, 200);
                    $scope.qtdCardsVistos++;
                }
            }
            if (acao == 'v') {
                if ($scope.qtdCardsVistos > 3) {
                    controleCarrocel += 405;
                    $('#carrosselInicio').animate({ 'left': controleCarrocel }, 200);
                    $scope.qtdCardsVistos--;
                }
            }
        };
        function drawChartContatos() {
            var qtdContatosAlta = $scope.totaisContatos.totalAlta;
            var qtdContatosMedia = $scope.totaisContatos.totalMedia;
            var qtdContatosBaixa = $scope.totaisContatos.totalBaixa;
            var qtdContatosSem = $scope.totaisContatos.totalSem;
            var percContatosAlta = $scope.totaisContatos.percentualAlta;
            var percContatosMedia = $scope.totaisContatos.percentualMedia;
            var percContatosBaixa = $scope.totaisContatos.percentualBaixa;
            var percContatosSem = $scope.totaisContatos.percentualSem;
            var txtAlta = percContatosAlta.toFixed() + '% (' + qtdContatosAlta + ') alta';
            var txtMedia = percContatosMedia.toFixed() + '% (' + qtdContatosMedia + ') média';
            var txtBaixa = percContatosBaixa.toFixed() + '% (' + qtdContatosBaixa + ') baixa';
            var txtSem = percContatosSem.toFixed() + '% (' + qtdContatosSem + ') sem';
            var data = google.visualization.arrayToDataTable([
                ['Confiabilidade', 'Indicadores'],
                [txtAlta, (qtdContatosAlta)],
                [txtMedia, (qtdContatosMedia)],
                [txtBaixa, (qtdContatosBaixa)],
                [txtSem, (qtdContatosSem)],
            ]);
            var options = {
                width: 250,
                height: 140,
                pieHole: 0.8,
                pieSliceText: 'none',
                legend: 'none',
                tooltip: { trigger: "none" },
                chartArea: {
                    width: 250,
                    height: 120,
                },
                slices: {
                    0: { color: '#75c08c' },
                    1: { color: '#f9e069' },
                    2: { color: '#ff5050' },
                    3: { color: '#c6c5c5' },
                }
            };
            var chart = new google.visualization.PieChart(document.getElementById('donut_contatos'));
            chart.draw(data, options);
        }
        ;
        $scope.abrirFecharFiltroUsuario = function () {
            $scope.filtroUsuarioAberto = !$scope.filtroUsuarioAberto;
            if ($scope.filtroUsuarioAberto) {
                $('#idFiltroUsuarioInicio').show();
                setTimeout(function () {
                    document.getElementById("buscarUsuariosInicio").focus();
                }, 10);
            }
            else {
                $('#idFiltroUsuarioInicio').hide();
            }
        };
        $scope.selecionarUsuarioFiltrar = function (pDados) {
            if (pDados) {
                $scope.usuArioSelecionadoFiltro = pDados;
                document.getElementById("sltUsuarioInicio").innerText = pDados.nome;
                $scope.idUsuarioSel = $scope.usuArioSelecionadoFiltro._id;
            }
            _usuarioSelecionadoFiltroInicio = true;
            $scope.mostrarEmptyStatesNovaConta = false;
            $('#usuarioFiltroDefinido').show();
            $scope.filtroUsuarioAberto = false;
            $scope.inicio();
            $scope.outroUsuario = true;
            $scope.buscarUsuariosInicio = '';
        };
        $scope.limparFiltroUsuarioInicio = function () {
            $scope.mostrarEmptyStatesNovaConta = false;
            _usuarioSelecionadoFiltroInicio = false;
            document.getElementById("sltUsuarioInicio").innerText = 'Filtrar por usuário';
            $('#usuarioFiltroDefinido').hide();
            $scope.idUsuarioSel = '';
            $scope.inicio();
        };
        $(document).mouseup(function (e) {
            var filtroUsuarioInicio = $('#idFiltroUsuarioInicio');
            var btnFiltroUsuarioInicio = $('#sltUsuarioInicio');
            var filtroCad = $('#idDivListaCadenciasAtivas');
            var abrirDivCad = $('#divGeralAnalyticsInicio .imgVerCadAtivas');
            if (!filtroUsuarioInicio.is(e.target) && filtroUsuarioInicio.has(e.target).length === 0) {
                if (!btnFiltroUsuarioInicio.is(e.target) && btnFiltroUsuarioInicio.has(e.target).length === 0) {
                    $scope.filtroUsuarioAberto = false;
                    filtroUsuarioInicio.hide();
                }
            }
            if (!filtroCad.is(e.target) && filtroCad.has(e.target).length === 0) {
                if (!abrirDivCad.is(e.target) && abrirDivCad.has(e.target).length === 0) {
                    $scope.divListaCadenciasAtivas = false;
                    $('#idDivListaCadenciasAtivas').hide();
                    $scope.$apply();
                }
            }
        });
        $scope.fazerDownloadRelatorio = function () {
            $('#esconderDiv1').hide();
            $('#esconderDiv2').hide();
            $('#esconderDiv3').hide();
            _usuarioSelecionadoFiltroInicio ? $('#esconderDiv5').hide() && $('#usuarioFiltroDefinido').hide() : $('#esconderDiv4').hide();
            window.print();
            $('#esconderDiv1').show();
            $('#esconderDiv2').show();
            $('#esconderDiv3').show();
            _usuarioSelecionadoFiltroInicio ? $('#esconderDiv5').show() && $('#usuarioFiltroDefinido').show() : $('#esconderDiv4').show();
        };
        $scope.fecharAlertaMensagemConfiguracaoEmail = function () {
            $rootScope.mostrarMensagemErroConfigEmail = false;
        };
        if ($rootScope.permissoes.tipoUsuario != "U") {
            $scope.loaderInicio = true;
            $('#menuPrincipal').addClass('blur');
            $('#pagInicio').addClass('blur');
            $('#emptyTelasInicio').addClass('blur');
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/filtros/usuarios"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                }
            }).then(function (response) {
                $scope.listaUsuariosInicio = [];
                response.data.forEach(function (time) {
                    time.loginsAtivos.forEach(function (usuario) {
                        $scope.listaUsuariosInicio.push(usuario);
                        if (usuario.fotoPerfil)
                            usuario.fotoPerfil = "".concat($rootScope.bucketUserPhotos, "/usuariosRamper/") + usuario._id.toString() + "/" + usuario.fotoPerfil;
                        else
                            usuario.fotoPerfil = "".concat($rootScope.bucket, "/avatarPadrao.jpg");
                    });
                });
                $scope.listaUsuariosInicio.sort(function (a, b) {
                    return a.nome < b.nome ? -1 : a.nome > b.nome ? 1 : 0;
                });
                $scope.selecionarUsuarioFiltrar($scope.listaUsuariosInicio.find(function (x) { return x.email === $rootScope.emailUsuario; }));
            });
        }
        else {
            $scope.inicio();
        }
        ;
        $scope.abrirListaCadAtivas = function () {
            $scope.arrayCadenciasAtivas.length < 8 ? $('#idDivListaCadenciasAtivas').css('margin-bottom', ($scope.arrayCadenciasAtivas.length * 42 + 2) * -1) : $('#idDivListaCadenciasAtivas').css('margin-bottom', '-294px');
            $scope.divListaCadenciasAtivas = !$scope.divListaCadenciasAtivas;
            $scope.divListaCadenciasAtivas ? $('#idDivListaCadenciasAtivas').show() : $('#idDivListaCadenciasAtivas').hide();
        };
        $scope.montarClassesDivListaCad = function () {
            if ($scope.arrayCadenciasAtivas[0]) {
                $scope.arrayCadenciasAtivas[0].classe = 'hoverPrimeiraDiv';
                $scope.arrayCadenciasAtivas[$scope.arrayCadenciasAtivas.length - 1].classe = 'hoverUltimaDiv';
            }
        };
        $scope.consultarCapturasHoje = function () {
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/login/consultarContatosMixpanel"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto
                }
            }).then(function (rConsultarContatosMixpanel) {
                if (rConsultarContatosMixpanel.data) {
                    rConsultarContatosMixpanel.data = false;
                    mixpanel.track("Captura Extensão | O usuario capturou contatos hoje", {});
                }
            });
        };
        // TODO: usar permissionamento
        $scope.checkVoipPermission = function () {
            // return ($rootScope.Plano === 'P' || $rootScope.temDireitoConexao) && $rootScope.Plano !== 'B' && !$rootScope.permissoes.banner.seePipeline;
            return true;
        };
        $scope.consultarCapturasHoje();
    }]);
