angular.module("ramperv3").service("MonsterAlterarMinhaSenhaService", [
    "$http",
    "$location",
    "$rootScope",
    function ($http, $location, $rootScope) {
        /**
         * Deve consumir a api para realizar a alteração da senha do usuário
         * @param {senha, senhaConfirmacao} user nova senha do usuário
         */
        function alterarMinhaSenha(user) {
            var headers = {
                "ramp3r-auth": "ramp3r-authorization",
                "ramp3r-l": $location.$$path.split("/")[3],
            };
            var data = {
                novaSenha: user.senha,
                confirmacaoSenha: user.senhaConfirmacao,
            };
            return $http({
                method: "POST",
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/login/resetarMinhaSenha"),
                headers: headers,
                data: data,
            }).then(function (response) {
                if (response) {
                    desbloquearConta();
                }
                return;
            });
        }
        /**
         * Deve consumir a api para realizar o debloqueio da conta caso necessário
         */
        function desbloquearConta() {
            var headers = {
                "ramp3r-auth": "ramp3r-authorization",
                "ramp3r-l": $location.$$path.split("/")[3],
            };
            $http({
                method: "PATCH",
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/login/desbloquear-conta"),
                headers: headers,
            });
        }
        return { alterarMinhaSenha: alterarMinhaSenha };
    },
]);
