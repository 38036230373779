angular.module('ramperv3')
    .component('sidebarChatboxQuestionario', {
    templateUrl: 'views/sidebarChatboxQuestionario.html',
    controller: ['$rootScope', '$scope', '$http', function ($rootScope, $scope, $http) {
            $ctrl = this;
            $scope.questao = '';
            $scope.listaQuestoes = [];
            $scope.idQuestionario = null;
            $scope.bucket = $rootScope.bucket;
            var getQuestoes = function () {
                $http({
                    method: 'GET',
                    url: "".concat($rootScope.ramperV3ApiBaseUrl, "/chat/questionario"),
                    headers: {
                        'ramp3r-auth': 'ramp3r-authorization',
                        'ramp3r-l': $rootScope.IDCrypto,
                        'Authorization': 'Bearer ' + $rootScope.Token
                    }
                })
                    .then(function (res) {
                    return res.data;
                })
                    .then(function (questionario) {
                    if (!questionario.err && !questionario.warning) {
                        $scope.idQuestionario = questionario._id;
                        $scope.listaQuestoes = questionario.questoes;
                    }
                });
            };
            $scope.insertQuestao = function () {
                if ($scope.questao == '')
                    return;
                if ($scope.questao == null)
                    return;
                $http({
                    method: 'POST',
                    url: "".concat($rootScope.ramperV3ApiBaseUrl, "/chat/questionario"),
                    headers: {
                        'ramp3r-auth': 'ramp3r-authorization',
                        'ramp3r-l': $rootScope.IDCrypto,
                        'Authorization': 'Bearer ' + $rootScope.Token
                    },
                    data: {
                        questao: $scope.questao
                    }
                })
                    .then(function (res) {
                    return res.data;
                })
                    .then(function (questionario) {
                    $scope.listaQuestoes = questionario.questoes;
                    $scope.questao = '';
                });
            };
            $scope.deletarQuestao = function (idQuestao) {
                $http({
                    method: 'DELETE',
                    url: "".concat($rootScope.ramperV3ApiBaseUrl, "/chat/questionario/").concat($scope.idQuestionario, "/").concat(idQuestao),
                    headers: {
                        'ramp3r-auth': 'ramp3r-authorization',
                        'ramp3r-l': $rootScope.IDCrypto,
                        'Authorization': 'Bearer ' + $rootScope.Token
                    }
                })
                    .then(function (res) {
                    return res.data;
                })
                    .then(function (questionario) {
                    $scope.listaQuestoes = questionario.questoes;
                });
            };
            $scope.editarQuestao = function (idQuestao, questao) {
                $http({
                    method: 'PUT',
                    url: "".concat($rootScope.ramperV3ApiBaseUrl, "/chat/questionario/").concat($scope.idQuestionario, "/").concat(idQuestao),
                    headers: {
                        'ramp3r-auth': 'ramp3r-authorization',
                        'ramp3r-l': $rootScope.IDCrypto,
                        'Authorization': 'Bearer ' + $rootScope.Token
                    },
                    data: {
                        questao: questao
                    }
                })
                    .then(function (res) {
                    return res.data;
                })
                    .then(function (questionario) {
                    $scope.listaQuestoes = questionario.questoes;
                });
            };
            $scope.handleUpdateQuestao = function (e, item) {
                if (e.which === 13 && !e.shiftKey) {
                    $scope.editarQuestao(item._id, item.questao);
                }
            };
            $scope.close = function () {
                $ctrl.close();
            };
            $('#input-questao').keydown(function (e) {
                if (e.which === 13 && !e.shiftKey) {
                    e.preventDefault();
                    $scope.insertQuestao();
                }
            });
            $('#container-input-questao').click(function (e) {
                $('#input-questao').focus();
            });
            $('#input-questao').focus(function () {
                $('.botao-input-questao').hide();
            });
            $('#input-questao').blur(function () {
                5;
                $('.botao-input-questao').show();
            });
            $ctrl.$onInit = function () {
                getQuestoes();
            };
        }],
    bindings: {
        close: '&'
    }
});
