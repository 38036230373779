angular.module("ramperv3").component("sidebarChatboxContato", {
    templateUrl: "views/sidebarChatboxContato.html",
    controller: ['$rootScope', '$scope', function ($rootScope, $scope) {
            $ctrl = this;
            $scope.bucket = $rootScope.bucket;
            $scope.isProspectSectionOpen = document
                .querySelector("#dadosContato")
                .classList.contains("show");
            $scope.isCompanySectionOpen = document
                .querySelector("#dadosEmpresa")
                .classList.contains("show");
            $scope.prospect = null;
            $scope.company = null;
            $scope.socialNetworks = null;
            $scope.pending = true;
            $scope.$on("prospectLoadingEvent", function (event, value) {
                if (value != null) {
                    $scope.pending = value;
                    $(".sidebar-contato-content").toggleClass("blur", value);
                }
            });
            $scope.$on("prospectChanged", function (event, value) {
                if (value) {
                    $scope.prospect = value;
                    $scope.company = value.empresa;
                    $scope.socialNetworks = {
                        linkedin: $scope.filterSocialNetwork(value.redesSociais, "L"),
                        facebook: $scope.filterSocialNetwork(value.redesSociais, "F"),
                        twitter: $scope.filterSocialNetwork(value.redesSociais, "T"),
                        instagram: $scope.filterSocialNetwork(value.redesSociais, "I"),
                    };
                }
                else {
                    $scope.prospect = null;
                    $scope.company = null;
                    $scope.socialNetworks = null;
                }
            });
            $scope.toggleProspectSection = function () {
                $scope.isProspectSectionOpen = !$scope.isProspectSectionOpen;
            };
            $scope.toggleCompanySection = function () {
                $scope.isCompanySectionOpen = !$scope.isCompanySectionOpen;
            };
            $scope.filterSocialNetwork = function (socialNetworks, socialNetwork) {
                var filtered = socialNetworks.filter(function (sn) {
                    return sn.tipo == socialNetwork;
                });
                return filtered[0];
            };
            $scope.isLink = function (socialNetwork) {
                if (socialNetwork) {
                    return socialNetwork.indexOf("http") > -1;
                }
                return false;
            };
            $scope.showProduct = function (origin) {
                var defaultProduct = "Prospect";
                var prducts = {
                    DL: "Engage",
                };
                return prducts[origin] || defaultProduct;
            };
            $scope.showOrigin = function (origin) {
                var origins = {
                    I: "Importação de lista",
                    M: "Inclusão manual",
                    C: "Extensão",
                    E: "Encontrados via Ramper",
                    R: "Reengajamento",
                    CH: "Chat",
                    DL: "Engage",
                };
                return origins[origin] || "";
            };
            $scope.selectReliability = function (reliability) {
                var noInfo = { color: "#7A7A7A", text: "Sem" };
                var low = { color: "#E04747", text: "Baixa" };
                var medium = { color: "#EFB734", text: "Média" };
                var high = { color: "#1BB258", text: "Alta" };
                if (reliability == null || reliability == 0)
                    return noInfo;
                if (reliability < 40)
                    return low;
                if (reliability < 70)
                    return medium;
                return high;
            };
            $scope.close = function () {
                $ctrl.close();
            };
        }],
    bindings: {
        close: "&",
    },
});
