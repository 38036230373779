var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
angular.module("ramperv3").service("MonsterEnviarContatosIntegracaoService", [
    "$rootScope", "$http",
    function ($rootScope, $http) {
        /**
         * Deve consumir a api para obter as integrações configuradas
         */
        function obterIntegracoes() {
            var url = "/integracoes/consultarIntegracoes";
            var headers = { "ramp3r-auth": "ramp3r-authorization", "ramp3r-l": $rootScope.IDCrypto, 'Authorization': 'Bearer ' + $rootScope.Token };
            var params = { status: "A" };
            return $http({ method: "GET", url: url, headers: headers, params: params });
        }
        /**
         * Deve consumir a api para enviar contatos para integração
         */
        function enviarContatoCRM(integration, contatoIds, queryParams) {
            var headers = { "ramp3r-auth": "ramp3r-authorization", "ramp3r-l": $rootScope.IDCrypto, 'Authorization': 'Bearer ' + $rootScope.Token };
            return $http({
                method: "POST",
                url: "/integracoes/crm",
                headers: headers,
                params: __assign({ crm: integration.application, apiKey: integration.apiKey, objectIdContato: contatoIds }, queryParams),
            });
        }
        /**
         * Deve consumir a api para enviar contatos para integração utilizando a fila
         */
        function publicarContatoCRM(integration, contatoIds, queryParams, integracao) {
            var headers = { "ramp3r-auth": "ramp3r-authorization", "ramp3r-l": $rootScope.IDCrypto, 'Authorization': 'Bearer ' + $rootScope.Token };
            var data = { crm: integration.application, objectIdContato: contatoIds, integracao: integracao };
            return $http({ method: "POST", url: "/integracoes/crm/fila", headers: headers, params: queryParams, data: data });
        }
        //========================================================================//
        //=============================PIPELINE===================================//
        //========================================================================//
        /**
         * Deve consumir a api para obter od usuários do Pipeline
         */
        function obterUsuariosPipeline(apiKey) {
            var url = "/integracoes/linkseller/usuarios";
            var headers = { "ramp3r-auth": "ramp3r-authorization", "ramp3r-l": $rootScope.IDCrypto, 'Authorization': 'Bearer ' + $rootScope.Token };
            var params = { apiKey: apiKey };
            return $http({ method: "GET", url: url, headers: headers, params: params });
        }
        /**
         * Deve consumir a api para obter od usuários do Pipeline
         */
        function obterPipelinesPipeline(apiKey) {
            var url = "/integracoes/linkseller/pipelines";
            var headers = { "ramp3r-auth": "ramp3r-authorization", "ramp3r-l": $rootScope.IDCrypto, 'Authorization': 'Bearer ' + $rootScope.Token };
            var params = { apiKey: apiKey };
            return $http({ method: "GET", url: url, headers: headers, params: params });
        }
        /**
         * Deve consumir a api para obter od usuários do Pipeline
         */
        function obterEstagiosPipeline(apiKey, idPipeline) {
            var url = '/integracoes/linkseller/pipelines/' + idPipeline + '/estagios';
            var headers = { "ramp3r-auth": "ramp3r-authorization", "ramp3r-l": $rootScope.IDCrypto, 'Authorization': 'Bearer ' + $rootScope.Token };
            var params = { apiKey: apiKey };
            return $http({ method: "GET", url: url, headers: headers, params: params });
        }
        /**
         * Deve consumir a api para salvar as configurações do Pipedrive
         */
        function salvarOpcoesPipeline(apiKey, idVendedor, idPipeline, idEstagio) {
            var url = "/integracoes/salvar";
            var headers = { "ramp3r-auth": "ramp3r-authorization", "ramp3r-l": $rootScope.IDCrypto, 'Authorization': 'Bearer ' + $rootScope.Token };
            var data = { apiKey: apiKey, idVendedor: idVendedor, idPipeline: idPipeline, idEstagio: idEstagio, aplicacao: "linkseller" };
            return $http({ method: "POST", url: url, headers: headers, data: data });
        }
        //========================================================================//
        //===========================DYNAMICS 365================================//
        //========================================================================//
        /**
         * Deve consumir a api para obter as configurações do Dynamics 365
         */
        function obterDadosDynamics365() {
            var url = "/integracoes/dynamics365/retorna-auth/".concat($rootScope.DadosCadastrais.objectIdLogin);
            var headers = { "ramp3r-auth": "ramp3r-authorization", "ramp3r-l": $rootScope.IDCrypto, 'Authorization': 'Bearer ' + $rootScope.Token };
            return $http({ method: 'GET', url: url, headers: headers });
        }
        //========================================================================//
        //=============================PIPEDRIVE==================================//
        //========================================================================//
        /**
         * Deve consumir a api para obter as configurações do Pipedrive
         */
        function obterOpcoesPipedrive(apiKey) {
            var url = "/integracoes/pipedrive/consultarDadosPipelineEstagiosUsuarios";
            var headers = { "ramp3r-auth": "ramp3r-authorization", "ramp3r-l": $rootScope.IDCrypto, 'Authorization': 'Bearer ' + $rootScope.Token };
            var params = { apiKey: apiKey };
            return $http({ method: "GET", url: url, headers: headers, params: params });
        }
        /**
         * Deve consumir a api para salvar as configurações do Pipedrive
         */
        function salvarOpcoesPipedrive(apiKey, pipelineId, stageId, userId) {
            var url = "/integracoes/salvar";
            var headers = { "ramp3r-auth": "ramp3r-authorization", "ramp3r-l": $rootScope.IDCrypto, 'Authorization': 'Bearer ' + $rootScope.Token };
            var data = { apiKey: apiKey, pipelineId: pipelineId, stageId: stageId, userId: userId, aplicacao: "P" };
            return $http({ method: "POST", url: url, headers: headers, data: data });
        }
        return {
            obterIntegracoes: obterIntegracoes,
            enviarContatoCRM: enviarContatoCRM,
            publicarContatoCRM: publicarContatoCRM,
            obterUsuariosPipeline: obterUsuariosPipeline,
            obterPipelinesPipeline: obterPipelinesPipeline,
            salvarOpcoesPipeline: salvarOpcoesPipeline,
            obterEstagiosPipeline: obterEstagiosPipeline,
            obterDadosDynamics365: obterDadosDynamics365,
            obterOpcoesPipedrive: obterOpcoesPipedrive,
            salvarOpcoesPipedrive: salvarOpcoesPipedrive,
        };
    },
]);
