angular.module('ramperv3')
    .controller('SegmentacoesController', ['$rootScope', '$scope', '$http', '$locale', 'PermissionamentoService', function ($rootScope, $scope, $http, $locale, PermissionamentoService) {
        ga('set', 'page', document.location.hash);
        ga('send', 'pageview');
        $scope.showsubMenuContatos = $rootScope.permissoes.segmentacoes.moveToSegmentation || $rootScope.permissoes.segmentacoes.sendToSegmentation
            || $rootScope.permissoes.segmentacoes.removeFromSegmentation;
        $scope.showSubMenuDetalhes = $rootScope.permissoes.segmentacoes.rename || $rootScope.permissoes.segmentacoes.delete;
        $rootScope.consultarStatusUsuario();
        var _pagina = -10;
        var _campoOrdem = 'nome';
        var _ordem = 1;
        var _limite = 10;
        var _segmentacoesSelecionadasExcluir = [];
        $scope.buscarContato = '';
        $scope.filtroStatus = '-';
        $locale.NUMBER_FORMATS.GROUP_SEP = '.';
        $scope.filtroConfiabilidade = 'T';
        $scope.nomeBtnAvancar = 'AVANÇAR';
        $rootScope.viewBtnAvancar = true;
        $scope.campoFiltroFiltro = false;
        $scope.popupDuplicarPara = false;
        $scope.popupTransferirPara = false;
        _clickCheckTodosSeg = false;
        $scope.semContatosSegBusca = false;
        $scope.campoBuscaHabiliado = false;
        $scope.campoFiltroHabiliado = false;
        $scope.campoBuscaFiltro = false;
        $rootScope.viewBtnSalvarDuplicarPara = false;
        $('#dvMensagemNomeSegmentacaoFiltro').hide();
        $('#txtMsgErroSegmentacaoFiltro').hide();
        $('#idFundoPopupSegmentacao').hide();
        $scope.divSelecionada = 0;
        $rootScope.popupExportarContatosSeg = false;
        $scope.trocarOrdem = true;
        $scope.filtroSegmentacao = '-';
        $scope.filtroContato = '-';
        $scope.buscar = '';
        $scope.inptRenomearSegmentacao = '';
        $scope.acao = 'a';
        $scope.destino = 'd';
        $scope.especificacao = 'e';
        $scope.totalSelecionados = 0;
        $scope.totalDLSelecionados = 0;
        $scope.totalSelecionadosSeg = 0;
        $scope.totalContatosSegmantacao = 0;
        $scope.totalContatos = 0;
        $rootScope.mostrarMenu = true;
        $rootScope.popupRemove = false;
        $rootScope.semContatosCadencia = false;
        $scope.popupRenomearSegmentacao = false;
        $scope.semSegmentacoesFiltro = false;
        $scope.semSegmentacoesBusca = false;
        var _clickCheckTodos = false;
        $rootScope.subMenuDeSegmentacoes = false;
        $scope.popupFiltrarSegmentacoes = false;
        $rootScope.dvDetalhesGeralSegmentacoesCapturashow = false;
        $rootScope.popupCompararSegmentacao = false;
        $rootScope.redirectSegmentacoes();
        $scope.semSegmentacoes = false;
        var _arraySegmentacoesSelecionadas = [];
        var _segmentacaoSelecionada = null;
        $scope.listaUsuarios = [];
        $scope.listaCadencias = [];
        $scope.listaSegmentacaoTransferir = [];
        $scope.converterEmNovoAoTransferirContatos = false;
        $scope.desativarCheckTodosSegContato = false;
        $scope.expandOrCollapseSideMenu = true;
        $scope.menuNavAberto = true;
        $scope.segmentacaoIntegracaoSite = false;
        var _filtros = {
            tela: 'Segmentacoes',
            tipoDaChave: $rootScope.permissoes.tipoUsuario
        };
        var _filtrosEstatisticas = {
            objectId: ''
        };
        $('#dvDetalhesGeralSegmentacoes').hide();
        $scope.consultarSegmentacoes = function () {
            $scope.semSegmentacoesBusca = false;
            $scope.semSegmentacoesFiltro = false;
            $scope.semSegmentacoes = false;
            $('#tbleContatosCadencia').removeClass('emptyAtivo');
            $rootScope.loaderAtivo = true;
            $('#pagSegmentacoes').addClass('blur');
            $('#menuPrincipal').addClass('blur');
            var params = {
                integracaoSite: true,
            };
            Object.assign(params, _filtros);
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/segmentacoes/consultarSegmentacoes"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                params: params
            }).then(function (rConsulta) {
                $rootScope.loaderAtivo = false;
                $('#pagSegmentacoes').removeClass('blur');
                $('#menuPrincipal').removeClass('blur');
                $scope.showBrowserSegmentacoes = [];
                $scope.semSegmentacoes = false;
                if (rConsulta.data.length > 0) {
                    $scope.semSegmentacoesBusca = false;
                    $scope.semSegmentacoesFiltro = false;
                    $scope.semSegmentacoes = false;
                    rConsulta.data.forEach(function (segmentacao) {
                        segmentacao.segSelecionada = '';
                        segmentacao.txtMais = 'mais';
                        segmentacao.controleInfos = 0;
                        $scope.showBrowserSegmentacoes.push(segmentacao);
                    });
                }
                else {
                    $scope.total = "0";
                    if ($scope.showBrowserSegmentacoes.length <= 0) {
                        if ($scope.campoBuscaFiltro == true) {
                            $scope.semSegmentacoesBusca = true;
                            $scope.buscaOuFiltro = 'Sua busca';
                            $('#tbleContatosCadencia').addClass('emptyAtivo');
                            $scope.semSegmentacoesFiltro = false;
                        }
                        else if ($scope.campoFiltroFiltro == true) {
                            $scope.semSegmentacoesFiltro = true;
                            $scope.buscaOuFiltro = 'Seu filtro';
                            $('#tbleContatosCadencia').addClass('emptyAtivo');
                            $scope.semSegmentacoesBusca = false;
                        }
                        else {
                            $scope.semSegmentacoes = true;
                            $scope.semSegmentacoesBusca = false;
                            $scope.semSegmentacoesFiltro = false;
                        }
                    }
                }
                $scope.total = $scope.showBrowserSegmentacoes.length;
                $scope.contadorSelecionados();
            });
        };
        $scope.consultarDisparosSegmantacao = function (pFiltros) {
            _segmentacaoSelecionada.controleInfos = 0;
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/segmentacoes/consultarDisparosSegmantacao"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                params: {
                    objectId: pFiltros.objectId,
                    tipoDaConsulta: 'S'
                }
            }).then(function (rConsultarDisparosSegmantacao) {
                $scope.showBrowserSegmentacoes.forEach(function (segmentacao) {
                    if (segmentacao._id._id == rConsultarDisparosSegmantacao.data._id) {
                        segmentacao.cadencias = rConsultarDisparosSegmantacao.data.cadencias;
                        segmentacao.infoDisparos = rConsultarDisparosSegmantacao.data.infoDisparos;
                        segmentacao.controleInfos = 1;
                    }
                });
            });
        };
        $scope.fecharDetalhes = function () {
            $('#dvDetalhesGeral').fadeOut();
            $('#dvDetalhesDados').hide();
            $('#dvDetalhesMetricas').hide();
            $('#dvDetalhesContatos').hide();
            $('#dvDetalhesContatoResumo').hide();
            $('#checkSegmentacoesContatos').prop('checked', false);
            _clickCheckTodosSeg = false;
            _clickCheckTodosSegmtacoes = false;
            $scope.semContatosSegBusca = false;
            $scope.semContatosSegFiltro = false;
            $scope.totalSelecionadosSeg = 0;
            $scope.campoFiltroHabiliado = false;
            $scope.campoBuscaHabiliado = false;
            $scope.buscarContato = '';
            $scope.removerFiltroTudoContato();
            $scope.removerParametrosContatos();
            $scope.consultarSegmentacoes();
            if ($rootScope.permissoes.tipoUsuario != "U") {
                $scope.selecionarUsuarioSeg($scope.teste);
            }
            else {
                $scope.consultarCadenciasSimples();
            }
            //$scope.recarregarPagina()
        };
        removerDestaqueMenu = function () {
            $('#liDetalhesDados').removeClass('liMenuDetalhesActive');
            $('#liDetalhesMetricas').removeClass('liMenuDetalhesActive');
            $('#liDetalhesCadencias').removeClass('liMenuDetalhesActive');
            $('#liDetalhesSegmentacoes').removeClass('liMenuDetalhesActive');
            $('#liDetalhesResumo').removeClass('liMenuDetalhesActive');
        };
        $scope.consultarSegmentacoesPorUsuario = function () {
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/segmentacoes/consultarSegmentacoesUsuario"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                params: {
                    tipoDaChave: $rootScope.permissoes.tipoUsuario
                }
            }).then(function (response) {
                $scope.listaSegmentacaoTransferir = response.data;
                $scope.listaSegmentacaoTransferir.sort(function (a, b) {
                    return a.nome < b.nome ? -1 : a.nome > b.nome ? 1 : 0;
                });
            });
        };
        $scope.transferirPara = function () {
            $scope.popupTransferirPara = true;
            $('#idFundoPopupSegmentacao').fadeIn();
            $scope.subMenuDeSegmentacoesContatos = false;
            $('#abrirSubMenuSegmentacoesContatos').hide();
        };
        $scope.fecharTransferirPara = function (pFechar) {
            if (pFechar) {
                var contatos = [];
                var nomeContatos = [];
                $scope.showBrowserContatos.filter(function (obj) {
                    return obj.checkSelecionadoSeg;
                }).forEach(function (contato) {
                    contatos.push(contato);
                    nomeContatos.push(contato.nomeCompleto);
                });
                var segmentacaoSelecionada = $('#segmentacaoTransferir')[0].selectedOptions[0].value;
                $http({
                    method: 'POST',
                    url: "".concat($rootScope.ramperV3ApiBaseUrl, "/segmentacaoDetalhes/moverSegmentacoesDetalhes"),
                    headers: {
                        'ramp3r-auth': 'ramp3r-authorization',
                        'ramp3r-l': $rootScope.IDCrypto,
                        'Authorization': 'Bearer ' + $rootScope.Token
                    },
                    params: {
                        objectIdContato: contatos.map(function (m) { return m._id; }),
                        objectIdSegmentacoes: segmentacaoSelecionada,
                        todos: _clickCheckTodosSeg,
                        objectId: _segmentacaoSelecionada._id._id,
                        objectIdLogin: $scope.escolherUsuario ? $scope.escolherUsuario : '',
                        buscaTexto: $scope.buscarContato,
                        dataInicio: $scope.dataInicioFiltroContato ? new Date($scope.dataInicioFiltroContato) : '',
                        dataFim: $scope.dataFinalFiltroContato ? new Date($scope.dataFinalFiltroContato) : '',
                        status: verificaStatusContatoERetorna($scope.statusContato),
                        alta: $('#checkboxAltaInput').is(':checked'),
                        media: $('#checkboxMediaInput').is(':checked'),
                        baixa: $('#checkboxBaixaInput').is(':checked'),
                        sem: $('#checkboxSemInput').is(':checked'),
                        usuario: $scope.escolherUsuario ? $scope.escolherUsuario : '',
                        converterParaNovo: $scope.converterEmNovoAoTransferirContatos
                    }
                }).then(function () {
                    $scope.removerParametrosContatos();
                    setTimeout(function () {
                        $scope.consultarContatos();
                    }, 100);
                });
                if ($scope.converterEmNovoAoTransferirContatos) {
                    mixpanel.track("Segmentação | Transferiu para outra segmentação convertendo em Novo", {
                        "Moveu de": $scope.nomeSegSelecionada,
                        "Moveu para": segmentacaoSelecionada,
                        "Total contatos": contatos.length,
                        "Contatos": nomeContatos,
                        "Local_IP": $rootScope.ip
                    });
                }
                else {
                    mixpanel.track("Segmentação | Moveu para outra segmentação", {
                        "Moveu de": $scope.nomeSegSelecionada,
                        "Moveu para": segmentacaoSelecionada,
                        "Total contatos": contatos.length,
                        "Contatos": nomeContatos,
                        "Local_IP": $rootScope.ip
                    });
                }
            }
            $scope.popupTransferirPara = false;
            $('#idFundoPopupSegmentacao').fadeOut();
            $scope.segmentacaoTransferir = '';
            $rootScope.viewBtnSalvarTransferirPara = false;
        };
        $scope.duplicarPara = function () {
            $scope.popupDuplicarPara = true;
            $('#idFundoPopupSegmentacao').fadeIn();
            $('#abrirSubMenuSegmentacoesContatos').hide();
            $scope.subMenuDeSegmentacoesContatos = false;
        };
        $scope.habilitarBtnDuplicarPara = function () {
            if ($scope.segmentacaoDuplicar == null || $scope.segmentacaoDuplicar == '' || !$scope.segmentacaoDuplicar)
                $rootScope.viewBtnSalvarDuplicarPara = false;
            else
                $rootScope.viewBtnSalvarDuplicarPara = true;
        };
        $scope.habilitarBtnTransferirPara = function () {
            if ($scope.segmentacaoTransferir == null || $scope.segmentacaoTransferir == '' || !$scope.segmentacaoTransferir)
                $rootScope.viewBtnSalvarTransferirPara = false;
            else
                $rootScope.viewBtnSalvarTransferirPara = true;
        };
        $scope.fecharDuplicarPara = function (pFechar) {
            if (pFechar) {
                var contatos = [];
                var nomeContatos = [];
                $scope.showBrowserContatos.filter(function (obj) {
                    return obj.checkSelecionadoSeg;
                }).forEach(function (contato) {
                    contatos.push(contato);
                    nomeContatos.push(contato.nomeCompleto);
                });
                var segmentacaoSelecionada = $('#segmentacaoDuplicar')[0].selectedOptions[0].value;
                $http({
                    method: 'POST',
                    url: "".concat($rootScope.ramperV3ApiBaseUrl, "/segmentacoesDetalhes/enviarSegmentacoesDetalhes"),
                    headers: {
                        'ramp3r-auth': 'ramp3r-authorization',
                        'ramp3r-l': $rootScope.IDCrypto,
                        'Authorization': 'Bearer ' + $rootScope.Token
                    },
                    params: {
                        objectIdContato: contatos.map(function (m) { return m._id; }),
                        objectIdSegmentacoes: segmentacaoSelecionada,
                        todos: _clickCheckTodosSeg,
                        objectId: _segmentacaoSelecionada._id._id,
                        objectIdLogin: $scope.escolherUsuario ? $scope.escolherUsuario : '',
                        buscaTexto: $scope.buscarContato,
                        dataInicio: $scope.dataInicioFiltroContato ? new Date($scope.dataInicioFiltroContato) : '',
                        dataFim: $scope.dataFinalFiltroContato ? new Date($scope.dataFinalFiltroContato) : '',
                        status: verificaStatusContatoERetorna($scope.statusContato),
                        alta: $('#checkboxAltaInput').is(':checked'),
                        media: $('#checkboxMediaInput').is(':checked'),
                        baixa: $('#checkboxBaixaInput').is(':checked'),
                        sem: $('#checkboxSemInput').is(':checked'),
                        usuario: $scope.escolherUsuario ? $scope.escolherUsuario : ''
                    }
                }).then(function () {
                    $scope.removerParametrosContatos();
                    setTimeout(function () {
                        $scope.consultarContatos();
                    }, 100);
                });
                mixpanel.track("Segmentação | Enviou para outra segmentação", {
                    "Enviou de": $scope.nomeSegSelecionada,
                    "Enviou para": segmentacaoSelecionada,
                    "Total contatos": contatos.length,
                    "Contatos": nomeContatos,
                    "Local_IP": $rootScope.ip
                });
            }
            $scope.popupDuplicarPara = false;
            $('#idFundoPopupSegmentacao').fadeOut();
            $scope.segmentacaoDuplicar = '';
            $rootScope.viewBtnSalvarDuplicarPara = false;
        };
        $scope.desejaExcluir = function () {
            $scope.popupRemoveContato = true;
            $('#idFundoPopupSegmentacao').fadeIn();
            $scope.subMenuDeSegmentacoesContatos = false;
            $('#abrirSubMenuSegmentacoesContatos').hide();
        };
        $scope.desejaExcluirFechar = function (pFechar) {
            if (pFechar) {
                var contatos = [];
                var nomeContatos = [];
                $scope.showBrowserContatos.filter(function (obj) {
                    return obj.checkSelecionadoSeg;
                }).forEach(function (contato) {
                    contatos.push(contato);
                    nomeContatos.push(contato.nomeCompleto);
                });
                $http({
                    method: 'POST',
                    url: "".concat($rootScope.ramperV3ApiBaseUrl, "/segmentacoesDetalhes/removerSegmentacoesDetalhes"),
                    headers: {
                        'ramp3r-auth': 'ramp3r-authorization',
                        'ramp3r-l': $rootScope.IDCrypto,
                        'Authorization': 'Bearer ' + $rootScope.Token
                    },
                    params: {
                        objectIdContato: _clickCheckTodosSeg ? null : contatos.map(function (m) { return m._id; }),
                        objectIdSegmentacoes: _segmentacaoSelecionada._id._id,
                        todos: _clickCheckTodosSeg,
                        objectIdLogin: $scope.escolherUsuario ? $scope.escolherUsuario : '',
                        buscaTexto: $scope.buscarContato,
                        dataInicio: $scope.dataInicioFiltroContato ? new Date($scope.dataInicioFiltroContato) : '',
                        dataFim: $scope.dataFinalFiltroContato ? new Date($scope.dataFinalFiltroContato) : '',
                        status: verificaStatusContatoERetorna($scope.statusContato),
                        alta: $('#checkboxAltaInput').is(':checked'),
                        media: $('#checkboxMediaInput').is(':checked'),
                        baixa: $('#checkboxBaixaInput').is(':checked'),
                        sem: $('#checkboxSemInput').is(':checked'),
                        usuario: $scope.escolherUsuario ? $scope.escolherUsuario : ''
                    }
                }).then(function () {
                    $scope.removerParametrosContatos();
                    setTimeout(function () {
                        $scope.consultarContatos();
                    }, 100);
                });
                mixpanel.track("Segmentação | Removeu contatos da segmentação", {
                    "Segmentação": $scope.nomeSegSelecionada,
                    "Total contatos": contatos.length,
                    "Contatos": nomeContatos,
                    "Local_IP": $rootScope.ip
                });
            }
            $scope.popupRemoveContato = false;
            $('#idFundoPopupSegmentacao').fadeOut();
        };
        $scope.filtrarContatosSegmentacao = function () {
            if (!$scope.listaUsuarios.length)
                $scope.consultarUsuarios();
            $scope.popupFiltrarContatos = true;
            $('#idFundoPopupSegmentacao').fadeIn();
            $scope.buscarContato = '';
            $scope.semContatosSegBusca = false;
            $scope.campoFiltroHabiliado = true;
            $scope.campoBuscaHabiliado = false;
        };
        $scope.fecharfiltrarContatosSegmentacao = function (pFechar) {
            $scope.popupFiltrarContatos = false;
            $('#idFundoPopupSegmentacao').fadeOut();
            if (pFechar) {
                $scope.removerParametrosContatos();
                $scope.consultarContatos();
            }
        };
        $scope.habiliarBtnDuplicarPara = function () {
            if ($scope.segmentacaoFiltroContatosDuplicarPara == null || $scope.segmentacaoFiltroContatosDuplicarPara == '' || !$scope.segmentacaoFiltroContatosDuplicarPara)
                $scope.viewBtnSalvarDuplicarPara = false;
            else
                $scope.viewBtnSalvarDuplicarPara = true;
        };
        $scope.habiliarBtnTransferirPara = function () {
            if ($scope.segmentacaoFiltroContatosTransferirPara == null || $scope.segmentacaoFiltroContatosTransferirPara == '' || !$scope.segmentacaoFiltroContatosTransferirPara)
                $scope.viewBtnSalvarTransferirPara = false;
            else
                $scope.viewBtnSalvarTransferirPara = true;
        };
        $scope.consultarTotaisParaContatos = function () {
            $scope.desativarCheckTodosSegContato = true;
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/login/consultarTotais"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                params: {
                    tipoDaChave: $rootScope.permissoes.tipoUsuario,
                    totalEspecifico: {
                        tela: 'ContatosEmSegmentacoes',
                        tipoDaChave: $scope.usuarioFiltro ? "U" : $rootScope.permissoes.tipoUsuario,
                        objectId: _segmentacaoSelecionada._id.objectIdLogin,
                        _id: _segmentacaoSelecionada._id._id,
                        buscaTexto: $scope.buscarContato,
                        skip: _pagina,
                        limit: _limite,
                        ordem: _campoOrdem,
                        ordemAscDesc: _ordem,
                        dataInicio: $scope.dataInicioFiltroContato ? new Date($scope.dataInicioFiltroContato) : '',
                        dataFim: $scope.dataFinalFiltroContato ? new Date($scope.dataFinalFiltroContato) : '',
                        status: verificaStatusContatoERetorna($scope.statusContato),
                        alta: $('#checkboxAltaInput').is(':checked'),
                        media: $('#checkboxMediaInput').is(':checked'),
                        baixa: $('#checkboxBaixaInput').is(':checked'),
                        sem: $('#checkboxSemInput').is(':checked'),
                        usuario: $scope.escolherUsuario ? $scope.escolherUsuario : ''
                    }
                }
            }).then(function (rConsulta) {
                $scope.desativarCheckTodosSegContato = false;
                if (rConsulta.data.length > 0)
                    rConsulta.data.forEach(function (total) {
                        if (total.contatos == _segmentacaoSelecionada.total)
                            $scope.totalContatosSegSelecionada = 'Você possui ' + formatarValor(total.contatos) + ' contato' + (total.contatos != 1 ? 's nessa segmentação.' : ' nessa segmentação.');
                        else {
                            if (total.contatos != 1)
                                $scope.totalContatosSegSelecionada = 'Foram encontrados ' + formatarValor(total.contatos) + ' contatos para este filtro.';
                            else
                                $scope.totalContatosSegSelecionada = 'Foi encontrado ' + formatarValor(total.contatos) + ' contato para este filtro.';
                        }
                        $scope.totalContatos = total.contatos;
                    });
                else
                    $scope.totalContatosSegSelecionada = "Você não possui contatos nessa segmentação.";
            });
        };
        $scope.detalhesSegmentacoes = function (pSegmentacao) {
            if (PermissionamentoService.allowAccessToScreenAction('segmentacoes', 'details')) {
                $scope.qtdContatosCadencia = pSegmentacao._id.aliasDisparos.length;
                try {
                    $scope.nomeDoArquivo = pSegmentacao._id.aliasListas[0] ? pSegmentacao._id.aliasListas[0].nome : '';
                }
                catch (e) {
                    $scope.nomeDoArquivo = '';
                }
                try {
                    $scope.linkDoArquivo = pSegmentacao._id.aliasListas[0] ? pSegmentacao._id.aliasListas[0].linkDownloadListaOriginal : '';
                }
                catch (e) {
                    $scope.linkDoArquivo = '';
                }
                $scope.totalContatosSegSelecionada = 'Você possui ' + pSegmentacao.total + ' contato' + (pSegmentacao.total != 1 ? 's nessa segmentação.' : ' nessa segmentação.');
                $scope.segmentacaoIntegracaoSite = pSegmentacao._id.integracaoSite;
                $scope.nomeSegSelecionada = pSegmentacao._id.segmentacao;
                $scope.dataCriacaoSegSelecionada = getTimestamp(pSegmentacao._id._id);
                $scope.origemSegSelecionada = pSegmentacao._id.origem;
                _segmentacaoSelecionada = pSegmentacao;
                esconderTodasDivsDetalhes();
                removerDestaqueMenu();
                $scope.clickMenuDetalhes('d');
                $('#dvDetalhesGeral').fadeIn();
                $scope.campoBuscaHabiliado = false;
                $scope.campoFiltroHabiliado = false;
                $scope.semContatosSegBusca = false;
                $scope.semContatosSegFiltro = false;
                $scope.consultarSegmentacoesPorUsuario();
            }
        };
        $scope.consultarCadencias = function (pBuscar) {
            $scope.listaCadencias = [];
            $('#tbleContatosCadencia').removeClass('emptyAtivo');
            $rootScope.loaderAtivo = true;
            $scope.semCadenciasSegmentacoes = false;
            $('#dvDetalhesGeral').addClass('blur');
            $scope.semCadSegBusca = false;
            $('#cadenciasSegmentacoes').removeClass('emptyAtivo');
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/disparos/consultarCadencias"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                params: {
                    objectId: _segmentacaoSelecionada._id._id,
                    buscar: pBuscar ? pBuscar : '',
                    ordem: "empresa",
                    ordemAscDesc: 1,
                    tipoDaChave: 'S',
                    tipoDaConsulta: 'S',
                    dataInicio: '',
                    dataFim: '',
                    status: '',
                    segmentacao: '',
                    origem: undefined
                }
            }).then(function (rConsultaCadencias) {
                $scope.listaCadencias = [];
                $rootScope.loaderAtivo = false;
                $('#dvDetalhesGeral').removeClass('blur');
                if (rConsultaCadencias.data.length > 0) {
                    $scope.semCadenciasSegmentacoes = false;
                    rConsultaCadencias.data.forEach(function (cadencia) {
                        cadencia.checkSelecionado = false;
                        $scope.listaCadencias.push(cadencia);
                    });
                }
                else if ($scope.listaCadencias.length == 0) {
                    $scope.semCadenciasSegmentacoes = true;
                    $scope.semCadSegBusca = false;
                    $scope.buscaOuFiltro = 'Sua busca';
                    $('#cadenciasSegmentacoes').addClass('emptyAtivo');
                    if ($scope.buscarCadenciaSegmentacoes) {
                        $scope.semCadSegBusca = true;
                        $scope.semCadenciasSegmentacoes = false;
                    }
                }
                else {
                    $scope.semCadenciasSegmentacoes = false;
                }
            });
        };
        $("#ordemCadencia").on('change', function () {
            if ($('#ordemCadencia')[0].selectedOptions[0].text == "A-Z" ||
                $('#ordemCadencia')[0].selectedOptions[0].text == "Menor data")
                $scope.reverseDetalhes = false;
            else
                $scope.reverseDetalhes = true;
            $scope.propertyNameDetalhes = $('#ordemCadencia')[0].selectedOptions[0].value;
            $scope.$apply();
        });
        $scope.removerFiltroRecarregar = function () {
            $scope.buscarCadenciaPorSegmentacao = '';
            $scope.consultarCadencias();
        };
        $scope.clickMenuDetalhes = function (pDetalhe, pEmpresa) {
            $scope.esconderEmptyStatesDetalhes();
            esconderTodasDivsDetalhes();
            removerDestaqueMenu();
            if (pDetalhe == 'd') {
                $('#liDetalhesDados').addClass('liMenuDetalhesActive');
                $('#dvDetalhesDados').fadeIn();
            }
            else if (pDetalhe == 'm') {
                $rootScope.loaderAtivo = true;
                $('#dvBlur').addClass('blur');
                $('#liDetalhesMetricas').addClass('liMenuDetalhesActive');
                $('#dvDetalhesMetricas').fadeIn();
                $('#labe1').addClass('active');
                $('#labe2').removeClass('active');
                $('#labe3').removeClass('active');
                $scope.$broadcast('getMetricas', { tela: "S", objectId: _segmentacaoSelecionada._id._id });
                $rootScope.loaderAtivo = false;
                $('#dvBlur').removeClass('blur');
            }
            else if (pDetalhe == 'c') {
                $scope.removerParametrosContatos();
                $scope.consultarContatos();
                $('#liDetalhesContatos').addClass('liMenuDetalhesActive');
                $('#dvDetalhesContatos').fadeIn();
            }
            else if (pDetalhe == 'ca') {
                $scope.consultarCadencias();
                $('#liDetalhesCadencias').addClass('liMenuDetalhesActive');
                $('#dvDetalhesCadencias').fadeIn();
            }
            else if (pDetalhe == 'r') {
                $('#liDetalhesResumo').addClass('liMenuDetalhesActive');
                $('#dvDetalhesContatoResumo').fadeIn();
            }
            else if (pDetalhe == 'h') {
                $('#liDetalhesHistorico').addClass('liMenuDetalhesActive');
                $('#dvDetalhesHistorico').fadeIn();
            }
        };
        esconderTodasDivsDetalhes = function () {
            $('#dvDetalhesDados').hide();
            $('#dvDetalhesMetricas').hide();
            $('#dvDetalhesCadencias').hide();
            $('#dvDetalhesContatoResumo').hide();
            $('#dvDetalhesContatos').hide();
            $('#dvDetalhesSegmentacoes').hide();
            $scope.semContatosSegBusca = false;
        };
        removerDestaqueMenu = function () {
            $('#liDetalhesDados').removeClass('liMenuDetalhesActive');
            $('#liDetalhesMetricas').removeClass('liMenuDetalhesActive');
            $('#liDetalhesContatos').removeClass('liMenuDetalhesActive');
            $('#liDetalhesResumo').removeClass('liMenuDetalhesActive');
            $('#liDetalhesCadencias').removeClass('liMenuDetalhesActive');
        };
        infiniteScrolling('#tbleContatosCadencia', function () {
            var alreadyReturnedAllContacts = $scope.showBrowserContatos ? $scope.showBrowserContatos.length === $scope.totalContatos : false;
            if (!alreadyReturnedAllContacts) {
                $scope.consultarContatos();
            }
        });
        $scope.removerParametrosContatos = function () {
            _pagina = -10;
            _campoOrdem = 'nome';
            _ordem = 1;
            $scope.buscarContato = '';
            $('#checkContatos').prop('checked', false);
            _clickCheckTodosSeg = false;
            $scope.showBrowserContatos = [];
            $scope.totalContatosSegmantacao = 0;
        };
        $scope.consultarContatos = function () {
            $rootScope.loaderAtivo = true;
            $('#dvDetalhesGeral').addClass('blur');
            $('#tbleContatosCadencia').removeClass('emptyAtivo');
            if (_pagina == -10)
                $scope.showBrowserContatos = [];
            _pagina = (_pagina + 10);
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/segmentacoesDetalhes/consultarContatos"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                params: {
                    objectId: _segmentacaoSelecionada._id._id,
                    buscaTexto: $scope.buscarContato,
                    skip: _pagina,
                    limit: _limite,
                    ordem: _campoOrdem,
                    ordemAscDesc: _ordem,
                    dataInicio: $scope.dataInicioFiltroContato ? new Date($scope.dataInicioFiltroContato) : '',
                    dataFim: $scope.dataFinalFiltroContato ? new Date($scope.dataFinalFiltroContato) : '',
                    status: verificaStatusContatoERetorna($scope.statusContato),
                    alta: $('#checkboxAltaInput').is(':checked'),
                    media: $('#checkboxMediaInput').is(':checked'),
                    baixa: $('#checkboxBaixaInput').is(':checked'),
                    sem: $('#checkboxSemInput').is(':checked'),
                    usuario: $scope.escolherUsuario ? $scope.escolherUsuario : ''
                }
            }).then(function (rConsulta) {
                if (rConsulta.data.length > 0) {
                    $scope.totalContatosSegmantacao += rConsulta.data.length;
                    $scope.semContatosSegBusca = false;
                    $scope.semContatoSegmentacoes = false;
                    $scope.semContatosSegFiltro = false;
                    rConsulta.data.forEach(function (contato) {
                        $scope.showBrowserContatos.push(contato);
                    });
                }
                else {
                    if ($scope.showBrowserContatos.length <= 0) {
                        if ($scope.campoBuscaHabiliado == true) {
                            $scope.semContatosSegBusca = true;
                            $scope.buscaOuFiltro = 'Sua busca';
                            $('#tbleContatosCadencia').addClass('emptyAtivo');
                            $scope.semContatosSegFiltro = false;
                            $scope.semContatoSegmentacoes = false;
                        }
                        else if ($scope.campoFiltroHabiliado == true) {
                            $scope.semContatosSegFiltro = true;
                            $scope.buscaOuFiltro = 'Seu filtro';
                            $('#tbleContatosCadencia').addClass('emptyAtivo');
                            $scope.semContatosSegBusca = false;
                            $scope.semContatoSegmentacoes = false;
                        }
                        else {
                            $scope.semContatoSegmentacoes = true;
                            $scope.semContatosSegBusca = false;
                            $scope.semContatosSegFiltro = false;
                        }
                    }
                    else if ($scope.showBrowserContatos.length > 0)
                        $scope.semContatoSegmentacoes = false;
                }
                $scope.contadorSelecionadosSeg();
                $scope.consultarTotaisParaContatos();
                $rootScope.loaderAtivo = false;
                $('#dvDetalhesGeral').removeClass('blur');
                $('#checkSegmentacoesContatos').prop('checked', false);
                _clickCheckTodosSeg = false;
            });
        };
        $scope.ordecacaoContatos = function () {
            if ($('#ordemContato')[0].selectedOptions[0].text == "A-Z")
                _ordem = 1;
            else
                _ordem = -1;
            _pagina = -10;
            _campoOrdem = $('#ordemContato')[0].selectedOptions[0].value;
            $scope.consultarContatos();
        };
        $("#ordemContato").on('change', function () {
            $scope.ordecacaoContatos();
        });
        $(document).mouseup(function (e) {
            var subMenu = $('#abrirSubMenuSegmentacoesContatos');
            if (!subMenu.is(e.target) && subMenu.has(e.target).length === 0)
                subMenu.hide();
        });
        $(document).mouseup(function (e) {
            var subMenu = $('.subMenuSegmentacoes');
            if (!subMenu.is(e.target) && subMenu.has(e.target).length === 0)
                subMenu.hide();
        });
        $(document).mouseup(function (e) {
            var subMenu = $('.subMenuContatosEspecificoSegmentacoes');
            if (!subMenu.is(e.target) && subMenu.has(e.target).length === 0)
                subMenu.hide();
        });
        $scope.clickCheckTodos = function () {
            _clickCheckTodos = !_clickCheckTodos;
            $scope.showBrowserSegmentacoes.forEach(function (item) {
                item.checkSelecionado = _clickCheckTodos;
                if (item._id.integracaoSite)
                    item.checkSelecionado = false;
            });
            $scope.contadorSelecionados();
        };
        $scope.clickCheckTodosSegContato = function () {
            _clickCheckTodosSeg = !_clickCheckTodosSeg;
            $scope.showBrowserContatos.forEach(function (item) {
                item.checkSelecionadoSeg = _clickCheckTodosSeg;
            });
            $scope.contadorSelecionadosSeg();
            if (_clickCheckTodosSeg) {
                $scope.totalSelecionadosSeg = $scope.totalContatos;
            }
        };
        $scope.clickCheckSegContato = function (pItemSegContato) {
            if (!pItemSegContato.checkSelecionadoSeg)
                pItemSegContato.checkSelecionadoSeg = false;
            else
                pItemSegContato.checkSelecionadoSeg = true;
            $('#checkSegmentacoesContatos').prop('checked', false);
            _clickCheckTodosSeg = false;
            $scope.contadorSelecionadosSeg();
        };
        $scope.clickCheck = function (pItemSegmentacao) {
            if (!pItemSegmentacao.checkSelecionado)
                pItemSegmentacao.checkSelecionado = false;
            else
                pItemSegmentacao.checkSelecionado = true;
            $scope.contadorSelecionados();
        };
        $scope.removerFiltroContato = function () {
            $scope.campoFiltroHabiliado = false;
            $scope.removerFiltroTudoContato();
            $scope.esconderEmptyStates();
            $scope.clickMenuDetalhes('c');
        };
        $scope.esconderEmptyStates = function () {
            $scope.semContatosSegBusca = false;
            $scope.semContatosSegFiltro = false;
            $scope.semContatoSegmentacoes = false;
        };
        $scope.esconderEmptyStatesDetalhes = function () {
            $scope.semContatosSegBusca = false;
            $scope.semContatoSegmentacoes = false;
            $scope.semCadenciasSegmentacoes = false;
        };
        $scope.contadorSelecionadosSeg = function () {
            $scope.totalSelecionadosSeg =
                $scope.showBrowserContatos.filter(function (obj) {
                    return obj.checkSelecionadoSeg;
                }).length;
            if ($scope.totalSelecionadosSeg > 1) {
                $scope.nomeSelecionadoSegContato = 'selecionados';
                $scope.nomeBtnExcluirCad = 'Excluir selecionadas';
                $scope.oContatoSelecionado = 'os contatos selecionados';
                $scope.seuContatoTambemPermanecera = 'seus contatos também permanecerão';
                $scope.seusContatosSairao = 'seus contatos sairão';
                $scope.contatoSelecionado = 'os contatos selecionados dessa';
                $scope.contatoTambemSeraRemovido = 'Os contatos também serão removidos';
                $scope.esteplural = 'estes';
                $scope.segmentacoesplural = 'contatos';
                $('#abrirSubMenuSegmentacoesContatos').removeClass('subMenuSegmentacoesContatosMaior');
                $('#abrirSubMenuSegmentacoesContatos').addClass('subMenuSegmentacoesContatos');
            }
            else {
                $scope.nomeSelecionadoSegContato = 'selecionado';
                $scope.nomeBtnExcluirCad = 'Excluir';
                $scope.contatoTambemSeraRemovido = 'O contato também será removido';
                $scope.oContatoSelecionado = 'o contato selecionado';
                $scope.seusContatosSairao = 'seu contato sairá';
                $scope.seuContatoTambemPermanecera = 'seu contato também permanecerá';
                $scope.contatoSelecionado = 'o contato selecionado dessa';
                $scope.esteplural = 'este';
                $scope.segmentacoesplural = 'contato';
                $('#abrirSubMenuSegmentacoesContatos').addClass('subMenuSegmentacoesContatosMaior');
                $('#abrirSubMenuSegmentacoesContatos').removeClass('subMenuSegmentacoesContatos');
            }
        };
        $scope.contadorSelecionados = function () {
            $scope.totalSelecionados =
                $scope.showBrowserSegmentacoes.filter(function (obj) {
                    return obj.checkSelecionado;
                }).length;
            $scope.totalDLSelecionados =
                $scope.showBrowserSegmentacoes.filter(function (obj) {
                    return obj._id.integracaoSite;
                }).length;
            if ($scope.totalSelecionados > 1) {
                $scope.nomeSelecionado = 'selecionados';
                $scope.nomeBtnExcluirCad = 'Excluir selecionadas';
                $scope.oContatoSelecionado = 'os contatos selecionados';
                $scope.seuContatoTambemPermanecera = 'seus contatos também permanecerão';
                $scope.seusContatosSairao = 'seus contatos sairão';
                $scope.contatoSelecionado = 'os contatos selecionados dessa';
                $scope.contatoTambemSeraRemovido = 'Os contatos também serão removidos';
                $scope.esteplural = 'estes';
                $scope.segmentacoesplural = 'contatos';
                $('#abrirSubMenuSegmentacoesContatos').removeClass('subMenuSegmentacoesContatosMaior');
                $('#abrirSubMenuSegmentacoesContatos').addClass('subMenuSegmentacoesContatos');
            }
            else {
                $scope.nomeSelecionado = 'selecionado';
                $scope.nomeBtnExcluirCad = 'Excluir';
                $scope.contatoTambemSeraRemovido = 'O contato também será removido';
                $scope.oContatoSelecionado = 'o contato selecionado';
                $scope.seusContatosSairao = 'seu contato sairá';
                $scope.seuContatoTambemPermanecera = 'seu contato também permanecerá';
                $scope.contatoSelecionado = 'o contato selecionado dessa';
                $scope.esteplural = 'este';
                $scope.segmentacoesplural = 'contato';
                $('#abrirSubMenuSegmentacoesContatos').addClass('subMenuSegmentacoesContatosMaior');
                $('#abrirSubMenuSegmentacoesContatos').removeClass('subMenuSegmentacoesContatos');
            }
            if ($scope.totalSelecionados < $scope.showBrowserSegmentacoes.length - $scope.totalDLSelecionados) {
                $('#checkSegmentacoes').prop('checked', false);
                _clickCheckTodos = false;
            }
            else if ($scope.totalSelecionados = $scope.showBrowserSegmentacoes.length - $scope.totalDLSelecionados) {
                $('#checkSegmentacoes').prop('checked', true);
                _clickCheckTodos = true;
            }
            if ($scope.totalSelecionados < $scope.showBrowserSegmentacoes.length - $scope.totalDLSelecionados) {
                $('#checkSegmentacoesContatos').prop('checked', false);
                _clickCheckTodos = false;
            }
            else if ($scope.totalSelecionados = $scope.showBrowserSegmentacoes.length - $scope.totalDLSelecionados) {
                $('#checkSegmentacoesContatos').prop('checked', true);
                _clickCheckTodos = true;
            }
        };
        $scope.abrirSubMenu = function (pDados) {
            if (pDados == 'G') {
                $('#abrirSubMenuSegmentacoes').show();
                $rootScope.subMenuDeSegmentacoes = true;
            }
            else if (pDados == 'E') {
                $('#abrirSubMenuSegmentacoesEspecifica').show();
                $rootScope.subMenuSegmentacaoEspecifica = true;
            }
            else if (pDados == 'C') {
                $('#abrirSubMenuSegmentacoesContatos').show();
                $scope.subMenuDeSegmentacoesContatos = true;
            }
        };
        $scope.abrirPopupFiltrarTeste = function (pDados) {
            if ($rootScope.administrativoOuVisao) {
                $scope.usuarioFiltroSeg = '';
            }
            else {
                $scope.usuarioFiltroSeg = $rootScope.DadosCadastrais.objectIdLogin;
            }
            $scope.consultarCadenciasSimples();
            $scope.consultarUsuarios();
            $('#popUpSeg').show();
            $scope.popupFiltrarSegmentacoes = true;
        };
        setTimeout(function () {
            $scope.abrirPopupFiltrarTeste('seg');
        }, 4000);
        $scope.trocarDivLimparFiltro = function () {
            $scope.limparFiltroOpen = true;
            $scope.limparFiltroClose = false;
        };
        $scope.abrirPopupFiltrar = function (pDados) {
            if ($rootScope.administrativoOuVisao) {
                $scope.usuarioFiltroSeg = '';
            }
            else {
                $scope.usuarioFiltroSeg = $rootScope.DadosCadastrais.objectIdLogin;
            }
            $scope.consultarCadenciasSimples();
            $scope.consultarUsuarios();
            if (pDados == 'co') {
                $('.dvDetalhes').addClass('blur');
                $rootScope.popupFiltrarContatosCadencias = true;
                setTimeout(function () {
                    $("#enviadosCad").candlestick({ swipe: false, on: 's', off: 'n', default: '' });
                    $("#abertosCad").candlestick({ swipe: false, on: 's', off: 'n', default: '' });
                    $("#cliquesCad").candlestick({ swipe: false, on: 's', off: 'n', default: '' });
                }, 10);
            }
            else if (pDados == 'seg') {
                $('#popUpSeg').show();
                $scope.popupFiltrarSegmentacoes = true;
            }
        };
        $scope.tirarBlur = function () {
            $('#menuPrincipal').removeClass('blur');
            $('#pagSegmentacoes').removeClass('blur');
            $('#dvDetalhesGeral').removeClass('blur');
            $('.dvDetalhesContatos').removeClass('blur');
            $('.navbar-expand-md').removeClass('blur');
        };
        $(document).mouseup(function (e) {
            var popUpSegmentacao = $('#popUpSeg');
            var popUpSegmentacaoContatoExportar = $('#exportacaoContatoSegGeral');
            var data = $('.dr-picker');
            var fundoPopupNovo = $('#idFundoPopupSegmentacao');
            if (popUpSegmentacao.has(e.target).length === 0 && data.has(e.target).length === 0) {
                popUpSegmentacao.hide();
                data.hide();
                $scope.popupFiltrarSegmentacoes = false;
            }
            if (popUpSegmentacaoContatoExportar.is(e.target)) {
                popUpSegmentacaoContatoExportar.fadeOut();
                $scope.tirarBlur();
                $scope.popupRemoveContato = false;
            }
            if (fundoPopupNovo.is(e.target)) {
                if ($scope.popupRemove) {
                    $scope.fecharPopupRemover();
                }
                else if ($scope.popupRenomearSegmentacao) {
                    $scope.fecharRenomearSegmentacao();
                }
                else if ($scope.popupDuplicarPara) {
                    $scope.fecharDuplicarPara(false);
                }
                else if ($scope.popupTrasferirPara) {
                    $scope.fecharTransferirPara(false);
                }
                else if ($scope.popupRemoveContato) {
                    $scope.desejaExcluirFechar(false);
                }
                else if ($scope.popupFiltrarContatos) {
                    $scope.fecharfiltrarContatosSegmentacao(false);
                }
                $scope.$apply();
            }
        });
        $(document).keydown(function (e) {
            if (e.keyCode == 27) {
                if ($scope.popupRemove) {
                    $scope.fecharPopupRemover();
                }
                else if ($scope.popupRenomearSegmentacao) {
                    $scope.fecharRenomearSegmentacao();
                }
                else if ($scope.popupDuplicarPara) {
                    $scope.fecharDuplicarPara(false);
                }
                else if ($scope.popupTransferirPara) {
                    $scope.fecharTransferirPara(false);
                }
                else if ($scope.popupRemoveContato) {
                    $scope.desejaExcluirFechar(false);
                }
                else if ($scope.popupFiltrarContatos) {
                    $scope.fecharfiltrarContatosSegmentacao(false);
                }
                $scope.$apply();
            }
        });
        $scope.fecharPopupFiltrar = function (pDados) {
            if (pDados == 'co' || pDados == 'cox') {
                $('.dvDetalhes').removeClass('blur');
                $scope.popupFiltrarSegmentacoes = false;
            }
            else if (pDados == 'seg') {
                $('#popUpSeg').hide();
                $scope.popupFiltrarSegmentacoes = false;
            }
        };
        $scope.removerFiltros = function () {
            $scope.limparFiltroOpen = true;
            $scope.limparFiltroClose = false;
            $scope.dataInicioFiltro = null;
            $scope.dataFinalFiltro = null;
            $scope.cadenciaFiltroSeg = null;
            $scope.sequenciaFiltro = null;
            $scope.usuarioFiltroSeg = null;
            $scope.statusFiltro = null;
            $scope.nomeUsuarioFiltroSeg = '';
            $scope.nomeCadenciaFiltroSeg = '';
            $scope.buscarUsuariosSeg = '';
            $scope.buscarCadenciaFiltroSeg = '';
            document.getElementById("idCadenciaSegFiltro").innerText = 'Cadência';
            document.getElementById("idUsuariosSegFiltro").innerText = 'Usuários';
            $scope.usuarioSelecionadoFiltro = 0;
            $('#sltData')[0].value = null;
            $('#sltDataFiltroSegmentacoes')[0].value = null;
            setTimeout(function () { $('#sltDataFiltroSegmentacoes').data('DRPEx').clear(); }, 10);
            _filtros = {
                tela: 'Segmentacoes',
                tipoDaChave: $rootScope.permissoes.tipoUsuario
            };
            _pagina = -10;
            setTimeout(function () {
                $scope.consultarCadenciasSimples();
            }, 10);
        };
        $('#sltDataFiltroSegmentacoes').dateRangePicker({
            startOfWeek: 0,
            weekStart: '0',
            format: 'DD/MM/YY',
            separator: ' até ',
            shortcuts: {
                'prev-days': [1, 7, 15],
                'prev': ['week', 'month', 'year']
            },
            title: 'Respondido de: ',
            animationTime: 200
        })
            .bind('DRPEx-change', function (event, obj) {
            $scope.dataInicioFiltro = obj.start;
            $scope.dataInicioFiltro.setHours(0);
            $scope.dataInicioFiltro.setMinutes(0);
            $scope.dataInicioFiltro.setSeconds(0);
            $scope.dataFinalFiltro = obj.end;
            $scope.dataFinalFiltro.setHours(23);
            $scope.dataFinalFiltro.setMinutes(59);
            $scope.dataFinalFiltro.setSeconds(59);
        });
        $('#sltDataFiltroSegmentacoesContatos').dateRangePicker({
            startOfWeek: 0,
            weekStart: '0',
            format: 'DD/MM/YY',
            separator: ' até ',
            shortcuts: {
                'prev-days': [1, 7, 15],
                'prev': ['week', 'month', 'year']
            },
            title: 'Respondido de: ',
            animationTime: 200
        })
            .bind('DRPEx-change', function (event, obj) {
            $scope.dataInicioFiltroContato = obj.start;
            $scope.dataInicioFiltroContato.setHours(0);
            $scope.dataInicioFiltroContato.setMinutes(0);
            $scope.dataInicioFiltroContato.setSeconds(0);
            $scope.dataFinalFiltroContato = obj.end;
            $scope.dataFinalFiltroContato.setHours(23);
            $scope.dataFinalFiltroContato.setMinutes(59);
            $scope.dataFinalFiltroContato.setSeconds(59);
        });
        $scope.consultarCadenciasSimples = function (pDados) {
            var contatoSelecionado = '';
            try {
                contatoSelecionado = pDados;
            }
            catch (e) {
                contatoSelecionado = '';
            }
            $scope.listaCadencias = [];
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/disparos/consultarCadenciasSimples"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                params: {
                    tipoDaChave: contatoSelecionado != '' ? "U" : $rootScope.permissoes.tipoUsuario,
                    objectId: contatoSelecionado
                }
            }).then(function (response) {
                response.data.forEach(function (item) {
                    $scope.listaCadencias.push(item);
                });
                $scope.listaCadencias.sort(function (a, b) {
                    return a.nome < b.nome ? -1 : a.nome > b.nome ? 1 : 0;
                });
            });
        };
        $scope.apagarUmaOuVariasSegmentacoes = function () {
            if ($('#dvDetalhesGeral').is(':visible'))
                _arraySegmentacoesSelecionadas.push(_segmentacaoSelecionada._id._id);
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/segmentacoes/removerSegmentacao"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                params: {
                    objectIdSegmentacoes: _arraySegmentacoesSelecionadas
                }
            }).then(function (response) {
                $scope.consultarSegmentacoes();
            });
            $scope.fecharPopupRemover();
            if ($('#dvDetalhesGeral').is(':visible'))
                $scope.fecharDetalhes();
            mixpanel.track("Segmentação | Excluiu segmentações", {
                "ID Segmentação": _segmentacoesSelecionadasExcluir,
                "Local_IP": $rootScope.ip
            });
            _segmentacoesSelecionadasExcluir = [];
        };
        $scope.editarSegmentacao = function () {
            var objectIdSegmentacao = $('#dvDetalhesGeral').is(':visible') ? _segmentacaoSelecionada._id._id : _segmentacaoSelecionada._id._id;
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/segmentacoes/editarSegmentacao"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                params: {
                    objectIdSegmentacao: objectIdSegmentacao,
                    nome: $scope.inptRenomearSegmentacao
                }
            }).then(function (response) {
                $scope.consultarSegmentacoes();
                $scope.contadorSelecionados();
            });
            $scope.nomeSegSelecionada = $scope.inptRenomearSegmentacao;
            $scope.fecharRenomearSegmentacao();
        };
        $scope.consultarUsuarios = function () {
            if ($scope.listaUsuarios.length == 0) {
                if ($rootScope.permissoes.tipoUsuario != "U") {
                    $http({
                        method: 'GET',
                        url: "".concat($rootScope.ramperV3ApiBaseUrl, "/filtros/usuarios"),
                        headers: {
                            'ramp3r-auth': 'ramp3r-authorization',
                            'ramp3r-l': $rootScope.IDCrypto,
                            'Authorization': 'Bearer ' + $rootScope.Token
                        }
                    }).then(function (response) {
                        response.data.forEach(function (time) {
                            time.loginsAtivos.forEach(function (usuario) {
                                $scope.listaUsuarios.push(usuario);
                                if (usuario.fotoPerfil)
                                    usuario.fotoPerfil = "".concat($rootScope.bucketUserPhotos, "/usuariosRamper/") + usuario._id.toString() + "/" + usuario.fotoPerfil;
                                else
                                    usuario.fotoPerfil = "".concat($rootScope.bucket, "/avatarPadrao.jpg");
                            });
                        });
                        $scope.listaUsuarios.sort(function (a, b) {
                            return a.nome < b.nome ? -1 : a.nome > b.nome ? 1 : 0;
                        });
                    });
                }
                else {
                    var usuario = {
                        nome: $rootScope.nomeUsuarioLogado,
                        _id: $rootScope.DadosCadastrais.objectIdLogin
                    };
                    $scope.listaUsuarios.push(usuario);
                }
            }
        };
        $scope.limparFiltroOpen = true;
        $scope.limparFiltroClose = false;
        $scope.filtrarSegmentacoes = function () {
            $scope.limparFiltroOpen = false;
            $scope.limparFiltroClose = true;
            _filtros = {
                tela: 'Segmentacoes',
                dataInicio: $scope.dataInicioFiltro,
                dataFim: $scope.dataFinalFiltro,
                cadencia: $scope.cadenciaFiltroSeg,
                usuario: $scope.usuarioFiltroSeg,
                tipoDaChave: $rootScope.permissoes.tipoUsuario
            };
            if ($('#sltDataFiltroSegmentacoes')[0].value == '' && ($scope.nomeUsuarioFiltroSeg == null || $scope.nomeUsuarioFiltroSeg == '') && ($scope.nomeCadenciaFiltroSeg == null || $scope.nomeCadenciaFiltroSeg == '')) {
                $('#idBtnFiltroSegmentacoes').removeClass().addClass('btnVoltarPadroNovoFiltro');
                $('#idImgFiltrarSeg').attr('src', "".concat($rootScope.bucket, "/filtro.png"));
                $scope.campoFiltroFiltro = false;
            }
            else {
                $scope.zerarCamposOrdenacao();
                $('#idBtnFiltroSegmentacoes').removeClass().addClass('btnFiltroTempNovoHabilitado');
                $('#idImgFiltrarSeg').attr('src', "".concat($rootScope.bucket, "/filtro-wt-novo.svg"));
                $scope.campoFiltroFiltro = true;
            }
            $scope.montarNomeFiltros();
            $scope.consultarSegmentacoes();
            $scope.campoBuscaFiltro = false;
            $scope.popupFiltrarSegmentacoes = false;
        };
        $scope.renomearSegmentacao = function (pDados, itemSegmentacao) {
            if (pDados == 'G') {
                _segmentacaoSelecionada = itemSegmentacao;
                $scope.inptRenomearSegmentacao = _segmentacaoSelecionada._id.segmentacao;
                $scope.popupRenomearSegmentacao = true;
                $('#idFundoPopupSegmentacao').fadeIn();
            }
            else if (pDados == 'E') {
                $scope.inptRenomearSegmentacao = $scope.nomeSegSelecionada;
                $scope.popupRenomearSegmentacao = true;
                $('#idFundoPopupSegmentacao').fadeIn();
            }
            setTimeout(function () { document.getElementById("inptRenomearSegmentacao").focus(); }, 90);
        };
        $scope.removerFiltroTudoContato = function () {
            $scope.dataInicioFiltroContato = null;
            $scope.dataFinalFiltroContato = null;
            $scope.statusContato = null;
            $scope.usuarioFiltro = null;
            $scope.escolherUsuario = null;
            $('#sltDataFiltroSegmentacoesContatos')[0].value = null;
            $('#checkboxAltaInput').prop('checked', false);
            $('#checkboxMediaInput').prop('checked', false);
            $('#checkboxBaixaInput').prop('checked', false);
            $('#checkboxSemInput').prop('checked', false);
        };
        $scope.fecharRenomearSegmentacao = function () {
            $scope.limparVariaveis();
            $('#idFundoPopupSegmentacao').fadeOut();
            $scope.popupRenomearSegmentacao = false;
        };
        $scope.salvarRenomearSeg = function () {
            mixpanel.track("Segmentação | Renomeou a segmentação", {
                "Nome": $scope.inptRenomearSegmentacao,
                "Local_IP": $rootScope.ip
            });
            $scope.editarSegmentacao();
        };
        filtrarPorSegmentacoesSelecionadas = function () {
            _arraySegmentacoesSelecionadas = $scope.showBrowserSegmentacoes
                .filter(function (obj) { return obj.checkSelecionado; }).map(function (x) {
                _segmentacoesSelecionadasExcluir.push(x._id.segmentacao);
                return x._id._id;
            });
        };
        calcularTotalContatosMensagem = function (pDados) {
            var showBrowserSegmentacoes = $('#dvDetalhesGeral').is(':visible') ? [_segmentacaoSelecionada] : $scope.showBrowserSegmentacoes.filter(function (obj) { return obj.checkSelecionado; });
            var totalContatosSelecionados = showBrowserSegmentacoes.map(function (obj) { return obj.total; })
                .reduce(function (accumulator, currentValue) { return accumulator + currentValue; }, 0);
            $scope.fraseExcluirFinal = 'contato ficará sem segmentação.';
            if ((pDados == 'G' || pDados == 'E') && totalContatosSelecionados > 1) {
                $scope.fraseExcluirFinal = 'contatos ficarão sem segmentação.';
            }
            $scope.totalContatosMensagem = "".concat(totalContatosSelecionados, " ").concat($scope.fraseExcluirFinal);
        };
        calcularTotalCadenciaMensagem = function () {
            if ($('#dvDetalhesGeral').is(':visible') || _arraySegmentacoesSelecionadas.length == 1) {
                var segmentacao_1 = $('#dvDetalhesGeral').is(':visible') ? _segmentacaoSelecionada._id : $scope.showBrowserSegmentacoes.filter(function (obj) { return obj.checkSelecionado; })[0]._id;
                var aliasDisparos_1 = segmentacao_1.aliasDisparos[0];
                var params = { objectIdSegmentacao: segmentacao_1._id };
                $http({
                    method: 'GET',
                    url: "".concat($rootScope.ramperV3ApiBaseUrl, "/automacoes/consultarAutomacoes"),
                    headers: {
                        'ramp3r-auth': 'ramp3r-authorization',
                        'ramp3r-l': $rootScope.IDCrypto,
                        'Authorization': 'Bearer ' + $rootScope.Token
                    },
                    params: params
                }).then(function (rConsultaAutomacoes) {
                    if (aliasDisparos_1 && rConsultaAutomacoes.data.length <= 0) {
                        $scope.totalCadenciaMensagem = "A cad\u00EAncia ".concat(aliasDisparos_1.nome, " ser\u00E1 finalizada.");
                    }
                    if (aliasDisparos_1 && rConsultaAutomacoes.data.length > 0) {
                        var cadenciaEnvios = rConsultaAutomacoes.data[0].disparos[0];
                        $scope.totalCadenciaMensagem = "A cad\u1EBDncia ".concat(cadenciaEnvios.nome, " ser\u00E1 finalizada e a automa\u00E7\u00E3o de n\u00E3o respondidos da cad\u1EBDncia ").concat(aliasDisparos_1.nome, " para a segmenta\u00E7\u00E3o ser\u00E1 excluida. Caso exista automa\u00E7\u00E3o de envio para integra\u00E7\u00E3o, ser\u00E1 mantida.");
                    }
                    if (aliasDisparos_1 == undefined && rConsultaAutomacoes.data.length > 0) {
                        var cadenciaEnvios = rConsultaAutomacoes.data[0].disparos[0];
                        $scope.totalCadenciaMensagem = "A automa\u00E7\u00E3o de n\u00E3o respondidos da cad\u00EAncia ".concat(cadenciaEnvios.nome, " para a segmenta\u00E7\u00E3o ").concat(segmentacao_1.segmentacao, " ser\u00E1 excluida. Caso exista automa\u00E7\u00E3o de envio para integra\u00E7\u00E3o, ser\u00E1 mantida.");
                    }
                    if (aliasDisparos_1 == undefined && rConsultaAutomacoes.data.length <= 0) {
                        $scope.totalCadenciaMensagem = '';
                    }
                    $('#idFundoPopupSegmentacao').fadeIn();
                    $scope.popupRemove = true;
                    setTimeout(function () { $('#idInptExcluir').focus(); }, 90);
                });
            }
            else {
                $scope.totalCadenciaMensagem = "A cad\u00EAncia ou o envio para a segmenta\u00E7\u00E3o da automa\u00E7\u00E3o de n\u00E3o respondido ser\u00E3o finalizadas, se a segmenta\u00E7\u00E3o excluida estiver vinculada a elas.";
                $('#idFundoPopupSegmentacao').fadeIn();
                $scope.popupRemove = true;
                setTimeout(function () { $('#idInptExcluir').focus(); }, 90);
            }
        };
        $scope.excluirSegmentacao = function (pDados) {
            filtrarPorSegmentacoesSelecionadas();
            calcularTotalContatosMensagem(pDados);
            calcularTotalCadenciaMensagem();
        };
        $scope.fecharPopupRemover = function () {
            $('#idFundoPopupSegmentacao').fadeOut();
            $scope.popupRemove = false;
            $scope.digiteExcluir = '';
        };
        $scope.enter = function (keyEvent) {
            if (keyEvent.which === 13) {
                if ($scope.popupRemove) {
                    if ($scope.digiteExcluir == 'EXCLUIR')
                        $scope.apagarUmaOuVariasSegmentacoes();
                }
                else if ($scope.popupRenomearSegmentacao) {
                    if ($scope.inptRenomearSegmentacao != '')
                        $scope.salvarRenomearSeg();
                }
                else if ($('#dvDetalhesCadencias').is(':visible')) {
                    $scope.consultarCadencias($scope.buscarCadenciaPorSegmentacao);
                    $scope.buscarCadenciaSegmentacoes = true;
                }
                else if ($('#dvDetalhesContatos').is(':visible')) {
                    _ordem = 1;
                    _campoOrdem = "nome";
                    _pagina = -10;
                    $scope.consultarContatos();
                    $scope.campoBuscaHabiliado = true;
                    $scope.campoFiltroHabiliado = false;
                    $scope.removerFiltroTudoContato();
                }
                else {
                    $scope.removerFiltros();
                    $scope.zerarCamposOrdenacao();
                    _filtros = {
                        tela: 'Segmentacoes',
                        buscar: $scope.buscarSegmentacoes,
                        tipoDaChave: $rootScope.permissoes.tipoUsuario
                    };
                    $scope.consultarSegmentacoes();
                    $scope.campoBuscaFiltro = true;
                    $scope.campoFiltroFiltro = false;
                    $scope.semSegmentacoesBusca = false;
                    $('#idBtnFiltroSegmentacoes').removeClass().addClass('btnVoltarPadroNovoFiltro');
                }
            }
        };
        $scope.recarregarPagina = function () {
            $scope.zerarCamposOrdenacao();
            $scope.removerFiltros();
            $scope.txtNomeFiltros = '';
            $scope.semSegmentacoesFiltro = false;
            $scope.semSegmentacoesBusca = false;
            $scope.semSegmentacoes = false;
            $scope.campoFiltroFiltro = false;
            $scope.campoBuscaFiltro = false;
            $scope.buscarSegmentacoes = '';
            $('#idBtnFiltroSegmentacoes').removeClass().addClass('btnVoltarPadroNovoFiltro');
            $('#idImgFiltrarSeg').attr('src', "".concat($rootScope.bucket, "/filtro.png"));
            $scope.consultarSegmentacoes();
        };
        $scope.adicionarSegmentacao = function () {
            $scope.tipoAdicionar = '';
            $scope.tituloAdicionar = 'Criação de segmentação';
            $scope.descricaoAdicionar = 'Crie uma segmentação e já inclua contatos com as configurações avançadas.';
            $('#dvAdicionarSegmentacoes').fadeIn();
        };
        $scope.fecharAdicionar = function () {
            $('#dvAdicionarSegmentacoes').fadeOut();
            $scope.limparVariaveis();
        };
        $scope.adiconarSegmentacoesNextStep = function (pDados) {
            $scope.tipoAdicionar = pDados;
            if (pDados == 'filtro') {
                $scope.descricaoAdicionar = 'Crie uma segmentação através dos filtros avançados';
                _countDiv = 1;
                showEtapa(_countDiv);
                $('#formNovaSegmentacaoFiltro').fadeIn();
                $('#voltarCriacaoSegFiltro').hide();
            }
            else if (pDados == 'simples') {
                $scope.descricaoAdicionar = 'Crie uma nova segmentação e importe contatos manualmente';
                $('#formNovaSegmentacaoSimples').fadeIn();
                $('#telaUmAddSegSimples').show();
                $('#telaDoisAddSegSimples').hide();
                setTimeout(function () { $('#idNomeNovaSegmentacao').focus(); }, 10);
            }
        };
        $scope.fecharSegmentacaoSimples = function () {
            $('#dvAdicionarSegmentacoes').fadeOut();
            $('#formNovaSegmentacaoSimples').fadeOut();
            $scope.limparVariaveis();
        };
        $scope.limparVariaveis = function () {
            $scope.nomeNovaSegmentacao = '';
            $scope.nomeNovaSegmentacaoFiltro = '';
            $rootScope.viewBtnSalvarSegSimples = false;
            $scope.nomeBtnAvancar = 'AVANÇAR';
            $scope.inptRenomearSegmentacao = '';
            $scope.divSelecionada = 0;
            $('#filtro1').removeClass('divSelectFiltrosSelecionado').addClass('divSelectFiltros');
            $('#filtro2').removeClass('divSelectFiltrosSelecionado').addClass('divSelectFiltros');
            $('#filtro3').removeClass('divSelectFiltrosSelecionado').addClass('divSelectFiltros');
            $('#nomeNovaSegmentacaoFiltro').removeClass('corTextoErro');
            $('#divNomeSegFiltro').removeClass('corTextoErro');
            $('#dvMensagemNomeSegmentacaoFiltro').fadeOut();
            $('#inptRenomearSegmentacao').removeClass('corTextoErro');
            $('#divRenomerSeg').removeClass('corTextoErro');
            $scope.removerDivErro();
        };
        $scope.habiliarBtnSalvar = function () {
            if ($scope.nomeNovaSegmentacao == null || $scope.nomeNovaSegmentacao == '' || !$scope.nomeNovaSegmentacao)
                $rootScope.viewBtnSalvarSegSimples = false;
            else
                $rootScope.viewBtnSalvarSegSimples = true;
        };
        $scope.salvarSegmentacoes = function (pDados) {
            if (pDados == 'simples') {
                mixpanel.track("Segmentação | Criou segmentação simples", {
                    "Nome": $scope.nomeNovaSegmentacao,
                    "Local_IP": $rootScope.ip
                });
                $http({
                    method: 'POST',
                    url: "".concat($rootScope.ramperV3ApiBaseUrl, "/segmentacoes/salvarSegmentacao"),
                    headers: {
                        'ramp3r-auth': 'ramp3r-authorization',
                        'ramp3r-l': $rootScope.IDCrypto,
                        'Authorization': 'Bearer ' + $rootScope.Token
                    },
                    params: {
                        nome: $scope.nomeNovaSegmentacao,
                        origem: "S"
                    }
                }).then(function (rRetorno) {
                    $scope.recarregarPagina();
                });
                $('#telaUmAddSegSimples').hide();
                $('#telaDoisAddSegSimples').fadeIn();
            }
        };
        $scope.voltarCriarSegmentacao = function () {
            $('#dvAdicionarSegmentacoes').hide();
            $('#formNovaSegmentacaoSimples').hide();
            $scope.limparVariaveis();
            $scope.adicionarSegmentacao();
        };
        $scope.clickCheckTodosResultados = function () {
            _clickCheckTodosResultados = !_clickCheckTodosResultados;
            $scope.showBrowserSegmentacoes.forEach(function (item) {
                item.checkSelecionadoResultado = _clickCheckTodosResultados;
            });
            $scope.contadorSelecionadosResultados();
        };
        $scope.checkResultado = function (pResultado) {
            if (!pResultado.checkSelecionadoResultado)
                pResultado.checkSelecionadoResultado = false;
            else
                pResultado.checkSelecionadoResultado = true;
            $scope.contadorSelecionadosResultados();
        };
        $scope.contadorSelecionadosResultados = function () {
            $scope.totalSelecionadosResultados =
                $scope.showBrowserSegmentacoes.filter(function (obj) {
                    return obj.checkSelecionadoResultado;
                }).length;
            if ($scope.totalSelecionadosResultados > 1) {
                $scope.nomeComecoSelecionado = 'Foram encontrados';
                $scope.nomeFinalSelecionado = 'contatos para sua nova segmentação';
                $rootScope.viewBtnAvancar = true;
            }
            else if ($scope.totalSelecionadosResultados == 1) {
                $scope.nomeComecoSelecionado = 'Foi encontrado';
                $scope.nomeFinalSelecionado = 'contato para sua nova segmentação';
                $rootScope.viewBtnAvancar = true;
            }
            else
                $rootScope.viewBtnAvancar = false;
            if ($scope.totalSelecionadosResultados < $scope.showBrowserSegmentacoes.length) {
                $('#checkContatosResultadoSeg').prop('checked', false);
                _clickCheckTodosResultados = false;
            }
            else if ($scope.totalSelecionadosResultados = $scope.showBrowserSegmentacoes.length) {
                $('#checkContatosResultadoSeg').prop('checked', true);
                _clickCheckTodosResultados = true;
            }
        };
        $scope.abrirTelas = function () {
            $('#PassoContatos2').hide();
            $('#PassoContatos3').hide();
            $('#PassoContatos4').hide();
        };
        $scope.esconderEtapas = function () {
            $('#passo1').hide();
            $('#passo2').hide();
            $('#passo3').hide();
            $('#passo4').hide();
        };
        showEtapa = function (etapa) {
            $scope.esconderEtapas();
            var x = etapa + 1;
            switch (etapa) {
                case 1:
                    $scope.coresPassoPassoInicial();
                    $('#voltarCriacaoSegFiltro').hide();
                    break;
                case 2:
                    $('#dvTraco1').removeClass('dvTracoAzulPassoPasso').addClass('dvTracoVerdePassoPasso');
                    $('#spanInicioManual').removeClass('txtAzulPassoPasso').addClass('txtVerdePassoPasso');
                    $('#imgPasso1').attr('src', "".concat($rootScope.bucket, "/ramper-v-claro-15.png"));
                    $('#dvTraco2').removeClass('dvTracoCinzaPassoPasso').addClass('dvTracoAzulPassoPasso');
                    $('#spanConfiguracao').removeClass('txtCinzaPassoPasso').addClass('txtAzulPassoPasso');
                    $('#dvTraco2').removeClass('dvTracoVerdePassoPasso').addClass('dvTracoAzulPassoPasso');
                    $('#spanConfiguracao').removeClass('txtVerdePassoPasso').addClass('txtAzulPassoPasso');
                    $('#imgPasso2').attr('src', "".concat($rootScope.bucket, "/ramper-v-escuro-15.png"));
                    $('#dvTraco3').removeClass('dvTracoAzulPassoPasso').addClass('dvTracoCinzaPassoPasso');
                    $('#spanFinalizacaoManual').removeClass('txtAzulPassoPasso').addClass('txtCinzaPassoPasso');
                    $('#imgPasso3').attr('src', "".concat($rootScope.bucket, "/ramper-cinza-15.png"));
                    break;
                case 3:
                    $('#dvTraco2').removeClass('dvTracoAzulPassoPasso').addClass('dvTracoVerdePassoPasso');
                    $('#spanConfiguracao').removeClass('txtAzulPassoPasso').addClass('txtVerdePassoPasso');
                    $('#imgPasso2').attr('src', "".concat($rootScope.bucket, "/ramper-v-claro-15.png"));
                    $('#dvTraco3').removeClass('dvTracoCinzaPassoPasso').addClass('dvTracoAzulPassoPasso');
                    $('#spanFinalizacaoManual').removeClass('txtCinzaPassoPasso').addClass('txtAzulPassoPasso');
                    $('#imgPasso3').attr('src', "".concat($rootScope.bucket, "/ramper-v-escuro-15.png"));
                    break;
                default: return;
            }
            $('#passo' + etapa.toString()).fadeIn();
        };
        $scope.coresPassoPassoInicial = function () {
            $('#dvTraco1').removeClass('dvTracoVerdePassoPasso').addClass('dvTracoAzulPassoPasso');
            $('#spanInicioManual').removeClass('txtVerdePassoPasso').addClass('txtAzulPassoPasso');
            $('#imgPasso1').attr('src', "".concat($rootScope.bucket, "/ramper-v-escuro-15.png"));
            $('#dvTraco2').removeClass('dvTracoVerdePassoPasso').addClass('dvTracoCinzaPassoPasso');
            $('#dvTraco2').removeClass('dvTracoAzulPassoPasso').addClass('dvTracoCinzaPassoPasso');
            $('#spanConfiguracao').removeClass('txtVerdePassoPasso').addClass('txtCinzaPassoPasso');
            $('#spanConfiguracao').removeClass('txtAzulPassoPasso').addClass('txtCinzaPassoPasso');
            $('#imgPasso2').attr('src', "".concat($rootScope.bucket, "/ramper-cinza-15.png"));
            $('#dvTraco3').removeClass('dvTracoVerdePassoPasso').addClass('dvTracoCinzaPassoPasso');
            $('#dvTraco3').removeClass('dvTracoAzulPassoPasso').addClass('dvTracoCinzaPassoPasso');
            $('#spanFinalizacaoManual').removeClass('txtVerdePassoPasso').addClass('txtCinzaPassoPasso');
            $('#spanFinalizacaoManual').removeClass('txtAzulPassoPasso').addClass('txtCinzaPassoPasso');
            $('#imgPasso3').attr('src', "".concat($rootScope.bucket, "/ramper-cinza-15.png"));
        };
        $scope.removerClasseErro = function (pDados) {
            if (pDados == 'nomeSegFiltro') {
                $('#nomeNovaSegmentacaoFiltro').removeClass('corTextoErro');
                $('#divNomeSegFiltro').removeClass('corTextoErro');
                $('#dvMensagemNomeSegmentacaoFiltro').fadeOut();
            }
        };
        $scope.avancar = function () {
            var qtdErros = 0;
            if (_countDiv == 1) {
                if ($scope.nomeNovaSegmentacaoFiltro == null || $scope.nomeNovaSegmentacaoFiltro == '' || !$scope.nomeNovaSegmentacaoFiltro) {
                    $('#nomeNovaSegmentacaoFiltro').addClass('corTextoErro');
                    $('#divNomeSegFiltro').addClass('corTextoErro');
                    $('#dvMensagemNomeSegmentacaoFiltro').fadeIn();
                    qtdErros++;
                }
                if ($scope.divSelecionada == 0) {
                    $scope.adicionarDivErro();
                    qtdErros++;
                }
                if (qtdErros == 0) {
                    _countDiv++;
                    $('#voltarCriacaoSegFiltro').show();
                    showEtapa(_countDiv);
                    $('#checkContatosResultadoSeg').prop('checked', true);
                    _clickCheckTodosResultados = true;
                    $rootScope.totalCheckSelecionadosResultados = true;
                    $scope.totalSelecionadosResultados = $scope.showBrowserSegmentacoes.length;
                    $scope.showBrowserSegmentacoes.forEach(function (item) {
                        item.checkSelecionadoResultado = true;
                    });
                    if ($scope.showBrowserSegmentacoes.length > 1) {
                        $scope.nomeComecoSelecionado = 'Foram encontrados';
                        $scope.nomeFinalSelecionado = 'contatos para sua nova segmentação';
                        $rootScope.viewBtnAvancar = true;
                    }
                    else if ($scope.showBrowserSegmentacoes.length == 1) {
                        $scope.nomeComecoSelecionado = 'Foi encontrado';
                        $scope.nomeFinalSelecionado = 'contato para sua nova segmentação';
                        $rootScope.viewBtnAvancar = true;
                    }
                    else
                        $rootScope.viewBtnAvancar = false;
                }
            }
            else if (_countDiv == 2) {
                _countDiv++;
                $('#voltarCriacaoSegFiltro').hide();
                $scope.nomeBtnAvancar = 'FECHAR';
                showEtapa(_countDiv);
            }
            else {
                mixpanel.track("Segmentação | Criou segmentação com filtros pré-configurados", {
                    "Nome": $scope.nomeNovaSegmentacaoFiltro,
                    "Total contatos": $scope.totalSelecionadosResultados,
                    "Local_IP": $rootScope.ip
                });
                $scope.fecharSegmentacaoFiltro();
            }
        };
        $scope.voltar = function () {
            if (_countDiv > 1)
                $rootScope.viewBtnAvancar = true;
            _countDiv--;
            if (_countDiv == 1)
                $('#voltarCriacaoSegFiltro').hide();
            showEtapa(_countDiv);
        };
        $scope.selecionarFiltroCriacaoSeg = function (pDados) {
            if (pDados == '1') {
                if ($('#filtro1').hasClass('divSelectFiltrosSelecionado')) {
                    $('#filtro1').removeClass('divSelectFiltrosSelecionado').addClass('divSelectFiltros');
                    $scope.divSelecionada = 0;
                }
                else {
                    $('#filtro1').removeClass('divSelectFiltros').addClass('divSelectFiltrosSelecionado');
                    $('#filtro2').removeClass('divSelectFiltrosSelecionado').addClass('divSelectFiltros');
                    $('#filtro3').removeClass('divSelectFiltrosSelecionado').addClass('divSelectFiltros');
                    $scope.removerDivErro();
                    $scope.divSelecionada = 1;
                }
            }
            if (pDados == '2') {
                if ($('#filtro2').hasClass('divSelectFiltrosSelecionado')) {
                    $('#filtro2').removeClass('divSelectFiltrosSelecionado').addClass('divSelectFiltros');
                    $scope.divSelecionada = 0;
                }
                else {
                    $('#filtro2').removeClass('divSelectFiltros').addClass('divSelectFiltrosSelecionado');
                    $('#filtro1').removeClass('divSelectFiltrosSelecionado').addClass('divSelectFiltros');
                    $('#filtro3').removeClass('divSelectFiltrosSelecionado').addClass('divSelectFiltros');
                    $scope.removerDivErro();
                    $scope.divSelecionada = 1;
                }
            }
            if (pDados == '3') {
                if ($('#filtro3').hasClass('divSelectFiltrosSelecionado')) {
                    $('#filtro3').removeClass('divSelectFiltrosSelecionado').addClass('divSelectFiltros');
                    $scope.divSelecionada = 0;
                }
                else {
                    $('#filtro3').removeClass('divSelectFiltros').addClass('divSelectFiltrosSelecionado');
                    $('#filtro2').removeClass('divSelectFiltrosSelecionado').addClass('divSelectFiltros');
                    $('#filtro1').removeClass('divSelectFiltrosSelecionado').addClass('divSelectFiltros');
                    $scope.removerDivErro();
                    $scope.divSelecionada = 1;
                }
            }
        };
        $scope.adicionarDivErro = function () {
            $('#filtro1').addClass('divBrodaCorErro');
            $('#filtro2').addClass('divBrodaCorErro');
            $('#filtro3').addClass('divBrodaCorErro');
            $('#txtMsgErroSegmentacaoFiltro').fadeIn();
        };
        $scope.removerDivErro = function () {
            $('#filtro1').removeClass('divBrodaCorErro');
            $('#filtro2').removeClass('divBrodaCorErro');
            $('#filtro3').removeClass('divBrodaCorErro');
            $('#txtMsgErroSegmentacaoFiltro').fadeOut();
        };
        $scope.fecharSegmentacaoFiltro = function () {
            $('#dvAdicionarSegmentacoes').fadeOut();
            $('#formNovaSegmentacaoFiltro').fadeOut();
            $scope.limparVariaveis();
        };
        $scope.abrirPopupComparar = function () {
            $('#menuPrincipal').addClass('blur');
            $('#pagSegmentacoes').addClass('blur');
            $rootScope.popupCompararSegmentacao = true;
        };
        $scope.fecharPopupComparar = function () {
            $('#menuPrincipal').removeClass('blur');
            $('#pagSegmentacoes').removeClass('blur');
            $rootScope.popupCompararSegmentacao = false;
        };
        $scope.getTimestampTela = function (_id) {
            return getTimestamp(_id);
        };
        $scope.exportarContatosSeg = function () {
            $scope.qtdSelecionadosExportar = $scope.showBrowserContatos.filter(function (obj) {
                return obj.checkSelecionadoSeg;
            }).length;
            if ($scope.qtdSelecionadosExportar == '1') {
                $scope.pluralS = '';
                $scope.pluralOs = '';
                $scope.primeiraMsgExportar = 'Seu contato já está sendo processado e, ao final disto,';
            }
            else {
                $scope.pluralS = 's';
                $scope.primeiraMsgExportar = 'Seus contatos já estão sendo processados e, ao final disto,';
                $scope.pluralOs = 'os';
            }
            $rootScope.popupExportarContatosSeg = true;
            $('#dvDetalhesGeral').addClass('blur');
            $('#pagContatos').addClass('blur');
            $('.navbar').addClass('blur');
            $('#exportacaoContatoSegGeral').fadeIn();
            mixpanel.track("Segmentação | Exportou contatos", {
                "Segmentação": $scope.nomeSegSelecionada,
                "Total contatos": $scope.qtdSelecionadosExportar,
                "Local_IP": $rootScope.ip
            });
        };
        $scope.fecharPopupExportarContatosSeg = function () {
            $rootScope.popupExportarContatosSeg = false;
            $('#dvDetalhesGeral').removeClass('blur');
            $('#pagContatos').removeClass('blur');
            $('.navbar').removeClass('blur');
        };
        $('#dvAdicionarSegmentacoes').hide();
        $('#telaUmAddSegSimples').hide();
        $('#dvDetalhesGeral').hide();
        $scope.mostrarSegundaLinha = function (pSeg) {
            _segmentacaoSelecionada = pSeg;
            _filtrosEstatisticas = {
                objectId: _segmentacaoSelecionada._id._id
            };
            if (_segmentacaoSelecionada.segSelecionada == 'Selecionada') {
                _segmentacaoSelecionada.segSelecionada = '';
                _segmentacaoSelecionada.txtMais = 'mais';
                $('#linha' + pSeg._id._id).addClass('subirListaFora');
                $('#img' + pSeg._id._id).removeClass('classeVirarImagem');
                setTimeout(function () { $('#linha' + pSeg._id._id).removeClass('subirListaFora').addClass('classeInvisivelCad'); }, 300);
            }
            else {
                _segmentacaoSelecionada.segSelecionada = 'Selecionada';
                $('#linha' + pSeg._id._id).removeClass('classeInvisivelCad').addClass('descerLinhaDentro');
                setTimeout(function () { $('#linha' + pSeg._id._id).removeClass('descerLinhaDentro'); }, 500);
                $('#img' + pSeg._id._id).addClass('classeVirarImagem');
                _segmentacaoSelecionada.txtMais = 'menos';
                if (_segmentacaoSelecionada.controleInfos == 0)
                    $scope.consultarDisparosSegmantacao(_filtrosEstatisticas);
            }
        };
        $scope.abrirFiltroCadenciaSeg = function () {
            $scope.filtroCadenciaSegAberto = !$scope.filtroCadenciaSegAberto;
            if ($scope.filtroCadenciaSegAberto) {
                $('#idFiltroCadenciaSeg').show();
                $('#idListaCadenciaSeg').animate({ scrollTop: 0 }, 10);
                setTimeout(function () { document.getElementById("idCadenciaFiltroSeg").focus(); }, 10);
            }
            else
                $('#idFiltroCadenciaSeg').hide();
        };
        $scope.selecionarCadenciaSeg = function (pDados) {
            if (pDados || pDados != null) {
                $scope.cadenciaFiltroSeg = pDados._id;
                document.getElementById("idCadenciaSegFiltro").innerText = pDados.nome;
            }
            else {
                $scope.cadenciaFiltroSeg = null;
                document.getElementById("idCadenciaSegFiltro").innerText = 'Sem cadência';
            }
            $scope.nomeCadenciaFiltroSeg = pDados.nome;
            $scope.abrirFiltroCadenciaSeg();
        };
        $(document).mouseup(function (e) {
            var filtroCad = $('#idFiltroCadenciaSeg');
            if (!filtroCad.is(e.target) && filtroCad.has(e.target).length === 0) {
                $scope.filtroCadenciaSegAberto = false;
                filtroCad.hide();
            }
        });
        $scope.abrirFiltroUsuariosSeg = function () {
            $scope.filtroUsuariosSegAberto = !$scope.filtroUsuariosSegAberto;
            if ($scope.filtroUsuariosSegAberto) {
                $('#idFiltroUsuariosSeg').show();
                $('#idListaUsuSeg').animate({ scrollTop: 0 }, 10);
                setTimeout(function () { document.getElementById("buscarUsuariosSeg").focus(); }, 10);
            }
            else
                $('#idFiltroUsuariosSeg').hide();
        };
        $scope.selecionarUsuarioSeg = function (pDados) {
            $scope.teste = pDados;
            $scope.usuarioFiltroSeg = pDados._id;
            $scope.nomeUsuarioFiltroSeg = pDados.nome;
            document.getElementById("idUsuariosSegFiltro").innerText = pDados.nome;
            $scope.filtroUsuariosSegAberto = false;
            $('#idFiltroUsuariosSeg').hide();
            $scope.consultarCadenciasSimples(pDados._id);
            $scope.nomeCadenciaFiltroSeg = '';
            $scope.cadenciaFiltroSeg = null;
            document.getElementById("idCadenciaSegFiltro").innerText = 'Cadência';
        };
        $(document).mouseup(function (e) {
            var filtroUsuario = $('#idFiltroUsuariosSeg');
            var inputFiltroUsuario = $('#buscarUsuariosSeg');
            var divOrdenar = $('#idOrdenarSeg');
            if (!filtroUsuario.is(e.target) && filtroUsuario.has(e.target).length === 0) {
                $scope.filtroUsuariosSegAberto = false;
                filtroUsuario.hide();
            }
            if (inputFiltroUsuario.is(e.target)) {
                $scope.filtroUsuariosSegAberto = true;
            }
            if (!divOrdenar.is(e.target) && divOrdenar.has(e.target).length === 0) {
                $scope.ordenarSegmentacaoAberto = false;
                divOrdenar.hide();
            }
        });
        $scope.montarNomeFiltros = function () {
            var contagem = 0;
            $scope.txtNomeFiltros = '';
            if ($('#sltDataFiltroSegmentacoes')[0].value != '' && $('#sltDataFiltroSegmentacoes')[0].value != null) {
                $scope.txtNomeFiltros = 'Filtrado por ' + $('#sltDataFiltroSegmentacoes')[0].value;
                contagem++;
            }
            if ($scope.nomeUsuarioFiltroSeg != '' && $scope.nomeUsuarioFiltroSeg != null) {
                contagem == 0 ? $scope.txtNomeFiltros = 'Filtrado por ' + $scope.nomeUsuarioFiltroSeg : $scope.txtNomeFiltros += ' > ' + $scope.nomeUsuarioFiltroSeg;
                contagem++;
            }
            if ($scope.nomeCadenciaFiltroSeg != '' && $scope.nomeCadenciaFiltroSeg != null) {
                contagem == 0 ? $scope.txtNomeFiltros = 'Filtrado por ' + $scope.nomeCadenciaFiltroSeg : $scope.txtNomeFiltros += ' > ' + $scope.nomeCadenciaFiltroSeg;
                contagem++;
            }
        };
        $scope.abrirOrdenar = function () {
            $scope.ordenarSegmentacaoAberto = !$scope.ordenarSegmentacaoAberto;
            if ($scope.ordenarSegmentacaoAberto) {
                $('#idOrdenarSeg').show();
            }
            else
                $('#idOrdenarSeg').hide();
        };
        var ordemNome = false;
        var ordemData = false;
        $scope.ordenarSegmentacoes = function (pDados, pValue) {
            $scope.trocarOrdem = false;
            var textoOrd = document.getElementById("idTxtOrdenarSeg");
            document.getElementById("txtOrdenacaoAlfabeticaSeg").innerText = 'A-Z';
            document.getElementById("txtOrdenacaoDataSeg").innerText = 'Data';
            if (pDados == 'n') {
                if (!ordemNome) {
                    $scope.reverse = false;
                    $('#imgOrdenacaoSeg').attr('src', "".concat($rootScope.bucket, "/imgOrdemCrescente.png"));
                    textoOrd.innerText = 'A-Z';
                    document.getElementById("txtOrdenacaoAlfabeticaSeg").innerText = 'Z-A';
                }
                else {
                    $scope.reverse = true;
                    $('#imgOrdenacaoSeg').attr('src', "".concat($rootScope.bucket, "/imgOrdemDecrescente.png"));
                    textoOrd.innerText = 'Z-A';
                    document.getElementById("txtOrdenacaoAlfabeticaSeg").innerText = 'A-Z';
                }
                ordemData = false;
                ordemNome = !ordemNome;
            }
            else if (pDados == 'd') {
                if (!ordemData) {
                    $scope.reverse = false;
                    $('#imgOrdenacaoSeg').attr('src', "".concat($rootScope.bucket, "/imgOrdemCrescente.png"));
                    textoOrd.innerText = 'Menor data primeiro';
                    document.getElementById("txtOrdenacaoDataSeg").innerText = 'Maior data';
                }
                else {
                    $scope.reverse = true;
                    $('#imgOrdenacaoSeg').attr('src', "".concat($rootScope.bucket, "/imgOrdemDecrescente.png"));
                    textoOrd.innerText = 'Maior data primeiro';
                    document.getElementById("txtOrdenacaoDataSeg").innerText = 'Menor data';
                }
                ordemNome = false;
                ordemData = !ordemData;
            }
            $scope.tituloOrdenacao = textoOrd.innerText;
            $scope.dadosParaOrdenacao = pDados;
            $scope.propertyName = pValue;
            $scope.ordenarSegmentacaoAberto = false;
            $('#idOrdenarSeg').hide();
        };
        $scope.alterarOrdenacaoSeg = function () {
            $scope.ordenarSegmentacoes($scope.dadosParaOrdenacao, $scope.propertyName);
        };
        $scope.zerarCamposOrdenacao = function () {
            $scope.reverse = false;
            $scope.trocarOrdem = true;
            $scope.tituloOrdenacao = '';
            document.getElementById("idTxtOrdenarSeg").innerText = 'Ordenar';
            document.getElementById("txtOrdenacaoAlfabeticaSeg").innerText = 'A-Z';
            document.getElementById("txtOrdenacaoDataSeg").innerText = 'Data';
        };
        $scope.consultarSegmentacoes();
        $scope.consultarCapturasHoje = function () {
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/login/consultarContatosMixpanel"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                }
            }).then(function (rConsultarContatosMixpanel) {
                if (rConsultarContatosMixpanel.data) {
                    rConsultarContatosMixpanel.data = false;
                    mixpanel.track("Captura Extensão | O usuario capturou contatos hoje", {});
                }
            });
        };
        $scope.consultarCapturasHoje();
        $scope.colapseOrExpandMenu = function (menu) {
            if (menu == 'filtro') {
                $scope.menuNavAberto = !$scope.menuNavAberto;
                if ($scope.menuNavAberto) {
                    $("#expandOrCollapseSideMenu").addClass("collapseMenuNav");
                    $("#menuNav").removeClass("menuNavCol");
                    $("#expandOrCollapseSideMenu").removeClass("expandMenuNav");
                    setTimeout(function () {
                        $("#tituloMenuLateral").removeClass("escondeMenuLateral");
                        $("#conteudoMenuLateral").removeClass("escondeMenuLateral");
                    }, 300);
                }
                else {
                    $("#expandOrCollapseSideMenu").removeClass("collapseMenuNav");
                    $("#menuNav").addClass("menuNavCol");
                    $("#tituloMenuLateral").addClass("escondeMenuLateral");
                    $("#conteudoMenuLateral").addClass("escondeMenuLateral");
                    $("#expandOrCollapseSideMenu").addClass("expandMenuNav");
                }
            }
        };
    }]);
