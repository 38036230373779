angular.module("ramperv3").service("MonsterDuplaAutenticacaoService", [
    "$http",
    "$rootScope",
    function ($http, $rootScope) {
        /**
         * Deve consumir a api para validar o token
         * @param token credenciais para validar dupla autenticação
         */
        function validarTokenMfa(token) {
            return $http({
                method: "POST",
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/login/mfa/validate"),
                headers: { 'Authorization': 'Bearer ' + $rootScope.responseLogin.data.token },
                data: { token: token },
            });
        }
        return { validarTokenMfa: validarTokenMfa };
    },
]);
