angular
    .module('ramperv3')
    .constant('ContatoStatusLabel', {
    QUARENTENA: 'Quarentena',
    OPT_OUT: 'Optout',
    INTERESSE: 'Interesse',
    SEM_INTERESSE: 'Sem interesse',
    NOVO: 'Novo',
    BOUNCE: 'Bounce',
    PROCESSAMENTO: 'Processamento',
    NAO_RECEBEM: 'Não recebem',
    EM_ABORDAGEM: 'Em abordagem',
    JA_ABORDADO: 'Já abordado',
    RESPONDIDO: 'Respondido',
    NEUTRO: 'Neutro',
    SEM_VALIDACAO: 'Sem validação',
    DOMINIO_BLOQUEADO: 'Domínio bloqueado'
});
angular
    .module('ramperv3')
    .constant('RamperIntegracao', {
    P: 'Pipedrive',
    A: 'Agendor',
    HBCRM: 'Hubspot',
    closeIO: 'CloseIO',
    E: 'Exact Sales',
    N: 'Nectar CRM',
    R: 'RDStation',
    Z: 'Zoho',
    F: 'Funil De Vendas',
    S: 'Fresh Sales',
    moskit: 'Moskit',
    U: 'RD Station CRM',
    I: 'PipeRun',
    O: 'SalesForce',
    linkseller: 'Pipeline',
    D365: 'Dynamics 365'
});
angular
    .module('ramperv3')
    .constant('RamperIntegracaoCategoria', {
    C: 'CRM',
    P: 'Pré-Venda',
    I: 'Inbound',
});
