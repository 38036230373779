angular.module("ramperv3").service("MonsterNoticiaService", [
    "$rootScope", "$http",
    function ($rootScope, $http) {
        /**
         * Deve consumir a api para obter a noticia
         */
        function obterNoticia() {
            var url = "".concat($rootScope.ramperV3ApiBaseUrl, "/noticias");
            var headers = {
                "ramp3r-auth": "ramp3r-authorization",
                "ramp3r-l": $rootScope.IDCrypto,
                'Authorization': 'Bearer ' + $rootScope.Token
            };
            return $http({ method: "GET", url: url, headers: headers });
        }
        /**
         * Deve consumir a api para não exibir noticia novamente
         */
        function naoExibirNoticiaNovamente(idNoticia) {
            var url = "".concat($rootScope.ramperV3ApiBaseUrl, "/noticias/").concat(idNoticia, "/nao-exibir-novamente");
            var headers = {
                "ramp3r-auth": "ramp3r-authorization",
                "ramp3r-l": $rootScope.IDCrypto,
                'Authorization': 'Bearer ' + $rootScope.Token
            };
            return $http({ method: "PATCH", url: url, headers: headers });
        }
        return {
            obterNoticia: obterNoticia,
            naoExibirNoticiaNovamente: naoExibirNoticiaNovamente,
        };
    },
]);
