angular.module('ramperv3')
    .controller('IntegracoesController', ['$rootScope', '$scope', '$http', function ($rootScope, $scope, $http) {
        ga('send', 'pageview');
        ga('set', 'page', document.location.hash);
        $scope.txtInicioIntegracao1 = 'A integração permite que você envie seus contatos do Ramper Prospect para o ';
        $scope.txtInicioIntegracao2 = 'Isto facilita e dá agilidade ao seu processo de prospecção!';
        $rootScope.loaderAtivo = true;
        $('#pagIntegracoes').addClass('blur');
        $('#menuPrincipal').addClass('blur');
        $('#dvPreVendas').hide();
        $('#dvInbound').hide();
        $('#dvAgenda').hide();
        $('#dvCRM').hide();
        $('.descricaoBotaoTelas').hide();
        $('#estagioCampo').hide();
        $('#usuarioCampo').hide();
        var configurarEngageAutoSend = function (idIntegracao) {
            $('#engageAutoSendConfiguration').removeClass('invisivel');
            $('#engageAutoSendConfiguration').appendTo('#' + idIntegracao);
            if (['idConfigPiperun', 'idConfigIntegracaoPadrao'].includes(idIntegracao)) {
                $('#engageAutoSendConfiguration').addClass('espacoTopo25');
                $('#txtHashPiperun').removeClass('margin-bottom-20P');
            }
            if (['idConfigSalesforce', 'idConfigHubspot'].includes(idIntegracao)) {
                $('.divIntegracaoOAuth').addClass('tAlignLeft');
                $('.divSpanIntegracaoGmailOffice').addClass('margin-bottom20');
                $('#engageAutoSendConfiguration').addClass('mTop50');
                $('.divSpanIntegracaoGmailOffice').removeClass('telaDoisTituloGmailOffdice');
            }
            if (idIntegracao === 'idConfigD365') {
                $('#engageAutoSendConfiguration').addClass('mTop50');
                $('#engageAutoSendConfiguration').addClass('tAlignLeft');
                $('#engageAutoSendConfiguration').addClass('margin-left25 ');
            }
            if (idIntegracao === 'idConfigMoskit') {
                $('#txtDominioResponsavelMoskit').removeClass('margin-bottom-20P');
                $('#txtDominioResponsavelMoskit').addClass('margin-bottom50');
                $('#engageAutoSendConfiguration').addClass('mTop50');
            }
        };
        var getIdConfigIntegracao = function (aplicacaoIntegrar) {
            var idIntegracao = 'idConfigIntegracaoPadrao';
            if (aplicacaoIntegrar === 'D365') {
                idIntegracao = 'idConfigD365';
            }
            if (aplicacaoIntegrar === 'HBCRM') {
                idIntegracao = 'idConfigHubspot';
            }
            if (aplicacaoIntegrar === 'I') {
                idIntegracao = 'idConfigPiperun';
            }
            if (aplicacaoIntegrar === 'linkseller') {
                idIntegracao = 'idConfigLinkseller';
            }
            if (aplicacaoIntegrar === 'moskit') {
                idIntegracao = 'idConfigMoskit';
            }
            if (aplicacaoIntegrar === 'N') {
                idIntegracao = 'idConfigNectar';
            }
            if (aplicacaoIntegrar === 'O') {
                idIntegracao = 'idConfigSalesforce';
            }
            if (aplicacaoIntegrar === 'P') {
                idIntegracao = 'idConfigPipedrive';
            }
            return idIntegracao;
        };
        var resetarLayoutConfigurarEngageAutoSend = function () {
            $('#engageAutoSendConfiguration').addClass('invisivel');
            $('#engageAutoSendConfiguration').removeClass('espacoTopo25');
            $('#txtHashPiperun').addClass('margin-bottom-20P');
            $('.divIntegracaoOAuth').removeClass('tAlignLeft');
            $('.divSpanIntegracaoGmailOffice').removeClass('margin-bottom20');
            $('#engageAutoSendConfiguration').removeClass('mTop50');
            $('.divSpanIntegracaoGmailOffice').addClass('telaDoisTituloGmailOffice');
            $('#engageAutoSendConfiguration').removeClass('mTop50');
            $('#engageAutoSendConfiguration').removeClass('tAlignLeft');
            $('#engageAutoSendConfiguration').removeClass('margin-left25 ');
            $('#txtDominioResponsavelMoskit').addClass('margin-bottom-20P');
            $('#txtDominioResponsavelMoskit').removeClass('margin-bottom50');
        };
        setTimeout(function () {
            $rootScope.consultarStatusUsuario();
            $rootScope.mostrarMenu = true;
            var _editar = false;
            $scope.habilitarPropriedadesApps = false;
            $scope.habilitarVistasPropriedades = false;
            $scope.opcaoEditar = false;
            $scope.NotificacaoIntegracao = false;
            $scope.terminarIntegracaoGoogle = false;
            $scope.integracaoCrmFeita = false;
            $scope.integracaoInboundFeita = false;
            $scope.passo = 1;
            $scope.pipelinesPipedrive = [];
            $scope.pipelinePipedrive = null;
            $scope.estagiosPipedrive = [];
            $scope.estagiosPipedriveShow = [];
            $scope.estagioPipedrive = null;
            $scope.usuariosPipedrive = [];
            $scope.usuarioPipedrive = null;
            $scope.etapasLinkseller = [];
            $scope.pipelinesLinkseller = [];
            $scope.vendedoresLinkseller = [];
            $scope.codigoFunilLinkseller = null;
            $scope.codigoVendedorLinkseller = null;
            $scope.codigoEtapaLinkseller = null;
            $scope.voltarUmPasso = false;
            $scope.viewBtnAvancar = true;
            $scope.popupRemove = false;
            $scope.voltarAtivado = true;
            $scope.engageAutoSend = true;
            resetaTelaDynamics();
            $('#dvCRM').show();
            $('.descricaoBotaoTelas').show();
            $scope.divBtnCloseIO = false;
            $scope.divBtnSalesforce = false;
            $scope.divBtnFreshsales = false;
            $scope.divBtnHubSpot = false;
            $scope.divBtnPiperun = false;
            $scope.divBtnAgendor = false;
            $scope.divBtnRdStation = false;
            $scope.divBtnPipedrive = false;
            $scope.divBtnZoho = false;
            $scope.divBtnNectar = false;
            $scope.divBtnMoskit = false;
            $scope.divBtnPlug = false;
            $scope.divBtnFunildevendas = false;
            $scope.divBtnExactSales = false;
            $('.passo2moskit').hide();
            $('.passo2nectar').hide();
            $('.passo2zoho').hide();
            $scope.divBtnDynamics = false;
            $scope.divBtnLinkseller = false;
            $('#txtDominioResponsavelMoskit').removeClass('corTextoErro');
            $('#dvMensagemMoskitResponsavel').fadeOut();
            $('#txtDominioEstagioMoskit').removeClass('corTextoErro');
            $('#dvMensagemMoskitEstagio').fadeOut();
            $scope.listaIntegracoes = [];
            $scope.abrirTela();
        }, 2000);
        $scope.verificarPermissaoEngage = function () {
            // COMENTADO ATÉ O ENGAGE VOLTAR A SER UTILIZADO!!!
            // $http({
            //     method: 'GET',
            //     url: '/disparos/checkEngageActivation',
            //     headers: {
            //         'ramp3r-auth': 'ramp3r-authorization',
            //         'ramp3r-l': $rootScope.IDCrypto
            //     }
            // }).then(function (response) {
            // remover esse endpoint após finalização do permisionamento
            // e verificar apenas a permissao createEngage
            // $scope.usuarioAtivoEngage = response.data.engageAtivo || $rootScope.permissoes.cadencias.createEngage;
            // }).catch(function(){
            $scope.usuarioAtivoEngage = false;
            // });
        };
        $scope.abrirTela = function () {
            $rootScope.loaderAtivo = true;
            $('#pagIntegracoes').addClass('blur');
            $('#menuPrincipal').addClass('blur');
            $scope.integracaoNectar = [];
            $scope.integracaoZoho = [];
            $scope.integracaoPipedrive = [];
            $scope.integracaoFunilDeVendas = [];
            $scope.integracaoLinkseller = null;
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/integracoes/consultarIntegracoes"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto
                }
            }).then(function (rConsultaIntegracoes) {
                $scope.listaIntegracoes = rConsultaIntegracoes.data;
                $scope.calendar = false;
                $scope.listaIntegracoes.forEach(function (integracoes) {
                    if (integracoes.application == 'P') {
                        $scope.apiKeyPipedrive = integracoes.apiKey;
                        $scope.integracaoPipedrive.push(integracoes);
                    }
                    else if (integracoes.application == 'R') {
                        $scope.apiKeyRdStation = integracoes.apiKey;
                    }
                    else if (integracoes.application == 'E') {
                        $scope.dominioApiKeyExactsales = integracoes.apiKey;
                        $scope.dominioContaExactSales = integracoes.aliasExactsales.email;
                    }
                    else if (integracoes.application == 'I')
                        $scope.hashPiperun = integracoes.apiKey;
                    else if (integracoes.application == 'Z')
                        $scope.integracaoZoho.push(integracoes);
                    else if (integracoes.application == 'N')
                        $scope.integracaoNectar.push(integracoes);
                    else if (integracoes.application == 'F')
                        $scope.integracaoFunilDeVendas.push(integracoes);
                    else if (integracoes.application === 'linkseller') {
                        $scope.apiKeyLinkseller = integracoes.apiKey;
                        $scope.integracaoLinkseller = integracoes;
                    }
                    else
                        $scope.apiKeyGeral = integracoes.apiKey;
                    $scope.selecionarIntegracao(integracoes.application);
                    $scope.engageAutoSend = integracoes.engageAutoSend;
                });
                $rootScope.loaderAtivo = false;
                $('#pagIntegracoes').removeClass('blur');
                $('#menuPrincipal').removeClass('blur');
            });
        };
        $scope.clickMenuDetalhes = function (pDetalhe) {
            $scope.esconderTodasDivsDetalhes();
            $scope.removerDestaqueMenu();
            if (pDetalhe == 'c') {
                $('#liDetalhesCRM').addClass('liMenuDetalhesActive');
                $('#dvCRM').fadeIn();
            }
            else if (pDetalhe == 'p') {
                $('#liDetalhesPreVendas').addClass('liMenuDetalhesActive');
                $('#imagemExactSalesInicial').addClass('exactSalesAlinhamento');
                $('#dvPreVendas').fadeIn();
            }
            else if (pDetalhe == 'i') {
                $('#liDetalhesInbound').addClass('liMenuDetalhesActive');
                $('#dvInbound').fadeIn();
            }
            else if (pDetalhe == 'a') {
                $('#liDetalhesAgenda').addClass('liMenuDetalhesActive');
                $('#dvAgenda').fadeIn();
            }
        };
        $scope.esconderTodasDivsDetalhes = function () {
            $('#dvCRM').hide();
            $('#dvPreVendas').hide();
            $('#dvInbound').hide();
            $('#dvAgenda').hide();
        };
        $scope.mostrarToastErro = function () {
            var toast = document.getElementById("toast");
            toast.className = "show";
            setTimeout(function () {
                toast.className = "hide";
                location.reload(true);
            }, 2000);
        };
        $scope.removerDestaqueMenu = function () {
            $('#liDetalhesCRM').removeClass('liMenuDetalhesActive');
            $('#liDetalhesPreVendas').removeClass('liMenuDetalhesActive');
            $('#liDetalhesInbound').removeClass('liMenuDetalhesActive');
            $('#liDetalhesAgenda').removeClass('liMenuDetalhesActive');
        };
        $scope.removerConfiguracao = function (pAplicacaoIntegrar) {
            if (pAplicacaoIntegrar == 'freshsales')
                $scope.integracaoFeita = 'Freshsales';
            else if (pAplicacaoIntegrar == 'O')
                $scope.integracaoFeita = 'Salesforce';
            else if (pAplicacaoIntegrar == 'closeIO')
                $scope.integracaoFeita = 'Close.io';
            else if (pAplicacaoIntegrar == 'HBCRM') {
                $scope.integracaoFeita = 'HubSpot';
                localStorage.removeItem('hubspot');
            }
            else if (pAplicacaoIntegrar == 'I')
                $scope.integracaoFeita = 'PipeRun';
            else if (pAplicacaoIntegrar == 'A')
                $scope.integracaoFeita = 'Agendor';
            else if (pAplicacaoIntegrar == 'P')
                $scope.integracaoFeita = 'Pipedrive';
            else if (pAplicacaoIntegrar == 'zoho')
                $scope.integracaoFeita = 'Zoho';
            else if (pAplicacaoIntegrar == 'N')
                $scope.integracaoFeita = 'Nectar CRM';
            else if (pAplicacaoIntegrar == 'moskit')
                $scope.integracaoFeita = 'Moskit';
            else if (pAplicacaoIntegrar == 'F')
                $scope.integracaoFeita = 'Funil de Vendas';
            else if (pAplicacaoIntegrar == 'U')
                $scope.integracaoFeita = 'RD Station CRM';
            else if (pAplicacaoIntegrar === 'D365')
                $scope.integracaoFeita = 'Dynamics 365';
            else if (pAplicacaoIntegrar === 'linkseller') {
                $scope.integracaoFeita = 'Ramper Pipeline';
            }
            $scope.popupRemove = true;
            $scope.aplicacaoRemover = pAplicacaoIntegrar;
            setTimeout(function () { $('#idInptExcluir').focus(); }, 90);
            $('#menuPrincipal').addClass('blur');
            $('#pagIntegracoes').addClass('blur');
            $('#idPopupRemoveIntegracoes').show();
            if ($('#dvCRM').is(':visible')) {
                $scope.crmAberto = true;
                $scope.preVendasAberto = false;
                $scope.inboundAberto = false;
            }
            else if ($('#dvPreVendas').is(':visible')) {
                $scope.crmAberto = false;
                $scope.preVendasAberto = true;
                $scope.inboundAberto = false;
            }
            else if ($('#dvInbound').is(':visible')) {
                $scope.crmAberto = false;
                $scope.preVendasAberto = false;
                $scope.inboundAberto = true;
            }
            $scope.engageAutoSend = false;
        };
        $scope.fecharPopupRemover = function () {
            $('#menuPrincipal').removeClass('blur');
            $('#pagIntegracoes').removeClass('blur');
            $scope.popupRemove = false;
        };
        $scope.habilitarSelects = function (pDados) {
            if (pDados == 1) {
                if ($scope.contasGA != null) {
                    $scope.habilitarPropriedadesApps = true;
                }
            }
            if (pDados == 2) {
                if ($scope.propriedadesApps != null) {
                    $scope.habilitarVistasPropriedades = true;
                }
            }
            if (pDados == 3) {
                if ($scope.vistasPropriedades != null) {
                    $scope.viewBtnAvancar = true;
                }
            }
        };
        $scope.ajustesTamanhoDeImagem = function () {
            $('#imgInicioIntegracao').removeClass();
            $('#imgFinalIntegracao').removeClass();
            $('#integracaoFinalizacao').removeClass();
            $('#telaInicialIntegracoes').removeClass('margin-leftSalesForce');
            $('#logoRamperImg').removeClass();
            $('#imagemIntegracaoInicial').removeClass();
            $('#exactSalesWidth').removeClass('exactSalesWidth');
            $scope.privado = "";
            $('#imgInicioIntegracao').addClass('imagemIntegracoesRamper');
            $('#imgInicioIntegracaoZenvia').addClass('imagemIntegracoesRamperZenvia');
            $('#imgFinalIntegracao').addClass('imgConclusaoIntegracao');
            $('#logoRamperImg').addClass('imgConclusaoRamper');
        };
        $scope.esconderDivsPassos = function () {
            $('#passoApiKeyDiv').hide();
            $('#passo2moskit').hide();
            $('#passo2nectar').hide();
            $('#passo2zoho').hide();
            $('#passo1zoho').hide();
        };
        $scope.mostrarSoApiKey = function () {
            $('#passoApiKeyDiv').show();
            $('#passo2moskit').hide();
            $('#passo2nectar').hide();
            $('#passo2zoho').hide();
            $('#passo1zoho').hide();
        };
        $scope.nectarInicial = function () {
            $scope.txtPassoMeioIntegracao = 'API Key';
            $scope.integracaoFeita = 'Nectar CRM';
            $scope.txtInicioIntegracao1 = $scope.txtInicioIntegracao1 + $scope.integracaoFeita + '.';
            $('#imgInicioIntegracao').attr('src', "".concat($rootScope.bucket, "/nectar-opcional.png"));
            $('#imgFinalIntegracao').attr('src', "".concat($rootScope.bucket, "/nectar-opcional.png"));
            $('#imgInicioIntegracao').addClass('widthNectar');
            $('#imgFinalIntegracao').addClass('widthNectarPequeno');
            $('#passoApiKeyDiv').show();
            $('#passo2moskit').hide();
            $('#passo1zoho').hide();
            $scope.privado = "privado";
        };
        $scope.pipeDriveInicial = function () {
            $scope.txtPassoMeioIntegracao = 'API Key';
            $scope.integracaoFeita = 'Pipedrive';
            $scope.txtInicioIntegracao1 = $scope.txtInicioIntegracao1 + $scope.integracaoFeita + '.';
            $('#imgInicioIntegracao').attr('src', "".concat($rootScope.bucket, "/pipedrive.png"));
            $('#imgFinalIntegracao').attr('src', "".concat($rootScope.bucket, "/pipedrive.png"));
            $('#integracaoFinalizacao').removeClass().addClass('mLeft-14');
            $('#imgFinalIntegracao').removeClass().addClass('imgPipedriveFinal');
            $('#logoRamperImg').removeClass().addClass('imgRamperFinal');
            $scope.comSemInteresse = 'com Interesse';
            $scope.esconderDivsPassos();
            if (!$scope.opcaoEditar) {
                $scope.apiKeyPipedrive = '';
            }
        };
        $scope.configurar = function (pAplicacaoIntegrar) {
            if ($rootScope.permissoes.integracoes.integrate) {
                $scope.aplicacaoIntegrar = pAplicacaoIntegrar;
                if ($('#dvCRM').is(':visible')) {
                    if ($scope.integracaoCrmFeita == false && ($rootScope.permissoes.integracoes.onlyProductsRamper == false || pAplicacaoIntegrar == 'linkseller')) {
                        $scope.apiKeyGeral = '';
                        $scope.abrirTelaParaIntegracao();
                        $scope.ajustesTamanhoDeImagem();
                        $scope.comSemInteresse = 'Com Interesse';
                        $scope.txtPassoMeioIntegracao = 'API Key';
                        if (pAplicacaoIntegrar == 'freshsales') {
                            $scope.integracaoFeita = 'Freshsales';
                            $scope.txtInicioIntegracao1 = $scope.txtInicioIntegracao1 + $scope.integracaoFeita + '.';
                            $('#imgInicioIntegracao').attr('src', "".concat($rootScope.bucket, "/freshsales2.png"));
                            $('#imgFinalIntegracao').attr('src', "".concat($rootScope.bucket, "/freshsales2.png"));
                            $('#imgFinalIntegracao').removeClass().addClass('imgFreshsalesFinal');
                            $scope.apiKeyFreshsales = '';
                            $scope.dominioContaFreshsales = '';
                            $scope.esconderDivsPassos();
                        }
                        else if (pAplicacaoIntegrar == 'O') {
                            $scope.integracaoFeita = 'Salesforce';
                            $scope.txtInicioIntegracao1 = $scope.txtInicioIntegracao1 + $scope.integracaoFeita + '.';
                            $('#imgInicioIntegracao').attr('src', "".concat($rootScope.bucket, "/salesforce2.png"));
                            $('#imgFinalIntegracao').attr('src', "".concat($rootScope.bucket, "/salesforce2.png"));
                            $('#imgInicioIntegracao').addClass('salesForceWidth');
                            $('#telaInicialIntegracoes').addClass('margin-leftSalesForce');
                            $('#integracaoFinalizacao').removeClass().addClass('salesForceMargin');
                            $('#imgFinalIntegracao').addClass('salesForceImageFinal');
                            $('#imagemIntegracaoInicial').addClass('margin-left--15');
                            $scope.esconderDivsPassos();
                        }
                        else if (pAplicacaoIntegrar == 'closeIO') {
                            $scope.integracaoFeita = 'Close.io';
                            $scope.txtInicioIntegracao1 = $scope.txtInicioIntegracao1 + $scope.integracaoFeita + '.';
                            $('#imgInicioIntegracao').attr('src', "".concat($rootScope.bucket, "/closeio.png"));
                            $('#imgFinalIntegracao').attr('src', "".concat($rootScope.bucket, "/closeio.png"));
                            $('#integracaoFinalizacao').addClass('marginIntegracaoCloseIO');
                            $('#logoRamperImg').addClass('marginTop-15');
                            $('#imgFinalIntegracao').addClass('fotoPoupupTrocarUsuario');
                            $scope.apiKeyCloseIO = '';
                            $scope.dominioContaFreshsales = '';
                            $scope.mostrarSoApiKey();
                        }
                        else if (pAplicacaoIntegrar == 'HBCRM') {
                            $scope.integracaoFeita = 'HubSpot';
                            $scope.txtInicioIntegracao1 = $scope.txtInicioIntegracao1 + $scope.integracaoFeita + '.';
                            $('#imgInicioIntegracao').attr('src', "".concat($rootScope.bucket, "/hubspot.png"));
                            $('#imgFinalIntegracao').attr('src', "".concat($rootScope.bucket, "/hubspot.png"));
                            $('#imgInicioIntegracao').removeClass().addClass('imgHubspotInicial');
                            $('#imgFinalIntegracao').removeClass().addClass('imgHubspotFinal');
                            $('#integracaoFinalizacao').addClass('mLeft-30');
                            $scope.esconderDivsPassos();
                        }
                        else if (pAplicacaoIntegrar == 'I') {
                            $scope.txtPassoMeioIntegracao = 'Hash da etapa';
                            $scope.integracaoFeita = 'PipeRun';
                            $scope.txtInicioIntegracao1 = $scope.txtInicioIntegracao1 + $scope.integracaoFeita + '.';
                            $('#imgInicioIntegracao').attr('src', "".concat($rootScope.bucket, "/piperun.png"));
                            $('#imgFinalIntegracao').attr('src', "".concat($rootScope.bucket, "/piperun.png"));
                            $('#imgFinalIntegracao').removeClass().addClass('imgPiperunFinal');
                            $('#imgInicioIntegracao').removeClass().addClass('imgPiperunInicial');
                            $('#integracaoFinalizacao').addClass('mLeft-30');
                            $('#spanApiKey').addClass('r-29');
                            $scope.esconderDivsPassos();
                        }
                        else if (pAplicacaoIntegrar == 'A') {
                            $scope.integracaoFeita = 'Agendor';
                            $scope.txtInicioIntegracao1 = $scope.txtInicioIntegracao1 + $scope.integracaoFeita + '.';
                            $('#imgInicioIntegracao').attr('src', "".concat($rootScope.bucket, "/agendor.png"));
                            $('#imgFinalIntegracao').attr('src', "".concat($rootScope.bucket, "/agendor.png"));
                            $('#imgInicioIntegracao').removeClass();
                            $('#imgFinalIntegracao').removeClass().addClass('imgAgendorFinal');
                            $('#integracaoFinalizacao').addClass('mLeft-30');
                            $('#imagemIntegracaoInicial').addClass('margin-left-n-70');
                            $scope.mostrarSoApiKey();
                        }
                        else if (pAplicacaoIntegrar == 'P') {
                            $scope.pipeDriveInicial();
                            $scope.esconderDivsPassos();
                        }
                        else if (pAplicacaoIntegrar == 'zoho') {
                            $scope.zohoPadrao();
                            $scope.avancarPasso1zoho();
                        }
                        else if (pAplicacaoIntegrar == 'N') {
                            $scope.nectarInicial();
                        }
                        else if (pAplicacaoIntegrar == 'moskit') {
                            $scope.integracaoFeita = 'Moskit';
                            $scope.txtInicioIntegracao1 = $scope.txtInicioIntegracao1 + $scope.integracaoFeita + '.';
                            $('#imgInicioIntegracao').attr('src', "".concat($rootScope.bucket, "/moskit-azul.png"));
                            $('#imgFinalIntegracao').attr('src', "".concat($rootScope.bucket, "/moskit-azul.png"));
                            $('#imgInicioIntegracao').addClass('widthMoskit');
                            $('#imgFinalIntegracao').addClass('widthMoskitPequeno');
                            $('#integracaoFinalizacao').addClass('marginIntegracaoMoskit');
                            $('#passoApiKeyDiv').show();
                            $('#passo2nectar').hide();
                            $scope.mostrarSoApiKey();
                        }
                        else if (pAplicacaoIntegrar == 'funildevendas') {
                            $scope.integracaoFeita = 'Funil de Vendas';
                            $scope.txtInicioIntegracao1 = $scope.txtInicioIntegracao1 + $scope.integracaoFeita + '.';
                            $('#imgInicioIntegracao').attr('src', "".concat($rootScope.bucket, "/funil-de-vendas.png"));
                            $('#imgFinalIntegracao').attr('src', "".concat($rootScope.bucket, "/funil-de-vendas.png"));
                            $('#imgInicioIntegracao').removeClass().addClass('imgFunilDeVendas');
                            $('#imgFinalIntegracao').removeClass().addClass('imgConclusaoRamper');
                            $('#imgFinalIntegracao').removeClass().addClass('imgFunilDeVendasFinal');
                            $scope.esconderDivsPassos();
                        }
                        else if (pAplicacaoIntegrar == 'U') {
                            $scope.integracaoFeita = 'RD Station CRM';
                            $scope.txtInicioIntegracao1 = $scope.txtInicioIntegracao1 + $scope.integracaoFeita + '.';
                            $('#imgInicioIntegracao').attr('src', "".concat($rootScope.bucket, "/RDStation-CRM.png"));
                            $('#imgFinalIntegracao').attr('src', "".concat($rootScope.bucket, "/RDStation-CRM.png"));
                            $('#imgFinalIntegracao').addClass('imgPlugIntegracao');
                            $('#integracaoFinalizacao').addClass('marginPlug');
                            $('#imgInicioIntegracao').addClass('plugmarginImage');
                            $scope.mostrarSoApiKey();
                        }
                        else if (pAplicacaoIntegrar == 'D365') {
                            resetaTelaDynamics();
                            $scope.integracaoFeita = 'Dynamics 365';
                            $scope.txtInicioIntegracao1 = $scope.txtInicioIntegracao1 + $scope.integracaoFeita + '.';
                            $('#imgInicioIntegracao').attr('src', "".concat($rootScope.bucket, "/Dynamics CRM - 200x60.png"));
                            $('#imgFinalIntegracao').attr('src', "".concat($rootScope.bucket, "/Dynamics CRM - 180x45.png"));
                            $('#imgInicioIntegracao').addClass('dynamicsForceWidth');
                            $('#telaInicialIntegracoes').addClass('margin-leftSalesForce');
                            $('#integracaoFinalizacao').removeClass().addClass('mLeft-14');
                            $('#imgFinalIntegracao').addClass('dynamicsForceImageFinal');
                            $('#imagemIntegracaoInicial').addClass('margin-left--15');
                            $scope.esconderDivsPassos();
                        }
                        else if (pAplicacaoIntegrar === 'linkseller') {
                            $scope.integracaoFeita = 'Ramper Pipeline';
                            $scope.txtInicioIntegracao1 = $scope.txtInicioIntegracao1 + $scope.integracaoFeita + '.';
                            $('#imgInicioIntegracao').attr('src', "".concat($rootScope.bucket, "/logo-ramper-pipeline.svg"));
                            $('#imgFinalIntegracao').attr('src', "".concat($rootScope.bucket, "/logo-ramper-pipeline.svg"));
                            $scope.esconderDivsPassos();
                        }
                        if (['A', 'closeIO', 'D365', 'HBCRM', 'I', 'O', 'U']
                            .includes(pAplicacaoIntegrar) && $scope.usuarioAtivoEngage) {
                            var idIntegracao = getIdConfigIntegracao(pAplicacaoIntegrar);
                            configurarEngageAutoSend(idIntegracao);
                        }
                    }
                }
                if ($('#dvInbound').is(':visible')) {
                    if (!$scope.integracaoInboundFeita && $rootScope.permissoes.integracoes.onlyProductsRamper == false) {
                        $scope.abrirTelaParaIntegracao();
                        $scope.ajustesTamanhoDeImagem();
                        $scope.comSemInteresse = 'Sem Interesse';
                        if (pAplicacaoIntegrar == 'R') {
                            $scope.esconderDivsPassos();
                            $scope.rdStationConfiguracoes();
                        }
                    }
                }
                ;
                if ($('#dvPreVendas').is(':visible')) {
                    if (!$scope.integracaoPreVendasFeita && $rootScope.permissoes.integracoes.onlyProductsRamper == false) {
                        $scope.abrirTelaParaIntegracao();
                        $scope.ajustesTamanhoDeImagem();
                        $scope.comSemInteresse = 'Sem Interesse';
                        if (pAplicacaoIntegrar == 'E') {
                            $scope.exactSalesConfiguracoes();
                            $scope.esconderDivsPassos();
                        }
                    }
                }
                ;
                if ($('#dvAgenda').is(':visible')) {
                    $scope.abrirTelaParaIntegracao();
                    if (pAplicacaoIntegrar == 'CAL') {
                        $.ajax({
                            url: "".concat($rootScope.ramperV3ApiBaseUrl, "/validarCalendar/autorizar"),
                            success: function (r) {
                                if ((r.url) && (r.url != null))
                                    window.open(r.url);
                            },
                            headers: {
                                'ramp3r-auth': 'ramp3r-authorization',
                                'ramp3r-l': $rootScope.IDCrypto,
                                'Authorization': 'Bearer ' + $rootScope.Token
                            },
                            async: false
                        });
                    }
                }
            }
        };
        $scope.exactSalesConfiguracoes = function () {
            $scope.txtPassoMeioIntegracao = 'API Key';
            $scope.integracaoFeita = 'ExactSales';
            $scope.txtInicioIntegracao1 = $scope.txtInicioIntegracao1 + $scope.integracaoFeita + '.';
            $('#imgInicioIntegracao').attr('src', "".concat($rootScope.bucket, "/exact-sales.png"));
            $('#imgFinalIntegracao').attr('src', "".concat($rootScope.bucket, "/exact-sales.png"));
            $('.heightIntegracoesPassoAPIkey').hide();
            $('#exactSalesWidth').addClass('exactSalesWidth');
            $('#imgFinalIntegracao').addClass('exactSalesFinal');
            $('#imgInicioIntegracao').addClass('exactSalesInicial');
            $scope.comSemInteresse = 'Com Interesse';
        };
        $scope.rdStationConfiguracoes = function () {
            $scope.txtPassoMeioIntegracao = 'Integração';
            $scope.integracaoFeita = 'RD Station';
            $scope.txtInicioIntegracao1 = $scope.txtInicioIntegracao1 + $scope.integracaoFeita + '.';
            $('#imgInicioIntegracao').attr('src', "".concat($rootScope.bucket, "/rd-station.png"));
            $('#imgFinalIntegracao').attr('src', "".concat($rootScope.bucket, "/rd-station.png"));
            $('#imgFinalIntegracao').removeClass().addClass('imgRdStationFinal');
            $('#integracaoFinalizacao').addClass('marginIntegracaoRdStation');
            $('#imgInicioIntegracao').addClass('w220');
            $('.heightIntegracoesPassoAPIkey').hide();
            $scope.comSemInteresse = 'Sem Interesse';
        };
        $scope.abrirTelaParaIntegracao = function () {
            $scope.removerDivsErro();
            $scope.voltarUmPasso = false;
            $scope.nomeBtnAvancar = 'AVANÇAR';
            $scope.passo = 1;
            passoUmAtivo();
            $('#dvIntegracao').fadeIn();
            $('#passo1').show();
            $('#passo2').hide();
            $('#passo3').hide();
            $('#idPasso1').show();
        };
        $scope.editarIntegracaoCrm = function (pDados) {
            _editar = true;
            $scope.opcaoEditar = true;
            $scope.removerDivsErro();
            $scope.voltarUmPasso = false;
            $scope.nomeBtnAvancar = 'AVANÇAR';
            $scope.passo = 2;
            passoDoisAtivo();
            $('#dvIntegracao').fadeIn();
            $('#passo1').hide();
            $('#passo2').show();
            $('#passo3').hide();
            $('#idPasso1').hide();
            $scope.aplicacaoIntegrar = pDados;
            if (pDados == 'moskit') {
                $scope.txtPassoMeioIntegracao = 'API Key';
                $scope.integracaoFeita = 'Moskit';
                $scope.txtInicioIntegracao1 = $scope.txtInicioIntegracao1 + $scope.integracaoFeita + '.';
                $('#imgInicioIntegracao').attr('src', "".concat($rootScope.bucket, "/moskit-azul.png"));
                $('#imgFinalIntegracao').attr('src', "".concat($rootScope.bucket, "/moskit-azul.png"));
                $('#imgInicioIntegracao').addClass('widthMoskit');
                $('#imgFinalIntegracao').addClass('widthMoskitPequeno');
                $('#integracaoFinalizacao').addClass('marginIntegracaoMoskit');
                $('#passoApiKeyDiv').show();
                $('#passo2nectar').hide();
                $scope.mostrarSoApiKey();
            }
            if (pDados == 'A') {
                $scope.txtPassoMeioIntegracao = 'API Key';
                $scope.integracaoFeita = 'Agendor';
                $scope.txtInicioIntegracao1 = $scope.txtInicioIntegracao1 + $scope.integracaoFeita + '.';
                $('#imgInicioIntegracao').attr('src', "".concat($rootScope.bucket, "/agendor.png"));
                $('#imgFinalIntegracao').attr('src', "".concat($rootScope.bucket, "/agendor.png"));
                $('#imgInicioIntegracao').removeClass();
                $('#imgFinalIntegracao').removeClass().addClass('imgAgendorFinal');
                $('#integracaoFinalizacao').addClass('mLeft-30');
                $scope.mostrarSoApiKey();
            }
            if (pDados == 'N') {
                var integracao = $scope.integracaoNectar[0];
                $scope.nectarInicial();
                $scope.avancarPassoNectar();
                $scope.apiKeyGeral = integracao.apiKey;
                $scope.nomePipeLineInput = integracao.aliasNectarcrms ? integracao.aliasNectarcrms.nomeDoPipeline : '';
                $scope.codigoEtapaInput = integracao.aliasNectarcrms ? integracao.aliasNectarcrms.etapa : '';
                $scope.emailResponsavelInput = integracao.aliasNectarcrms ? integracao.aliasNectarcrms.responsavel : '';
                $scope.emailautorInput = integracao.aliasNectarcrms ? integracao.aliasNectarcrms.autor : '';
            }
            if (pDados == 'Z') {
                $scope.aplicacaoIntegrar = 'zoho';
                var integracao = $scope.integracaoZoho[0];
                $scope.zohoPadrao();
                $scope.avancarPasso1zoho();
                $scope.clientIdZoho = integracao.aliasZoho ? integracao.aliasZoho.clientId : '';
                $scope.clientSecretZoho = integracao.aliasZoho ? integracao.aliasZoho.clientSecret : '';
                $scope.userCodeZoho = integracao.aliasZoho ? integracao.aliasZoho.grantToken : '';
            }
            if (pDados == 'funildevendas') {
                $scope.esconderDivsPassos();
                setTimeout(function () { $('#txtApiKeyFunilDeVendas input').focus(); }, 90);
                $scope.segundaTelaFunilDeVendas = false;
                $scope.txtPassoMeioIntegracao = 'API Key';
                $scope.integracaoFeita = 'Funil de Vendas';
                $scope.txtInicioIntegracao1 = $scope.txtInicioIntegracao1 + $scope.integracaoFeita + '.';
                $('#imgInicioIntegracao').attr('src', "".concat($rootScope.bucket, "/funil-de-vendas.png"));
                $('#imgFinalIntegracao').attr('src', "".concat($rootScope.bucket, "/funil-de-vendas.png"));
                $('#imgInicioIntegracao').removeClass('imagemIntegracoesRamper').addClass('imgFunilDeVendas');
                $('#imgFinalIntegracao').removeClass('imgConclusaoIntegracao').addClass('imgConclusaoRamper');
                $('#imgFinalIntegracao').removeClass().addClass('imgFunilDeVendasFinal');
                var integracao = $scope.integracaoFunilDeVendas[0];
                $scope.apiKeyFunilDeVendas = integracao.apiKey;
                $scope.codigoVendedorFV = integracao.aliasFunildevendas.vendedor ? integracao.aliasFunildevendas.vendedor : null;
                $scope.codigoCanalVendasFV = integracao.aliasFunildevendas.canalDeVendas ? integracao.aliasFunildevendas.canalDeVendas : null;
                $scope.codigoFunilFV = integracao.aliasFunildevendas.funil ? integracao.aliasFunildevendas.funil : null;
                $scope.codigoEtapaFV = integracao.aliasFunildevendas.etapa ? integracao.aliasFunildevendas.etapa : null;
            }
            if (pDados == 'P') {
                $scope.segundaTelaPipedrive = false;
                $scope.esconderDivsPassos();
                $scope.pipeDriveInicial();
                $scope.apiKeyPipedrive = $scope.integracaoPipedrive[0].apiKey;
            }
            if (pDados == 'HBCRM') {
                $scope.txtPassoMeioIntegracao = 'API Key';
                $scope.integracaoFeita = 'HubSpot';
                $scope.txtInicioIntegracao1 = $scope.txtInicioIntegracao1 + $scope.integracaoFeita + '.';
                $('#imgInicioIntegracao').attr('src', "".concat($rootScope.bucket, "/hubspot.png"));
                $('#imgFinalIntegracao').attr('src', "".concat($rootScope.bucket, "/hubspot.png"));
                $('#imgInicioIntegracao').removeClass().addClass('imgHubspotInicial');
                $('#imgFinalIntegracao').removeClass().addClass('imgHubspotFinal');
                $('#integracaoFinalizacao').addClass('mLeft-30');
                $scope.viewBtnAvancar = false;
            }
            if (pDados == 'U') {
                $scope.txtPassoMeioIntegracao = 'API Key';
                $scope.integracaoFeita = 'RD Station CRM';
                $scope.txtInicioIntegracao1 = $scope.txtInicioIntegracao1 + $scope.integracaoFeita + '.';
                $('#imgInicioIntegracao').attr('src', "".concat($rootScope.bucket, "/RDStation-CRM.png"));
                $('#imgFinalIntegracao').attr('src', "".concat($rootScope.bucket, "/RDStation-CRM.png"));
                $('#imgFinalIntegracao').addClass('imgPlugIntegracao');
                $('#integracaoFinalizacao').addClass('marginPlug');
                $('#imgInicioIntegracao').addClass('plugmarginImage');
                $scope.mostrarSoApiKey();
            }
            if (pDados == 'O') {
                $('.heightIntegracoesPassoAPIkey').hide();
                $scope.txtPassoMeioIntegracao = 'API Key';
                $scope.integracaoFeita = 'Salesforce';
                $scope.txtInicioIntegracao1 = $scope.txtInicioIntegracao1 + $scope.integracaoFeita + '.';
                $('#imgInicioIntegracao').attr('src', "".concat($rootScope.bucket, "/salesforce2.png"));
                $('#imgFinalIntegracao').attr('src', "".concat($rootScope.bucket, "/salesforce2.png"));
                $('#imgInicioIntegracao').addClass('salesForceWidth');
                $('#telaInicialIntegracoes').addClass('margin-leftSalesForce');
                $('#integracaoFinalizacao').removeClass().addClass('salesForceMargin');
                $('#imgFinalIntegracao').addClass('salesForceImageFinal');
                $('#imagemIntegracaoInicial').addClass('margin-left--15');
                $scope.esconderDivsPassos();
                $scope.comSemInteresse = 'Com Interesse';
                $scope.viewBtnAvancar = false;
            }
            if (pDados == 'I') {
                $scope.txtPassoMeioIntegracao = 'Hash da etapa';
                $scope.integracaoFeita = 'PipeRun';
                $scope.txtInicioIntegracao1 = $scope.txtInicioIntegracao1 + $scope.integracaoFeita + '.';
                $('#imgInicioIntegracao').attr('src', "".concat($rootScope.bucket, "/piperun.png"));
                $('#imgFinalIntegracao').attr('src', "".concat($rootScope.bucket, "/piperun.png"));
                $('#imgFinalIntegracao').removeClass().addClass('imgPiperunFinal');
                $('#imgInicioIntegracao').removeClass().addClass('imgPiperunInicial');
                $('#integracaoFinalizacao').addClass('mLeft-30');
                $('#spanApiKey').addClass('r-29');
                $scope.esconderDivsPassos();
            }
            if ((pDados == 'freshsales') || (pDados == 'E') || (pDados == 'R') || (pDados == 'Z')) {
                $('.heightIntegracoesPassoAPIkey').hide();
            }
            if (pDados == 'D365') {
                $scope.aplicacaoIntegrar = pDados;
                $scope.viewBtnAvancar = false;
                resetaTelaDynamics();
                $scope.txtPassoMeioIntegracao = 'API Key';
                $scope.integracaoFeita = 'Dynamics 365';
                $scope.txtInicioIntegracao1 = $scope.txtInicioIntegracao1 + $scope.integracaoFeita + '.';
                $('#imgInicioIntegracao').attr('src', "".concat($rootScope.bucket, "/Dynamics CRM - 200x60.png"));
                $('#imgFinalIntegracao').attr('src', "".concat($rootScope.bucket, "/Dynamics CRM - 180x45.png"));
                $('#imgInicioIntegracao').addClass('dynamicsForceWidth');
                $('#telaInicialIntegracoes').addClass('margin-leftSalesForce');
                $('#integracaoFinalizacao').removeClass().addClass('mLeft-14');
                $('#imgFinalIntegracao').addClass('dynamicsForceImageFinal');
                $('#imagemIntegracaoInicial').addClass('margin-left--15');
                $scope.esconderDivsPassos();
            }
            if (pDados === 'linkseller') {
                $scope.segundaTelaLinkseller = false;
                $scope.esconderDivsPassos();
                $scope.integracao = pDados;
                setTimeout(function () { $('#txtApiKeyLinkseller input').focus(); }, 90);
                $scope.txtPassoMeioIntegracao = 'API Key';
                $scope.integracaoFeita = 'Ramper Pipeline';
                $scope.txtInicioIntegracao1 = $scope.txtInicioIntegracao1 + $scope.integracaoFeita + '.';
                $('#imgInicioIntegracao').attr('src', "".concat($rootScope.bucket, "/logo-ramper-pipeline.svg"));
                $('#imgFinalIntegracao').attr('src', "".concat($rootScope.bucket, "/logo-ramper-pipeline.svg"));
                $('#imgFinalIntegracao').removeClass('imgConclusaoIntegracao').addClass('imgLinksellerFinal');
                if ($scope.apiKeyLinkseller) {
                    $scope.abrirIntegracaoLinkseller();
                }
            }
            if ($scope.usuarioAtivoEngage) {
                var idIntegracao = getIdConfigIntegracao(pDados);
                configurarEngageAutoSend(idIntegracao);
            }
        };
        $scope.removerIntegracaoCrm = function () {
            $rootScope.loaderAtivo = true;
            $('#dvBlur').addClass('blur');
            mixpanel.track("Integrações | Removeu", {
                "Integração": $scope.integracaoFeita,
                "Local_IP": $rootScope.ip
            });
            $http({
                method: 'DELETE',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/integracoes/remover"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                params: {
                    aplicacao: $scope.aplicacaoRemover
                }
            }).then(function () {
                $scope.esconderBotoesEditarRemover('crm');
                $scope.limparVariaveis();
                $scope.removerDivsErro();
                $scope.addBackgroundCrm();
                $scope.integracaoNaoDisponivel();
                $scope.integracaoCrmFeita = false;
                $scope.avancarPassoMoskit();
                $scope.avancarPassoNectar();
                $scope.avancarPasso1zoho();
                $scope.opcaoEditar = false;
                $scope.fecharPopupRemover();
                $('#dvBlur').removeClass('blur');
                $rootScope.loaderAtivo = false;
                $('#estagioCampo').hide();
                $('#usuarioCampo').hide();
            });
        };
        $scope.zohoPadrao = function () {
            $scope.txtPassoMeioIntegracao = 'API Key';
            $scope.integracaoFeita = 'Zoho';
            $scope.txtInicioIntegracao1 = $scope.txtInicioIntegracao1 + $scope.integracaoFeita + '.';
            $('#imgInicioIntegracao').attr('src', "".concat($rootScope.bucket, "/zoho.png"));
            $('#imgFinalIntegracao').attr('src', "".concat($rootScope.bucket, "/zoho.png"));
            $('#imgFinalIntegracao').removeClass().addClass('imgZohoFinal');
            $('#imgInicioIntegracao').removeClass().addClass('imgZohoIntegracao');
            $('#integracaoFinalizacao').addClass('aberturasInterna');
            $scope.esconderDivsPassos();
        };
        $scope.editarIntegracaoInbound = function (pDados) {
            $scope.opcaoEditar = true;
            $scope.removerDivsErro();
            $scope.esconderDivsPassos();
            $scope.voltarUmPasso = false;
            $scope.nomeBtnAvancar = 'AVANÇAR';
            $scope.passo = 2;
            passoDoisAtivo();
            $('#dvIntegracao').fadeIn();
            $('#passo1').hide();
            $('#passo2').show();
            $('#passo3').hide();
            $('#idPasso1').hide();
            $scope.aplicacaoIntegrar = pDados;
            if ($('#divBtnRdStation').is(':visible')) {
                $scope.ajustesTamanhoDeImagem();
                $scope.rdStationConfiguracoes();
            }
            if ($scope.aplicacaoIntegrar) {
                $scope.viewBtnAvancar = false;
            }
        };
        $scope.removerIntegracaoInbound = function () {
            $rootScope.loaderAtivo = true;
            $('#dvBlur').addClass('blur');
            mixpanel.track("Integrações | Removeu", {
                "Integração": $scope.integracaoFeita,
                "Local_IP": $rootScope.ip
            });
            $http({
                method: 'DELETE',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/integracoes/remover"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                params: {
                    aplicacao: $scope.aplicacaoRemover
                }
            }).then(function () {
                $scope.esconderBotoesEditarRemover('inbound');
                $scope.limparVariaveis();
                $scope.removerDivsErro();
                $scope.addBackgroundInbound();
                $scope.integracaoNaoDisponivel();
                $scope.fecharPopupRemover();
                $scope.integracaoInboundFeita = false;
                $scope.opcaoEditar = false;
                $('#dvBlur').removeClass('blur');
                $rootScope.loaderAtivo = false;
            });
        };
        $scope.editarIntegracaoPreVendas = function (pDados) {
            $scope.opcaoEditar = true;
            $scope.removerDivsErro();
            $scope.esconderDivsPassos();
            $scope.voltarUmPasso = false;
            $scope.nomeBtnAvancar = 'AVANÇAR';
            $scope.passo = 2;
            passoDoisAtivo();
            $('#dvIntegracao').fadeIn();
            $('#passo1').hide();
            $('#passo2').show();
            $('#passo3').hide();
            $('#idPasso1').hide();
            $scope.aplicacaoIntegrar = pDados;
            if ($('#divBtnExactSales').is(':visible')) {
                $scope.ajustesTamanhoDeImagem();
                $scope.exactSalesConfiguracoes();
            }
        };
        $scope.removerIntegracaoPreVendas = function () {
            $rootScope.loaderAtivo = true;
            $('#dvBlur').addClass('blur');
            mixpanel.track("Integrações | Removeu", {
                "Integração": $scope.integracaoFeita,
                "Local_IP": $rootScope.ip
            });
            $http({
                method: 'DELETE',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/integracoes/remover"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                params: {
                    aplicacao: $scope.aplicacaoRemover
                }
            }).then(function () {
                $scope.esconderBotoesEditarRemover('preVendas');
                $scope.limparVariaveis();
                $scope.removerDivsErro();
                $scope.addBackgroundPreVendas();
                $scope.integracaoNaoDisponivel();
                $scope.fecharPopupRemover();
                $scope.integracaoPreVendasFeita = false;
                $scope.opcaoEditar = false;
                $('#dvBlur').removeClass('blur');
                $rootScope.loaderAtivo = false;
            });
        };
        $scope.esconderBotoesEditarRemover = function (pDados) {
            if (pDados == 'crm') {
                $scope.divBtnFreshsales = false;
                $scope.divBtnSalesforce = false;
                $scope.divBtnCloseIO = false;
                $scope.divBtnHubSpot = false;
                $scope.divBtnPiperun = false;
                $scope.divBtnAgendor = false;
                $scope.divBtnPipedrive = false;
                $scope.divBtnZoho = false;
                $scope.divBtnNectar = false;
                $scope.divBtnMoskit = false;
                $scope.divBtnPlug = false;
                $scope.divBtnFunildevendas = false;
                $scope.divBtnDynamics = false;
                $scope.divBtnLinkseller = false;
            }
            else if (pDados == 'inbound') {
                $scope.divBtnRdStation = false;
            }
            else if (pDados == 'preVendas') {
                $scope.divBtnExactSales = false;
            }
        };
        $scope.limparVariaveis = function () {
            $scope.apiKeyFreshsales = '';
            $scope.apiKeyFunilDeVendas = '';
            $scope.apiKeyGeral = '';
            $scope.apiKeyLinkseller = '';
            $scope.apiKeyPipedrive = '';
            $scope.apiKeyRdStation = '';
            $scope.clientIdZoho = '';
            $scope.clientSecretZoho = '';
            $scope.codigoCanalVendasFV = '';
            $scope.codigoEtapaFV = '';
            $scope.codigoEtapaInput = '';
            $scope.codigoEtapaLinkseller = null;
            $scope.codigoFunilFV = '';
            $scope.codigoFunilLinkseller = null;
            $scope.codigoVendedorFV = '';
            $scope.codigoVendedorLinkseller = null;
            $scope.digiteExcluir = '';
            $scope.dominioApiKeyExactsales = '';
            $scope.dominioContaExactSales = '';
            $scope.dominioContaFreshsales = '';
            $scope.emailautorInput = '';
            $scope.emailResponsavelInput = '';
            $scope.estagioPipedrive = null;
            $scope.hashPiperun = '';
            $scope.moskitResponsavelInput = '';
            $scope.moskitEstagioInput = '';
            $scope.nomePipeLineInput = '';
            $scope.pipelinePipedrive = null;
            $scope.segundaTelaFunilDeVendas = false;
            $scope.segundaTelaLinkseller = false;
            $scope.segundaTelaPipedrive = false;
            $scope.usuarioPipedrive = null;
            $scope.userCodeZoho = '';
            $scope.txtInicioIntegracao1 = 'A integração permite que você envie seus contatos do Ramper Prospect para o ';
            $scope.engageAutoSend = true;
        };
        $scope.fecharConfigurar = function () {
            _editar = false;
            passoUmAtivo();
            $scope.abrirTela();
            $scope.esconderDivsPassos();
            $('#dvIntegracao').fadeOut();
            $('#spanApiKey').removeClass('r-29');
            if ($scope.passo == 3) {
                $scope.selecionarIntegracao($scope.aplicacaoIntegrar);
            }
            else {
                $scope.limparVariaveis();
            }
            if ($scope.usuarioAtivoEngage) {
                resetarLayoutConfigurarEngageAutoSend();
            }
        };
        $scope.changePipelines = function () {
            if ($scope.estagiosPipedrive) {
                $scope.estagioPipedrive = null;
                $scope.estagiosPipedriveShow = $scope.estagiosPipedrive.filter(function (obj) {
                    return obj.pipeline_id == $scope.pipelinePipedrive;
                });
                if ($scope.pipelinePipedrive != null) {
                    $('#estagioCampo').show();
                }
            }
        };
        $scope.limparCamposPipeDrive = function () {
            $scope.pipelinePipedrive = null;
            $scope.estagioPipedrive = null;
            $scope.usuarioPipedrive = null;
            $scope.estagiosPipedriveShow = [];
        };
        $scope.checandoCampoPreenchido = function () {
            if ($scope.estagioPipedrive != null) {
                $('#usuarioCampo').show();
            }
            if ($scope.pipelinePipedrive != null) {
                $('#estagioCampo').show();
            }
        };
        $scope.getEtapaFunilLinkseller = function () {
            var idPipeline = $scope.codigoFunilLinkseller;
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/integracoes/linkseller/pipelines/").concat(idPipeline, "/estagios"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                params: {
                    apiKey: $scope.apiKeyLinkseller,
                }
            }).then(function (consultaEtapaLinkseller) {
                $scope.etapasLinkseller = consultaEtapaLinkseller.data;
            }).catch(function (error) {
                $scope.mostrarToastErro();
            });
        };
        $scope.selectEtapaLinkseller = function () {
            if ($scope.codigoEtapaLinkseller != null) {
                $scope.viewBtnAvancar = true;
                if ($scope.usuarioAtivoEngage) {
                    configurarEngageAutoSend('idConfigLinkseller');
                }
            }
        };
        $scope.selectFunilLinkseller = function () {
            if ($scope.codigoFunilLinkseller != null) {
                $scope.getEtapaFunilLinkseller();
            }
        };
        $scope.abrirIntegracaoLinkseller = function () {
            $scope.codigoEtapaLinkseller = $scope.codigoEtapaLinkseller || $scope.integracaoLinkseller.aliasLinkseller.idEstagio;
            $scope.codigoFunilLinkseller = $scope.codigoFunilLinkseller || $scope.integracaoLinkseller.aliasLinkseller.idPipeline;
            $scope.codigoVendedorLinkseller = $scope.codigoVendedorLinkseller || $scope.integracaoLinkseller.aliasLinkseller.idUsuarioLinkseller;
        };
        $scope.configurarIntegracaoLinkseller = function () {
            $scope.segundaTelaLinkseller = true;
            $('#selectFunilLinkseller').hide();
            $('#selectEtapaLinkseller').hide();
            $scope.voltarUmPasso = true;
            $scope.removerDivsErro();
            if (!$scope.codigoEtapaLinkseller) {
                $scope.viewBtnAvancar = false;
            }
            $rootScope.loaderAtivo = true;
            $('#dvIntegracao').addClass('blur');
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/integracoes/linkseller/usuarios"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                params: {
                    apiKey: $scope.apiKeyLinkseller
                }
            }).then(function (consultaVendedoresLinkseller) {
                $scope.vendedoresLinkseller = consultaVendedoresLinkseller.data;
            }).catch(function (error) {
                $scope.mostrarToastErro();
            });
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/integracoes/linkseller/pipelines"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                params: {
                    apiKey: $scope.apiKeyLinkseller
                }
            }).then(function (consultaFunisLinkseller) {
                $scope.pipelinesLinkseller = consultaFunisLinkseller.data;
                $('#dvIntegracao').removeClass('blur');
                $rootScope.loaderAtivo = false;
            }).catch(function (error) {
                $scope.mostrarToastErro();
            });
            if ($scope.codigoFunilLinkseller) {
                $scope.getEtapaFunilLinkseller();
            }
        };
        $scope.avancar = function () {
            var qtdErros = 0;
            if ($scope.passo == 1) {
                $('#passo1').hide();
                $('#passo2').fadeIn();
                $('#passo3').hide();
                $scope.removerDivsErro();
                passoDoisAtivo();
                $scope.voltarUmPasso = true;
                $scope.passo++;
                if ($scope.aplicacaoIntegrar == 'O' || $scope.aplicacaoIntegrar == 'HBCRM')
                    $scope.viewBtnAvancar = false;
                if ($scope.aplicacaoIntegrar == 'I') {
                    setTimeout(function () { $('#txtHashPiperun input').focus(); }, 90);
                }
                if ($scope.aplicacaoIntegrar == 'funildevendas')
                    setTimeout(function () { $('#txtApiKeyFunilDeVendas input').focus(); }, 90);
                if ($scope.aplicacaoIntegrar == 'moskit')
                    $scope.ondeEncontroAPI = 'https://goramper.zendesk.com/hc/pt-br/articles/360022270534-Como-integrar-o-Ramper-com-o-Moskit-CRM';
                if ($scope.aplicacaoIntegrar == 'N')
                    $scope.ondeEncontroAPI = 'https://goramper.zendesk.com/hc/pt-br/articles/360022531813-Como-integrar-o-Ramper-com-o-NectarCRM';
                if ($scope.aplicacaoIntegrar == 'HBCRM')
                    $scope.ondeEncontroAPI = 'https://goramper.zendesk.com/hc/pt-br/articles/360022349014-Como-integrar-o-Ramper-com-o-HubSpot-CRM';
                if ($scope.aplicacaoIntegrar == 'D365') {
                    $scope.viewBtnAvancar = false;
                }
                if ($scope.aplicacaoIntegrar == 'R') {
                    $scope.viewBtnAvancar = false;
                }
                if ($scope.aplicacaoIntegrar === 'linkseller') {
                    $('#passo1zoho').hide();
                    $('#passoApiKeyDiv').hide();
                    setTimeout(function () { $('#txtApiKeyLinkseller input').focus(); }, 90);
                }
                if (['A', 'closeIO', 'D365', 'HBCRM', 'I', 'O', 'U']
                    .includes($scope.aplicacaoIntegrar) && $scope.usuarioAtivoEngage) {
                    var idIntegracao = getIdConfigIntegracao($scope.aplicacaoIntegrar);
                    configurarEngageAutoSend(idIntegracao);
                }
            }
            else if ($scope.passo == 2) {
                var verificarEngageAutoSend = document.getElementById('switchId');
                $scope.engageAutoSend = verificarEngageAutoSend.checked ? true : false;
                if ($scope.aplicacaoIntegrar == 'moskit') {
                    $scope.voltarUmPasso = true;
                    $http({
                        method: 'GET',
                        url: "".concat($rootScope.ramperV3ApiBaseUrl, "/integracoes/moskit/consultarUsersStage"),
                        headers: {
                            'ramp3r-auth': 'ramp3r-authorization',
                            'ramp3r-l': $rootScope.IDCrypto,
                            'Authorization': 'Bearer ' + $rootScope.Token
                        },
                        params: {
                            apiKey: $scope.apiKeyGeral
                        }
                    }).then(function (rConsultaUsersStage) {
                        $scope.modelResponsavelMoskit = '';
                        $scope.modelEstagioMoskit = '';
                        $scope.responsavelMoskit = rConsultaUsersStage.data.users;
                        $scope.estagioMoskit = rConsultaUsersStage.data.stages;
                    });
                    if ($scope.apiKeyGeral == null || $scope.apiKeyGeral == '' || !$scope.apiKeyGeral) {
                        $('#txtApiKeyGeral').addClass('corTextoErro');
                        $('#dvMensagemApiKeyGeral').fadeIn();
                        qtdErros++;
                    }
                    if (qtdErros == 0) {
                        if ($('#passoApiKeyDiv').is(':visible')) {
                            $scope.avancarPasso2Moskit();
                        }
                        else if (qtdErros == 0) {
                            if ($scope.moskitResponsavelInput == null || $scope.moskitResponsavelInput == '' || !$scope.moskitResponsavelInput) {
                                $('#txtDominioResponsavelMoskit').addClass('corTextoErro');
                                $('#dvMensagemMoskitResponsavel').fadeIn();
                                qtdErros++;
                            }
                            if ($scope.moskitEstagioInput == null || $scope.moskitEstagioInput == '' || !$scope.moskitEstagioInput) {
                                $('#txtDominioEstagioMoskit').addClass('corTextoErro');
                                $('#dvMensagemMoskitEstagio').fadeIn();
                                qtdErros++;
                            }
                            if (qtdErros == 0) {
                                $rootScope.loaderAtivo = true;
                                $('#dvIntegracao').addClass('blur');
                                var texto = "Integrações | " + ($scope.opcaoEditar ? "Editou" : "Integrou");
                                mixpanel.track(texto, {
                                    "Integração": $scope.integracaoFeita,
                                    "Local_IP": $rootScope.ip
                                });
                                $http({
                                    method: 'POST',
                                    url: "".concat($rootScope.ramperV3ApiBaseUrl, "/integracoes/salvar"),
                                    headers: {
                                        'ramp3r-auth': 'ramp3r-authorization',
                                        'ramp3r-l': $rootScope.IDCrypto,
                                        'Authorization': 'Bearer ' + $rootScope.Token
                                    },
                                    data: {
                                        aplicacao: 'moskit',
                                        apiKey: $scope.apiKeyGeral,
                                        responsavelMoskit: $scope.moskitResponsavelInput.id,
                                        estagioMoskit: $scope.moskitEstagioInput.id,
                                        engageAutoSend: $scope.engageAutoSend
                                    }
                                }).then(function () {
                                    $('#dvIntegracao').removeClass('blur');
                                    $rootScope.loaderAtivo = false;
                                    $scope.avancarPasso3();
                                });
                            }
                        }
                    }
                }
                else if ($scope.aplicacaoIntegrar == 'GA') {
                    $('#passo1').hide();
                    $('#passo2').hide();
                    $('#passo3').fadeIn();
                    passoTresAtivo();
                    $scope.passo++;
                    $scope.voltarUmPasso = false;
                    $scope.nomeBtnAvancar = 'FECHAR';
                    if ($scope.usuarioAtivoEngage) {
                        resetarLayoutConfigurarEngageAutoSend();
                    }
                }
                else if ($scope.aplicacaoIntegrar == 'freshsales') {
                    if ($scope.apiKeyFreshsales == null || $scope.apiKeyFreshsales == '' || !$scope.apiKeyFreshsales) {
                        $('#txtApiKeyFreshsales').addClass('corTextoErro');
                        $('#dvMensagemApiKeyFreshsales').fadeIn();
                        qtdErros++;
                    }
                    if ($scope.dominioContaFreshsales == null || $scope.dominioContaFreshsales == '' || !$scope.dominioContaFreshsales) {
                        $('#txtDominioContaFreshsales').addClass('corTextoErro');
                        $('#dvMensagemDominioContaFreshsales').fadeIn();
                        qtdErros++;
                    }
                    if (qtdErros == 0) {
                        $scope.avancarPasso3();
                    }
                }
                else if ($scope.aplicacaoIntegrar == 'zoho') {
                    if ($scope.clientIdZoho == null || $scope.clientIdZoho == '' || !$scope.clientIdZoho) {
                        $('#txtClientIdZoho').addClass('corTextoErro');
                        $('#dvMsgClientIdZoho').fadeIn();
                        qtdErros++;
                    }
                    if (qtdErros == 0) {
                        if ($('#passo1zoho').is(':visible')) {
                            $scope.avancarPasso2zoho();
                            $scope.voltarUmPasso = true;
                        }
                        else if (qtdErros == 0) {
                            if ($scope.clientSecretZoho == null || $scope.clientSecretZoho == '' || !$scope.clientSecretZoho) {
                                $('#txtClientSecretZoho').addClass('corTextoErro');
                                $('#dvMsgClientSecretZoho').fadeIn();
                                qtdErros++;
                            }
                            if ($scope.userCodeZoho == null || $scope.userCodeZoho == '' || !$scope.userCodeZoho) {
                                $('#txtUserCodeZoho').addClass('corTextoErro');
                                $('#dvMsgUserCodeZoho').fadeIn();
                                qtdErros++;
                            }
                            if (qtdErros == 0) {
                                $rootScope.loaderAtivo = true;
                                $('#dvIntegracao').addClass('blur');
                                var texto = "Integrações | " + ($scope.opcaoEditar ? "Editou" : "Integrou");
                                mixpanel.track(texto, {
                                    "Integração": $scope.integracaoFeita,
                                    "Local_IP": $rootScope.ip
                                });
                                $http({
                                    method: 'POST',
                                    url: "".concat($rootScope.ramperV3ApiBaseUrl, "/integracoes/salvar"),
                                    headers: {
                                        'ramp3r-auth': 'ramp3r-authorization',
                                        'ramp3r-l': $rootScope.IDCrypto,
                                        'Authorization': 'Bearer ' + $rootScope.Token
                                    },
                                    data: {
                                        aplicacao: 'Z',
                                        apikey: $scope.userCodeZoho,
                                        clientId: $scope.clientIdZoho,
                                        clientSecret: $scope.clientSecretZoho,
                                        grantTokenCode: $scope.userCodeZoho,
                                        engageAutoSend: $scope.engageAutoSend
                                    }
                                }).then(function () {
                                    $('#dvIntegracao').removeClass('blur');
                                    $rootScope.loaderAtivo = false;
                                    $scope.avancarPasso3();
                                });
                            }
                        }
                    }
                }
                else if ($scope.aplicacaoIntegrar == 'A' || $scope.aplicacaoIntegrar == 'closeIO' || $scope.aplicacaoIntegrar == 'U') {
                    if ($scope.apiKeyGeral == null || $scope.apiKeyGeral == '' || !$scope.apiKeyGeral) {
                        $('#txtApiKeyGeral').addClass('corTextoErro');
                        $('#dvMensagemApiKeyGeral').fadeIn();
                        $('#dvMensagemApiKeyGeralErro').fadeOut();
                        qtdErros++;
                    }
                    if (qtdErros == 0) {
                        $http({
                            method: 'POST',
                            url: "".concat($rootScope.ramperV3ApiBaseUrl, "/integracoes/verificar-conexao"),
                            headers: {
                                'ramp3r-auth': 'ramp3r-authorization',
                                'ramp3r-l': $rootScope.IDCrypto,
                                'Authorization': 'Bearer ' + $rootScope.Token
                            },
                            data: {
                                plataforma: $scope.aplicacaoIntegrar,
                                apiKey: $scope.apiKeyGeral
                            }
                        }).then(function (result) {
                            $scope.salvarIntegracaoApiKey();
                        }).catch(function (error) {
                            $('#dvIntegracao').removeClass('blur');
                            $rootScope.loaderAtivo = false;
                            $('#txtApiKeyGeral').addClass('corTextoErro');
                            $('#dvMensagemApiKeyGeralErro').fadeIn();
                            $('#dvMensagemApiKeyGeral').fadeOut();
                            $scope.msgErroIntegracao = "Houve falha na conexão, verifique suas credenciais.";
                        });
                    }
                }
                else if ($scope.aplicacaoIntegrar == 'I') {
                    if ($scope.hashPiperun == null || $scope.hashPiperun == '' || !$scope.hashPiperun) {
                        $('#txtHashPiperun').addClass('corTextoErro');
                        $('#dvMensagemHashPiperun').fadeIn();
                        qtdErros++;
                    }
                    if (qtdErros == 0) {
                        $rootScope.loaderAtivo = true;
                        $('#dvIntegracao').addClass('blur');
                        var texto = "Integrações | " + ($scope.opcaoEditar ? "Editou" : "Integrou");
                        mixpanel.track(texto, {
                            "Integração": $scope.integracaoFeita,
                            "Local_IP": $rootScope.ip
                        });
                        $http({
                            method: 'POST',
                            url: "".concat($rootScope.ramperV3ApiBaseUrl, "/integracoes/salvar"),
                            headers: {
                                'ramp3r-auth': 'ramp3r-authorization',
                                'ramp3r-l': $rootScope.IDCrypto,
                                'Authorization': 'Bearer ' + $rootScope.Token
                            },
                            data: {
                                aplicacao: $scope.aplicacaoIntegrar,
                                apiKey: $scope.hashPiperun,
                                engageAutoSend: $scope.engageAutoSend
                            }
                        }).then(function () {
                            $('#dvIntegracao').removeClass('blur');
                            $rootScope.loaderAtivo = false;
                            $scope.avancarPasso3();
                        });
                    }
                }
                else if ($scope.aplicacaoIntegrar == 'R') {
                    $rootScope.loaderAtivo = true;
                    $('#dvIntegracao').addClass('blur');
                    var texto = "Integrações | " + ($scope.opcaoEditar ? "Editou" : "Integrou");
                    mixpanel.track(texto, {
                        "Integração": $scope.integracaoFeita,
                        "Local_IP": $rootScope.ip
                    });
                    var promise = new Promise(function (resolve, reject) {
                        var interval = setInterval(function () {
                            if (window.location.hash !== '#/integracoes') {
                                clearInterval(interval);
                                return reject();
                            }
                            var rdStationLocalStorage = localStorage.getItem('rdStation');
                            if (rdStationLocalStorage) {
                                clearInterval(interval);
                                localStorage.removeItem('rdStation');
                                return resolve(true);
                            }
                        }, 750);
                    });
                    $('#dvIntegracao').removeClass('blur');
                    $rootScope.loaderAtivo = false;
                    promise.then(function () {
                        $scope.viewBtnAvancar = true;
                        $scope.avancarPasso3();
                    });
                }
                else if ($scope.aplicacaoIntegrar == 'P') {
                    if (!$scope.segundaTelaPipedrive) {
                        if ($scope.apiKeyPipedrive == null || $scope.apiKeyPipedrive == '' || !$scope.apiKeyPipedrive) {
                            $('#txtApiKeyPipedrive').addClass('corTextoErro');
                            $('#dvMensagemCrmErro').fadeOut();
                            $('#dvMensagemApiKeyPipedrive').fadeIn();
                            qtdErros++;
                        }
                        if (qtdErros == 0) {
                            $http({
                                method: 'POST',
                                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/integracoes/verificar-conexao"),
                                headers: {
                                    'ramp3r-auth': 'ramp3r-authorization',
                                    'ramp3r-l': $rootScope.IDCrypto,
                                    'Authorization': 'Bearer ' + $rootScope.Token
                                },
                                data: {
                                    plataforma: $scope.aplicacaoIntegrar,
                                    apiKey: $scope.apiKeyPipedrive
                                }
                            }).then(function (result) {
                                $scope.salvarIntegracaoPipedrive();
                            }).catch(function (error) {
                                $('#dvIntegracao').removeClass('blur');
                                $rootScope.loaderAtivo = false;
                                $('#txtApiKeyPipedrive').addClass('corTextoErro');
                                $('#dvMensagemApiKeyPipedrive').fadeOut();
                                $('#dvMensagemCrmErro').fadeIn();
                                $scope.msgErroIntegracao = "Houve falha na conexão, verifique suas credenciais.";
                            });
                        }
                    }
                    else {
                        $rootScope.loaderAtivo = true;
                        $('#dvIntegracao').addClass('blur');
                        var texto = "Integrações | " + ($scope.opcaoEditar ? "Editou" : "Integrou");
                        mixpanel.track(texto, {
                            "Integração": $scope.integracaoFeita,
                            "Local_IP": $rootScope.ip
                        });
                        $http({
                            method: 'POST',
                            url: "".concat($rootScope.ramperV3ApiBaseUrl, "/integracoes/salvar"),
                            headers: {
                                'ramp3r-auth': 'ramp3r-authorization',
                                'ramp3r-l': $rootScope.IDCrypto,
                                'Authorization': 'Bearer ' + $rootScope.Token
                            },
                            data: {
                                aplicacao: $scope.aplicacaoIntegrar,
                                apiKey: $scope.apiKeyPipedrive,
                                pipelineId: $scope.pipelinePipedrive,
                                stageId: $scope.estagioPipedrive,
                                userId: $scope.usuarioPipedrive,
                                engageAutoSend: $scope.engageAutoSend
                            }
                        }).then(function () {
                            $('#dvIntegracao').removeClass('blur');
                            $rootScope.loaderAtivo = false;
                            $scope.avancarPasso3();
                        });
                    }
                }
                else if ($scope.aplicacaoIntegrar == 'N') {
                    if ($scope.apiKeyGeral == null || $scope.apiKeyGeral == '' || !$scope.apiKeyGeral) {
                        $('#txtApiKeyGeral').addClass('corTextoErro');
                        $('#dvMensagemApiKeyGeral').fadeIn();
                        qtdErros++;
                    }
                    if (qtdErros == 0) {
                        if ($('#passoApiKeyDiv').is(':visible')) {
                            $scope.avancarPasso2Nectar();
                            $scope.voltarUmPasso = true;
                        }
                        else if (qtdErros == 0) {
                            if ($scope.nomePipeLineInput == null || $scope.nomePipeLineInput == '' || !$scope.nomePipeLineInput) {
                                $('#txtPipeLineNome').addClass('corTextoErro');
                                $('#dvMensagemInformeNomePipeLine').fadeIn();
                                qtdErros++;
                            }
                            if ($scope.emailResponsavelInput == null || $scope.emailResponsavelInput == '' || !$scope.emailResponsavelInput) {
                                $('#txtPipeLineResponsavel').addClass('corTextoErro');
                                $('#dvMensagemInformeEmail').fadeIn();
                                qtdErros++;
                            }
                            if ($scope.codigoEtapaInput == null || $scope.codigoEtapaInput == '' || !$scope.codigoEtapaInput) {
                                $('#txtPipeLineCodigo').addClass('corTextoErro');
                                $('#dvMensagemInformeCodigoEtapa').fadeIn();
                                qtdErros++;
                            }
                            if ($scope.emailautorInput == null || $scope.emailautorInput == '' || !$scope.emailautorInput) {
                                $('#txtPipeLineEmailAutor').addClass('corTextoErro');
                                $('#dvMensagemInformeEmailAutor').fadeIn();
                                qtdErros++;
                            }
                            if (qtdErros == 0) {
                                $rootScope.loaderAtivo = true;
                                $('#dvIntegracao').addClass('blur');
                                var texto = "Integrações | " + ($scope.opcaoEditar ? "Editou" : "Integrou");
                                mixpanel.track(texto, {
                                    "Integração": $scope.integracaoFeita,
                                    "Local_IP": $rootScope.ip
                                });
                                if ($scope.opcaoEditar) {
                                    $http({
                                        method: 'DELETE',
                                        url: "".concat($rootScope.ramperV3ApiBaseUrl, "/integracoes/remover"),
                                        headers: {
                                            'ramp3r-auth': 'ramp3r-authorization',
                                            'ramp3r-l': $rootScope.IDCrypto,
                                            'Authorization': 'Bearer ' + $rootScope.Token
                                        },
                                        params: {
                                            aplicacao: 'N'
                                        }
                                    }).then(function () {
                                        $http({
                                            method: 'POST',
                                            url: "".concat($rootScope.ramperV3ApiBaseUrl, "/integracoes/salvar"),
                                            headers: {
                                                'ramp3r-auth': 'ramp3r-authorization',
                                                'ramp3r-l': $rootScope.IDCrypto,
                                                'Authorization': 'Bearer ' + $rootScope.Token
                                            },
                                            data: {
                                                aplicacao: $scope.aplicacaoIntegrar,
                                                apiKey: $scope.apiKeyGeral,
                                                nomeDoPipeline: $scope.nomePipeLineInput,
                                                etapa: $scope.codigoEtapaInput,
                                                responsavel: $scope.emailResponsavelInput,
                                                autor: $scope.emailautorInput,
                                                engageAutoSend: $scope.engageAutoSend
                                            }
                                        }).then(function () {
                                            $('#dvIntegracao').removeClass('blur');
                                            $rootScope.loaderAtivo = false;
                                            $scope.avancarPasso3();
                                        });
                                    });
                                }
                                else {
                                    $http({
                                        method: 'POST',
                                        url: "".concat($rootScope.ramperV3ApiBaseUrl, "/integracoes/salvar"),
                                        headers: {
                                            'ramp3r-auth': 'ramp3r-authorization',
                                            'ramp3r-l': $rootScope.IDCrypto,
                                            'Authorization': 'Bearer ' + $rootScope.Token
                                        },
                                        data: {
                                            aplicacao: $scope.aplicacaoIntegrar,
                                            apiKey: $scope.apiKeyGeral,
                                            nomeDoPipeline: $scope.nomePipeLineInput,
                                            etapa: $scope.codigoEtapaInput,
                                            responsavel: $scope.emailResponsavelInput,
                                            autor: $scope.emailautorInput,
                                            engageAutoSend: $scope.engageAutoSend
                                        }
                                    }).then(function () {
                                        $('#dvIntegracao').removeClass('blur');
                                        $rootScope.loaderAtivo = false;
                                        $scope.avancarPasso3();
                                    });
                                }
                            }
                        }
                    }
                }
                else if ($scope.aplicacaoIntegrar == 'E') {
                    if ($scope.dominioApiKeyExactsales == null || $scope.dominioApiKeyExactsales == '' || !$scope.dominioApiKeyExactsales) {
                        $('#txtApiKeyExactSales').addClass('corTextoErro');
                        $('#dvMensagemApiKeyExactSales').fadeIn();
                        qtdErros++;
                    }
                    if ($scope.dominioContaExactSales == null || $scope.dominioContaExactSales == '' || !$scope.dominioContaExactSales) {
                        $('#txtEmailExactSales').addClass('corTextoErro');
                        $('#dvMensagemEmailPreVendedor').fadeIn();
                        qtdErros++;
                    }
                    if (qtdErros == 0) {
                        var texto = "Integrações | " + ($scope.opcaoEditar ? "Editou" : "Integrou");
                        mixpanel.track(texto, {
                            "Integração": $scope.integracaoFeita,
                            "Local_IP": $rootScope.ip
                        });
                        $http({
                            method: 'POST',
                            url: "".concat($rootScope.ramperV3ApiBaseUrl, "/integracoes/salvar"),
                            headers: {
                                'ramp3r-auth': 'ramp3r-authorization',
                                'ramp3r-l': $rootScope.IDCrypto,
                                'Authorization': 'Bearer ' + $rootScope.Token
                            },
                            data: {
                                aplicacao: $scope.aplicacaoIntegrar,
                                apiKey: $scope.dominioApiKeyExactsales,
                                emailExactSales: $scope.dominioContaExactSales
                            }
                        }).then(function () {
                            $('#dvIntegracao').removeClass('blur');
                            $rootScope.loaderAtivo = false;
                            $scope.avancarPasso3();
                        });
                    }
                }
                else if ($scope.aplicacaoIntegrar == 'funildevendas') {
                    if (!$scope.segundaTelaFunilDeVendas) {
                        if ($scope.apiKeyFunilDeVendas == null || $scope.apiKeyFunilDeVendas == '' || !$scope.apiKeyFunilDeVendas) {
                            $('#txtApiKeyFunilDeVendas').addClass('corTextoErro');
                            $('#dvMensagemApiKeyFunilDeVendas').fadeIn();
                            qtdErros++;
                        }
                        if (qtdErros == 0) {
                            setTimeout(function () { $('#txtCodigoVendedorFV input').focus(); }, 90);
                            $scope.segundaTelaFunilDeVendas = true;
                            $scope.voltarUmPasso = true;
                            $scope.removerDivsErro();
                            if ($scope.usuarioAtivoEngage) {
                                configurarEngageAutoSend('idConfigFunilDeVendas');
                            }
                        }
                    }
                    else {
                        if ($scope.codigoVendedorFV == null || $scope.codigoVendedorFV == '' || !$scope.codigoVendedorFV) {
                            $('#txtCodigoVendedorFV').addClass('corTextoErro');
                            $('#dvMensagemVendedorFV').fadeIn();
                            qtdErros++;
                        }
                        if ($scope.codigoCanalVendasFV == null || $scope.codigoCanalVendasFV == '' || !$scope.codigoCanalVendasFV) {
                            $('#txtCodigoCanalVendasFV').addClass('corTextoErro');
                            $('#dvMensagemCodigoCanalVendasFV').fadeIn();
                            qtdErros++;
                        }
                        if ($scope.codigoFunilFV == null || $scope.codigoFunilFV == '' || !$scope.codigoFunilFV) {
                            $('#txtCodigoFunilFV').addClass('corTextoErro');
                            $('#dvMensagemCodigoFunilFV').fadeIn();
                            qtdErros++;
                        }
                        if ($scope.codigoEtapaFV == null || $scope.codigoEtapaFV == '' || !$scope.codigoEtapaFV) {
                            $('#txtCodigoEtapaFV').addClass('corTextoErro');
                            $('#dvMensagemCodigoEtapaFV').fadeIn();
                            qtdErros++;
                        }
                        if (qtdErros == 0) {
                            var texto = "Integrações | " + ($scope.opcaoEditar ? "Editou" : "Integrou");
                            mixpanel.track(texto, {
                                "Integração": $scope.integracaoFeita,
                                "Local_IP": $rootScope.ip
                            });
                            $http({
                                method: 'POST',
                                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/integracoes/salvar"),
                                headers: {
                                    'ramp3r-auth': 'ramp3r-authorization',
                                    'ramp3r-l': $rootScope.IDCrypto,
                                    'Authorization': 'Bearer ' + $rootScope.Token
                                },
                                data: {
                                    aplicacao: 'F',
                                    apiKey: $scope.apiKeyFunilDeVendas,
                                    vendedorFDV: $scope.codigoVendedorFV,
                                    funilFDV: $scope.codigoFunilFV,
                                    etapaFDV: $scope.codigoEtapaFV,
                                    canalDeVendasFDV: $scope.codigoCanalVendasFV,
                                    engageAutoSend: $scope.engageAutoSend
                                }
                            }).then(function () {
                                $('#dvIntegracao').removeClass('blur');
                                $rootScope.loaderAtivo = false;
                                $scope.avancarPasso3();
                            });
                        }
                    }
                }
                else if ($scope.aplicacaoIntegrar === 'linkseller') {
                    if (!$scope.segundaTelaLinkseller) {
                        if (!$scope.apiKeyLinkseller || $scope.apiKeyLinkseller === null || $scope.apiKeyLinkseller === '') {
                            $('#txtApiKeyLinkseller').addClass('corTextoErro');
                            $('#dvMensagemApiKeyLinkseller').fadeIn();
                            qtdErros++;
                        }
                        if (qtdErros === 0) {
                            $http({
                                method: 'POST',
                                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/integracoes/verificar-conexao"),
                                headers: {
                                    'ramp3r-auth': 'ramp3r-authorization',
                                    'ramp3r-l': $rootScope.IDCrypto,
                                    'Authorization': 'Bearer ' + $rootScope.Token
                                },
                                data: {
                                    plataforma: $scope.aplicacaoIntegrar,
                                    apiKey: $scope.apiKeyLinkseller
                                }
                            }).then(function (result) {
                                $scope.configurarIntegracaoLinkseller();
                            }).catch(function (error) {
                                if (error.status !== 401) {
                                    $scope.mostrarToastErro();
                                    return;
                                }
                                $('#dvIntegracao').removeClass('blur');
                                $rootScope.loaderAtivo = false;
                                $('#txtApiKeyLinkseller').addClass('corTextoErro');
                                $('#msgErroApiKeyLinkseller').addClass('txtMsgErroIntegracoes');
                                $('#dvMensagemApiKeyLinkseller').fadeOut();
                                $('#msgErroApiKeyLinkseller').fadeIn();
                                $scope.msgErroIntegracao = "Houve falha na conexão, verifique suas credenciais.";
                            });
                        }
                    }
                    else {
                        if (qtdErros === 0) {
                            var texto = "Integrações | " + ($scope.opcaoEditar ? "Editou" : "Integrou");
                            mixpanel.track(texto, {
                                "Integração": $scope.integracaoFeita,
                                "Local_IP": $rootScope.ip
                            });
                            $http({
                                method: 'POST',
                                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/integracoes/salvar"),
                                headers: {
                                    'ramp3r-auth': 'ramp3r-authorization',
                                    'ramp3r-l': $rootScope.IDCrypto,
                                    'Authorization': 'Bearer ' + $rootScope.Token
                                },
                                data: {
                                    aplicacao: 'linkseller',
                                    apiKey: $scope.apiKeyLinkseller,
                                    idPipeline: $scope.codigoFunilLinkseller,
                                    idEstagio: $scope.codigoEtapaLinkseller,
                                    idVendedor: $scope.codigoVendedorLinkseller,
                                    engageAutoSend: $scope.engageAutoSend
                                }
                            }).then(function () {
                                $('#dvIntegracao').removeClass('blur');
                                $rootScope.loaderAtivo = false;
                                $('#imgFinalIntegracao').removeClass('imgConclusaoIntegracao').addClass('imgLinksellerFinal');
                                $scope.avancarPasso3();
                            });
                        }
                    }
                }
                else if ($scope.aplicacaoIntegrar == 'HBCRM') {
                    if (localStorage.getItem('hubspot')) {
                        $('#dvIntegracao').addClass('blur');
                        salvarIntegracaoHubspot();
                    }
                    else {
                        $('#msgErroHubspot').fadeIn();
                        $scope.viewBtnAvancar = false;
                    }
                }
                else {
                    if ($scope.aplicacaoIntegrar == 'O') {
                        $scope.avancarPasso3();
                    }
                    else {
                        if ($scope.aplicacaoIntegrar == 'D365') {
                            $scope.viewBtnAvancar = true;
                            $scope.avancarPasso3();
                        }
                    }
                }
            }
            else if ($scope.passo == 3) {
                $scope.fecharConfigurar();
            }
        };
        $scope.avancarPasso3 = function () {
            passoTresAtivo();
            $scope.passo++;
            $('#passo1').hide();
            $('#passo2').hide();
            $('#passo3').fadeIn();
            $scope.voltarUmPasso = false;
            $scope.nomeBtnAvancar = 'FECHAR';
            if ($scope.usuarioAtivoEngage) {
                resetarLayoutConfigurarEngageAutoSend();
            }
            $scope.$apply();
        };
        $scope.salvarIntegracaoApiKey = function () {
            $rootScope.loaderAtivo = true;
            $('#dvIntegracao').addClass('blur');
            var texto = "Integrações | " + ($scope.opcaoEditar ? "Editou" : "Integrou");
            mixpanel.track(texto, {
                "Integração": $scope.integracaoFeita,
                "Local_IP": $rootScope.ip
            });
            $http({
                method: 'POST',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/integracoes/salvar"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                data: {
                    aplicacao: $scope.aplicacaoIntegrar,
                    apiKey: $scope.apiKeyGeral,
                    engageAutoSend: $scope.engageAutoSend
                }
            }).then(function (result) {
                $('#dvIntegracao').removeClass('blur');
                $rootScope.loaderAtivo = false;
                $scope.avancarPasso3();
            });
        };
        $scope.salvarIntegracaoPipedrive = function () {
            $scope.segundaTelaPipedrive = true;
            $('#estagioCampo').hide();
            $('#usuarioCampo').hide();
            $scope.voltarUmPasso = true;
            $scope.removerDivsErro();
            if (!$scope.usuarioPipedrive)
                $scope.viewBtnAvancar = false;
            $rootScope.loaderAtivo = true;
            $('#dvIntegracao').addClass('blur');
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/integracoes/pipedrive/consultarDadosPipelineEstagiosUsuarios"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                params: {
                    apiKey: $scope.apiKeyPipedrive
                }
            }).then(function (rConsultarDadosPipedrive) {
                $scope.pipelinesPipedrive = rConsultarDadosPipedrive.data.pipelines;
                $scope.estagiosPipedrive = rConsultarDadosPipedrive.data.estagios;
                $scope.estagiosPipedriveShow = rConsultarDadosPipedrive.data.estagios;
                $scope.usuariosPipedrive = rConsultarDadosPipedrive.data.usuarios;
                setTimeout(function () {
                    if (_editar && $scope.voltarAtivado) {
                        $('#estagioCampo').show();
                        $('#usuarioCampo').show();
                        for (var i = 0; i < $('#idPipelinePipedrive')[0].options.length; i++) {
                            try {
                                var obj = $('#idPipelinePipedrive')[0].options[i].value;
                                if (obj == $scope.integracaoPipedrive[0].aliasPipedrives.pipelineId) {
                                    $('#idPipelinePipedrive')[0].selectedIndex = i;
                                    $scope.pipelinePipedrive = obj;
                                    $scope.viewBtnAvancar = $('#idUsuarioPipedrive')[0].selectedIndex > 0 && $('#idEstagioPipedrive')[0].selectedIndex > 0 && $('#idPipelinePipedrive')[0].selectedIndex > 0;
                                    break;
                                }
                            }
                            catch (e) { }
                        }
                        ;
                        for (var i = 0; i < $('#idEstagioPipedrive')[0].options.length; i++) {
                            try {
                                var obj = $('#idEstagioPipedrive')[0].options[i].value;
                                if (obj == $scope.integracaoPipedrive[0].aliasPipedrives.stageId) {
                                    $('#idEstagioPipedrive')[0].selectedIndex = i;
                                    $scope.estagioPipedrive = obj;
                                    $scope.viewBtnAvancar = $('#idUsuarioPipedrive')[0].selectedIndex > 0 && $('#idEstagioPipedrive')[0].selectedIndex > 0 && $('#idPipelinePipedrive')[0].selectedIndex > 0;
                                    break;
                                }
                            }
                            catch (e) { }
                        }
                        ;
                        for (var i = 0; i < $('#idUsuarioPipedrive')[0].options.length; i++) {
                            try {
                                var obj = $('#idUsuarioPipedrive')[0].options[i].value;
                                if (obj == $scope.integracaoPipedrive[0].aliasPipedrives.userId) {
                                    $('#idUsuarioPipedrive')[0].selectedIndex = i;
                                    $scope.usuarioPipedrive = obj;
                                    $scope.viewBtnAvancar = $('#idUsuarioPipedrive')[0].selectedIndex > 0 && $('#idEstagioPipedrive')[0].selectedIndex > 0 && $('#idPipelinePipedrive')[0].selectedIndex > 0;
                                    break;
                                }
                            }
                            catch (e) { }
                        }
                        ;
                    }
                    else {
                        $scope.limparCamposPipeDrive();
                    }
                    if ($scope.integracaoPipedrive.length > 0) {
                        if ($scope.apiKeyPipedrive != $scope.integracaoPipedrive[0].apiKey) {
                            $scope.limparCamposPipeDrive();
                            $('#estagioCampo').hide();
                            $('#usuarioCampo').hide();
                        }
                    }
                    $scope.viewBtnAvancar = $('#idUsuarioPipedrive')[0].selectedIndex > 0 && $('#idEstagioPipedrive')[0].selectedIndex > 0 && $('#idPipelinePipedrive')[0].selectedIndex > 0;
                    _editar = false;
                    $scope.$apply();
                }, 250);
                $('#dvIntegracao').removeClass('blur');
                $rootScope.loaderAtivo = false;
                $scope.voltarAtivado = true;
            });
        };
        var salvarIntegracaoHubspot = function () {
            $http({
                method: 'POST',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/integracoes/salvar"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                data: {
                    aplicacao: 'HBCRM',
                    engageAutoSend: $scope.engageAutoSend
                }
            }).then(function () {
                var texto = "Integrações | " + ($scope.opcaoEditar ? "Editou" : "Integrou");
                mixpanel.track(texto, {
                    "Integração": $scope.integracaoFeita,
                    "Local_IP": $rootScope.ip
                });
                localStorage.removeItem('hubspot');
                $('#dvIntegracao').removeClass('blur');
                $scope.avancarPasso3();
            });
        };
        $scope.abrirIntegracaoHubspot = function () {
            $scope.viewBtnAvancar = true;
            $('#msgErroHubspot').fadeOut();
            $.ajax({
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/integracoes/hubspot/oauth/autorizar"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                data: {
                    engageAutoSend: $scope.engageAutoSend
                },
                success: function (response) {
                    if ((response.data) && (response.data != null)) {
                        window.open(response.data);
                    }
                },
                async: false
            });
        };
        $scope.abrirIntegracaoSalesforce = function () {
            $scope.viewBtnAvancar = true;
            $.ajax({
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/validarSalesForce/autorizar"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                data: {
                    engageAutoSend: $scope.engageAutoSend
                },
                success: function (r) {
                    if ((r.url) && (r.url != null)) {
                        window.open(r.url);
                    }
                    ;
                    var texto = "Integrações | " + ($scope.opcaoEditar ? "Editou" : "Integrou");
                    mixpanel.track(texto, {
                        "Integração": $scope.integracaoFeita,
                        "Local_IP": $rootScope.ip
                    });
                    $scope.avancar();
                },
                async: false
            });
        };
        $scope.abrirIntegracaoRDStation = function () {
            $.ajax({
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/rdStationInicioConfiguracoes"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                success: function (r) {
                    if ((r.url) && (r.url != null)) {
                        window.open(r.url);
                    }
                    ;
                    var texto = "Integrações | " + ($scope.opcaoEditar ? "Editou" : "Integrou");
                    mixpanel.track(texto, {
                        "Integração": $scope.integracaoFeita,
                        "Local_IP": $rootScope.ip
                    });
                    $scope.avancar();
                },
                async: false
            });
        };
        $scope.removerDivsErro = function () {
            $('#dvMensagemApiKeyGeral').hide();
            $('#dvMensagemApiKeyGeralErro').hide();
            $('#dvMensagemCrmErro').hide();
            $('#dvMensagemEmailPreVendedor').hide();
            $('#dvMensagemInformeEmail').hide();
            $('#dvMensagemInformeCodigoEtapa').hide();
            $('#dvMensagemInformeEmailAutor').hide();
            $('#txtApiKeyGeral').removeClass('corTextoErro');
            // agendor
            $('#dvMensagemApiKeyAgendor').hide();
            $('#txtApiKeyAgendor').removeClass('corTextoErro');
            // freshsales
            $('#dvMensagemApiKeyFreshsales').hide();
            $('#dvMensagemDominioContaFreshsales').hide();
            $('#txtApiKeyFreshsales').removeClass('corTextoErro');
            $('#txtDominioContaFreshsales').removeClass('corTextoErro');
            // exactsales
            $('#dvMensagemApiKeyExactSales').hide();
            $('#txtApiKeyExactSales').removeClass('corTextoErro');
            $('#txtEmailExactSales').removeClass('corTextoErro');
            // funilDeVendas
            $('#dvMensagemApiKeyFunilDeVendas').hide();
            $('#dvMensagemCodigoCanalVendasFV').hide();
            $('#dvMensagemCodigoEtapaFV').hide();
            $('#dvMensagemCodigoFunilFV').hide();
            $('#dvMensagemVendedorFV').hide();
            $('#txtApiKeyFunilDeVendas').removeClass('corTextoErro');
            $('#txtCodigoCanalVendasFV').removeClass('corTextoErro');
            $('#txtCodigoEtapaFV').removeClass('corTextoErro');
            $('#txtCodigoFunilFV').removeClass('corTextoErro');
            $('#txtCodigoVendedorFV').removeClass('corTextoErro');
            // hubspot
            $('#msgErroHubspot').hide();
            // linkseller
            $('#dvMensagemApiKeyLinkseller').hide();
            $('#dvMensagemCodigoEtapaLinkseller').hide();
            $('#dvMensagemCodigoFunilLinkseller').hide();
            $('#dvMensagemVendedorLinkseller').hide();
            $('#msgErroApiKeyLinkseller').hide();
            $('#txtApiKeyLinkseller').removeClass('corTextoErro');
            // pipedrive
            $('#dvMensagemApiKeyPipedrive').hide();
            $('#txtApiKeyPipedrive').removeClass('corTextoErro');
            // pipeline
            $('#dvMensagemInformeNomePipeLine').hide();
            $('#txtPipeLineNome').removeClass('corTextoErro');
            $('#txtPipeLineResponsavel').removeClass('corTextoErro');
            $('#txtPipeLineCodigo').removeClass('corTextoErro');
            $('#txtPipeLineEmailAutor').removeClass('corTextoErro');
            //  piperun
            $('#dvMensagemHashPiperun').hide();
            $('#txtHashPiperun').removeClass('corTextoErro');
            // rdstation
            $('#dvMensagemApiKeyRdStation').hide();
            $('#txtApiKeyRdStation').removeClass('corTextoErro');
            // zoho
            $('#dvMsgClientIdZoho').hide();
            $('#dvMsgClientSecretZoho').hide();
            $('#dvMsgUserCodeZoho').hide();
            $('#txtClientIdZoho').removeClass('corTextoErro');
            $('#txtClientSecretZoho').removeClass('corTextoErro');
            $('#txtUserCodeZoho').removeClass('corTextoErro');
        };
        $scope.atualizarInfosIntegracoes = function (pDados) {
            if (pDados == 'apiF') {
                $('#txtApiKeyFreshsales').removeClass('corTextoErro');
                $('#txtApiKeyFreshsales').removeClass('bBottomTextAreaErro');
                $('#dvMensagemApiKeyFreshsales').fadeOut();
            }
            if (pDados == 'dominioF') {
                $('#txtDominioContaFreshsales').removeClass('corTextoErro');
                $('#txtDominioContaFreshsales').removeClass('bBottomTextAreaErro');
                $('#dvMensagemDominioContaFreshsales').fadeOut();
            }
            if (pDados == 'apiGeral') {
                $('#txtApiKeyGeral').removeClass('corTextoErro');
                $('#dvMensagemApiKeyGeral').fadeOut();
            }
            if (pDados == 'I') {
                $('#txtHashPiperun').removeClass('corTextoErro');
                $('#dvMensagemHashPiperun').fadeOut();
            }
            if (pDados == 'R') {
                $('#txtApiKeyRdStation').removeClass('corTextoErro');
                $('#dvMensagemApiKeyRdStation').fadeOut();
            }
            if (pDados == 'P') {
                $('#txtApiKeyPipedrive').removeClass('corTextoErro');
                $('#dvMensagemApiKeyPipedrive').hide();
            }
            if (pDados == 'pipelineNP') {
                $('#txtPipeLineNome').removeClass('corTextoErro');
                $('#dvMensagemInformeNomePipeLine').fadeOut();
            }
            if (pDados == 'pipelineER') {
                $('#txtPipeLineResponsavel').removeClass('corTextoErro');
                $('#dvMensagemInformeEmail').fadeOut();
            }
            if (pDados == 'pipelineCE') {
                $('#txtPipeLineCodigo').removeClass('corTextoErro');
                $('#dvMensagemInformeCodigoEtapa').fadeOut();
            }
            if (pDados == 'pipelineEA') {
                $('#txtPipeLineEmailAutor').removeClass('corTextoErro');
                $('#dvMensagemInformeEmailAutor').fadeOut();
            }
            if (pDados == 'funildevendas') {
                $('#txtApiKeyFunilDeVendas').removeClass('corTextoErro');
                $('#dvMensagemApiKeyFunilDeVendas').fadeOut();
            }
            if (pDados == 'moskitResponsavel') {
                $('#txtDominioResponsavelMoskit').removeClass('corTextoErro');
                $('#dvMensagemMoskitResponsavel').fadeOut();
            }
            if (pDados == 'moskitEstagio') {
                $('#txtDominioEstagioMoskit').removeClass('corTextoErro');
                $('#dvMensagemMoskitEstagio').fadeOut();
            }
            if (pDados == 'codigoVendedorFV') {
                $('#txtCodigoVendedorFV').removeClass('corTextoErro');
                $('#dvMensagemVendedorFV').fadeOut();
            }
            if (pDados == 'codigoCanalVendasFV') {
                $('#txtCodigoCanalVendasFV').removeClass('corTextoErro');
                $('#dvMensagemCodigoCanalVendasFV').fadeOut();
            }
            if (pDados == 'codigoFunilFV') {
                $('#txtCodigoFunilFV').removeClass('corTextoErro');
                $('#dvMensagemCodigoFunilFV').fadeOut();
            }
            if (pDados == 'codigoEtapaFV') {
                $('#txtCodigoEtapaFV').removeClass('corTextoErro');
                $('#dvMensagemCodigoEtapaFV').fadeOut();
            }
            if (pDados == 'apiKeyExactSales') {
                $('#txtApiKeyExactSales').removeClass('corTextoErro');
                $('#dvMensagemApiKeyExactSales').fadeOut();
            }
            if (pDados == 'emailExactSales') {
                $('#txtEmailExactSales').removeClass('corTextoErro');
                $('#dvMensagemEmailPreVendedor').fadeOut();
            }
            if (pDados == 'clienteZoho') {
                $('#txtClientSecretZoho').removeClass('corTextoErro');
                $('#dvMsgClientSecretZoho').fadeOut();
            }
            if (pDados == 'userCodeZoho') {
                $('#txtUserCodeZoho').removeClass('corTextoErro');
                $('#dvMsgUserCodeZoho').fadeOut();
            }
            if (pDados == 'clienteIdZoho') {
                $('#txtClientIdZoho').removeClass('corTextoErro');
                $('#dvMsgClientIdZoho').fadeOut();
            }
            if (pDados === 'linkseller') {
                $('#txtApiKeyLinkseller').removeClass('corTextoErro');
                $('#dvMensagemApiKeyLinkseller').fadeOut();
            }
        };
        $scope.clicksPipedrive = function (pDados) {
            if (pDados == 'usuario') {
                $scope.viewBtnAvancar = true;
                if ($scope.usuarioAtivoEngage) {
                    configurarEngageAutoSend('idConfigPipedrive');
                }
            }
        };
        $scope.selecionarIntegracao = function (pAplicacaoIntegrar) {
            if (pAplicacaoIntegrar == 'freshsales') {
                $scope.tirarBackgroundCrm();
                $('#dvConfigFreshsales').removeClass('opacityImage').removeClass('integracaoNaoDisponivel').addClass('cardBackgroundColor');
                $scope.divBtnFreshsales = true;
                $scope.integracaoCrmFeita = true;
            }
            else if (pAplicacaoIntegrar == 'O') {
                $scope.tirarBackgroundCrm();
                $('#dvConfigSalesForce').removeClass('opacityImage').removeClass('integracaoNaoDisponivel').addClass('cardBackgroundColor');
                $scope.divBtnSalesforce = true;
                $scope.integracaoCrmFeita = true;
            }
            else if (pAplicacaoIntegrar == 'closeIO') {
                $scope.tirarBackgroundCrm();
                $('#dvConfigcloseIO').removeClass('opacityImage').removeClass('integracaoNaoDisponivel').addClass('cardBackgroundColor');
                $scope.divBtnCloseIO = true;
                $scope.integracaoCrmFeita = true;
            }
            else if (pAplicacaoIntegrar == 'HBCRM') {
                $scope.tirarBackgroundCrm();
                $('#dvConfigHubSpot').removeClass('opacityImage').removeClass('integracaoNaoDisponivel').addClass('cardBackgroundColor');
                $scope.divBtnHubSpot = true;
                $scope.integracaoCrmFeita = true;
            }
            else if (pAplicacaoIntegrar == 'I') {
                $scope.tirarBackgroundCrm();
                $('#dvConfigPiperun').removeClass('opacityImage').removeClass('integracaoNaoDisponivel').addClass('cardBackgroundColor');
                $scope.divBtnPiperun = true;
                $scope.integracaoCrmFeita = true;
            }
            else if (pAplicacaoIntegrar == 'A') {
                $scope.tirarBackgroundCrm();
                $('#dvConfigAgendor').removeClass('opacityImage').removeClass('integracaoNaoDisponivel').addClass('cardBackgroundColor');
                $scope.divBtnAgendor = true;
                $scope.integracaoCrmFeita = true;
            }
            else if (pAplicacaoIntegrar == 'R') {
                $scope.tirarBackgroundInbound();
                $('#dvConfigRdStation').removeClass('opacityImage').removeClass('integracaoNaoDisponivel').addClass('cardBackgroundColor');
                $scope.divBtnRdStation = true;
                $scope.integracaoInboundFeita = true;
            }
            else if (pAplicacaoIntegrar == 'P') {
                $scope.tirarBackgroundCrm();
                $('#dvConfigPipedrive').removeClass('opacityImage').removeClass('integracaoNaoDisponivel').addClass('cardBackgroundColor');
                $scope.divBtnPipedrive = true;
                $scope.integracaoCrmFeita = true;
            }
            else if (pAplicacaoIntegrar == 'Z') {
                $scope.tirarBackgroundCrm();
                $('#dvConfigZoho').removeClass('opacityImage').removeClass('integracaoNaoDisponivel').addClass('cardBackgroundColor');
                $scope.divBtnZoho = true;
                $scope.integracaoCrmFeita = true;
            }
            else if (pAplicacaoIntegrar == 'N') {
                $scope.tirarBackgroundCrm();
                $('#dvConfigNectar').removeClass('opacityImage').removeClass('integracaoNaoDisponivel').addClass('cardBackgroundColor');
                $scope.divBtnNectar = true;
                $scope.integracaoCrmFeita = true;
            }
            else if (pAplicacaoIntegrar == 'moskit') {
                $scope.tirarBackgroundCrm();
                $('#dvConfigMoskit').removeClass('opacityImage').removeClass('integracaoNaoDisponivel').addClass('cardBackgroundColor');
                $scope.divBtnMoskit = true;
                $scope.integracaoCrmFeita = true;
            }
            else if (pAplicacaoIntegrar == 'U') {
                $scope.tirarBackgroundCrm();
                $('#dvConfigPlug').removeClass('opacityImage').removeClass('integracaoNaoDisponivel').addClass('cardBackgroundColor');
                $scope.divBtnPlug = true;
                $scope.integracaoCrmFeita = true;
            }
            else if (pAplicacaoIntegrar == 'E') {
                $scope.tirarBackgroundPreVendas();
                $('#dvConfigExactSales').removeClass('opacityImage').removeClass('integracaoNaoDisponivel').addClass('cardBackgroundColor');
                $scope.divBtnExactSales = true;
                $scope.integracaoPreVendasFeita = true;
            }
            else if (pAplicacaoIntegrar == 'F') {
                $scope.tirarBackgroundCrm();
                $('#dvConfigFunilDeVendas').removeClass('opacityImage').removeClass('integracaoNaoDisponivel').addClass('cardBackgroundColor');
                $scope.divBtnFunildevendas = true;
                $scope.integracaoCrmFeita = true;
            }
            else if (pAplicacaoIntegrar == 'D365') {
                $scope.tirarBackgroundCrm();
                $('#dvConfigDynamics').removeClass('opacityImage').removeClass('integracaoNaoDisponivel').addClass('cardBackgroundColor');
                $scope.divBtnDynamics = true;
                $scope.integracaoCrmFeita = true;
            }
            else if (pAplicacaoIntegrar === 'linkseller') {
                $scope.tirarBackgroundCrm();
                $('#dvConfigLinkseller').removeClass('opacityImage').removeClass('integracaoNaoDisponivel').addClass('cardBackgroundColor');
                $scope.divBtnLinkseller = true;
                $scope.integracaoCrmFeita = true;
            }
        };
        $scope.tirarBackgroundCrm = function () {
            if ($rootScope.permissoes.integracoes.onlyProductsRamper) {
                $('#dvConfigAgendor').removeClass('cardBackgroundColor').removeClass('opacityImage').addClass('integracaoNaoDisponivel');
                $('#dvConfigcloseIO').removeClass('cardBackgroundColor').removeClass('opacityImage').addClass('integracaoNaoDisponivel');
                $('#dvConfigDynamics').removeClass('cardBackgroundColor').removeClass('opacityImage').addClass('integracaoNaoDisponivel');
                $('#dvConfigFreshsales').removeClass('cardBackgroundColor').removeClass('opacityImage').addClass('integracaoNaoDisponivel');
                $('#dvConfigFunilDeVendas').removeClass('cardBackgroundColor').removeClass('opacityImage').addClass('integracaoNaoDisponivel');
                $('#dvConfigHubSpot').removeClass('cardBackgroundColor').removeClass('opacityImage').addClass('integracaoNaoDisponivel');
                $('#dvConfigMoskit').removeClass('cardBackgroundColor').removeClass('opacityImage').addClass('integracaoNaoDisponivel');
                $('#dvConfigNectar').removeClass('cardBackgroundColor').removeClass('opacityImage').addClass('integracaoNaoDisponivel');
                $('#dvConfigPipedrive').removeClass('cardBackgroundColor').removeClass('opacityImage').addClass('integracaoNaoDisponivel');
                $('#dvConfigPiperun').removeClass('cardBackgroundColor').removeClass('opacityImage').addClass('integracaoNaoDisponivel');
                $('#dvConfigPlug').removeClass('cardBackgroundColor').removeClass('opacityImage').addClass('integracaoNaoDisponivel');
                $('#dvConfigSalesForce').removeClass('cardBackgroundColor').removeClass('opacityImage').addClass('integracaoNaoDisponivel');
                $('#dvConfigZoho').removeClass('cardBackgroundColor').removeClass('opacityImage').addClass('integracaoNaoDisponivel');
                $('#dvConfigLinkseller').removeClass('cardBackgroundColor').addClass('opacityImage');
                return;
            }
            $('#dvConfigAgendor').removeClass('cardBackgroundColor').removeClass('integracaoNaoDisponivel').addClass('opacityImage');
            $('#dvConfigcloseIO').removeClass('cardBackgroundColor').removeClass('integracaoNaoDisponivel').addClass('opacityImage');
            $('#dvConfigDynamics').removeClass('cardBackgroundColor').removeClass('integracaoNaoDisponivel').addClass('opacityImage');
            $('#dvConfigFreshsales').removeClass('cardBackgroundColor').removeClass('integracaoNaoDisponivel').addClass('opacityImage');
            $('#dvConfigFunilDeVendas').removeClass('cardBackgroundColor').removeClass('integracaoNaoDisponivel').addClass('opacityImage');
            $('#dvConfigHubSpot').removeClass('cardBackgroundColor').removeClass('integracaoNaoDisponivel').addClass('opacityImage');
            $('#dvConfigLinkseller').removeClass('cardBackgroundColor').removeClass('integracaoNaoDisponivel').addClass('opacityImage');
            $('#dvConfigMoskit').removeClass('cardBackgroundColor').removeClass('integracaoNaoDisponivel').addClass('opacityImage');
            $('#dvConfigNectar').removeClass('cardBackgroundColor').removeClass('integracaoNaoDisponivel').addClass('opacityImage');
            $('#dvConfigPipedrive').removeClass('cardBackgroundColor').removeClass('integracaoNaoDisponivel').addClass('opacityImage');
            $('#dvConfigPiperun').removeClass('cardBackgroundColor').removeClass('integracaoNaoDisponivel').addClass('opacityImage');
            $('#dvConfigPlug').removeClass('cardBackgroundColor').removeClass('integracaoNaoDisponivel').addClass('opacityImage');
            $('#dvConfigSalesForce').removeClass('cardBackgroundColor').removeClass('integracaoNaoDisponivel').addClass('opacityImage');
            $('#dvConfigZoho').removeClass('cardBackgroundColor').removeClass('integracaoNaoDisponivel').addClass('opacityImage');
        };
        $scope.addBackgroundCrm = function () {
            $('#dvConfigAgendor').removeClass('opacityImage').removeClass('integracaoNaoDisponivel').addClass('cardBackgroundColor');
            $('#dvConfigcloseIO').removeClass('opacityImage').removeClass('integracaoNaoDisponivel').addClass('cardBackgroundColor');
            $('#dvConfigDynamics').removeClass('opacityImage').removeClass('integracaoNaoDisponivel').addClass('cardBackgroundColor');
            $('#dvConfigFreshsales').removeClass('opacityImage').removeClass('integracaoNaoDisponivel').addClass('cardBackgroundColor');
            $('#dvConfigFunilDeVendas').removeClass('opacityImage').removeClass('integracaoNaoDisponivel').addClass('cardBackgroundColor');
            $('#dvConfigHubSpot').removeClass('opacityImage').removeClass('integracaoNaoDisponivel').addClass('cardBackgroundColor');
            $('#dvConfigLinkseller').removeClass('opacityImage').removeClass('integracaoNaoDisponivel').addClass('cardBackgroundColor');
            $('#dvConfigMoskit').removeClass('opacityImage').removeClass('integracaoNaoDisponivel').addClass('cardBackgroundColor');
            $('#dvConfigNectar').removeClass('opacityImage').removeClass('integracaoNaoDisponivel').addClass('cardBackgroundColor');
            $('#dvConfigPipedrive').removeClass('opacityImage').removeClass('integracaoNaoDisponivel').addClass('cardBackgroundColor');
            $('#dvConfigPiperun').removeClass('opacityImage').removeClass('integracaoNaoDisponivel').addClass('cardBackgroundColor');
            $('#dvConfigPlug').removeClass('opacityImage').removeClass('integracaoNaoDisponivel').addClass('cardBackgroundColor');
            $('#dvConfigSalesForce').removeClass('opacityImage').removeClass('integracaoNaoDisponivel').addClass('cardBackgroundColor');
            $('#dvConfigZoho').removeClass('opacityImage').removeClass('integracaoNaoDisponivel').addClass('cardBackgroundColor');
        };
        $scope.integracaoNaoDisponivel = function () {
            if ($rootScope.permissoes.integracoes.onlyProductsRamper) {
                $('#dvConfigAgendor').removeClass('opacityImage').removeClass('cardBackgroundColor').addClass('integracaoNaoDisponivel');
                $('#dvConfigcloseIO').removeClass('opacityImage').removeClass('cardBackgroundColor').addClass('integracaoNaoDisponivel');
                $('#dvConfigDynamics').removeClass('opacityImage').removeClass('cardBackgroundColor').addClass('integracaoNaoDisponivel');
                $('#dvConfigFreshsales').removeClass('opacityImage').removeClass('cardBackgroundColor').addClass('integracaoNaoDisponivel');
                $('#dvConfigFunilDeVendas').removeClass('opacityImage').removeClass('cardBackgroundColor').addClass('integracaoNaoDisponivel');
                $('#dvConfigHubSpot').removeClass('opacityImage').removeClass('cardBackgroundColor').addClass('integracaoNaoDisponivel');
                $('#dvConfigMoskit').removeClass('opacityImage').removeClass('cardBackgroundColor').addClass('integracaoNaoDisponivel');
                $('#dvConfigNectar').removeClass('opacityImage').removeClass('cardBackgroundColor').addClass('integracaoNaoDisponivel');
                $('#dvConfigPipedrive').removeClass('opacityImage').removeClass('cardBackgroundColor').addClass('integracaoNaoDisponivel');
                $('#dvConfigPiperun').removeClass('opacityImage').removeClass('cardBackgroundColor').addClass('integracaoNaoDisponivel');
                $('#dvConfigPlug').removeClass('opacityImage').removeClass('cardBackgroundColor').addClass('integracaoNaoDisponivel');
                $('#dvConfigSalesForce').removeClass('opacityImage').removeClass('cardBackgroundColor').addClass('integracaoNaoDisponivel');
                $('#dvConfigZoho').removeClass('opacityImage').removeClass('cardBackgroundColor').addClass('integracaoNaoDisponivel');
                $('#dvConfigRdStation').removeClass('opacityImage').removeClass('cardBackgroundColor').addClass('integracaoNaoDisponivel');
                $('#dvConfigExactSales').removeClass('opacityImage').removeClass('cardBackgroundColor').addClass('integracaoNaoDisponivel');
            }
        };
        $scope.tirarBackgroundInbound = function () {
            if ($rootScope.permissoes.integracoes.onlyProductsRamper) {
                $('#dvConfigRdStation').removeClass('cardBackgroundColor').removeClass('opacityImage').addClass('integracaoNaoDisponivel');
            }
            $('#dvConfigRdStation').removeClass('cardBackgroundColor').removeClass('integracaoNaoDisponivel').addClass('opacityImage');
        };
        $scope.addBackgroundInbound = function () {
            $('#dvConfigRdStation').removeClass('opacityImage').removeClass('integracaoNaoDisponivel').addClass('cardBackgroundColor');
        };
        $scope.tirarBackgroundPreVendas = function () {
            if ($rootScope.permissoes.integracoes.onlyProductsRamper) {
                $('#dvConfigExactSales').removeClass('cardBackgroundColor').removeClass('opacityImage').addClass('integracaoNaoDisponivel');
            }
            $('#dvConfigExactSales').removeClass('cardBackgroundColor').removeClass('integracaoNaoDisponivel').addClass('opacityImage');
        };
        $scope.addBackgroundPreVendas = function () {
            $('#dvConfigExactSales').removeClass('opacityImage').removeClass('integracaoNaoDisponivel').addClass('cardBackgroundColor');
        };
        $scope.voltar = function () {
            if ($scope.passo > 1 && $scope.usuarioAtivoEngage) {
                resetarLayoutConfigurarEngageAutoSend();
            }
            if ($scope.passo == 2) {
                if ($scope.aplicacaoIntegrar == 'moskit') {
                    if ($('#passoApiKeyDiv').is(':visible')) {
                        $('#passo1').fadeIn();
                        $('#passo2').hide();
                        $('#passo3').hide();
                        $scope.voltarUmPasso = false;
                        $scope.avancarPassoMoskit();
                        $scope.passo--;
                    }
                    else {
                        $('#passo2moskit').hide();
                        $('#passoApiKeyDiv').show();
                        if ($scope.opcaoEditar == true) {
                            $scope.voltarUmPasso = false;
                        }
                    }
                }
                else if ($scope.aplicacaoIntegrar == 'zoho') {
                    if ($('#passo1zoho').is(':visible')) {
                        $('#passo1').fadeIn();
                        $('#passo2').hide();
                        $('#passo3').hide();
                        $scope.voltarUmPasso = false;
                        passoUmAtivo();
                        $scope.passo--;
                    }
                    else {
                        $scope.avancarPasso1zoho();
                        if ($scope.opcaoEditar == true) {
                            $scope.voltarUmPasso = false;
                        }
                    }
                }
                else if ($scope.aplicacaoIntegrar == 'N') {
                    if ($('#passoApiKeyDiv').is(':visible')) {
                        $('#passo1').fadeIn();
                        $('#passo2').hide();
                        $('#passo3').hide();
                        $scope.voltarUmPasso = false;
                        passoUmAtivo();
                        $scope.passo--;
                    }
                    else {
                        $('#passo2nectar').hide();
                        $('#passoApiKeyDiv').show();
                        if ($scope.opcaoEditar == true) {
                            $scope.voltarUmPasso = false;
                        }
                    }
                }
                else if ($scope.aplicacaoIntegrar == 'funildevendas') {
                    if (!$scope.segundaTelaFunilDeVendas) {
                        $('#passo1').fadeIn();
                        $('#passo2').hide();
                        $('#passo3').hide();
                        passoUmAtivo();
                        $scope.voltarUmPasso = false;
                        $scope.passo--;
                    }
                    else {
                        $scope.segundaTelaFunilDeVendas = false;
                        if ($scope.opcaoEditar)
                            $scope.voltarUmPasso = false;
                    }
                }
                else if ($scope.aplicacaoIntegrar == 'P') {
                    if (!$scope.segundaTelaPipedrive) {
                        $scope.voltarAtivado = false;
                        $('#passo1').fadeIn();
                        $('#passo2').hide();
                        $('#passo3').hide();
                        passoUmAtivo();
                        $scope.voltarUmPasso = false;
                        $scope.passo--;
                    }
                    else {
                        $scope.limparCamposPipeDrive();
                        $scope.segundaTelaPipedrive = false;
                        $scope.viewBtnAvancar = true;
                        if ($scope.opcaoEditar) {
                            $scope.voltarUmPasso = false;
                            _editar = true;
                        }
                    }
                }
                else if ($scope.aplicacaoIntegrar === 'linkseller') {
                    if ($scope.segundaTelaLinkseller) {
                        $scope.segundaTelaLinkseller = false;
                        $scope.viewBtnAvancar = true;
                        _editar = true;
                        if ($scope.apiKeyLinkseller) {
                            $scope.abrirIntegracaoLinkseller();
                            if ($scope.usuarioAtivoEngage) {
                                configurarEngageAutoSend('idConfigLinkseller');
                            }
                        }
                    }
                }
                else {
                    $('#passo1').fadeIn();
                    $('#passo2').hide();
                    $('#passo3').hide();
                    passoUmAtivo();
                    $scope.voltarUmPasso = false;
                    $scope.passo--;
                }
            }
            else if ($scope.passo == 3) {
                $('#passo1').hide();
                $('#passo2').fadeIn();
                $('#passo3').hide();
                passoDoisAtivo();
                $scope.passo--;
            }
        };
        $scope.avancarPasso2Moskit = function () {
            $('#passoApiKeyDiv').hide();
            $('#passo2moskit').show();
            if ($scope.usuarioAtivoEngage) {
                configurarEngageAutoSend('idConfigMoskit');
            }
        };
        $scope.avancarPassoMoskit = function () {
            $('#passoApiKeyDiv').show();
            $('#passo2moskit').hide();
        };
        $scope.avancarPasso2Nectar = function () {
            $('#passoApiKeyDiv').hide();
            $('#passo2nectar').show();
            if ($scope.usuarioAtivoEngage) {
                configurarEngageAutoSend('idConfigNectar');
            }
        };
        $scope.avancarPassoNectar = function () {
            $('#passoApiKeyDiv').show();
            $('#passo2nectar').hide();
        };
        $scope.avancarPasso1zoho = function () {
            $('#passo2zoho').hide();
            $('#passo1zoho').show();
        };
        $scope.avancarPasso2zoho = function () {
            $('#passo2zoho').show();
            $('#passo1zoho').hide();
            if ($scope.usuarioAtivoEngage) {
                configurarEngageAutoSend('idConfigZoho');
            }
        };
        passoUmAtivo = function () {
            $scope.viewBtnAvancar = true;
            $('#dvTraco1').removeClass('dvTracoVerdePassoPasso', 'dvTracoCinzaPassoPasso').addClass('dvTracoAzulPassoPasso');
            $('#spanNome').removeClass('txtVerdePassoPasso', 'txtCinzaPassoPasso').addClass('txtAzulPassoPasso');
            $('#imgPasso1').attr('src', "".concat($rootScope.bucket, "/ramper-v-escuro-15.png"));
            $('#dvTraco2').removeClass('dvTracoVerdePassoPasso').addClass('dvTracoCinzaPassoPasso');
            $('#dvTraco2').removeClass('dvTracoAzulPassoPasso').addClass('dvTracoCinzaPassoPasso');
            $('#spanApiKey').removeClass('txtVerdePassoPasso').addClass('txtCinzaPassoPasso');
            $('#spanApiKey').removeClass('txtAzulPassoPasso').addClass('txtCinzaPassoPasso');
            $('#imgPasso2').attr('src', "".concat($rootScope.bucket, "/ramper-cinza-15.png"));
            $('#dvTraco3').addClass('dvTracoCinzaPassoPasso');
            $('#spanFinalizacao').addClass('txtCinzaPassoPasso');
            $('#imgPasso3').attr('src', "".concat($rootScope.bucket, "/ramper-cinza-15.png"));
        };
        passoDoisAtivo = function () {
            $('#dvTraco1').removeClass('dvTracoAzulPassoPasso', 'dvTracoCinzaPassoPasso').addClass('dvTracoVerdePassoPasso');
            $('#spanNome').removeClass('txtAzulPassoPasso', 'txtCinzaPassoPasso').addClass('txtVerdePassoPasso');
            $('#imgPasso1').attr('src', "".concat($rootScope.bucket, "/ramper-v-claro-15.png"));
            $('#dvTraco2').removeClass('dvTracoCinzaPassoPasso').addClass('dvTracoAzulPassoPasso');
            $('#spanApiKey').removeClass('txtCinzaPassoPasso').addClass('txtAzulPassoPasso');
            $('#imgPasso2').attr('src', "".concat($rootScope.bucket, "/ramper-v-escuro-15.png"));
            $('#dvTraco3').addClass('dvTracoCinzaPassoPasso');
            $('#spanFinalizacao').addClass('txtCinzaPassoPasso');
            $('#imgPasso3').attr('src', "".concat($rootScope.bucket, "/ramper-cinza-15.png"));
        };
        passoTresAtivo = function () {
            $('#dvTraco1').removeClass('dvTracoAzulPassoPasso', 'dvTracoCinzaPassoPasso').addClass('dvTracoVerdePassoPasso');
            $('#spanNome').removeClass('txtAzulPassoPasso', 'txtCinzaPassoPasso').addClass('txtVerdePassoPasso');
            $('#imgPasso1').attr('src', "".concat($rootScope.bucket, "/ramper-v-claro-15.png"));
            $('#dvTraco2').removeClass('dvTracoAzulPassoPasso', 'dvTracoCinzaPassoPasso').addClass('dvTracoVerdePassoPasso');
            $('#spanApiKey').removeClass('txtAzulPassoPasso', 'txtCinzaPassoPasso').addClass('txtVerdePassoPasso');
            $('#imgPasso2').attr('src', "".concat($rootScope.bucket, "/ramper-v-claro-15.png"));
            $('#dvTraco3').removeClass('dvTracoCinzaPassoPasso', 'dvTracoVerdePassoPasso').addClass('dvTracoAzulPassoPasso');
            $('#spanFinalizacao').removeClass('txtCinzaPassoPasso', 'txtVerdePassoPasso').addClass('txtAzulPassoPasso');
            $('#imgPasso3').attr('src', "".concat($rootScope.bucket, "/ramper-v-escuro-15.png"));
        };
        $(document).mouseup(function (e) {
            var popupRemovreInt = $('#idPopupRemoveIntegracoes');
            if (popupRemovreInt.is(e.target)) {
                popupRemovreInt.fadeOut();
                $scope.fecharPopupRemover();
            }
        });
        $scope.enter = function (keyEvent) {
            if (keyEvent.which === 13)
                $scope.avancar();
        };
        $('#idCodigoEtapaInput').bind('input propertychange', function () {
            var texto = $(this).val();
            texto = texto.replace(/[^\d]/g, '');
            $(this).val(texto);
        });
        $('#dvIntegracao').hide();
        $scope.integracaoDynamics365 = function (resource, tipo) {
            if ($scope.parteDoisDynamics && (resource === null || tipo === null)) {
                $scope.mostrarErroDynamics = true;
                return null;
            }
            if (tipo !== null) {
                gravarCookie("tipoDynamics", tipo);
            }
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/integracoes/dynamics365/auth?resource=").concat(resource),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                params: {
                    engageAutoSend: $scope.engageAutoSend
                }
            }).then(function (response) {
                $scope.parteDoisDynamics = true;
                if (resource != null) {
                    $scope.viewBtnAvancar = true;
                    var texto = 'Integrações | ' + ($scope.opcaoEditar ? 'Editou' : 'Integrou');
                    mixpanel.track(texto, {
                        Integração: $scope.integracaoFeita,
                        Local_IP: $rootScope.ip
                    });
                }
                else {
                    $rootScope.loaderAtivo = true;
                    $('#pagIntegracoes').addClass('blur');
                }
                $scope.winDynamics = window.open(response.data, '_blank');
                checarFecharJanela($scope.winDynamics, resource);
            });
        };
        buscarTodosDynamicsDoUsuario = function () {
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/integracoes/dynamics365/discover"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                }
            }).then(function (response) {
                $scope.dynamicsOrganizations = response.data;
                $rootScope.loaderAtivo = false;
                $('#pagIntegracoes').removeClass('blur');
            });
        };
        checarFecharJanela = function (janela, resource) {
            var interval = setInterval(function () {
                if (janela.closed === true) {
                    clearInterval(interval);
                    if (!resource) {
                        buscarTodosDynamicsDoUsuario();
                    }
                    else {
                        $scope.avancar();
                    }
                }
            }, 750);
        };
        $scope.organizationDynamicsChanged = function (organization) {
            if (organization !== null) {
                $scope.mostrarErroDynamics = false;
            }
        };
        $scope.tipoDynamicsChanged = function (tipo) {
            if (tipo !== null) {
                $scope.mostrarErroDynamics = false;
            }
        };
        resetaTelaDynamics = function () {
            $scope.winDynamics = null;
            $scope.parteDoisDynamics = false;
            $scope.dynamicsOrganizations = [];
            $scope.selectedUrlDynamics = null;
            $scope.tipoDynamics = null;
            $scope.mostrarErroDynamics = false;
        };
        $scope.consultarCapturasHoje = function () {
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/login/consultarContatosMixpanel"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto
                }
            }).then(function (rConsultarContatosMixpanel) {
                if (rConsultarContatosMixpanel.data) {
                    rConsultarContatosMixpanel.data = false;
                    mixpanel.track("Captura Extensão | O usuario capturou contatos hoje", {});
                }
            });
        };
        $scope.consultarCapturasHoje();
        $scope.verificarPermissaoEngage();
        $scope.integracaoNaoDisponivel();
    }]);
