angular.module('ramperv3')
    .controller('UpsellController', ['$scope', '$rootScope', 'UpsellService', '$location', function ($scope, $rootScope, UpsellService, $location) {
        var iframeSource = "https://www.youtube.com/embed/Cnp5b-GluBA";
        window.addEventListener("keydown", function (event) {
            if (event.key == 'Escape' && $('#upsellModal').on('hide.bs.modal')) {
                var modal = document.getElementById("upsellModal");
                $scope.fecharModal(modal);
            }
        });
        $scope.autoPlay = function () {
            $('#upsellModal').on('shown.bs.modal', function () {
                $("#upsellVideo").attr('src', iframeSource + "?autoplay=1&amp;rel=0;modestbranding=1&amp;showinfo=0");
            });
            $('#upsellModal').on('hide.bs.modal', function () {
                $('#upsellVideo').attr('src', iframeSource);
            });
        };
        $scope.criarModal = function () {
            var modal = document.getElementById("upsellModal");
            var modalDialog = document.getElementById("upsellModalDialog");
            var modalContent = document.getElementById("upsellModalContent");
            var modalBody = document.getElementById("upsellModalBody");
            var btnFechar = document.getElementById("modalClose");
            modal.classList.add("modal", "fade");
            modal.classList.remove("forceDisplay");
            modalDialog.classList.add("modal-dialog");
            modalContent.classList.add("modal-content");
            modalBody.classList.add("modal-body");
            btnFechar.classList.add("d-block");
            mixpanel.track("Growth002 | Clicou no vídeo", {
                "Local_IP": $rootScope.ip,
                "NomeEmpresa": $rootScope.empresaLogado,
                "EmailUsuario": $rootScope.emailUsuario
            });
        };
        $scope.fecharModal = function (element) {
            element.classList.remove("modal", "fade");
            $('#upsellModalDialog').removeClass("modal-dialog");
            $('#upsellModalContent').removeClass("modal-content");
            $('#upsellModalBody').removeClass("modal-body");
            $('#modalClose').removeClass("d-block");
            element.classList.add("forceDisplay");
        };
        $scope.gerarSolicitacaoUpsell = function () {
            var janelaConectar = $location.url();
            janelaConectar === "/chat" ? "conversação" : "ligação";
            mixpanel.track("Growth002 | Clicou na solicitação de upsell da janela de " + janelaConectar, {
                "Local_IP": $rootScope.ip,
                "NomeEmpresa": $rootScope.empresaLogado,
                "EmailUsuario": $rootScope.emailUsuario
            });
            UpsellService.solicitarUpsell();
        };
    }]);
