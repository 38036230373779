'use strict';
angular
    .module('ramperv3')
    .service('PermissionamentoService', ['$rootScope', 'LoginService', '$http', '$q', function ($rootScope, LoginService, $http, $q) {
        var self = this;
        function isObjFalsy(obj) {
            if (!obj) {
                return true;
            }
            return Object.entries(obj).length <= 0;
        }
        this.setPermissionamento = function (permissoes) {
            if (isObjFalsy(permissoes)) {
                return LoginService.logout();
            }
            $rootScope.permissoes = {};
            $rootScope.permissoes.tipoUsuario = permissoes.userType;
            $rootScope.permissoes.produtos = permissoes.products;
            $rootScope.permissoes.cadencias = permissoes.permissions.cadence;
            $rootScope.permissoes.respostas = permissoes.permissions.response;
            $rootScope.permissoes.relatorios = permissoes.permissions.report;
            $rootScope.permissoes.times = permissoes.permissions.team;
            $rootScope.permissoes.segmentacoes = permissoes.permissions.segmentation;
            $rootScope.permissoes.contatos = permissoes.permissions.contacts;
            $rootScope.permissoes.templates = permissoes.permissions.template;
            $rootScope.permissoes.configuracoes = permissoes.permissions.settings;
            $rootScope.permissoes.edools = permissoes.permissions.edools;
            $rootScope.permissoes.integracoes = permissoes.permissions.integration;
            $rootScope.permissoes.ligacoes = permissoes.permissions.call;
            $rootScope.permissoes.chat = permissoes.permissions.chat;
            $rootScope.permissoes.creditoPagamentos = permissoes.permissions.creditPayments;
            /* TODO: remover após o periodo de uso do banner */
            $rootScope.permissoes.banner = permissoes.permissions.banner;
        };
        this.fetchPermissionamento = function () {
            return $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/permissoes"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
            })
                .then(function (response) {
                self.setPermissionamento(response.data.user);
            })
                .catch(function (error) {
                return LoginService.logout();
            });
        };
        this.allowAccessToScreen = function (property) {
            var userNotAuthenticated = 'Not Authenticated';
            var userNotAllowed = 'Not Allowed';
            if (isObjFalsy($rootScope.permissoes)) {
                return $q.reject(userNotAuthenticated);
            }
            if (isObjFalsy($rootScope.permissoes[property])) {
                return $q.reject(userNotAllowed);
            }
            if ($rootScope.permissoes[property].screen === true) {
                return $q.resolve(true);
            }
            return $q.reject(userNotAllowed);
        };
        this.allowAccessToScreenAction = function (screen, action) {
            if (isObjFalsy($rootScope.permissoes)) {
                return false;
            }
            if (isObjFalsy($rootScope.permissoes[screen])) {
                return false;
            }
            if ($rootScope.permissoes[screen][action] === true) {
                return true;
            }
            return false;
        };
    }]);
