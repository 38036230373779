angular.module("ramperv3").service("MonsterMfaService", [
    "$http",
    "$rootScope",
    function ($http, $rootScope) {
        /**
         * Deve consumir a api para gerar a imagem base64 mfa
         */
        function gerarBase64Mfa() {
            return $http({ method: "POST", url: "".concat($rootScope.ramperV3ApiBaseUrl, "/login/mfa/register"), headers: { 'Authorization': 'Bearer ' + $rootScope.Token } });
        }
        /**
         * Deve consumir a api para gerar a imagem base64 mfa
         * @param token credenciais para validar mfa
         */
        function validarTokenMfa(token) {
            return $http({
                method: "POST",
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/login/mfa/validate"),
                headers: { 'Authorization': 'Bearer ' + $rootScope.Token },
                data: { token: token },
            });
        }
        /**
         * Deve consumir a api para remover mfa
         */
        function removerMfa() {
            return $http({
                method: "DELETE",
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "login/mfa"),
                headers: { 'Authorization': 'Bearer ' + $rootScope.Token }
            });
        }
        return { gerarBase64Mfa: gerarBase64Mfa, validarTokenMfa: validarTokenMfa, removerMfa: removerMfa };
    },
]);
