angular
    .module('ramperv3')
    .controller('ChatFromContatoController', ['$rootScope', '$scope', '$http', 'serviceWorkerService', function ($rootScope, $scope, $http, serviceWorkerService) {
        ga('set', 'page', document.location.hash);
        ga('send', 'pageview');
        $rootScope.mostrarMenu = false;
        $rootScope.loaderAtivo = false;
        var retry = true;
        var delay = 0;
        $scope.objectIdDisparo = null;
        $scope.vendedor = null;
        $scope.prospect = null;
        $scope.mensagemErro = '';
        $scope.getDisparoEmail = function () {
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/disparos/chat"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': '0881d3992f',
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                params: {
                    _id: objectId
                }
            })
                .then(function (res) {
                return res.data;
            })
                .then(function (infoChat) {
                $scope.erro = infoChat.err;
                if (!infoChat.err) {
                    $scope.objectIdDisparo = infoChat.objectIdDisparo;
                    $scope.vendedor = infoChat.vendedor;
                    $scope.prospect = infoChat.prospect;
                    $scope.iniciarSessaoChat($scope.vendedor, $scope.prospect, "Link no email");
                    $scope.mensagemErro = '';
                }
                else {
                    $scope.mensagemErro = infoChat.mensagem;
                }
            });
        };
        $scope.getUserAndProspect = function (objectIdLink, nomeProspect, emailProspect) {
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/chat/getInfo"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': '0881d3992f'
                },
                params: {
                    objectIdLink: objectIdLink,
                    nomeProspect: nomeProspect,
                    emailProspect: emailProspect
                }
            })
                .then(function (res) {
                return res.data;
            })
                .then(function (infoChat) {
                $scope.erro = infoChat.err;
                if (!infoChat.err) {
                    $scope.objectIdDisparo = infoChat.idConversa;
                    $scope.vendedor = infoChat.vendedor;
                    $scope.prospect = infoChat.prospect;
                    $scope.iniciarSessaoChat($scope.vendedor, $scope.prospect, "Link externo");
                    $scope.mensagemErro = '';
                }
                else {
                    $scope.mensagemErro = infoChat.mensagem;
                }
            });
        };
        $scope.iniciarSessaoChat = function (vendedor, prospect, origemLink) {
            serviceWorkerService.registerWorker()
                .then(function (swRegistration) {
                serviceWorkerService
                    .subscribeContato(swRegistration, prospect._id);
                mixpanel.identify(prospect.email);
                mixpanel.people.set({
                    "$email": prospect.email,
                    "$name": prospect.nome,
                    "Prospect": "Prospect",
                    "$created": '',
                    "Status": '',
                    "TipoUsuario": '',
                    "Envios": '',
                    "EnviosDisponiveis": '',
                    "Capturas": '',
                    "CapturasDisponiveis": '',
                    "Versao": 'V3',
                    "CRM": '',
                    "Pre Vendas": '',
                    "Inbound": '',
                    "Inadimplente": '',
                    "Dias inadimplente": '',
                    "SuperLogica": '',
                    "Cs Responsável": '',
                    "Intercom": '',
                    "UltimoLogin": '',
                    "UltimaCaptura": '',
                    "UltimoDisparo": '',
                    "UltimaResposta": '',
                    "UltimaCadenciaCriada": '',
                    "QuantidadeCadenciaAtiva": '',
                    "Plano": '',
                    "Origem do Link": origemLink
                });
                mixpanel.track("Prospect | Clicou na janela de conversação", {
                    "Local_IP": $rootScope.ip
                });
            });
            setTimeout(function () {
                Talk.ready
                    .then(function () {
                    var truncateString = function (str, num) {
                        if (str.length <= num) {
                            return str;
                        }
                        return str.slice(0, num) + '...';
                    };
                    var me = new Talk.User({
                        id: 'contato_' + prospect._id,
                        name: truncateString(prospect.nome, 30),
                        email: prospect.email,
                        role: 'prospect',
                        custom: { "objectIdDisparo": objectId }
                    });
                    window.talkSession = new Talk.Session({
                        // appId: 't928W8rK',//teste
                        appId: 'g1sFkEsj',
                        me: me,
                    });
                    // talkSession.syncThemeForLocalDev('public/css/talkjs-theme-contato.css');
                    talkSession.setDesktopNotificationEnabled(Notification.permission == "granted");
                    var other = new Talk.User({
                        id: 'vendedor_' + vendedor._id,
                        name: vendedor.nome,
                        email: vendedor.email,
                        welcomeMessage: 'Oi, tudo bem?\nObrigado por entrar em contato. Como posso te ajudar?'
                    });
                    var conversation = talkSession.getOrCreateConversation(Talk.oneOnOneId(me, other));
                    conversation.setParticipant(me);
                    conversation.setParticipant(other);
                    conversation.setAttributes({
                        subject: truncateString(prospect.empresa, 30)
                    });
                    var inbox = talkSession.createInbox({
                        selected: conversation,
                        chatSubtitleMode: null
                    });
                    inbox.mount(document.getElementById('talkjs-container-contato'));
                    var notificaUsuarioSemDireitoAoChat = function (mensagem) {
                        $http({
                            method: 'POST',
                            url: "".concat($rootScope.ramperV3ApiBaseUrl, "/chat/email/usuario-sem-chat"),
                            headers: {
                                'ramp3r-auth': 'ramp3r-authorization',
                                'ramp3r-l': '0881d3992f'
                            },
                            data: {
                                objectIdLogin: vendedor._id,
                                prospect: prospect,
                                mensagem: mensagem.message.text
                            }
                        }).then(function (resposta) { });
                    };
                    var notificaUsuarioAusente = function (mensagem) {
                        $http({
                            method: 'POST',
                            url: "".concat($rootScope.ramperV3ApiBaseUrl, "/chat/notificacao/ausencia"),
                            headers: {
                                'ramp3r-auth': 'ramp3r-authorization',
                                'ramp3r-l': '0881d3992f'
                            },
                            data: {
                                objectIdLogin: vendedor._id,
                                idConversa: mensagem.conversation.id,
                                mensagem: mensagem.message.text,
                                emailProspect: prospect.email
                            }
                        }).then(function (resposta) {
                            mixpanel.identify(prospect.email);
                            mixpanel.people.set({
                                "$email": prospect.email,
                                "$name": prospect.nome,
                                "Prospect": "Prospect",
                                "$created": '',
                                "Status": '',
                                "TipoUsuario": '',
                                "Envios": '',
                                "EnviosDisponiveis": '',
                                "Capturas": '',
                                "CapturasDisponiveis": '',
                                "Versao": 'V3',
                                "CRM": '',
                                "Pre Vendas": '',
                                "Inbound": '',
                                "Inadimplente": '',
                                "Dias inadimplente": '',
                                "SuperLogica": '',
                                "Cs Responsável": '',
                                "Intercom": '',
                                "UltimoLogin": '',
                                "UltimaCaptura": '',
                                "UltimoDisparo": '',
                                "UltimaResposta": '',
                                "UltimaCadenciaCriada": '',
                                "QuantidadeCadenciaAtiva": '',
                                "Plano": '',
                                "Origem do Link": origemLink
                            });
                            mixpanel.track("Prospect | Enviou mensagem no chat", {
                                "Local_IP": $rootScope.ip
                            });
                            //inbox.off("sendMessage", notificaUsuarioAusente)
                        });
                    };
                    inbox.on("sendMessage", notificaUsuarioSemDireitoAoChat);
                    inbox.on("sendMessage", notificaUsuarioAusente);
                    $scope.$apply();
                })
                    .catch(function (err) {
                    if (retry === true) {
                        retry = false;
                        delay = 1000;
                        $scope.iniciarSessaoChat(vendedor, prospect, origemLink);
                    }
                    else {
                        alert('algo deu errado, por favor aguarde alguns minutos e tente novamente');
                    }
                });
            }, 0 + delay);
        };
        var criarCookie = function (nomeProspect, emailProspect) {
            var _cookie = 'chatForm_ramper';
            gravarCookie(_cookie, nomeProspect +
                "#EMA_" + emailProspect, 1, document.domain.indexOf('localhost') !== -1 ? 'localhost' : '.ramper.com.br');
        };
        var enviarParaForm = function (objectIdLink) {
            $http({
                method: 'GET',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/chat/recuperarLink"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': '0881d3992f'
                },
                params: { objectIdLink: objectIdLink }
            })
                .then(function (res) {
                window.open(res.data, '_self');
            }).catch(function () {
                alert('algo deu errado, por favor aguarde alguns minutos e tente novamente');
            });
        };
        var verificarCokie = function (objectIdLink) {
            var _cookie = 'chatForm_ramper';
            var conteudoCookie = lerCookie(_cookie);
            if (conteudoCookie) {
                var nomeProspect = conteudoCookie.split('#EMA_')[0];
                var emailProspect = conteudoCookie.split('#EMA_')[1];
                criarCookie(nomeProspect, emailProspect);
                $scope.getUserAndProspect(objectIdLink, nomeProspect, emailProspect);
            }
            else {
                enviarParaForm(objectIdLink);
            }
        };
        var objectId = document.URL.split('chat/')[1];
        var urlParams = objectId.split('!');
        if (urlParams.length === 1) {
            $scope.getDisparoEmail();
        }
        else {
            var objectIdLink = urlParams[1];
            verificarCokie(objectIdLink);
        }
    }]);
