var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
angular.module('ramperv3')
    .component('monsterModalEnviarContatosIntegracao', {
    templateUrl: 'views/monsterModalEnviarContatosIntegracao.html',
    controller: ['$rootScope', '$scope', 'RamperIntegracao', 'RamperIntegracaoCategoria', 'MonsterEnviarContatosIntegracaoService', 'MonsterToasterService', function ($rootScope, $scope, RamperIntegracao, RamperIntegracaoCategoria, MonsterEnviarContatosIntegracaoService, MonsterToasterService) {
            var _this = this;
            $scope.$watch('$ctrl.mixpanelTitulo', function (mixpanelTitulo) { return $scope.mixpanelTitulo = mixpanelTitulo; });
            $scope.$watch('$ctrl.categoria', function (categoria) { return $scope.categoria = RamperIntegracaoCategoria[categoria]; });
            $scope.$watch('$ctrl.apiKey', function (apiKey) { return $scope.apiKey = apiKey; });
            $scope.$watch('$ctrl.parametros', function (parametros) { return $scope.parametros = parametros; });
            $scope.$watch('$ctrl.contatoIds', function (contatoIds) { contatoIds ? $scope.contatoIds = contatoIds : $scope.contatoIds = []; });
            $scope.$watch('$ctrl.tipo', function (tipo) { return $scope.tipo = tipo; });
            $scope.$watch('$ctrl.tipo', function (tipo) { return $scope.nomeIntegracao = RamperIntegracao[tipo]; });
            $scope.$watch('$ctrl.abrir', function (abrir) { return $scope.abrirModal(abrir); });
            /**
             * Deve abrir a modal e carregar os dados quando necessário
             */
            $scope.abrirModal = function (abrir) { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    $scope.abrir = abrir;
                    if (abrir && $scope.tipo == 'P') {
                        $scope.obterOpcoesPipedrive();
                    }
                    if (abrir && $scope.tipo == 'linkseller') {
                        $scope.obterOpcoesPipeline();
                    }
                    if (abrir && $scope.tipo == 'D365') {
                        $scope.definirFormDynamics365();
                    }
                    return [2 /*return*/];
                });
            }); };
            $scope.fecharModal = function (status) {
                $scope.$ctrl.abrir = false;
                $scope.$ctrl.onClose({ status: status, contatoIds: $scope.contatoIds });
                setTimeout(function () {
                    $scope.formPipeDriveData = { pipelineId: null, stageId: null, userId: null, salvar: false };
                    $scope.formPipelineData = { idUsuario: null, idPipeline: null, idEstagio: null, salvar: false };
                    $scope.formDynamics365Data = { tipo: null, salvar: false };
                    var formPipeDrive = $scope.formPipeDrive;
                    angular.forEach(formPipeDrive.$$controls, function (control) {
                        var fieldElement = angular.element(control.$$element);
                        fieldElement.attr('dirty', 'false');
                    });
                    var formPipeline = $scope.formPipeline;
                    angular.forEach(formPipeline.$$controls, function (control) {
                        var fieldElement = angular.element(control.$$element);
                        fieldElement.attr('dirty', 'false');
                    });
                    var formDynamics365 = $scope.formDynamics365;
                    angular.forEach(formDynamics365.$$controls, function (control) {
                        var fieldElement = angular.element(control.$$element);
                        fieldElement.attr('dirty', 'false');
                    });
                }, 50);
            };
            $scope.enviarContatoCRM = function () {
                $rootScope.monsterShowLoading = true;
                MonsterEnviarContatosIntegracaoService.enviarContatoCRM({ application: $scope.tipo, apiKey: $scope.apiKey }, $scope.contatoIds, $scope.parametros).then(function () {
                    MonsterToasterService.showSuccessToaster("", "".concat($scope.contatoIds.length > 1 ? 'Contatos enviados' : 'Contato enviado', " com sucesso!"), 6000, 350);
                    mixpanel.track("".concat($scope.mixpanelTitulo, " | Enviou contato para a integra\u00E7\u00E3o com o ").concat($scope.categoria), { Local_IP: $rootScope.ip });
                    $rootScope.monsterShowLoading = false;
                    $scope.fecharModal('Enviado');
                }, function () {
                    MonsterToasterService.showErrorToaster("", "Erro ao enviar ".concat($scope.contatoIds.length > 1 ? 'contatos' : 'contato', " para ").concat($scope.nomeIntegracao, ". Tente novamente."), 6000);
                    $rootScope.monsterShowLoading = false;
                });
            };
            $scope.publicarContatoCRM = function (data) {
                $rootScope.monsterShowLoading = true;
                MonsterEnviarContatosIntegracaoService.publicarContatoCRM({ application: $scope.tipo }, $scope.contatoIds, $scope.parametros, data).then(function () {
                    MonsterToasterService.showSuccessToaster("", "".concat($scope.contatoIds.length > 1 ? 'Contatos enviados' : 'Contato enviado', " com sucesso!"), 6000, 350);
                    mixpanel.track("".concat($scope.mixpanelTitulo, " | Enviou contato para a integra\u00E7\u00E3o com o ").concat($scope.categoria), { Local_IP: $rootScope.ip });
                    $rootScope.monsterShowLoading = false;
                    $scope.fecharModal('Enviado');
                }, function () {
                    MonsterToasterService.showErrorToaster("", "Erro ao enviar ".concat($scope.contatoIds.length > 1 ? 'contatos' : 'contato', " para ").concat($scope.nomeIntegracao, ". Tente novamente."), 6000);
                    $rootScope.monsterShowLoading = false;
                });
            };
            //========================================================================//
            //=============================PIPELINE===================================//
            //========================================================================//
            $scope.formPipelineData = { idUsuario: null, idPipeline: null, idEstagio: null, salvar: false };
            /**
             * Deve obter os dados do formulário e salvar quando necessário
             */
            $scope.enviarContatoPipeline = function () { return __awaiter(_this, void 0, void 0, function () {
                var apiKey, idUsuario, idPipeline, idEstagio;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            apiKey = $scope.apiKey;
                            idUsuario = $scope.formPipelineData.idUsuario.value;
                            idPipeline = $scope.formPipelineData.idPipeline.value;
                            idEstagio = $scope.formPipelineData.idEstagio.value;
                            if (!$scope.formPipelineData.salvar) return [3 /*break*/, 2];
                            $rootScope.monsterShowLoading = true;
                            return [4 /*yield*/, MonsterEnviarContatosIntegracaoService.salvarOpcoesPipeline(apiKey, idUsuario, idPipeline, idEstagio)];
                        case 1:
                            _a.sent();
                            _a.label = 2;
                        case 2:
                            $scope.publicarContatoCRM({ idPipeline: idPipeline, idEstagio: idEstagio, idUsuario: idUsuario });
                            return [2 /*return*/];
                    }
                });
            }); };
            /**
             * Deve obter o dados da integração os dados do formulário
             */
            $scope.obterOpcoesPipeline = function () { return __awaiter(_this, void 0, void 0, function () {
                var _a, integracao, respostaUsuariosPromise, respostaPipelinesPromise, respostaEstagiosPromise, _b, respostaUsuarios, respostaPipelines, respostaEstagios, error_1;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            $rootScope.monsterShowLoading = true;
                            _c.label = 1;
                        case 1:
                            _c.trys.push([1, 4, , 5]);
                            _a = $scope;
                            return [4 /*yield*/, MonsterEnviarContatosIntegracaoService.obterIntegracoes()];
                        case 2:
                            _a.integracoes = (_c.sent()).data;
                            integracao = $scope.integracoes.filter(function (integracao) { return integracao.application == $scope.tipo; })[0];
                            respostaUsuariosPromise = MonsterEnviarContatosIntegracaoService.obterUsuariosPipeline($scope.apiKey);
                            respostaPipelinesPromise = MonsterEnviarContatosIntegracaoService.obterPipelinesPipeline($scope.apiKey);
                            respostaEstagiosPromise = MonsterEnviarContatosIntegracaoService.obterEstagiosPipeline($scope.apiKey, integracao.aliasLinkseller.idPipeline);
                            return [4 /*yield*/, Promise.all([respostaUsuariosPromise, respostaPipelinesPromise, respostaEstagiosPromise])];
                        case 3:
                            _b = _c.sent(), respostaUsuarios = _b[0], respostaPipelines = _b[1], respostaEstagios = _b[2];
                            $scope.pipeline = {
                                usuarios: respostaUsuarios.data.map(function (usuario) {
                                    return { value: usuario.id, option: usuario.email };
                                }),
                                pipelines: respostaPipelines.data.map(function (pipeline) {
                                    return { value: pipeline.id, option: pipeline.name };
                                }),
                                estagios: respostaEstagios.data.map(function (estagio) {
                                    return { value: estagio.id, option: estagio.name };
                                }),
                            };
                            $rootScope.monsterShowLoading = false;
                            $scope.definirFormPipeline();
                            $scope.$apply();
                            return [3 /*break*/, 5];
                        case 4:
                            error_1 = _c.sent();
                            MonsterToasterService.showErrorToaster("", "Erro ao obter as usu\u00E1rios do Pipeline. Tente novamente.", 6000);
                            $rootScope.monsterShowLoading = false;
                            return [3 /*break*/, 5];
                        case 5: return [2 /*return*/];
                    }
                });
            }); };
            /**
             * Deve retornar uma lista de usuários
             */
            $scope.obterListaUsuarioParaPipeline = function () {
                if ($scope.pipeline && $scope.pipeline.usuarios) {
                    return $scope.pipeline.usuarios;
                }
            };
            /**
             * Deve retornar uma lista de pipelines
             */
            $scope.obterListaPipelineParaPipeline = function () {
                if ($scope.pipeline && $scope.pipeline.pipelines) {
                    return $scope.pipeline.pipelines;
                }
            };
            /**
             * Deve retornar uma lista de estágios
             */
            $scope.obterListaEtapaFunilParaPipeline = function () {
                if ($scope.pipeline && $scope.pipeline.estagios) {
                    return $scope.pipeline.estagios;
                }
            };
            /**
             * Deve preencher os dados do formulário
             */
            $scope.definirFormPipeline = function () { return __awaiter(_this, void 0, void 0, function () {
                var integracao, idUsuario, idPipeline, idEstagio;
                return __generator(this, function (_a) {
                    integracao = $scope.integracoes.filter(function (integracao) { return integracao.application == $scope.tipo; })[0];
                    idUsuario = $scope.pipeline.usuarios.filter(function (usuario) { return usuario.value == integracao.aliasLinkseller.idUsuarioLinkseller; })[0];
                    idPipeline = $scope.pipeline.pipelines.filter(function (pipeline) { return pipeline.value == integracao.aliasLinkseller.idPipeline; })[0];
                    idEstagio = $scope.pipeline.estagios.filter(function (estagio) { return estagio.value == integracao.aliasLinkseller.idEstagio; })[0];
                    $scope.formPipelineData = { idUsuario: idUsuario, idPipeline: idPipeline, idEstagio: idEstagio, salvar: false };
                    return [2 /*return*/];
                });
            }); };
            /**
             * Deve limpar os estagio ao selecionar o novo pipeline
             */
            $scope.onFunilPipelineChange = function () { return __awaiter(_this, void 0, void 0, function () {
                var respostaEstagios;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            $rootScope.monsterShowLoading = true;
                            $scope.formPipelineData.idEstagio = null;
                            return [4 /*yield*/, MonsterEnviarContatosIntegracaoService.obterEstagiosPipeline($scope.apiKey, $scope.formPipelineData.idPipeline.value)];
                        case 1:
                            respostaEstagios = _a.sent();
                            $scope.pipeline.estagios = respostaEstagios.data.map(function (estagio) {
                                return { value: estagio.id, option: estagio.name };
                            });
                            $rootScope.monsterShowLoading = false;
                            $scope.$apply();
                            return [2 /*return*/];
                    }
                });
            }); };
            //========================================================================//
            //===========================DYNAMICS 365=================================//
            //========================================================================//
            $scope.formDynamics365Data = { tipo: null, salvar: false };
            /**
             * Deve obter os dados do formulário e salvar quando necessário
             */
            $scope.enviarContatoDynamics365 = function () { return __awaiter(_this, void 0, void 0, function () {
                var tipo, salvar;
                return __generator(this, function (_a) {
                    tipo = $scope.formDynamics365Data.tipo.value;
                    salvar = $scope.formDynamics365Data.salvar;
                    $scope.parametros.opcoesDynamics = { tipo: tipo, salvar: salvar };
                    $scope.enviarContatoCRM();
                    return [2 /*return*/];
                });
            }); };
            /**
             * Deve retornar uma lista de tipos
             */
            $scope.obterListaTiposParaDynamics365 = function () {
                return [{ value: 'leads', option: 'Leads' }, { value: 'contacts', option: 'Contatos' }];
            };
            /**
             * Deve preencher os dados do formulário
             */
            $scope.definirFormDynamics365 = function () { return __awaiter(_this, void 0, void 0, function () {
                var response_1, tipo, error_2;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            $rootScope.monsterShowLoading = true;
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, , 4]);
                            return [4 /*yield*/, MonsterEnviarContatosIntegracaoService.obterDadosDynamics365()];
                        case 2:
                            response_1 = _a.sent();
                            tipo = $scope.obterListaTiposParaDynamics365().filter(function (tipo) { return tipo.value == response_1.data.tipo; })[0];
                            $scope.formDynamics365Data = { tipo: tipo, salvar: false };
                            $rootScope.monsterShowLoading = false;
                            $scope.$apply();
                            return [3 /*break*/, 4];
                        case 3:
                            error_2 = _a.sent();
                            MonsterToasterService.showErrorToaster("", "Erro ao obter os dados do Dynamics 365. Tente novamente.", 6000);
                            $rootScope.monsterShowLoading = false;
                            return [3 /*break*/, 4];
                        case 4: return [2 /*return*/];
                    }
                });
            }); };
            //========================================================================//
            //=============================PIPEDRIVE==================================//
            //========================================================================//
            $scope.formPipeDriveData = { pipelineId: null, stageId: null, userId: null, salvar: false };
            /**
             * Deve obter os dados do formulário e salvar quando necessário
             */
            $scope.enviarContatoPipeDrive = function () { return __awaiter(_this, void 0, void 0, function () {
                var apiKey, pipelineId, stageId, userId;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            apiKey = $scope.apiKey;
                            pipelineId = $scope.formPipeDriveData.pipelineId.value;
                            stageId = $scope.formPipeDriveData.stageId.value;
                            userId = $scope.formPipeDriveData.userId.value;
                            $scope.parametros.pipelineOptions = { pipelineId: pipelineId, stageId: stageId, userId: userId };
                            if (!$scope.formPipeDriveData.salvar) return [3 /*break*/, 2];
                            $rootScope.monsterShowLoading = true;
                            return [4 /*yield*/, MonsterEnviarContatosIntegracaoService.salvarOpcoesPipedrive(apiKey, pipelineId, stageId, userId)];
                        case 1:
                            _a.sent();
                            _a.label = 2;
                        case 2:
                            $scope.enviarContatoCRM();
                            return [2 /*return*/];
                    }
                });
            }); };
            /**
             * Deve obter o dados da integração os dados do formulário
             */
            $scope.obterOpcoesPipedrive = function () { return __awaiter(_this, void 0, void 0, function () {
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            $rootScope.monsterShowLoading = true;
                            return [4 /*yield*/, MonsterEnviarContatosIntegracaoService.obterOpcoesPipedrive($scope.apiKey).then(function (result) { return __awaiter(_this, void 0, void 0, function () {
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0:
                                                $scope.pipedrive = {
                                                    pipelines: result.data.pipelines.map(function (pipeline) {
                                                        return { value: pipeline.id, option: pipeline.name };
                                                    }),
                                                    estagios: result.data.estagios.map(function (estagio) {
                                                        return { value: estagio.id, option: estagio.name, pipelineId: estagio.pipeline_id };
                                                    }),
                                                    usuarios: result.data.usuarios.map(function (usuario) {
                                                        return { value: usuario.id, option: usuario.name };
                                                    })
                                                };
                                                return [4 /*yield*/, $scope.definirFormPipeDrive()];
                                            case 1:
                                                _a.sent();
                                                $rootScope.monsterShowLoading = false;
                                                return [2 /*return*/];
                                        }
                                    });
                                }); }, function () {
                                    MonsterToasterService.showErrorToaster("", "Erro ao obter as op\u00E7\u00F5es do Pipedrive. Tente novamente.", 6000);
                                    $rootScope.monsterShowLoading = false;
                                })];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            }); };
            /**
             * Deve retornar uma lista de pipelines
             */
            $scope.obterListaPipelineParaPipeDriver = function () {
                if ($scope.pipedrive && $scope.pipedrive.pipelines) {
                    return $scope.pipedrive.pipelines;
                }
            };
            /**
             * Deve retornar uma lista de estágios filtrados por pipeline
             */
            $scope.obterListaEstagioParaPipeDriver = function () {
                if ($scope.pipedrive && $scope.pipedrive.estagios) {
                    return $scope.formPipeDriveData.pipelineId ? $scope.pipedrive.estagios.filter(function (estagio) { return estagio.pipelineId == $scope.formPipeDriveData.pipelineId.value; }) : $scope.pipedrive.estagios;
                }
            };
            /**
             * Deve retornar uma lista de pipelines
             */
            $scope.obterListaUsuarioParaPipeDriver = function () {
                if ($scope.pipedrive && $scope.pipedrive.usuarios) {
                    return $scope.pipedrive.usuarios;
                }
            };
            /**
             * Deve preencher os dados do formulário
             */
            $scope.definirFormPipeDrive = function () { return __awaiter(_this, void 0, void 0, function () {
                var result, integracao, pipelineId, stageId, userId;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, MonsterEnviarContatosIntegracaoService.obterIntegracoes()];
                        case 1:
                            result = _a.sent();
                            $scope.integracoes = result.data;
                            integracao = $scope.integracoes.filter(function (integracao) { return integracao.application == $scope.tipo; })[0];
                            pipelineId = $scope.pipedrive.pipelines.filter(function (pipeline) { return pipeline.value == integracao.aliasPipedrives.pipelineId; })[0];
                            stageId = $scope.pipedrive.estagios.filter(function (estagio) { return estagio.value == integracao.aliasPipedrives.stageId; })[0];
                            userId = $scope.pipedrive.usuarios.filter(function (usuario) { return usuario.value == integracao.aliasPipedrives.userId; })[0];
                            $scope.formPipeDriveData = { pipelineId: pipelineId, stageId: stageId, userId: userId, salvar: false };
                            return [2 /*return*/];
                    }
                });
            }); };
            /**
             * Deve limpar os estagio ao selecionar o novo pipeline
             */
            $scope.onPipelinePipedriveChange = function () {
                $scope.formPipeDriveData.stageId = null;
            };
        }],
    transclude: true,
    bindings: {
        abrir: '=',
        tipo: '<',
        categoria: '<',
        mixpanelTitulo: '@',
        contatoIds: '<',
        parametros: '<',
        apiKey: '<',
        onClose: '&'
    }
});
