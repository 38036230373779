angular.module('ramperv3')
    .component('adicionarSegmentacao', {
    templateUrl: 'views/adicionarSegmentacao.html',
    controller: ['$rootScope', '$scope', '$http', function ($rootScope, $scope, $http) {
            $ctrl = this;
            $scope.bucket = $rootScope.bucket;
            $scope.removerDivErro = function () {
                $('#filtro1').removeClass('divBrodaCorErro');
                $('#filtro2').removeClass('divBrodaCorErro');
                $('#filtro3').removeClass('divBrodaCorErro');
                $('#txtMsgErroSegmentacaoFiltro').fadeOut();
            };
            $scope.habilitarBtnSalvar = function () {
                $scope.viewBtnSalvarSegSimples = !!$scope.nomeNovaSegmentacao;
            };
            $scope.limparVariaveis = function () {
                $scope.nomeNovaSegmentacao = '';
                $scope.nomeNovaSegmentacaoFiltro = '';
                $scope.viewBtnSalvarSegSimples = false;
                $scope.nomeBtnAvancar = 'AVANÇAR';
                $scope.inptRenomearSegmentacao = '';
                $scope.divSelecionada = 0;
                $('#filtro1').removeClass('divSelectFiltrosSelecionado').addClass('divSelectFiltros');
                $('#filtro2').removeClass('divSelectFiltrosSelecionado').addClass('divSelectFiltros');
                $('#filtro3').removeClass('divSelectFiltrosSelecionado').addClass('divSelectFiltros');
                $('#nomeNovaSegmentacaoFiltro').removeClass('corTextoErro');
                $('#divNomeSegFiltro').removeClass('corTextoErro');
                $('#dvMensagemNomeSegmentacaoFiltro').fadeOut();
                $('#inptRenomearSegmentacao').removeClass('corTextoErro');
                $('#divRenomerSeg').removeClass('corTextoErro');
                $scope.removerDivErro();
            };
            $scope.fecharAdicionar = function () {
                $('#dvAdicionarSegmentacoes').fadeOut();
                $scope.limparVariaveis();
                setTimeout(function () {
                    $ctrl.close();
                }, 500);
            };
            $scope.fecharSegmentacaoSimples = function () {
                $('#formNovaSegmentacaoSimples').fadeOut();
                $scope.fecharAdicionar();
            };
            $scope.adiconarSegmentacoesNextStep = function (tipoAdicionar) {
                $scope.tipoAdicionar = tipoAdicionar;
                if (tipoAdicionar == 'filtro') {
                    $scope.descricaoAdicionar = 'Crie uma segmentação através dos filtros avançados';
                    _countDiv = 1;
                    $scope.showEtapa(_countDiv);
                    $('#formNovaSegmentacaoFiltro').fadeIn();
                    $('#voltarCriacaoSegFiltro').hide();
                }
                else if (tipoAdicionar == 'simples') {
                    $scope.descricaoAdicionar = 'Crie uma nova segmentação e importe contatos manualmente';
                    $('#formNovaSegmentacaoSimples').fadeIn();
                    $('#telaUmAddSegSimples').show();
                    $('#telaDoisAddSegSimples').hide();
                    setTimeout(function () { $('#idNomeNovaSegmentacao').focus(); }, 10);
                }
            };
            $scope.esconderEtapas = function () {
                $('#passo1').hide();
                $('#passo2').hide();
                $('#passo3').hide();
                $('#passo4').hide();
            };
            $scope.adicionarSegmentacao = function () {
                $scope.tipoAdicionar = '';
                $scope.tituloAdicionar = 'Criação de segmentação';
                $scope.descricaoAdicionar = 'Crie uma segmentação e já inclua contatos com as configurações avançadas.';
                $('#dvAdicionarSegmentacoes').fadeIn();
            };
            $scope.voltarCriarSegmentacao = function () {
                $('#dvAdicionarSegmentacoes').hide();
                $('#formNovaSegmentacaoSimples').hide();
                $scope.limparVariaveis();
                $scope.adicionarSegmentacao();
            };
            $scope.recarregarPagina = function () {
                $scope.txtNomeFiltros = '';
                $scope.semSegmentacoesFiltro = false;
                $scope.semSegmentacoesBusca = false;
                $scope.semSegmentacoes = false;
                $scope.campoFiltroFiltro = false;
                $scope.campoBuscaFiltro = false;
                $scope.buscarSegmentacoes = '';
                $('#idBtnFiltroSegmentacoes').removeClass().addClass('btnVoltarPadroNovoFiltro');
                $('#idImgFiltrarSeg').attr('src', "".concat($rootScope.bucket, "/filtro.png"));
            };
            $scope.salvarSegmentacoes = function (pDados) {
                if (pDados == 'simples') {
                    mixpanel.track("Segmentação | Criou segmentação simples", { "Nome": $scope.nomeNovaSegmentacao, "Local_IP": $rootScope.ip });
                    $http({
                        method: 'POST',
                        url: "".concat($rootScope.ramperV3ApiBaseUrl, "/segmentacoes/salvarSegmentacao"),
                        headers: {
                            'ramp3r-auth': 'ramp3r-authorization',
                            'ramp3r-l': $rootScope.IDCrypto,
                            'Authorization': 'Bearer ' + $rootScope.Token
                        },
                        params: {
                            nome: $scope.nomeNovaSegmentacao,
                            origem: "S"
                        }
                    }).then(function (rRetorno) {
                        $scope.recarregarPagina();
                    });
                    $('#telaUmAddSegSimples').hide();
                    $('#telaDoisAddSegSimples').fadeIn();
                }
            };
            $scope.showEtapa = function (etapa) {
                $scope.esconderEtapas();
                switch (etapa) {
                    case 1:
                        $scope.coresPassoPassoInicial();
                        $('#voltarCriacaoSegFiltro').hide();
                        break;
                    case 2:
                        $('#dvTraco1').removeClass('dvTracoAzulPassoPasso').addClass('dvTracoVerdePassoPasso');
                        $('#spanInicioManual').removeClass('txtAzulPassoPasso').addClass('txtVerdePassoPasso');
                        $('#imgPasso1').attr('src', "".concat($rootScope.bucket, "/ramper-v-claro-15.png"));
                        $('#dvTraco2').removeClass('dvTracoCinzaPassoPasso').addClass('dvTracoAzulPassoPasso');
                        $('#spanConfiguracao').removeClass('txtCinzaPassoPasso').addClass('txtAzulPassoPasso');
                        $('#dvTraco2').removeClass('dvTracoVerdePassoPasso').addClass('dvTracoAzulPassoPasso');
                        $('#spanConfiguracao').removeClass('txtVerdePassoPasso').addClass('txtAzulPassoPasso');
                        $('#imgPasso2').attr('src', "".concat($rootScope.bucket, "/ramper-v-escuro-15.png"));
                        $('#dvTraco3').removeClass('dvTracoAzulPassoPasso').addClass('dvTracoCinzaPassoPasso');
                        $('#spanFinalizacaoManual').removeClass('txtAzulPassoPasso').addClass('txtCinzaPassoPasso');
                        $('#imgPasso3').attr('src', "".concat($rootScope.bucket, "/ramper-cinza-15.png"));
                        break;
                    case 3:
                        $('#dvTraco2').removeClass('dvTracoAzulPassoPasso').addClass('dvTracoVerdePassoPasso');
                        $('#spanConfiguracao').removeClass('txtAzulPassoPasso').addClass('txtVerdePassoPasso');
                        $('#imgPasso2').attr('src', "".concat($rootScope.bucket, "/ramper-v-claro-15.png"));
                        $('#dvTraco3').removeClass('dvTracoCinzaPassoPasso').addClass('dvTracoAzulPassoPasso');
                        $('#spanFinalizacaoManual').removeClass('txtCinzaPassoPasso').addClass('txtAzulPassoPasso');
                        $('#imgPasso3').attr('src', "".concat($rootScope.bucket, "/ramper-v-escuro-15.png"));
                        break;
                    default:
                        return;
                }
                $('#passo' + etapa.toString()).fadeIn();
            };
            $ctrl.$onInit = function () {
                $scope.tipoAdicionarSegmentacao = '';
                $scope.tituloAdicionar = 'Criação de segmentação';
                $scope.descricaoAdicionar = 'Crie uma segmentação e já inclua contatos com as configurações avançadas.';
                $('#dvAdicionarSegmentacoes').fadeIn();
            };
        }],
    bindings: {
        close: '&'
    }
});
