'use strict';
angular
    .module('ramperv3')
    .service('UpsellService', ['$http', '$rootScope', function ($http, $rootScope) {
        function solicitarUpsell() {
            var nomeTime = $rootScope.Login.times[0].nomeTime;
            var emailUsuario = $rootScope.emailUsuario;
            var empresa = $rootScope.empresaLogado;
            var objectIdTime = $rootScope.Login.times[0]._id;
            var upsell = true;
            $http({
                method: 'POST',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/createUpsell"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                data: {
                    usuario: emailUsuario,
                    nomeTime: nomeTime,
                    empresa: empresa,
                    objectIdTime: objectIdTime,
                    upsell: upsell
                }
            }).then(function (res) {
                $rootScope.habilitarUpsell = false;
                $rootScope.exibirExperimentoUpsell = true;
            });
        }
        function solicitarListaUpsell() {
            var nomeTime = $rootScope.Login.times[0].nomeTime;
            var emailUsuario = $rootScope.emailUsuario;
            var empresa = $rootScope.empresaLogado;
            $http({
                method: 'POST',
                url: "".concat($rootScope.ramperV3ApiBaseUrl, "/upsell/solicitar"),
                headers: {
                    'ramp3r-auth': 'ramp3r-authorization',
                    'ramp3r-l': $rootScope.IDCrypto,
                    'Authorization': 'Bearer ' + $rootScope.Token
                },
                data: {
                    usuario: emailUsuario,
                    nomeTime: nomeTime,
                    empresa: empresa
                }
            }).then(function (res) {
                $rootScope.habilitarUpsell = false;
                $rootScope.exibirExperimentoUpsell = true;
            });
        }
        return {
            solicitarUpsell: solicitarUpsell,
            solicitarListaUpsell: solicitarListaUpsell
        };
    }]);
