'use strict';
angular
    .module('ramperv3')
    .service('JanelaDeLigacaoService', ['$rootScope', '$http', '$q', function ($rootScope, $http, $q) {
        this.buscarParametros = function () {
            return $q(function (resolve, reject) {
                $http({
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + $rootScope.Token
                    },
                    url: "".concat($rootScope.ramperV3ApiBaseUrl, "/usuarios/").concat($rootScope.DadosCadastrais.objectIdLogin, "/janela-de-ligacao/parametros")
                })
                    .then(function (response) { return resolve(response.data); })
                    .catch(function (response) { return reject(response.data); });
            });
        };
        this.atualizarParametros = function (data) {
            return $q(function (resolve, reject) {
                $http({
                    method: 'PUT',
                    headers: {
                        'Authorization': 'Bearer ' + $rootScope.Token
                    },
                    url: "".concat($rootScope.ramperV3ApiBaseUrl, "/usuarios/").concat($rootScope.DadosCadastrais.objectIdLogin, "/janela-de-ligacao/parametros"),
                    data: data,
                })
                    .then(function (response) { return resolve(response.data); })
                    .catch(function (response) { return reject(response.data); });
            });
        };
        this.removerParametros = function () {
            return $q(function (resolve, reject) {
                $http({
                    method: 'DELETE',
                    headers: {
                        'Authorization': 'Bearer ' + $rootScope.Token
                    },
                    url: "".concat($rootScope.ramperV3ApiBaseUrl, "/usuarios/").concat($rootScope.DadosCadastrais.objectIdLogin, "/janela-de-ligacao/parametros")
                })
                    .then(function (response) { return resolve(response.data); })
                    .catch(function (response) { return reject(response.data); });
            });
        };
        this.buscarOportunidades = function (tipoDaChave, abriuEmail, periodo, janelaAtencao) {
            return $q(function (resolve, reject) {
                $http({
                    method: 'GET',
                    url: "".concat($rootScope.ramperV3ApiBaseUrl, "/conexao/lista-de-oportunidades"),
                    headers: {
                        'Authorization': 'Bearer ' + $rootScope.Token
                    },
                    params: {
                        tipoDaChave: tipoDaChave,
                        abriuEmail: abriuEmail,
                        periodo: periodo,
                        janelaAtencao: janelaAtencao,
                    }
                })
                    .then(function (response) { return resolve(response.data); })
                    .catch(function (response) { return reject(response.data); });
            });
        };
    }]);
